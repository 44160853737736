import { Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider, Modal } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import Upload from "@mui/icons-material/AddSharp";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import RepeatCampaign from "../../components/repeatCampaign";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StatBoxnom from "../../components/StatBoxnom";  
 import Grid from "@mui/material/Grid";
 import SearchIcon from "@mui/icons-material/Search";
 import { FormattedMessage } from 'react-intl';
 import { useParams } from "react-router-dom";
 import Paper from '@mui/material/Paper'; // Import the Paper component
 import { uniqBy } from 'lodash';
 import HourglassTop from "@mui/icons-material/HourglassTop";
 import EmailIcon from "@mui/icons-material/Email";
 import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';


const Campaigns = ({}) => {
  const [openModal, setOpenModal] = useState(false);
  const [copiedSnackbarOpen, setCopiedSnackbarOpen] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const { campaignid } = useParams();
  const [campaignData, setCampaignData] = useState([]);
  const [filteredDateRangeData, setFilteredDateRangeData] = useState([]);
  const [dateRangeFilter, setDateRangeFilter] = useState([]);
  const [filteredDataWithType, setFilteredDataWithType] = useState([]);
  const [filteredDataWithdiferType, setFilteredDataWithdiferType] = useState([]);
const location = useLocation();
const idcontrato = useSelector((state) => state.user.idcontrato);
const [editedRows, setEditedRows] = useState({});
const theme = useTheme();
const colors = tokens(theme.palette.mode);
const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
const [data, setData] = useState([]); // Updated: Initialize data state
const [user, setUser] = useState([]); // Updated: Initialize data state
const [userData, setUserData] = useState([]); // Updated: Initialize user data state
const navigate = useNavigate();
const dispatch = useDispatch();
const token = useSelector((state) => state.token);
const loggedInUserId = useSelector((state) => state.user._id);
const { palette } = useTheme();
const main = palette.neutral.main;
const [filteredSearchData, setFilteredSearchData] = useState([]);
const { REACT_APP_API_ENDPOINT } = process.env;
const loggedinuserprofile = useSelector((state) => state.user.userprofile);
const [isFormSubmitted, setIsFormSubmitted] = useState(false);
const [dateRange, setDateRange] = useState([0, 100]);
const [isLoading, setIsLoading] = useState(true);
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [showSuccessPopup, setShowSuccessPopup] = useState(false);
const userProfile = useSelector((state) => state.user.userprofile);
const [exportedData, setExportedData] = useState(null);
const [searchInput, setSearchInput] = useState("");
const [totalcam, setTotalcam] = useState(0);
const [idcontratos, setIdcontratos] = useState([]);
const [modalOpen, setModalOpen] = useState(false);
const [modalContent, setModalContent] = useState('');
const [filteredEmails, setFilteredEmails] = useState([]);

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreateCampaignClick = () => {
    setShowCreateCampaign((prevState) => !prevState);
  };


  const copyEmailsToClipboard = (emails) => {
  
    // Check if 'emails' is an array
    if (!Array.isArray(emails)) {
      console.error("Error: 'emails' is not an array");
      return;
    }
  
    const emailsString = emails
      .filter((row) => row.user && row.user.email)
      .map((row) => row.user.email)
      .join(', ');
  };
  
const fetchCampaignData = async () => {
  try {
    const response1 = await fetch(`${REACT_APP_API_ENDPOINT}posts/campaign/${campaignid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response1.ok) {
      throw new Error(`API request failed with status: ${response1.status}`);
    }

    const jsonData = await response1.json();
    setCampaignData(jsonData);
  } catch (error) {
    console.error("Error fetching campaign data:", error);
  }
};

const fetchData = async () => {
  try {


    let endpoint = `${REACT_APP_API_ENDPOINT}session/${loggedInUserId}`;
    // Check the user profile and update the endpoint accordingly
    if (userProfile === "superadmin") {
      endpoint = `${REACT_APP_API_ENDPOINT}session/`;
    }

    if (userProfile === "admin"|| userProfile === "coadmin" || userProfile === "aprobador" ) {
      endpoint = `${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`;
    }
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const formData = await response.json();
    const dataWithFormattedDate = formData.map((row) => {
      const formattedRow = {
        ...row,
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
        user: row.user, // Ensure user information is included
      };
      return formattedRow;
    });
    setFilteredDateRangeData(dataWithFormattedDate);
   

    setIsLoading(false);

    // Sort the data chronologically based on 'createdAt'
    dataWithFormattedDate.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });

    setData(dataWithFormattedDate);
 


  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  const filteredData = data.filter((row) => {
    // Combine all relevant user fields into a single string
    const userFields = [
      row.user.userprofile,
      row.user.firstName,
      row.user.lastName,
      row.user.rut,
      row.user.email,
      row.user.occupation,
      row.user.location,
      row.user.area,
    ].filter((value) => typeof value === 'string').join(' ');

    // Combine all other column values into a single string
    const rowDataAsString = Object.values(row)
      .filter((value) => typeof value === 'string')
      .join(' ');

    // Combine both user and other fields for search
    const combinedSearchString = `${userFields} ${rowDataAsString}`;

    // Check if any email in campaignData.description matches the search input
    const emailsMatched = campaignData.description.some(email =>
      combinedSearchString.toLowerCase().includes(email.toLowerCase())
    );

    // Apply the search filter and email filter
    const searchInputLower = searchInput.toLowerCase();
    return combinedSearchString.toLowerCase().includes(searchInputLower) && emailsMatched;
  });

  setFilteredSearchData(filteredData);
  


const filteredDataWithType = data.filter((row) => {
  // Check if the user's email matches any value in campaignData.description
  const emailMatched = row.user?.email && campaignData.description.includes(row.user.email.toLowerCase());

  // Check if both type and email conditions are satisfied
  return row.type === (campaignData.opCampaign || 'defaultType') && emailMatched;
});

const filteredDataWithDifType = data.filter((row) => {
  // Check if the user's email matches any value in campaignData.description
  const emailMatched = row.user?.email && campaignData.description.includes(row.user.email.toLowerCase());

  // Check if both type and email conditions are satisfied
  return row.type !== (campaignData.opCampaign || 'defaultType') && emailMatched;
});


    setFilteredDataWithdiferType(filteredDataWithDifType);
    setFilteredDataWithType(filteredDataWithType);

  }, [isFormSubmitted, searchInput, campaignData.description, data]);

  useEffect(() => {
    const fetchDataAndCampaign = async () => {
      try {
        await Promise.all([fetchCampaignData(), fetchData()]);
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };
  
    fetchDataAndCampaign();
  }, []);

// Calculate the count of each area type from filtered data
const userp = filteredSearchData.reduce((counts, item) => {
  const usertt = item.user.userprofile || 'Unknown';
  counts[usertt] = (counts[usertt] || 0) + 1;
  return counts;
}, {});


// Calculate the count of each area type from filtered data
const tipottCounts = filteredSearchData.reduce((counts, item) => {
  const tipott = item.user.area || 'Unknown';
  counts[tipott] = (counts[tipott] || 0) + 1;
  return counts;
}, {});


// Calculate the count of each conflict type from filtered data
const userEmailCounts = filteredSearchData.reduce((counts, item) => {
const userEmail = item.user.rut || 'Unknown';
counts[userEmail] = (counts[userEmail] || 0) + 1;
return counts;
}, {});

// Calculate the count of each conflict type from filtered data
const userContrato = filteredSearchData.reduce((counts, item) => {
  const userEmail = item.user.idcontrato || 'Unknown';
  counts[userEmail] = (counts[userEmail] || 0) + 1;
  return counts;
  }, {});




const dataxperfil = Object.entries(userp).map(([label, count]) => ({
  label,
  count,
}));

const datax = Object.entries(tipottCounts).map(([label, count]) => ({
  label,
  count,
}));
const dataxconflicto = Object.entries(userEmailCounts).map(([label, count]) => ({
  label,
  count,
}));
const dataxcontrato = Object.entries(userContrato).map(([label, count]) => ({
  label,
  count,
}));

// Initialize a Map to store counts for each date
const dateCounts = new Map();

// Count occurrences for each date
filteredSearchData.forEach((row) => {
const dateKey = new Date(row.createdAt).toLocaleDateString("es-ES", {
year: "numeric",
month: "2-digit",
day: "2-digit",
});

if (dateCounts.has(dateKey)) {
dateCounts.set(dateKey, dateCounts.get(dateKey) + 1);
} else {
dateCounts.set(dateKey, 1);
}
});

// Convert the Map to an array of objects for chart data
const chartData = Array.from(dateCounts).map(([date, count]) => ({
label: date,
count: count,
}));

// Sort the chart data by date
chartData.sort((a, b) => new Date(b.label) - new Date(a.label));

// Render charts only if data is available
const latestEntriesfilteredData = uniqBy(filteredSearchData, 'user.email');
const latestEntriesfilteredDataWithType = uniqBy(filteredDataWithType, 'user.email');

const latestEntriesfilteredDataWithDifType = uniqBy(filteredDataWithdiferType, 'user.email');



  const handleRowClick = async (params, action) => {
    const rowId = params.row._id;
    
    if (action === "edit") {
      const { row, field } = params;
      const updatedRow = { ...row, [field]: params.value };

      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [row.id]: updatedRow,
      }));
  
      // Save the edited row
      await saveRowChanges(rowId, updatedRow);
    } else if (action === "delete") {
      // Add code to delete the row from the database using the rowId
      deleteRow(rowId);
    }
  };
  
  // Function to save the changes for a specific row
  const saveRowChanges = async (rowId, updatedRow) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}session/${rowId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });
    } catch (error) {
      console.error("Error saving row changes:", error);
    }
  };
  
  const deleteRow = async (rowId) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}session/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };

  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  if (isLoading) {
    return <div>{<FormattedMessage id="Cargando..."/>}</div>;
  }

  const columns = [  
    { field: "user.email", headerName: <FormattedMessage id="Email"/>, flex: 1,hide: true,   valueGetter: (params) => params.row.user.email, 
    headerClassName: "bold-header",
  },
    {
      field: "user.userprofile",
      headerName: <FormattedMessage id="Perfil" />,
      flex: 1,
      valueGetter: (params) => params.row.user.userprofile,
      headerClassName: "bold-header",
    },

    { field: "user.firstName", headerName: <FormattedMessage id="Nombres"/>, flex: 1, valueGetter: (params) => params.row.user.firstName,
    headerClassName: "bold-header",
  },
    { field: "user.lastName", headerName: <FormattedMessage id="Apellidos"/>, flex: 1,  valueGetter: (params) => params.row.user.lastName, 
    headerClassName: "bold-header",
  },
    { field: "user.rut", headerName: <FormattedMessage id="Rut"/>, flex: 1,hide: true,   valueGetter: (params) => params.row.user.rut, 
    headerClassName: "bold-header",
  },
 


    { field: "user.occupation", headerName: <FormattedMessage id="Rol"/>, hide: true, flex: 1, valueGetter: (params) => params.row.user.occupation, 
    headerClassName: "bold-header",
  },
    { field: "user.area", headerName: <FormattedMessage id="Area"/>, flex: 1, hide: true,  valueGetter: (params) => params.row.user.area, 
    headerClassName: "bold-header",
  },
  { field: "user.location", headerName: <FormattedMessage id="Organización"/>, flex: 1, hide: true,  valueGetter: (params) => params.row.user.location, 
  headerClassName: "bold-header",
},

{ field: "type", headerName: <FormattedMessage id="Tipo" />, flex: 1, valueGetter: (params) => params.row.type, 
headerClassName: "bold-header",
},

{ field: "fileName", headerName: <FormattedMessage id="Archivo" />, flex: 1, valueGetter: (params) => params.row.fileName, 
headerClassName: "bold-header",
},

{
  field: "createdAt",
  headerName: <FormattedMessage id="Actividad" />,
  flex: 1,      hide: userProfile !== "superadmin", headerClassName: "bold-header",

  valueGetter: (params) => {
    // Convert the date to the "es-ES" locale format with date and time
    const date = new Date(params.row.createdAt);
    const formattedDateTime = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
  
    });
    return formattedDateTime;
  },
},

  // Add more columns if needed
  
  ];
  
// Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

const styles = {
  // Define the rounded corners style for the box

  roundedBox: {
    width: "60%", // Make the box 50% wide
    borderRadius: "10px", // Adjust the value to control the roundness
    padding: "10px", // Add some padding for spacing
    backgroundColor: "white", // Set the background color to white
  },
  roundedInput: {
    borderRadius: "10px", // Adjust the value to control the roundness
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}; 

const handleSearchInputChange = (event) => {
  const inputValue = event.target.value;
  setSearchInput(inputValue);
};


const widgetStyle = {
  padding: '1rem',
  borderRadius: '15px', // Adjust the value for rounded corners
};


const emailsNotMatched = Object.values(
  [...filteredSearchData, ...filteredDataWithType].reduce((latestEmails, row) => {
    const email = row?.user?.email;
    if (email) {
      // Check if the email is not in filteredDataWithType or if the current row has a later date
      if (
        !filteredDataWithType.some(item => item.user.email === email) ||
        !latestEmails[email] ||
        new Date(row.createdAt) > new Date(latestEmails[email].createdAt)
      ) {
        latestEmails[email] = row;
      }
    }
    return latestEmails;
  }, {})
);

const emailColumns = [
  { field: "email", headerName: "Email", flex: 1, headerClassName: "bold-header" },
  {
    field: "completeName", // Change the field to a custom name
    headerName: <FormattedMessage id="Nombre" />, // Update header name accordingly
    flex: 1,
    headerClassName: "bold-header",
    valueGetter: (params) => {
      const row = params.row;
      const firstName = row.firstName || ""; // If firstName is undefined, set it to an empty string
      const lastName = row.lastName || ""; // If lastName is undefined, set it to an empty string
      return `${firstName} ${lastName}`.trim(); // Concatenate firstName and lastName, and trim any leading or trailing spaces
    },
  },
  {
    field: "type",
    headerName: <FormattedMessage id="Tipo Actividad" />,
    flex: 1,
    valueGetter: (params) => {
      // Fetch the campaignData.type based on the email
      const userData = data.find((row) => row.user.email === params.row.email);
      return userData ? userData.type : "sin actividad";
    },
    headerClassName: "bold-header",
  },
  {
    field: "createdAt",
    headerName: <FormattedMessage id="Actividad" />,
    flex: 1,
    hide: userProfile !== "superadmin",
    headerClassName: "bold-header",
    valueGetter: (params) => {
      // Fetch the createdAt value based on the email
      const userData = data.find((row) => row.user.email === params.row.email);
      return userData ? new Date(userData.createdAt).toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      }) : "";
    },
  },
];

const emailRows = campaignData.description.map((email, index) => ({
  id: index,
  email,

}));

// Add the type and createdAt fields from the userData to each row
emailRows.forEach((row, index) => {
  const userData = data.find((item) => item.user.email === row.email);
  if (userData) {
    emailRows[index].type = userData.type;
    emailRows[index].createdAt = userData.createdAt;
    emailRows[index].firstName = userData.user.firstName;
    emailRows[index].lastName = userData.user.lastName;


  }
});

console.log(emailRows);

  // Assuming campaignData and latestEntriesfilteredDataWithType are available
  const campaignDescriptionLength = campaignData.description.length;
  const latestEntriesLength = latestEntriesfilteredDataWithType.length;

  // Calculate the difference
  const titleDifference = campaignDescriptionLength - latestEntriesLength;

  const emailsInSecondDataGrid = latestEntriesfilteredDataWithType.map(row => row.user.email.toLowerCase());

  // Filter out emails from the third DataGrid that are also present in the second DataGrid
  

  const emailsSinActividad = emailRows.filter(row => row.type !== "login" && row.type !== "CI" && row.type !== "Read" && row.type !== "DueD" && row.type !== "PEP");
  const filteredEmailsNotMatched = emailsNotMatched.filter(row => !emailsInSecondDataGrid.includes(row.user.email.toLowerCase()));

  const progressconrespuesta = latestEntriesfilteredDataWithType /  campaignDescriptionLength;

  const emailsFromSinActividad = emailsSinActividad.map((row) => row.email);

  const emailsString = filteredEmailsNotMatched
    .filter((row) => row.user && row.user.email)
    .map((row) => row.user.email);

  const allEmails = [...emailsFromSinActividad, ...emailsString];

// Assuming campaignData.createdAt is a valid Date object
const createdAtDate = new Date(campaignData.createdAt);
const currentDate = new Date();

// Calculate the time difference in milliseconds
const timeDifferenceInMs = currentDate - createdAtDate;

// Convert milliseconds to days (you can use other time units as well)
const timeDifferenceInDays = Math.floor(timeDifferenceInMs / (1000 * 60 * 60 * 24));


  return (
    <Box m="10px" bgcolor="white"> {/* Apply white background color to the outermost Box */}
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={4}>
      <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white"> {/* Apply white background color to this Box */}
        <Header
  title={<FormattedMessage id="CAMPAÑA" />}
  subtitle={`${campaignData.comments} - ${campaignData.opCampaign}`}
/>
        </Box>
      </Grid>
<p></p>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', p: 2 }}>
          <Typography variant="h6">{modalContent}</Typography>
        </Box>
      </Modal>
<Grid item xs={12} sm={8} md={1}>


</Grid>

<Grid item xs={12} sm={6} md={4}>
     {/* Slider */}
   
   </Grid>
  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "130%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>
 <Typography variant="h6">
        
        <Button variant="outlined" onClick={() => handleOpenModal(campaignData.link)}>
            Invitación {new Date(campaignData.createdAt).toLocaleDateString('en-GB')}
          </Button>
         
       
        </Typography>
 <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="12px">
        
        {/* ROW 1 */}
        <Box
          gridColumn={{ xs: 'span 12', md: 'span 3' }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
            <StatBoxnom
title={campaignData.description.length.toString()}
subtitle= {<FormattedMessage id="Personas Enviadas" />}

            icon={
              <PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />
            }
                        titleProps={{ style: { textAlign: 'center' } }}
          />
        </Box>

       

        <Box
          gridColumn={{ xs: 'span 12', md: 'span 3' }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          
        >
          <StatBoxnom
            title={latestEntriesfilteredDataWithType.length.toString()}
            subtitle= {<FormattedMessage id="Con Respuesta" />}
            progress={progressconrespuesta.toString()} 
            increase={progressconrespuesta.toString()} 
              icon={
                <EmailIcon
                  sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "30px" }}
                />}
                            />
        </Box>

        <Box
    gridColumn={{ xs: 'span 12', md: 'span 3' }}
    backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
           <StatBoxnom
        title={titleDifference.toString()} // Set the difference as the title
        subtitle= {<FormattedMessage id="Sin Respuesta" />}


            icon={
              <ReportProblemOutlinedIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />
            }
                        titleProps={{ style: { textAlign: 'center' } }}
          />
          </Box>
          <Box
          gridColumn={{ xs: 'span 12', md: 'span 3' }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
            <StatBoxnom
  title={timeDifferenceInDays.toString()}
subtitle= {<FormattedMessage id="Demora en Días" />}

            icon={
              <HourglassTop sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />
            }
                        titleProps={{ style: { textAlign: 'center' } }}
          />
        </Box>
          </Box>

 <p></p>

 <p>

</p>
<Box
        m="40px 0 0 0"
        height="40vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[400],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {

            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
<Typography variant="h5" fontWeight="600" sx={{ padding: "20px" }}>
  <FormattedMessage id="Invitados" />
</Typography>
      {/* first DataGrid with sin repeteir third el email  */}
      <DataGrid
  rowHeight={40}
  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
  columns={emailColumns}
  rows={emailRows}
  headerClassName="bold-header"
  components={{ Toolbar: GridToolbar }}
  rowsPerPageOptions={[10, 25, 50]}
  onRowClick={handleRowClick}
/>

</Box>
<Box
        m="40px 0 0 0"
        height="40vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[400],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {

            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
 <Typography variant="h5" fontWeight="600" sx={{ padding: "20px" }}>
  <FormattedMessage id="Con Respuesta" />
</Typography>
      {/* Second DataGrid with additional filter */}
      <DataGrid
         rowHeight={40}
         localeText={esES.components.MuiDataGrid.defaultProps.localeText}
 
         columns={columns}
         rows={latestEntriesfilteredDataWithType.map((row) => ({
          ...row,
           id: row._id, 
         }))}
         headerClassName="bold-header"
         components={{ Toolbar: GridToolbar }}
       
         rowsPerPageOptions={[10, 25, 50]} // Include 25 as an option
 
         onRowClick={handleRowClick}
              />
             
              <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message="Copiado al portapapeles"
        />

      </Box>
   
      <p>
    
</p>
<Box
        m="30px 0 0 0"
        height="40vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[400],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {

            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
<Typography variant="h5" fontWeight="600" sx={{ padding: "20px" }}>
  <FormattedMessage id="Sin Respuestas Con Otra Actividad" />
</Typography>
      {/* forth DataGrid with sin repeteir third el email  */}
      <DataGrid
  rowHeight={40}
  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
  columns={columns}
  
  rows={filteredEmailsNotMatched.map(row => ({
    ...row,
     id: row._id, 
   }))}
  headerClassName="bold-header"
  components={{ Toolbar: GridToolbar }}
  rowsPerPageOptions={[10, 25, 50]}
  onRowClick={handleRowClick}
/>


              <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message="Copiado al portapapeles"
        />

      </Box>




<Box
        m="30px 0 0 0"
        height="40vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[400],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {

            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
<Typography variant="h5" fontWeight="600" sx={{ padding: "20px" }}>
  <FormattedMessage id="Sin Respuestas Sin Actividad" />
</Typography>
   {/* Button to copy emailsSinActividad */}
 
      {/* forth DataGrid with sin repeteir third el email  */}
      <DataGrid
  rowHeight={40}
  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
  columns={emailColumns}
  dad
  rows={emailsSinActividad}

  headerClassName="bold-header"
  components={{ Toolbar: GridToolbar }}
  rowsPerPageOptions={[10, 25, 50]}
  onRowClick={handleRowClick}
/>


              <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message="Copiado al portapapeles"
        />

      </Box>

      <p>

      </p>
      <div>
  {/* Button to copy filteredEmailsNotMatched */}
  <Button
    onClick={() => {
      

      // Corrected placement of curly braces for handleCreateCampaignClick
      handleCreateCampaignClick();
    }}
    sx={{
      borderRadius: "20px", // Corrected property name
      backgroundColor: colors.blueAccent[700],
      color: colors.grey[900],
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '10px 20px',
    }}
  >
    {/* Upload component inside Button */}
    <Upload sx={{ borderRadius: "20px", mr: '10px' }} />
    <FormattedMessage id="Repetir Invitación Pendientes" />
  </Button>


  {showCreateCampaign && <RepeatCampaign allEmails={allEmails} />}

  {/* Placeholder <p></p> */}
  <p></p>
</div>

      
              <p></p>

      {/*barras*/}
  {/* Bar Chart */}
  <Box gridColumn={{ xs: 'span 12', md: 'span 6' }} gridRow="span 2" backgroundColor={colors.primary[400]}>
          <Typography variant="h5" fontWeight="600" sx={{ borderRadius: "20px", padding: "30px 30px 0 30px" }}>
          {<FormattedMessage id="Por Fecha" />}

           
                      </Typography>
      <Box height="240px" mt="40px">
        <BarChart data={chartData} />
      </Box>
    

        
        </Box>




      <Box
              gridColumn={{ xs: 'span 12', md: 'span 4' }}
              sx={{ overflowX: 'auto' , paddingY: '40px' }} // Make the container horizontally scrollable

              gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ borderRadius: "20px", padding: "30px 30px 0 30px", overflowX: 'auto', paddingY:  '20px'}} // Make the container horizontally scrollable

                      
                    >
                              {<FormattedMessage id="Usuarios por Area" />}

                    </Typography>

                    <Box height="250px" mt="20px">

                    <BarChart data={datax} />

                    </Box>

        </Box>
        <p></p>
        {userProfile === 'superadmin' && (
        <>
          <Box
            gridColumn={{ xs: 'span 12', md: 'span 4' }}
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            sx={{ overflowX: 'auto', paddingY: '20px' }}
          >
            {/* Usuarios por Contrato chart content */}
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ borderRadius: "20px", padding: "30px 30px 0 30px", overflowX: 'auto', paddingY: '20px' }}
            >       
               {<FormattedMessage id="Usuarios por Contrato" />}

            </Typography>

            <Box height="250px" mt="20px">
              <BarChart data={dataxcontrato} />
            </Box>
          </Box>
          <p></p>
          </>
      )}

        <Box
              gridColumn={{ xs: 'span 12', md: 'span 4' }}
              gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    sx={{ overflowX: 'auto' , paddingY: '20px' }} // Make the container horizontally scrollable

                  >

                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ borderRadius: "20px", padding: "30px 30px 0 30px" }}
                    >
                                     {<FormattedMessage id="Usuarios por Perfil" />}

                      
                    </Typography>


                    <Box height="250px" mt="-20px">

                    <BarChart data={dataxperfil} />
                    </Box>
        </Box>
<p></p>
   <Box
                    gridColumn={{ xs: 'span 12', md: 'span 4' }}
                    gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          sx={{
                            width: '100%',
                            overflowX: 'auto',}} // Make the container horizontally scrollable

                        >
                          <Box
                            mt="25px"
                            p="0 30px"
                            display="flex "
                            justifyContent="space-between"
                            alignItems="center"
                          >
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      fontWeight="600"
                                      color={colors.grey[100]}
                                    >
                                  {<FormattedMessage id="Usuarios por Rut" />}

                                   </Typography>
                                    <Typography
                                      variant="h3"
                                      fontWeight="bold"
                                      color={colors.greenAccent[500]}
                                    >
                                    </Typography>
                                  </Box>
                            <Box>
                              
                            </Box>
                          
                          
                          </Box>
                              <Box height="250px" m="-20px 0 0 0">
                              <BarChart data={dataxconflicto} />

                            
                  </Box></Box>

<p></p>

    </Box>
    
  );
};

export default Campaigns;