// pagina oficial de campañas incluye el widget Nueva camapaña y el grid que muestas las campañas directamente

import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Modal, Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useMediaQuery,Dialog,
  DialogTitle,
  DialogContent,
  DialogActions} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setIdcontrato } from "../../state";
import { useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import CreateComplaint from "../../components/CreateComplaint";
import { useLocation } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";import BarChart from "../../components/BarChart";
import BarChartor from "../../components/BarChart";
import CreateTtvalue from "../../components/CreateTtvalue";
import CreateTtvalueFarma from "../../components/CreateTtvalueFarma";
import AddCircleIcon from '@mui/icons-material/FileCopy';

import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import StatBox from "../../components/StatBox";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StatBoxnom from "../../components/StatBoxnom";   import Grid from "@mui/material/Grid";

import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';


const Ttvalue = () => {
  const userEmail = useSelector((state) => state.user.email);
  const userEmpresa = useSelector((state) => state.user.location);
  const [client, setClient] = useState([]);
  const [fetchedData, setFetchedData] = useState(null);

  const location = useLocation();
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [selectedCellData, setSelectedCellData] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false); // Set initial value to false
  const [showCreateCampaignEdit, setShowCreateCampaignEdit] = useState(false); // Set initial value to false

  const [userSol, setUserSol] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [dateRange, setDateRange] = useState([0, 100]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const userProfile = useSelector((state) => state.user.userprofile);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [isLoading, setIsLoading] = useState(true);
  const [exportedData, setExportedData] = useState(null);
  const { _id, picturePath, email } = useSelector((state) => state.user);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [datax1, setDatax1] = useState([]);
  const [dataxprog1, setDataxprog1] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [totalcam, setTotalcam] = useState(0);
  const loggedclient = useSelector((state) => state.client);
  const [filterPending, setFilterPending] = useState(false); // State for checkbox value
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openForm, setOpenForm] = React.useState(false);
  const [openFormEdit, setOpenFormEdit] = React.useState(false);

  let filteredData = data;

  
  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };
  filteredData = data
  .filter((row) => {
    // Apply the date filter based on the "createdAt" field (assuming it is a numeric value)
    const createdAt = parseInt(row.createdAt);
    return createdAt >= dateRange[0] && createdAt <= dateRange[1];
  })
  .filter((row) => {
    // Apply the search filter based on all fields in the row
    const searchInputLower = searchInput.toLowerCase();
    for (const key in row) {
      if (row.hasOwnProperty(key) && typeof row[key] === 'string') {
        if (row[key].toLowerCase().includes(searchInputLower)) {
          return true; // Return true if any field contains the search input
        }
      }
    }
    return false; // Return false if no field contains the search input
  });

  // Calculate the count of each area type from filtered data
  const tipottCounts = filteredData.reduce((counts, item) => {
    const tipott = item.tipott || 'Unknown';
    counts[tipott] = (counts[tipott] || 0) + 1;
    return counts;
  }, {});

// Calculate the count of each conflict type from filtered data
const userEmailCounts = filteredData.reduce((counts, item) => {
  const userEmail = item.userEmail || 'Unknown';
  counts[userEmail] = (counts[userEmail] || 0) + 1;
  return counts;
}, {});


 useEffect(() => {
    setShowSuccessPopup(false);
    setSnackbarOpen(false);
    const fetchClients = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}clients/${idcontrato}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data1 = await response.json();

        }
      } catch (error) {
        console.log("Error fetching client data:", error);
      }
    };
  
    fetchClients(); // Call the fetchClients function when the component mounts
    fetchData();
   

  }, [isFormSubmitted]);

// Assuming you have a function to fetch the data
const fetchDataById = async (_id) => {
  try {
    const responseid = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue/${_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (responseid.ok) {
      const dataid = await responseid.json();
      console.log("fetchdddde", dataid);

      return dataid;

    }
 
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

  const fetchData = async () => {
  try {
     
  
    let endpoint = `${REACT_APP_API_ENDPOINT}ttvalue/user/${loggedInUserId}`;

    // Check the user profile and update the endpoint accordingly
    if (
      userProfile === "aprobador" || 
      userProfile === "coadmin" ||
      userProfile === "superadmin"  ||
      userProfile === "admin"
    ) {
      endpoint = `${REACT_APP_API_ENDPOINT}ttvalue/contract/${idcontrato}`;
    }
 

    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const formData = await response.json();

    // Extract the unique user IDs from the ttvalue data
    const userIds = formData.map((row) => row.userId);

    // Fetch user data for each unique user ID
    const userDataPromises = userIds.map((userId) => {
      return fetch(`${REACT_APP_API_ENDPOINT}users/${userId}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }).then((response) => response.json());
    });

    // Resolve all user data promises
    const userDataArray = await Promise.all(userDataPromises);

    // Create a map of userId to userEmail for efficient lookup
    const userIdToEmailMap = userDataArray.reduce((acc, userData) => {
      acc[userData._id] = userData.email;
      return acc;
    }, {});

    // Combine ttvalue data with userEmail
    const dataWithFormattedDate = formData.map((row) => ({
      ...row,
      userEmail: userIdToEmailMap[row.userId] || '', // Set userEmail to an empty string if not found
      id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-EN", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
          CurrentStep: row.approvals ? row.approvals.length : 0, // CurrentStep is the length of the approvals array

      }));
      
      setIsLoading(false);
      setData(dataWithFormattedDate);

     
    } catch (error) {
      console.error("Error fetching user data:", error);
    }      setIsLoading(false); // Set isLoading to false in case of an error

  };
  
 
 const datax = Object.entries(tipottCounts).map(([label, count]) => ({ label, count }));
  const dataxconflicto = Object.entries(userEmailCounts).map(([label, count]) => ({ label, count }));


  const handleCellClick = (params) => {
    const field = params.field;
    const cellData = params.row[field];
    setSelectedCellData(cellData);
    setSnackbarOpen(true);
  };



  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };




  const handleCreateCampaignClick = () => {
    setShowCreateCampaign((prevState) => !prevState);
  };

 
  const handleDownloadXLSX = async () => {
    if (data !== undefined && data !== null && data.length > 0) {
      try {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Declaraciones');
  
        // Define the headers for your data (assuming the first object in data has the keys)
        const headers = Object.keys(data[0]);
  
        // Add the headers to the worksheet
        worksheet.addRow(headers);
  
        // Add the data rows to the worksheet
        data.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
  
        // Generate a blob from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Convert the buffer to a blob
        const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        // Download the XLSX file
        saveAs(xlsxBlob, 'Transferencias de Valor.xlsx');
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
      // Handle the case when data is empty or undefined
    }
  };

  const autorizadaOrder = ["Por Evaluar", "Si", "No"];

  // Handle icon click
  const handleIconClick = async (event, row) => {

      // Fetch data by _id if it hasn't been fetched yet
      const data = await fetchDataById(row);

      setFetchedData(data);
      setOpenFormEdit(true);
    
  };


// Define the columns for the DataGrid, including the action column with an icon


// Row click handler
const handleRowDoubleClick = (params) => {
  // Navigate to a different page or perform the row click action
  const rowId = params.row._id; // Assuming _id is the unique identifier for the row
  navigate(`/ttvalueEvPage/${rowId}`); // Perform the navigation or action you want
};

const handleOpenForm = () => {
  setOpenForm(true);
};

const handleCloseForm = () => {
  setOpenForm(false);
};

const handleOpenFormEdit = () => {
  setOpenFormEdit(true);
};

const handleCloseFormEdit = () => {
  setOpenFormEdit(false);
};

  const columns = [
    { field: "_id", headerName: <FormattedMessage id="Id"/>, flex: 1 ,headerClassName: "bold-header",
  },
    { field: "tipott", headerName: <FormattedMessage id="Tipo"/>, flex: 1, editable: true , headerClassName: "bold-header",},
    { field: "userEmail", headerName: <FormattedMessage id="Solicitado por"/>, flex: 1, editable: true, headerClassName: "bold-header", },
    {
      field: "createdAt",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Creado"/>,
      flex: 1,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: 'aprobadores',
      headerName: <FormattedMessage id="Gobernanza" />,
      flex: 1,
      valueGetter: (params) => {
        return Array.isArray(params.value) ? params.value.join(', ') : 'No aprobadores';
      },
      headerClassName: 'bold-header',
    } ,
  
    {
      field: 'completionRatio',
      headerName: <FormattedMessage id="Evaluaciones" />,
      flex: 1,
      valueGetter: (params) => {
        const aprobadores = Array.isArray(params.row.aprobadores) ? params.row.aprobadores.length : 0;
        const currentStep = params.row.CurrentStep || 0;
        
        // Calculate the ratio
        return aprobadores > 0 ? `${currentStep} / ${aprobadores}` : 'No aprobadores';
      },
      headerClassName: 'bold-header',
    },
    
    {
      field: "estado",
      headerName: <FormattedMessage id="Estado Final" />,
      flex: 1,
      headerClassName: "bold-header",
     
    },

  
    {
      field: "opauth",
      headerName: <FormattedMessage id="Autorizacion Final" />,
      flex: 1,
      headerClassName: "bold-header",
      // Custom sorting logic for the "autorizada" column
      sortComparator: (v1, v2, param1, param2) => {
        const order1 = autorizadaOrder.indexOf(v1);
        const order2 = autorizadaOrder.indexOf(v2);
        return order1 - order2; // Sort according to the custom order
      }
    },

    { field: "estadoCierre",headerClassName: "bold-header", headerName: <FormattedMessage id="Cierre"/>,  flex: 1, editable: true },


  {
    field: 'actions',
    headerName: 'Clonar TV',
    headerClassName: "bold-header",
    width: 100,
    renderCell: (params) => (
      <IconButton
        onClick={(event) => handleIconClick(event, params.row._id)} // Use the correct _id directly
        color="primary"
      >
        <AddCircleIcon />
      </IconButton>
    ),
  }

  ];




  const formatDate = (dateString) => {
    if (!dateString) return "";
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Check if the date is valid
  
    const formattedDate = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
    return formattedDate;
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };


  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  let clientJSX;
  switch (loggedclient.industria) {
    case "Todos":
      clientJSX = (
        <Box>
          {!showCreateCampaignEdit && (
            <Button
              onClick={handleOpenForm}
              sx={{
                borderRadius: "20px",
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[900],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
              }}
            >
              <Upload sx={{ borderRadius: "20px", mr: '10px' }} />
              <FormattedMessage id="Nueva Transferencia " />
            </Button>
          )}
          
          {showCreateCampaign && (
          <>
            <CreateTtvalue _id={_id} />

            <CreateTtvalueFarma _id={_id} />

         
          </>
        )}


        </Box>
      );
      break;
    case "Salud":
      clientJSX = (
        <Box>
          {!showCreateCampaignEdit && (
            <Button
              onClick={handleOpenForm}
              sx={{
                borderRadius: "20px",
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[900],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
              }}
            >
              <Upload sx={{ borderRadius: "20px", mr: '10px' }} />
              <FormattedMessage id="Nueva Transferencia Salud" />
            </Button>
          )}
          
          {showCreateCampaign && (
          <>

            <CreateTtvalueFarma _id={_id} />

         
          </>
        )}


        </Box>
      );
      break;
    default:
      // For "Mineria" or any other case not covered above
      clientJSX = (
        <Box>
          {!showCreateCampaignEdit && (
            <Button
              onClick={handleOpenForm}
              sx={{
                borderRadius: "20px",
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[900],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
              }}
            >
              <Upload sx={{ borderRadius: "20px", mr: '10px' }} />
              <FormattedMessage id="Nueva Transferencia" />
            </Button>
          )}
          
          {showCreateCampaign && (
          <>

            <CreateTtvalue _id={_id} />

         
          </>
        )}


        </Box>
      );
      break;
  }
  

  return (
    <Box m="10px">

    <Grid container spacing={2}>
    <Grid item xs={12} sm={9} md={6}>
    

    {/* HEADER */}


    <Box display="flex" justifyContent="space-between" alignItems="center">
    <Header title={<FormattedMessage id="Transferencias de Valor"/>} subtitle="" />
    
    </Box>
     </Grid>

     <Grid item xs={12} sm={8} md={1}>
</Grid>



   
  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={4}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "100%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

</Grid>
{clientJSX}

{/*datagrid que lo ve el denunciante*/}
<p></p>


<Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gap="20px">
  {/* StatBox - 3 columns */}
  <Box gridColumn={{ xs: 'span 3', md: 'span 2' }} gridRow="span 2">

  </Box>
  </Box>
  <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
  <div style={{ marginRight: '25px' }}>
    <StatBoxnom
      title={filteredData.length.toString()}
      subtitle={<FormattedMessage id="Transferencias" />}
      icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
    />
  </div>
  
  <div style={{ marginRight: '25px' }}>
    <StatBoxnom
      title={filteredData.filter(row => row.estado === "Por Evaluar").length.toString()}
      subtitle={<FormattedMessage id="Por Evaluar" />}
      icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
    />
  </div>

  <div>
    <StatBoxnom
title={filteredData.filter(row => 
  row.estadoCierre === "Pendiente" && row.estado === "Evaluada"
).length.toString()}
      subtitle={<FormattedMessage id="Cierres Pendientes" />}
      icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
    />
  </div>
</Box>


<p></p>



          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{ borderRadius: "20px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
             
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
            >
            {/* Display loading spinner if isLoading is true */}
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={filteredData.map((row) => ({
            ...row,
            id: row._id, 
          }))}        
          initialState={{
            columns: {
              columnVisibilityModel: {
              
              },
            },
          }}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              headerClassName="bold-header"
              onRowDoubleClick={handleRowDoubleClick}

              rowsPerPageOptions={[5, 10, 20, 25]}
              />
              )}



        
<Dialog 
 open={openFormEdit} 
 onClose={handleCloseFormEdit} 
 fullScreen={!isNonMobileScreens}
 sx={{ 
   "& .MuiDialog-paper": { // Targeting the dialog's paper element
     width: "900px", // Set width to 300px
     maxWidth: "900px" // Ensure it doesn't expand beyond 300px
   }
 }}
>
          <DialogTitle>
          <Typography variant="h2">
      Clonar Transferencia de Valor
    </Typography>
   
    </DialogTitle>
  
        <DialogContent>
          {loggedclient.industria === "Mineria" ? (
      <CreateTtvalue _id={_id} data={fetchedData} />

    ) : loggedclient.industria === "Salud" ? (
      <CreateTtvalueFarma _id={_id} data={fetchedData} />
    ) : (
      <Typography variant="body1">Por favor selecciona una industria válida.</Typography>
    )}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFormEdit}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
  open={openForm} 
  onClose={handleCloseForm} 
  fullScreen={!isNonMobileScreens}
  sx={{ 
    "& .MuiDialog-paper": { // Targeting the dialog's paper element
      width: "900px", // Set width to 300px
      maxWidth: "900px" // Ensure it doesn't expand beyond 300px
    }
  }}
>
          <DialogTitle>
          <Typography variant="h2">Crear Transferencia de Valor</Typography>
          </DialogTitle>
        <DialogContent>
          {loggedclient.industria === "Mineria" ? (
      <CreateTtvalue />
    ) : loggedclient.industria === "Salud" ? (
      <CreateTtvalueFarma />
    ) : (
      <Typography variant="body1">Por favor selecciona una industria válida.</Typography>
    )}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cerrar</Button>
        </DialogActions>
      </Dialog>


            <Snackbar
              open={snackbarOpen}
              autoHideDuration={2000}
              onClose={handleSnackbarClose}
              message="Copiado al portapapeles"
            />
          </Box>
          <p></p>
          <Box>
                <Button
                    onClick={handleDownloadXLSX}
                    sx={{ borderRadius: "20px",
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                  >
                    <DownloadOutlinedIcon sx={{ borderRadius: "20px", mr: "10px" }} />
                    {<FormattedMessage id="Descargar Reporte"/>}
              </Button>
              <p></p>
              </Box>
 {/*barras*/}
         <Box
              gridColumn={{ xs: 'span 12', md: 'span 6' }}
              gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ borderRadius: "20px", padding: "30px 30px 0 30px" }}
                    >
                      {<FormattedMessage id="Declaraciones por Area"/>}
                      
                    </Typography>


                    <Box height="250px" mt="-20px">

                    <BarChart data={datax} />

                    </Box>


        </Box>
   <Box
                    gridColumn={{ xs: 'span 12', md: 'span 12' }}
                    gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                        >
                          <Box
                            mt="25px"
                            p="0 30px"
                            display="flex "
                            justifyContent="space-between"
                            alignItems="center"
                          >
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      fontWeight="600"
                                      color={colors.grey[100]}
                                    >
                                                            {<FormattedMessage id="Declarantes"/>}

                                  
                                    </Typography>
                                    <Typography
                                      variant="h3"
                                      fontWeight="bold"
                                      color={colors.greenAccent[500]}
                                    >
                                    </Typography>
                                  </Box>
                            <Box>
                              
                            </Box>
                          
                          
                          </Box>
                              <Box height="250px" m="-20px 0 0 0">
                              <BarChart data={dataxconflicto} />

                          
                  </Box></Box>
    </Box>
  );
};

export default Ttvalue;