import React, { useEffect, useState, useRef } from 'react';
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams, useRoutes } from 'react-router-dom';
import { Divider } from "@mui/material";
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es'; // Import the Spanish locale
import html2canvas from 'html2canvas';
import AWS from 'aws-sdk'; // Import the AWS SDK
import { useReactToPrint } from "react-to-print";
import { FormattedMessage } from 'react-intl';


import { Print } from '@mui/icons-material';
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,

  Radio,
  RadioGroup,
    InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
} from "@mui/material";
const greenText = { color: 'green' }; // Define the CSS style for green text


  const TtvaluedetWidget = () => {
    const [form, setForm] = useState(null);
    const dispatch = useDispatch();
    const loggedInUserId = useSelector((state) => state.user._id);
    const loggedinUserprofile = useSelector((state) => state.user.userprofile);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [pageType, setPageType] = useState("createform");
    const iscreateform = pageType === "createform";
    const { palette } = useTheme();
    const navigate = useNavigate();
    const token = useSelector((state) => state.token);
    const { _id } = useParams(); // Access the id1 parameter from the URL
    const { REACT_APP_API_ENDPOINT } = process.env;
    const [userSol, setUserSol] = useState(true);
    const [users, setUsers] = useState([]);
    const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
    const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const [approvals, setApprovals] = useState([]);
  
    const fetchFiles = async () => {
      try {
        // Fetch the form data
        const response = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue/${_id}`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
        });
        const data = await response.json();
  
        setForm(data);    
          setApprovals(data.approvals || []); // Set approvals from the fetched data
    
        // Fetch the user data
        const responseUser = await fetch(`${REACT_APP_API_ENDPOINT}users/${data.userId}`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
        });
        const dataUser = await responseUser.json();
        setUserSol(dataUser); // Update setForm to setFormState
    
        // Fetch approvers data (assuming data.aprobador contains an array of approver emails)
        const approverEmails = data.aprobadores || [];
        const userFetchPromises = approverEmails.map(email => {
          return fetch(`${REACT_APP_API_ENDPOINT}users/email/${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            },
          }).then(response => response.json());
        });
        const approversData = await Promise.all(userFetchPromises);
        console.log("Approvers data:", approversData);
    
        // Flatten the array of approvers data
        const flattenedApproversData = approversData.flatMap(approver => approver);
    
        setUsers(flattenedApproversData); // Set the array of all users fetched
    
  
    
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };
    
    useEffect(() => {
      if (_id) {
        fetchFiles();
      }
    }, [_id]); // Call fetchFiles when the component mounts or _id changes
  
  
    const containerRef = useRef(null);

  const {
    createdAt,
    userId,
    tipott,
    estado,
    idcontrato,
    razonauspicio,
    tipoauspicio,
    propositott,
    descripciontt,
    valortt,
    oppreciojusto,
    costoevento,
    comunidad,
    ubicacion,
    nombreproyecto,
    nombreorganizacion,
    oplucro,
    replegal,
    domiciliolegal,
    mailreplegal,
    link,
    nombrecontacto,
    telefonocontacto,
    emailcontacto,
    opdd,
    ddrespaldo,
    oppagos,
    exenciontributaria,
    cartasolicitud,
    valoranticipado,
    acuerdo,
    duraciontt,
    entrega,
    
    estadoCierre,
    orgpermiso,
    nombreb1,
    cargob1,
    organizacionb1,
    funcionariob1,
    nombreb2,
    cargob2,
    organizacionb2,
    funcionariob2,
    nombreb3,
    cargob3, 
    organizacionb3,
    funcionariob3,
    modoviaje, 
    clasesviaje,
    descripcionviaje,
    opalojamiento,
    costoporpersona,
    costoporpersonatransporte,
    costoporpersonainvitacion,
    costoporpersonacomida,
    costoporpersonaotros,
    otrosgastos,
    nombrerepresentantes1,
    cargosrepresentantes1,
    nombrerepresentantes2,
    cargosrepresentantes2,
    nombrerepresentantes3,
    cargosrepresentantes3,
    nombrerepresentantes4,
    cargosrepresentantes4,
    numeroasistentes,
    oporganizadoreventoestatal,
    txtorganizadoreventoestatal,
    opotrosauspicios,
    txtotrosauspicios,
    opmencionauspicio,
    opmkt,
    txtmkt,
    formadeaporte,
    opfuncionario,
    opauth,
    pictures,
    commentcierre,
    nationality,

  
  } = form || {};

  useEffect(() => {
    fetchFiles()
      .then(fileData => {
        setFiles(fileData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching files:', error);
        setFiles([]);
        setLoading(false);
      });
  }, [_id]);
  


  if (!form || form.length === 0) {
    return null;
  }
  
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', options);
  };

 

  const handleDownload = (filePath, fileName) => {
        

    const s3BucketURL = 'https://repoeticpro.s3.amazonaws.com'; // Use the correct endpoint
    const s3URL = `${s3BucketURL}/${encodeURIComponent(fileName)}`;
  
    // Initialize the AWS S3 client for downloading
    AWS.config.update({
      accessKeyId: REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
      region: "sa-east-1", // Set your AWS region
    });
    const s3 = new AWS.S3();


    const downloadLink = document.createElement('a');
    downloadLink.href = s3URL;
    downloadLink.target = '_blank'; // Open in a new tab
    downloadLink.download = fileName; // Specify the filename for the downloaded file
    downloadLink.click();

   
  };



  const hasEticproApproval = approvals.some(approval => approval.approver.includes('@eticpro.com'));

// Find the user with the smallest rangoAprobacion
const userWithSmallestRango = users.reduce((prev, curr) => 
  prev.rangoAprobacion < curr.rangoAprobacion ? prev : curr, users[0]
);


  return (

    

    <Box m="2rem 0">
            <div ref={componentRef} className="card">

      <div ref={containerRef}>
    
      
      <Box>
      <Typography variant="h2" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Transferencia de Valor"/>} <span style={greenText}></span></Typography>        

      <Typography variant="h4">{<FormattedMessage id="Estado"/>}: <span style={greenText}><FormattedMessage id={`${estado}`}/></span></Typography>
      <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Tipo"/>}: <span style={greenText}><FormattedMessage id={`${tipott}`}/></span></Typography>

      <div>{<FormattedMessage id="Recibida"/>}: <span style={greenText}>{new Date(createdAt).toLocaleDateString('es-ES')}</span></div>
      <div>{<FormattedMessage id="ID"/>}: <span style={greenText}>{_id.slice(-0)}</span></div>

      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '10px' }}>
  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Solicitante"/>}:</Typography>
  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
    <span style={greenText}>{userSol.firstName} {userSol.lastName}</span>
  </Typography>

  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Cargo"/>}:</Typography>
  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
    <span style={greenText}>{userSol.occupation}</span>
  </Typography>


  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Email"/>}:</Typography>
  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
    <span style={greenText}>{userSol.email}</span>
  </Typography>
</div>

    

<Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
{<FormattedMessage id="Fecha Solicitado"/>}:  
  <span style={greenText}>
    {format(new Date(createdAt), "dd MMMM yyyy HH:mm", { locale: esLocale })}
  </span>
  <p></p>
  </Typography>
      <Box>
         
     </Box>
      

          <p></p>
     
          <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{ borderRadius: "20px",
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
            

              <TextField
            label={<FormattedMessage id="Describa brevemente qué está siendo solicitado para aprobación y el propósito de la Transferencia de Valor de los gastos propuestos."
           />}     variant="filled"
                        value={descripciontt}
            name="descripciontt"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

{tipott === "inversion-social" && (
  <>

      <Typography>
      Beneficiario Potencial 
          </Typography>
<TextField

            label={<FormattedMessage id="Nombre de la Comunidad/Organización apoyada"
           />}     variant="filled"
                        value={comunidad}
            name="comunidad"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Fecha de entrega de la Inversión Social"
           />}     variant="filled"
                        value={entrega}
            name="entrega"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Ubicación de la Comunidad/Organización apoyada"
           />}     variant="filled"
            value={ubicacion}
            name="ubicacion"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Nombre de la Inversión Social (Proyecto de desarrollo comunitario)"
           />}     variant="filled"
                        value={nombreproyecto}
            name="nombreproyecto"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Nombre de la organización destinataria (si fuese aplicable)"
           />}     variant="filled"
                        value={nombreorganizacion}
            name="nombreorganizacion"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Nombre del Representante Legal"
           />}     variant="filled"
                        value={replegal}
            name="replegal"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Domicilio legal"
           />}     variant="filled"
                        value={domiciliolegal}
            name="domiciliolegal"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Email Representante Legal"
           />}     variant="filled"
                        value={mailreplegal}
            name="mailreplegal"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />



<TextField
            label={<FormattedMessage id="Sitio Web"/>}
                 variant="filled"
                        value={link}
            name="link"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Nombre Contacto principal en la organización destinataria"
           />}     variant="filled"
                        value={nombrecontacto}
            name="nombrecontacto"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Mail Contacto principal en la organización destinataria"
           />}     variant="filled"
                        value={emailcontacto}
            name="emailcontacto"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Telefono Contacto principal en la organización destinataria"
           />}     variant="filled"
                        value={telefonocontacto}
            name="telefonocontacto"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
 

  
<Typography variant="h6" gutterBottom> 
{<FormattedMessage id="Realizó el procedimiento de Diligencia Debida?"/>} 
      </Typography>
<RadioGroup
  aria-label={<FormattedMessage id="Opción"/>}
  name="opdd"
  value={opdd}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>





<Typography variant="h6" gutterBottom>    
 {<FormattedMessage id="¿Hay pagos a terceros como consultores u organizaciones para ejecutar la donación?. De ser una respuesta Afirmativa, tener presente el justo precio de mercado, y adjunte mas abajo la diligencia debida de este consultor u organización."/>} </Typography>  <RadioGroup
aria-label={<FormattedMessage id="Opción"/>}
name="oppagos"
  value={oppagos}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>


        <TextField
            label={<FormattedMessage id="Valor anticipado de la contribución de La Organización al desarrollo comunitario (en dinero y en especie)"
           />}     variant="filled"
                        value={valoranticipado}
            name="valoranticipado"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
          

          <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
  
<div>

   </div>
   </Box>


<TextField
            label={<FormattedMessage id="Señale cual es la inversión y su valor. Incluya Presupuesto o cotización."
           />}     variant="filled"
                        value={valortt}
            name="valortt"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Duración de la transferencia de valor"
           />}     variant="filled"
                        value={duraciontt}
            name="duraciontt"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />






    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}
      </Box>

{tipott === "donacion" && (
  <>

      <Typography>
      {<FormattedMessage id="Donación"/>}
          </Typography>
<TextField
            label={<FormattedMessage id="Nombre de la Donación"
           />}     variant="filled"
                        value={nombreproyecto}
            name="nombreproyecto"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Nombre de la organización destinataria (con/sin fin de lucro)"
           />}     variant="filled"
                        value={nombreorganizacion}
            name="nombreorganizacion"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
<TextField
            label={<FormattedMessage id="Fecha de entrega de la donación"
           />}     variant="filled"
                        value={entrega}
            name="entrega"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
<Typography variant="h6" gutterBottom>     
{<FormattedMessage id="Es la organización con fin de lucro?"/>}   </Typography>
<RadioGroup
  aria-label={<FormattedMessage id="Opción"/>}
  name="oplucro"
  value={oplucro}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>

<TextField
            label={<FormattedMessage id="Nombre del Representante Legal"
           />}     variant="filled"
                        value={replegal}
            name="replegal"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Domicilio Legal"
           />}     variant="filled"
                        value={domiciliolegal}
            name="domiciliolegal"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Email del Representante Legal"
           />}     variant="filled"
                        value={mailreplegal}
            name="mailreplegal"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />




<TextField
            label={<FormattedMessage id="Sitio Web"
           />}     variant="filled"
                        value={link}
            name="link"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Nombre Contacto principal en la organización destinataria"
           />}     variant="filled"
                        value={nombrecontacto}
            name="nombrecontacto"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Mail Contacto principal en la organización destinataria"
           />}     variant="filled"
                        value={emailcontacto}
            name="emailcontacto"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Telefono Contacto principal en la organización destinataria"
           />}     variant="filled"
                        value={telefonocontacto}
            name="telefonocontacto"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
 


 <TextField
            label={<FormattedMessage id="Ubicación de la Comunidad/Organización apoyada"
           />}     variant="filled"
                        value={comunidad}
            name="comunidad"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
          

      
        <TextField
            label={<FormattedMessage id="Valor anticipado de la contribución de La Organización al desarrollo comunitario (en dinero y en especie)"
           />}     variant="filled"
                        value={valoranticipado}
            name="valoranticipado"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
          


  
<p></p>

<div>

       
                    
   </div>
      



<TextField
            label={<FormattedMessage id="Duración de la transferencia de valor"
           />}     variant="filled"
                        value={duraciontt}
            name="duraciontt"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />






    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}
    

    {tipott === "regalo" && (
  <>

      <Typography>
Regalo
          </Typography>
          <p></p>
          <TextField
            label={<FormattedMessage id="Fecha de entrega del regalo"
           />}     variant="filled"
                        value={entrega}
            name="entrega"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
          <p></p>
<TextField
            label={<FormattedMessage id="Nombre Beneficiario 1"
           />}     variant="filled"
                        value={nombreb1}
            name="nombreb1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Cargo Beneficiario 1"
           />}     variant="filled"
                        value={cargob1}
            name="cargob1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Organización Beneficiario 1"
           />}     variant="filled"
                        value={organizacionb1}
            name="organizacionb1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<Typography variant="h6" gutterBottom>     {<FormattedMessage id="Funcionario del Gobierno?"/>}   </Typography>
<RadioGroup
  aria-label={<FormattedMessage id="Opción"/>}
  name="funcionariob1"
  value={funcionariob1}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>



<TextField
            label={<FormattedMessage id="Nombre Beneficiario 2"
           />}     variant="filled"
                        value={nombreb2}
            name="nombreb2"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Cargo Beneficiario 2"
           />}     variant="filled"
                        value={cargob2}
            name="cargob2"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Organización Beneficiario 2"
           />}     variant="filled"
                        value={organizacionb2}
            name="organizacionb2"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<Typography variant="h6" gutterBottom> 
    {<FormattedMessage id="Funcionario del Gobierno?"/>}   </Typography>
<RadioGroup
  aria-label={<FormattedMessage id="Opción"/>}
  name="funcionariob2"
  value={funcionariob2}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>


<TextField
            label={<FormattedMessage id="Razón del (los) regalo(s)"
           />}     variant="filled"
                        value={propositott}
            name="propositott"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Costo por persona del (los) regalo(s)"
           />}     variant="filled"
            value={valortt}
            name="valortt"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="La institución a la que pertenece el Beneficiario, ¿Lo permite?"
           />}     variant="filled"
                        value={orgpermiso}
            name="orgpermiso"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />







    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}
    

    {tipott === "comida" && (
  <>

      <Typography>
Comida
          </Typography>
<p></p>
          <TextField
            label={<FormattedMessage id="Fecha de acontecimiento de la comida"
           />}     variant="filled"
                        value={entrega}
            name="entrega"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
          <p></p>
<TextField
            label={<FormattedMessage id="Nombre Beneficiario 1"
           />}     variant="filled"
                        value={nombreb1}
            name="nombreb1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Cargo Beneficiario 1"
           />}     variant="filled"
                        value={cargob1}
            name="cargob1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Organización Beneficiario 1"
           />}     variant="filled"
                        value={organizacionb1}
            name="organizacionb1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<Typography variant="h6" gutterBottom> 
    {<FormattedMessage id="Funcionario del Gobierno?"/>}   </Typography>
<RadioGroup
  aria-label={<FormattedMessage id="Opción"/>}
  name="funcionariob1"
  value={funcionariob1}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>



<TextField
            label={<FormattedMessage id="Nombre Representante que ofrece la comida"
           />}     variant="filled"
                        value={nombrerepresentantes1}
            name="nombrerepresentantes1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Cargo Representante que ofrece la comida"
           />}     variant="filled"
                        value={cargosrepresentantes1}
            name="cargosrepresentantes1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
<p></p>

<TextField
            label={<FormattedMessage id="Número total de asistentes propuestos"
           />}     variant="filled"
                        value={numeroasistentes}
            name="numeroasistentes"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Costo por persona de la comida"
           />}     variant="filled"
                        value={valortt}
            name="valortt"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />








    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}

  

{tipott === "evento" && (
  <>

      <Typography>
Invitacion a Evento
          </Typography>
        
          <p></p>

<TextField
            label={<FormattedMessage id="Fecha de inicio del Evento"
           />}     variant="filled"
                        value={entrega}
            name="entrega"

            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
          <p></p>
<TextField
            label={<FormattedMessage id="Nombre Beneficiario 1"
           />}     variant="filled"
                        value={nombreb1}
            name="nombreb1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Cargo Beneficiario 1"
           />}     variant="filled"
                        value={cargob1}
            name="cargob1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Organización Beneficiario 1"
           />}     variant="filled"
                        value={organizacionb1}
            name="organizacionb1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<Typography variant="h6" gutterBottom>     {<FormattedMessage id="Funcionario del Gobierno?"/>}   </Typography>
<RadioGroup
  aria-label={<FormattedMessage id="Opción"/>}
  name="funcionariob1"
  value={funcionariob1}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>



<TextField
            label={<FormattedMessage id="Nombre Representante que ofrece la comida"
           />}     variant="filled"
                        value={nombrerepresentantes1}
            name="nombrerepresentantes1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Cargo Representante que da la comida"
           />}     variant="filled"
                        value={cargosrepresentantes1}
            name="cargosrepresentantes1"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
<p></p>

<TextField
            label={<FormattedMessage id="Número total de asistentes propuestos:"
           />}     variant="filled"
                        value={numeroasistentes}
            name="numeroasistentes"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Costo por persona de la comida"
           />}     variant="filled"
                        value={valortt}
            name="valortt"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />



<Box>
              <InputLabel>Seleccionar Modo de Viaje</InputLabel>

              <Select
                label={<FormattedMessage id="Modos de viaje para el evento"/>}
                value={values.modoviaje}
                name="modoviaje"
                 onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.modoviaje) && Boolean(errors.modoviaje)}
              >
                <MenuItem value="">
                  <em>{<FormattedMessage id="Modos de viaje para el evento"/>}</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="aereo">{<FormattedMessage id="Aéreo"/>}</MenuItem>
                <MenuItem value="busauto">{<FormattedMessage id="Auto/Bus"/>}</MenuItem>
                <MenuItem value="tren">{<FormattedMessage id="Tren"/>}</MenuItem>
                <MenuItem value="combinación">{<FormattedMessage id="Combinación"/>}</MenuItem>
              
              </Select>

          </Box>


          <p></p>


<TextField
            label={<FormattedMessage id="Si es combinación, favor describir: "
           />}     variant="filled"
                        value={descripcionviaje}
            name="clasesviaje"

            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<Box>
              <InputLabel>Clases de viaje</InputLabel>

              <Select
                label={<FormattedMessage id="Clase de Viaje"/>}
                value={values.clasesviaje}
                name="clasesviaje"
                 onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.clasesviaje) && Boolean(errors.clasesviaje)}
              >
                <MenuItem value="">
                  <em>{<FormattedMessage id="Seleccionar Clase de Viaje"/>}</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="turista">{<FormattedMessage id="Turista"/>}</MenuItem>
                <MenuItem value="ejecutiva">{<FormattedMessage id="Ejecutiva"/>}</MenuItem>
                <MenuItem value="primera">{<FormattedMessage id="Primera"/>}</MenuItem>
                <MenuItem value="charter">{<FormattedMessage id="Charter"/>}</MenuItem>
              
              </Select>

            
          </Box>







    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}



{tipott === "viaje" && (
  <>

      <Typography>
Invitacion a Viaje
          </Typography>
<p></p>
          <TextField
            label={<FormattedMessage id="Fecha de inicio del viaje"
           />}     variant="filled"
                        value={entrega}
            name="entrega"

            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
          <p></p>

          <TextField
            label={<FormattedMessage id="Propósito del viaje propuesto"
           />}     variant="filled"
                        value={propositott}
            name="propositott"

            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Destino"
           />}     variant="filled"
                        value={ubicacion}
            name="ubicacion"

            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Fecha de inicio del Viaje"
           />}     variant="filled"
                        value={entrega}
            name="entrega"

            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

                
             

           


<TextField
            label={<FormattedMessage id="Nombre Beneficiario 1"
           />}     variant="filled"
                        value={nombreb1}
            name="nombreb1"
          
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Cargo Beneficiario 1"
           />}     variant="filled"
                        value={cargob1}
            name="cargob1"
       
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Organización Beneficiario 1"
           />}     variant="filled"
                        value={organizacionb1}
            name="organizacionb1"

            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<Typography variant="h6" gutterBottom>     {<FormattedMessage id="

Funcionario del Gobierno?    "/>}   </Typography>
<RadioGroup
  aria-label={<FormattedMessage id="Opción"/>}
  name="funcionariob1"
  value={funcionariob1}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>



<TextField
            label={<FormattedMessage id="Nombre Representante(s) que va en el viaje"
           />}     variant="filled"
                        value={nombrerepresentantes1}
            name="nombrerepresentantes1"
      
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Cargo Representante que van en el viaje"
           />}     variant="filled"
                        value={cargosrepresentantes1}
            name="cargosrepresentantes1"
         
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
<p></p>

<TextField
            label={<FormattedMessage id="Número total de viajeros propuestos:"
           />}     variant="filled"
                        value={numeroasistentes}
            name="numeroasistentes"
          
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />




<Box>
              <InputLabel>Seleccionar Modo de Viaje</InputLabel>

              <Select
                label={<FormattedMessage id="Modos de viaje para el evento"/>}
                value={values.modoviaje}
                name="modoviaje"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.modoviaje) && Boolean(errors.modoviaje)}
              >
                <MenuItem value="">
                  <em>{<FormattedMessage id="Modos de viaje para el evento"/>}</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="aereo">{<FormattedMessage id="Aéreo"/>}</MenuItem>
                <MenuItem value="busauto">{<FormattedMessage id="Auto/Bus"/>}</MenuItem>
                <MenuItem value="tren">{<FormattedMessage id="Tren"/>}</MenuItem>
                <MenuItem value="combinación">{<FormattedMessage id="Combinación"/>}</MenuItem>
              
              </Select>

            
          </Box>


          <p></p>


<TextField
            label={<FormattedMessage id="Si es combinación, favor describir: "
           />}     variant="filled"
                        value={descripcionviaje}
            name="clasesviaje"
    
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<Box>
              <InputLabel>Clases de viaje</InputLabel>

              <Select
                label={<FormattedMessage id="Clase de Viaje"/>}
                value={values.clasesviaje}
                name="clasesviaje"
                 onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.clasesviaje) && Boolean(errors.clasesviaje)}
              >
                <MenuItem value="">
                  <em>{<FormattedMessage id="Seleccionar Clase de Viaje"/>}</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="turista">{<FormattedMessage id="Turista"/>}</MenuItem>
                <MenuItem value="ejecutiva">{<FormattedMessage id="Ejecutiva"/>}</MenuItem>
                <MenuItem value="primera">{<FormattedMessage id="Primera"/>}</MenuItem>
                <MenuItem value="charter">{<FormattedMessage id="Charter"/>}</MenuItem>
              
              </Select>

   
          </Box>


          <p></p>

          <Typography variant="h6" gutterBottom>     {<FormattedMessage id="

Se proveedra alojamiento?    "/>}   </Typography>
<RadioGroup
  aria-label={<FormattedMessage id="Opción"/>}
  name="opalojamiento"
  value={opalojamiento}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>


{opalojamiento === 'Si' && ( // Only render if the answer is 'Si'
<div>
        
      
              <TextField
                label={<FormattedMessage id="Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche:"
               />}     variant="filled"
                value={conflictxt}
                name="conflictxt"
             
                multiline
                fullWidth
                minRows={12}
                maxRows={20}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>
      )}




<TextField
            label={<FormattedMessage id="Fecha en que se efectuará o se realizó el viaje"
           />}     variant="filled"
                        value={entrega}
            name="entrega"

            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Presupuesto general del viaje propuesto:"
           />}     variant="filled"
                        value={valortt}
            name="valortt"
   
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Presupuesto general del viaje propuesto"
           />}     variant="filled"
                        value={valortt}
            name="valorr"
    
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Costo por persona del viaje propuesto:"
           />}     variant="filled"
                        value={costoporpersonatransporte}
            name="costoporpersonatransporte"
            
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Costo por persona de invitación a evento"
           />}     variant="filled"
                        value={costoporpersonainvitacion}
            name="costoporpersonainvitacion"
   
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Costo por persona del costo de comida más alto esperado:"
           />}     variant="filled"
                        value={costoporpersonacomida}
            name="costoporpersonacomida"
    
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Costo por persona de cualquier otro gasto previsible de este viaje:"
           />}     variant="filled"
                        value={costoporpersonaotros}
            name="costoporpersonaotros"
         
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Describa otros gastos previsibles:"
           />}     variant="filled"
                        value={otrosgastos}
            name="otrosgastos"
          
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />





    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}





{tipott === "auspicio" && (
  <>

      <h3><Typography>Auspicio</Typography></h3>

<p></p>
      <TextField
            label={<FormattedMessage id="Fecha de Inicio del Auspicio"
           />}     variant="filled"
                        value={entrega}
            name="entrega"
           
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />
          <p></p>
          <TextField
            label={<FormattedMessage id="Tipo de Auspicio"
           />}     variant="filled"
                        value={tipoauspicio}
            name="tipoauspicio"
    
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Duracion"
           />}     variant="filled"
                        value={duraciontt}
            name="duraciontt"
  
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Lugar"
           />}     variant="filled"
                        value={ubicacion}
            name="ubicacion"
           
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Propósito del evento"
           />}     variant="filled"
                        value={propositott}
            name="propositott"
     
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Razón de negocio para el auspicio:"
           />}     variant="filled"
                        value={razonauspicio}
            name="razonauspicio"
     
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Nombre de la entidad que organiza el evento"
           />}     variant="filled"
                        value={nombreorganizacion}
            name="nombreorganizacion"
  
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Costo total del evento"
           />}     variant="filled"
                        value={costoevento}
            name="costoevento"
     
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Costo del auspicio propuesto:"
           />}     variant="filled"
                        value={valortt}
            name="valortt"
  
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Nombre Representante(s) que va en el viaje"
           />}     variant="filled"
                        value={nombreb1}
            name="nombreb1"
 
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


             

            



<p></p>


          <Typography variant="h6" gutterBottom>     {<FormattedMessage id="

          (i) ¿Es la entidad que organiza el evento de propiedad de, o controlada por, una entidad gubernamental u otra entidad estatal? "/>}   </Typography>
<RadioGroup
  aria-label={<FormattedMessage id="Opción"/>}
  name="oporganizadoreventoestatal"
  value={oporganizadoreventoestatal}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>


{oporganizadoreventoestatal === 'Si' && ( // Only render if the answer is 'Si'
<div>
        
      
              <TextField
                label={<FormattedMessage id="Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche:"
               />}     variant="filled"
                value={txtorganizadoreventoestatal}
                name="txtorganizadoreventoestatal"
      
                multiline
                fullWidth
                minRows={12}
                maxRows={20}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>
      )}

<TextField
            label={<FormattedMessage id="(ii) Entregue el nombre(s), cargo(s) y vinculación(es) del (los) individuo(s) que sugirieron entregue el auspicio"
           />}     variant="filled"
                        value={nombrerepresentantes1}
            name="nombrerepresentantes1"
      
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />



<Typography variant="h6" gutterBottom> 
    {<FormattedMessage id="(iii) ¿Habrá otras empresas auspiciando?"/>}   
</Typography>
<RadioGroup
aria-label={<FormattedMessage id="Opción"/>}
name="opotrosauspicios"
value={opotrosauspicios}
sx={{ borderRadius: "20px",
"& .MuiSvgIcon-root": {
color: palette.primary.light, // Selected radio color (red)
},
"& .Mui-checked": {
color: "#FFFFFF", // Not selected radio color (white)
},
}}
>
<FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
<FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>


{opotrosauspicios === 'Si' && ( // Only render if the answer is 'Si'
<div>


    <TextField
      label={<FormattedMessage id="Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche:"
     />}     variant="filled"
      value={txtotrosauspicios}
      name="txtotrosauspicios"
      multiline
      fullWidth
      minRows={12}
      maxRows={20}
      maxLength={1000}
      sx={{ borderRadius: "20px", gridColumn: "span 5"
    }}
    
    />
</div>
)}

<Typography variant="h6" gutterBottom>     {<FormattedMessage id="

(iv) ¿Será mencionado su organización como auspiciador en los materiales del evento, publicaciones y otros materiales distribuidos relativos al evento?"/>}   </Typography>
<RadioGroup
aria-label={<FormattedMessage id="Opción"/>}

name="opmencionauspicio"
value={opmencionauspicio}
sx={{ borderRadius: "20px",
"& .MuiSvgIcon-root": {
color: palette.primary.light, // Selected radio color (red)
},
"& .Mui-checked": {
color: "#FFFFFF", // Not selected radio color (white)
},
}}
>
<FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
<FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>

<Typography variant="h6" gutterBottom>
       {<FormattedMessage id="(iv)(v) ¿Se permitirá su organización que realice cualquier forma de promoción o actividades de marketing en las instalaciones del evento?"/>}
          </Typography>
<RadioGroup
aria-label={<FormattedMessage id="Opción"/>}
name="opmkt"
value={opmkt}
sx={{ borderRadius: "20px",
"& .MuiSvgIcon-root": {
color: palette.primary.light, // Selected radio color (red)
},
"& .Mui-checked": {
color: "#FFFFFF", // Not selected radio color (white)
},
}}
>
<FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
<FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>


{opmkt === 'Si' && ( // Only render if the answer is 'Si'
<div>


    <TextField
      label={<FormattedMessage id="Si la respuesta es Sí, porfavor explique:"
     />}     variant="filled"
      value={txtmkt}
      name="txtmkt"
   
      multiline
      fullWidth
      minRows={12}
      maxRows={20}
      maxLength={1000}
      sx={{ borderRadius: "20px", gridColumn: "span 5"
    }}
    
    />
</div>
)}


<TextField
            label={<FormattedMessage id="(vi) ¿Cómo se proveerá el auspicio? Por ejemplo, cheque a la entidad organizadora, contribución en especies, pago directo al proveedor.  "
           />}     variant="filled"
                        value={formadeaporte}
            name="formadeaporte"
     
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Si el auspicio fue proveido a través de pago directo al proveedor, ¿Lo efectuo al justo precio de mercado?"
           />}     variant="filled"
                        value={oppreciojusto}
            name="oppreciojusto"
      
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}



{tipott === "otros" && (
  <>

      <h4><Typography>{<FormattedMessage id="Cualquier otra cosa de valor"/>}</Typography></h4>

<TextField
            label={<FormattedMessage id="Nombre Beneficiario 1"
           />}     variant="filled"
                        value={nombreb1}
            name="nombreb1"
      
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<TextField
            label={<FormattedMessage id="Cargo Beneficiario 1"
           />}     variant="filled"
                        value={cargob1}
            name="cargob1"
   
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Organización Beneficiario 1"
           />}     variant="filled"
                        value={organizacionb1}
            name="organizacionb1"
        
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />


<Typography variant="h6" gutterBottom>
       {<FormattedMessage id="Funcionario de Gobierno?"/>}
          </Typography>
<RadioGroup
  aria-label={<FormattedMessage id="Opción"/>}
  name="funcionariob1"
  value={funcionariob1}
    sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>




<TextField
            label={<FormattedMessage id="Razon / proposito"
           />}
                variant="filled"
                        value={propositott}
            name="propositott"
       
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />

<TextField
            label={<FormattedMessage id="Costo por persona"
           />}     variant="filled"
                        value={valortt}
            name="valortt"
       
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />




          <p></p>

<TextField
            label={<FormattedMessage id="Fecha en que se efectuará o se realizó la comida"
           />}     variant="filled"
                        value={entrega}
            name="entrega"
            multiline
            fullWidth
            sx={{ borderRadius: "20px",
    gridColumn: "span 4",
    pointerEvents: "none", // Disable editing
    userSelect: "text" // Allow text selection
  }}
  InputProps={{
    readOnly: true, // Make the input read-only
  }}
          />




    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}






<Box width="100%" >

        <Box flexBasis="100%" marginRight="1rem">
         
         {/* Check if the user is "comitetica" or "superadmin" */}
         <Box 
  sx={{ 
    borderRadius: "20px", 
    border: '1px solid', 
    p: '1rem', 
    mt: '1rem', 
    width: '350%'  // Increase width to 200%
  }}
>            <Typography variant="h4">Archivos Relacionados</Typography>
            {form.picturePath && form.picturePath.map((filePath, index) => {
   if (filePath) {
    const fileName = filePath.split('/').pop();
    return (
      <div key={index}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleDownload(filePath, fileName);
          }}
        >
          {fileName}
        </a>
      </div>
    );
  } else {
    return null; // or handle the case when filePath is null
  }
            })}
          </Box>
     
          </Box>
          </Box>
          </Box>

          <Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
  <div>
    {/* Pending Approvals */}
    {(() => {
  const pendingUsers = users.filter((user) => 
    !approvals.some(approval => approval.approver === user.email)
  );
  
  if (pendingUsers.length > 0 && form.estado != "Rechazada") {
    const userWithSmallestRango = pendingUsers[0];  // Get the first pending user by index

    return (
      <div key={userWithSmallestRango.email}>
        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
          Aprobador Pendiente: 
          <span style={greenText}>
            {`${userWithSmallestRango.firstName || ''} ${userWithSmallestRango.lastName || ''}`}
          </span>
        </Typography>
      </div>
    );
  } else {
    return <Typography variant="h6">Sin Aprobaciones Pendientes.</Typography>; // Fallback message
  }
})()}


    {/* Completed Approvals */}
    <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word', mt: '1rem' }}>
      Evaluaciones Realizadas:
    </Typography>

    {approvals.length > 0 ? approvals.map((approval) => {
      const user = users.find(user => user.email === approval.approver);

      return (
        <div key={approval.approver}>
          <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
            <span style={greenText}>
              {user ? `${user.firstName} ${user.lastName}` : approval.approver}
            </span>
          </Typography>

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
            Autorizado: <span style={greenText}>{approval.opauth}</span>
          </Typography>

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
            Detalle: <span style={greenText}>{approval.authtxt}</span>
          </Typography>

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
            Fecha Evaluación: <span style={greenText}>{new Date(approval.dateeval).toLocaleDateString()}</span>
          </Typography>

          <hr />
        </div>
      );
    }) : (
      <Typography variant="h6" sx={{ mt: '1rem' }}>No hay evaluaciones aún.</Typography> // Fallback if no approvals
    )}
  </div>
</Box>



      

        </div>
        </div>


</Box>



);
};


export default TtvaluedetWidget;



