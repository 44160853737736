import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import FlexBetween from "./FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AWS from 'aws-sdk';
import { FormattedMessage } from 'react-intl';
import RegSession from "./RegSession";
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { FormHelperText } from '@mui/material'; // Import FormHelperText from Material-UI
import debounce from 'lodash/debounce';


const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

AWS.config.update({
accessKeyId: REACT_APP_ACCESS_KEY_ID,
secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
region: "sa-east-1", // Set your AWS region
});

// Create an S3 object
const s3 = new AWS.S3();

const currentDate = new Date();
const { REACT_APP_API_ENDPOINT } = process.env;

const CreateDuediligence = ({ _id, onClose, dialogOpen, setDialogOpen }) => {
  
  const location = useLocation();
  const loggedinUseremail = useSelector((state) => state.user.email);
  const loggedinusercontrato = useSelector((state) => state.user.idcontrato);
  const userId = useSelector((state) => state.user._id);
  const [dds, setDds] = useState([]); // Initialize as an empty array
  const loggedinuserprofile = useSelector((state) => state.user.userprofile); 
  const userprofile = useSelector((state) => state.user.userprofile); 
  const [dialogMessage, setDialogMessage] = useState('');

  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState(null);
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [idcompanyx, setIdcompanyx] = useState([]);
  const [picturePathbk, setPicturePathbk] = useState([]);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const [score, setScore] = useState(0); // State to hold the score
  const [risk, setRisk] = useState(0); // State to hold the score
  const [emailTemplate, setemailTemplate] = useState([]); // State to store the parsed CSV data
  const [valuex, setValues] = useState({
    nombreempresa: '', // your other state variables
  });
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [company, setCompany] = useState([]); // State to hold the list of company
  const loggedInUserId = useSelector((state) => state.user._id);
  const [uploadedFiles, setUploadedFiles] = useState([]); // State for uploaded files
  const { palette } = useTheme();
  const [idcontrato, setIdcontrato] = useState(""); // Add this state
  const [form, setForm] = useState({ picturePath: "" }); // Initialize as an empty object 
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [showInfo2, setShowInfo2] = useState(false);
  const [showInfo3, setShowInfo3] = useState(false);

  const loggedclient = useSelector((state) => state.client);

  const countries = ['Chile',
    'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia', 'Austria',
    'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia',
    'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon',
    'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo (Congo-Brazzaville)', 'Costa Rica',
    'Cote d\'Ivoire', 'Croatia', 'Cuba', 'Cyprus', 'Czechia (Czech Republic)', 'Democratic Republic of the Congo', 'Denmark', 'Djibouti',
    'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini (fmr. "Swaziland")',
    'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea',
    'Guinea-Bissau', 'Guyana', 'Haiti', 'Holy See', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel',
    'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho',
    'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands',
    'Mauritania', 'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar (formerly Burma)',
    'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia (formerly Macedonia)',
    'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine State', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar',
    'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe',
    'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa',
    'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Tajikistan', 'Tanzania', 'Thailand',
    'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates',
    'United Kingdom', 'United States of America', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe',
  ];
  
  
  const isValidRut = (rut) => {
    if (!rut || rut.trim().length < 3) return false;
    const rutClean = rut.replace(/[^0-9Kk]/g, "").toUpperCase();
    const rutBody = rutClean.substring(0, rutClean.length - 1);
    let dv = rutClean.slice(-1);
    let sum = 0;
    let factor = 2;
  
    for (let i = rutBody.length - 1; i >= 0; i--) {
      sum += factor * parseInt(rutBody.charAt(i), 10);
      factor = (factor === 7) ? 2 : factor + 1;
    }
  
    const dvCalculated = 11 - (sum % 11);
    if (dvCalculated === 11) dv = '0';
    if (dvCalculated === 10) dv = 'K';
  
    return dv === (dvCalculated === 11 ? '0' : dvCalculated === 10 ? 'K' : String(dvCalculated));
  };
  
  yup.addMethod(yup.string, 'isValidRut', function (message) {
    return this.test('isValidRut', message, function (value) {
      const { path, createError } = this;
      return isValidRut(value) || createError({ path, message: message || 'RUT no es válido' });
    });
  });

  const campaignSchema = yup.object().shape({
    nombreempresa: yup.string().required('Seleccionar Contraparte is required'),
    rutempresa: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('RUT/TAX ID Organización  es requerido'),
      otherwise: yup.string().nullable(),
    }),
    emailcontacto: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().email('Invalid email').required('Email de Contacto is required'),
      otherwise: yup.string().nullable(),
    }),
    address: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Dirección es requerida'),
      otherwise: yup.string().nullable(),
    }),
    firstNameContacto: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Nombre de Contacto es requerido'),
      otherwise: yup.string().nullable(),
    }),
    lastNameContacto: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Apellido de Contacto es requerido'),
      otherwise: yup.string().nullable(),
    }),
    rutcontacto: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('RUT de Contacto es requerido').isValidRut('RUT de Contacto no es válido'),
      otherwise: yup.string().nullable(),
    }),
    nationalitycon: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Nacionalidad de Contacto es requerida'),
      otherwise: yup.string().nullable(),
    }),
    cargocontacto: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Cargo de Contacto es requerido'),
      otherwise: yup.string().nullable(),
    }),
    celularcontacto: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Celular de Contacto es requerido'),
      otherwise: yup.string().nullable(),
    }),
    nombrefantasia: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Nombre de Fantasía es requerido'),
      otherwise: yup.string().nullable(),
    }),
    representante: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Representante es requerido'),
      otherwise: yup.string().nullable(),
    }),
    nationalityrep: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Nacionalidad del Representante es requerida'),
      otherwise: yup.string().nullable(),
    }),
    industria: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Industria es requerida'),
      otherwise: yup.string().nullable(),
    }),
    reason: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Razón es requerida'),
      otherwise: yup.string().nullable(),
    }),
    comments: yup.string().when('nombreempresa', {
      is: (nombreempresa) => nombreempresa === 'nuevaEmpresa',
      then: yup.string().required('Comentarios son requeridos'),
      otherwise: yup.string().nullable(),
    }),
  });
  
  


  const handleInfoToggle = () => {
    setShowInfo(prev => !prev);
  };

  const campaignSchema1 = yup.object().shape({
   
 
  });

  const initialValuesEditCampaign = {
   
    solicitante: loggedInUserId,
    estado: "Por Evaluar",
    idcontrato: loggedinusercontrato, // Update the initial value
    emailaviso: loggedinUseremail,
  
//user
firstName: "",
lastName: "",
email: "",
password: "",
location: "",
occupation: "",
userprofile: "dd added",
idcontrato: "",
campaignid: "",
area: "",
rut: "",
celular: "",
status: "Por Evaluar",
location: "",

 //Organización
 idcompany: "",
 nationality: "",

 nombreempresa: "",
 rutempresa: "",
 address: "",
 firstNameContacto: "",
 lastNameContacto: "",
 rutcontacto: "",
 nationalitycon: "",

 emailcontacto: "",
 userIdcontacto: "",
 cargocontacto: "",
 celularcontacto: "",
 nombrefantasia: "",
 representante: "",
 nationalityrep: "",
 industria: "",
 reason: "",
 comments: "",
 numeroSAP: "",

 //fer
 opGiro: "",
 opContrato: "",
 opPagoanual: "",
 opReprenta: "",
 op80: "",
 opCI: "",
 opParaiso: "",
 opPaispago: "",
 opSugpep: "",
 opSii: "",
 opMoraprev: "",
 opComseeker: "",
 score: "",
 risk: "",

//cdd
opImplementado: "",
txtImplementado: "",
opCertificado: "",
txtCertificado: "",
opOficial: "",
txtOficial: "",
opCapacitaciones: "",
txtCapacitaciones: "",
opCapacitacionesproveedores: "",
txtCapacitacionesproveedores: "",
opCodigoetica: "",
txtCodigoetica: "",
opManualcumplimiento: "",
txtManualcumplimiento: "",
opCanaldenuncia: "",
txtCanaldenuncia: "",
opRepholding: "",
txtRepholding: "",
opChequeo: "",
txyChequeo: "",
opPep: "",
txtPep: "",
opInvestigacionI: "",
txtInvestigacionI: "",
opInvestigacionII: "",
txtInvestigacionII: "",
opTransmit: "",
txtTransmit: "",

//checklist
txtComseeker: "",
opFuentesla: "",
txtFuentesla: "",
opInfodirecta: "",
txtInfodirecta: "",
opNegativa: "",
txtNegativa: "",
opDifdds: "",
txtDifdds: "",
opAntanalisis: "",
txtAntanalisis: "",

//approval
opAuth: "",
txtAuth: "",
opMitigacion: "",
txtMitigacion: "",
picturePath: [],

  };
  
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}company/contract/${loggedinusercontrato}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setDds(data);
          // Assuming data.nombreempresa contains the list of existing companies
        } else {
          // Handle error when fetching clients
          console.log("Failed to fetch clients");
        }
      } catch (error) {
        console.log(error);
        // Handle error
      }
    };

    fetchClients();
  }, [token]);

  const fetchCompanyData = async (companyId) => {
    try {
     const response = await fetch(`${REACT_APP_API_ENDPOINT}company/${companyId}`, {
     
         method: 'GET',
         headers: {
           Authorization: `Bearer ${token}`,
         },
       });
 
       if (response.ok) {
         const companyData = await response.json();
         setSelectedCompanyData(companyData);
         console.log("companydata", companyData);
       } else {
         console.error('Error fetching company data:', response.status, response.statusText);
       }
     } catch (error) {
       console.error('Error fetching company data:', error);
     }
   };

  const handleFormSubmit = async (values, onSubmitProps) => {
        setForm(uploadedFiles);

        const answers = [
          values.opGiro, values.opContrato, values.opPagoanua, values.opReprenta,
    values.op80, values.opC, values.opParaiso, values.opPaispago, values.opSugpep,
    values.opSugpep, values.opSii, values.opMoraprev
        ];
  
          // Adjust the scoring system for each question
          const totalScore = answers.reduce((acc, answer, index) => {
            const scoreMap = {
              1: answer === "Si" ? -1 : 0,  // Special case for `opContrato`
              default: answer === "Si" ? 5 : 0,
            };
            return acc + (scoreMap[index] ?? scoreMap.default);
          }, 0);
  
              const averageScore = parseFloat((totalScore).toFixed(1));
            
              const result =
            averageScore >= 5.0 ? "Riesgo Alto" :
            averageScore >= 4.0 ? "Riesgo Medio" :
            "Riesgo Bajo";

              // Assuming setRisk and setScore are state-setting functions
              setRisk(result);
              setScore(averageScore);

  try {
  const formData = new FormData();

  let locationValue;

    if (values.nombreempresa === 'nuevaEmpresa') {
      locationValue = newCompanyName;
    } else {
      locationValue = values.nombreempresa;
    }


      const password = values.rut.substring(0, 6); // Get the first 6  digits of the "rut" as the password

      let selectedCompany;

    // Fetch company data if it's an existing company
    if (values.nombreempresa !== 'nuevaEmpresa') {
      selectedCompany = dds.find(
        (company) => company.nombreempresa === values.nombreempresa
      );

      if (selectedCompany) {
        await fetchCompanyData(selectedCompany._id);
      }
    }
    const estado = result !== "Riesgo Alto" ? "Finalizado" : "En Evaluacion";

    let companyDataToPost;

    // Use the fetched company data if it's an existing company
    if (selectedCompany && selectedCompanyData) {
      companyDataToPost = {
     
        solicitante: loggedInUserId,
        estado: estado,
        idcontrato: loggedinusercontrato,
        
        score: averageScore,
        risk: result,
        opGiro: values.opGiro,
        opContrato: values.opContrato,
        opPagoanual: values.opPagoanual,
        opReprenta: values.opReprenta,
        op80: values.op80,
        opCI: values.opCI,
        opParaiso: values.opParaiso,
        opPaispago: values.opPaispago,
        opSugpep: values.opSugpep,
        opSii: values.opSii,
        opMoraprev: values.opMoraprev,
        opComseeker: values.opComseeker,

       
        // Use the fetched company data
        nombreempresa: selectedCompanyData.nombreempresa,
        rutempresa: selectedCompanyData.rutempresa,
        address: selectedCompanyData.address,
        firstNameContacto: selectedCompanyData.firstNameContacto,
        lastNameContacto: selectedCompanyData.lastNameContacto,
        nationalitycon: selectedCompanyData.nationalitycon,

        emailcontacto: selectedCompanyData.emailcontacto,
        rutcontacto: selectedCompanyData.rutcontacto,
        celularcontacto: selectedCompanyData.celularcontacto,
        nombrefantasia: selectedCompanyData.nombrefantasia,
        representante: selectedCompanyData.representante,
        nationalityrep: selectedCompanyData.nationalityrep,

        industria: selectedCompanyData.industria,
        occupation: selectedCompanyData.cargocontacto,
        reason: selectedCompanyData.reason,
        comments: selectedCompanyData.comments,
        idcompany: selectedCompanyData._id,
        nationality: selectedCompanyData.nationality,
        numeroSAP: selectedCompanyData.numeroSAP,

      };
      

    } else {

      // Use the original form values
      companyDataToPost = {
        solicitante: loggedInUserId,
        estado: estado,
        idcontrato: loggedinusercontrato,

        score: averageScore,
        risk: result,
        opGiro: values.opGiro,
        opContrato: values.opContrato,
        opPagoanual: values.opPagoanual,
        opReprenta: values.opReprenta,
        op80: values.op80,
        opCI: values.opCI,
        opParaiso: values.opParaiso,
        opPaispago: values.opParaiso,
        opSugpep: values.opSugpep,
        opSii: values.opSii,
        opMoraprev: values.opMoraprev,
        opComseeker: values.opComseeker,

        nombreempresa: values.nombreempresa === 'nuevaEmpresa' ? newCompanyName : values.nombreempresa,
        rutempresa: values.rutempresa,
        address: values.address,
        firstNameContacto: values.firstNameContacto,
        lastNameContacto: values.lastNameContacto,
        nationalitycon: values.nationalitycon,
        nationality: values.nationality,

        emailcontacto: values.emailcontacto,
        rutcontacto: values.rutcontacto,
        celularcontacto: values.celularcontacto,
        nombrefantasia: values.nombrefantasia,
        representante: values.representante,
        nationalityrep: values.nationalityrep,
        industria: values.industria,
        occupation: values.cargocontacto,
        reason: values.reason,
        comments: values.comments,
        pictures: formData.pictures,
        numeroSAP: values.numeroSAP,

      };
    }


       // Append existing files as File objects
         if (form.picturePath && form.picturePath.length > 0) {
          form.picturePath.forEach((file) => {
          formData.append('pictures', s3Urls); // You can store the S3 URLs in your data
     
          });
        }

    // Append newly uploaded files to S3
    if (uploadedFiles.length > 0) {

          // Define the uploadFileToS3 function
          const uploadFileToS3 = async (file) => {
            AWS.config.update({
            accessKeyId: REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
            region: "sa-east-1", // Set your AWS region
            });
            const s3 = new AWS.S3();

            // Configure the S3 bucket and key where you want to upload the file
            const bucketName = 'repoeticpro';
            const uniqueSuffix = Date.now() + "-";
            const key = uniqueSuffix + file.name; // Add the unique suffix to the filename
       
            // Configure S3 upload parameters
            const params = {
              Bucket: bucketName,
              Key: key,
              Body: file, // The actual file object to upload
            };


  // Use the AWS S3 company to upload the file to S3
  try {
    await s3.upload(params).promise();
    const uploadResult = await s3.upload(params).promise();
    
    console.log(`File uploaded to S3: ${uploadResult.key}`);

    // Ensure that picturePath is an array (initialize if undefined)
    companyDataToPost.picturePath = companyDataToPost.picturePath || [];
    
    // Add the key to the picturePath array
    companyDataToPost.picturePath.push(uploadResult.key);

    return uploadResult.key; // Return the key for reference


  } catch (error) {
    console.error(`Error uploading file to S3: ${error.message}`);
    throw error;
  }

};

const s3Urls = await Promise.all(uploadedFiles.map(uploadFileToS3));

}
    // Make the POST request with the selected company data or original form values
    const companyResponse = await fetch(`${REACT_APP_API_ENDPOINT}company`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(companyDataToPost),
    });

    const responseData5 = await companyResponse.json();
if (responseData5.ok){


  
    companyDataToPost.idcompany = responseData5._id;
    console.log("se guardo organización", responseData5._id);

setIdcompanyx(responseData5._id);
}
 else {

  const companyResponserut = await fetch(`${REACT_APP_API_ENDPOINT}company/rut/${companyDataToPost.rutempresa}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },

  });

  const responseDatarut = await companyResponserut.json();
  setIdcompanyx(responseDatarut._id);
  console.log("se busco rut existente", companyDataToPost.rutempresa);

}


    
//crea usuario
  const userResponse2 = await fetch(`${REACT_APP_API_ENDPOINT}users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      password: password,
      firstName: values.firstNameContacto,
      lastName: values.lastNameContacto,
      email: values.emailcontacto,
      location: locationValue, 
      occupation: values.cargocontacto,
      userprofile: "declare",
      idcontrato: loggedinusercontrato,
      rut: values.rutcontacto,
      celular: values.celularcontacto,
      status: "InvitadoDD",
      estado: "dd declarado",
      industria: values.industria,
      rut: values.rutcontacto,
      occupation: values.cargocontacto,
      area: values.area,
      reason: values.reason,
      comments: values.comments,
      idcompany: responseData5._id,
      nationality: values.nationality,
      nationalitycon: values.nationalitycon,

    }),


  });
  const response2 = await userResponse2.json();
  let userIdd = null; // Declare idcompany in an outer scope
  userIdd = response2._id; // Assign a value to idcompany
  companyDataToPost.userIdcontacto = response2._id;
  //post dd : fer+datos organización


      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}dd`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...companyDataToPost,  nombrecliente: loggedclient.nombreempresa, 
        }),  
      
      });


      const responseData = await userResponse.json();



      if (userResponse.ok) {
        const { emailSent } = responseData;
        setDialogMessage(emailSent
          ? "El formulario de Diligencia Debida (FDD) a completar fue enviado por mail a la contraparte. Mientras tanto  Ud. puede seleccionar su diligencia Debida en la grilla y continuar con  su proceso de Checklist."
          : "Puede seleccionar su diligencia Debida en la grilla y continuar con su proceso de Checklist.");

        setShowSuccessPopup(true);
        const sessionType = result === "Riesgo Alto" ? "DueD RA" : "DueD RMB";

        RegSession({ token, userprofile, loggedInUserId, idcontrato, type: sessionType });
        onSubmitProps.resetForm();
        navigate("/dd");
      } else {
        console.error("Error storing user data:", userResponse.status, userResponse.statusText);
      }
    
     


    } catch (err) {
      console.log("Error creating dd:", err);
  
      if (err.response) {
        console.log("Response status:", err.response.status);
  
        // Use the .text() method to get the response body as text
        err.response.text().then(responseText => {
          console.log("Response data:", responseText);
        });
      }
    }
  };
 

  
  const handleCloseDialog = () => {
    setShowSuccessPopup(false);
    setShowCreateCampaign(false); // Add this line to close the create campaign dialog
    navigate("/successPage");

  };

  return (

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
    validateOnChange={false}
      validateOnBlur={true}


  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
 <p></p>
 
<Box>
<FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4" }}>
    <InputLabel>{<FormattedMessage id="Seleccionar Contraparte"/>}</InputLabel>

    <Select
            label={<FormattedMessage id="Contraparte que se hará la inteligencia debida"/>}
            value={values.nombreempresa}
            name="nombreempresa"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);

              const selectedCompany = dds.find(
                (company) => company.nombreempresa === e.target.value
              );

              if (selectedCompany || e.target.value === 'nuevaEmpresa') {
                setValues((prevValues) => ({ ...prevValues, nombreempresa: e.target.value }));
                setNewCompanyName(e.target.value);

                // Fetch company data if it's an existing company
                if (selectedCompany) {
                  console.log("companyselected", selectedCompany._id);
                  fetchCompanyData(selectedCompany._id);
                }
              }
            }}
      
      
      error={Boolean(touched.nombreempresa) && Boolean(errors.nombreempresa)}
    >
      <MenuItem value="nuevaEmpresa">
        {<FormattedMessage id="Nueva Contraparte"/>}
      </MenuItem>

      <MenuItem value="">
        <em>{<FormattedMessage id="Seleccionar Contraparte"/>}</em>
      </MenuItem>

      {[...new Set(dds.map(company => company.nombreempresa))].map((nombreempresa, index) => (
        <MenuItem key={index} value={nombreempresa}>
          {nombreempresa}
        </MenuItem>
      ))}
    </Select>

    {/* Campo de entrada para la nueva organización */}
    {values.nombreempresa === "nuevaEmpresa" && (
      <>
        <TextField
          label={<FormattedMessage id="Nombre Organización Contraparte"/>}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            setValues((prevValues) => ({ ...prevValues, nombreempresa: e.target.value }));
            setNewCompanyName(e.target.value);
          }}
          value={newCompanyName === undefined ? '' : newCompanyName}
          name="newCompanyName"
          error={Boolean(touched.nombreempresa) && Boolean(errors.nombreempresa)}
          fullWidth
          sx={{
            borderRadius: "20px",
            gridColumn: "span 4",
            '& input': {
              background: 'white',
            },
          }}
        />

      {/* New "RUT Organización" TextField */}
      <TextField
        label={<FormattedMessage id="RUT/TAX ID Organización"/>}
        onBlur={handleBlur}
        onChange={(event) => {
          // Use a regular expression to allow only numbers and no dots or characters
          const newValue = event.target.value.replace(/[^0-9ABCDEFGHIJKLMNOPQRSTUVWXYZ]/gi, '');
          handleChange({ target: { name: 'rutempresa', value: newValue } });
        }}
        value={values.rutempresa}
        name="rutempresa"
        error={Boolean(touched.rutempresa) && Boolean(errors.rutempresa)}
        helperText={touched.rutempresa && errors.rutempresa}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />

       {/* New "RUT Organización" TextField */}
       <FormControl fullWidth sx={{ borderRadius: '20px', gridColumn: 'span 4' }}>
       <InputLabel id="nationality-label">{<FormattedMessage id="Nacionalidad" />} </InputLabel>
    <Select
      labelId="nationality-label"
      id="nationality"
      name="nationality"
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.nationality}
      error={Boolean(touched.nationality) && Boolean(errors.nationality)}
    >
      {countries.map((country, index) => (
        <MenuItem key={index} value={country}>
          {country}
        </MenuItem>
      ))}
    </Select>
    {touched.nationality && errors.nationality && (
      <FormHelperText error>{errors.nationality}</FormHelperText>
    )}
  </FormControl>



  <TextField
        label={<FormattedMessage id="Rubro"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.industria}
        name="industria"
        error={Boolean(touched.industria) && Boolean(errors.industria)}
        helperText={touched.industria && errors.industria}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />

      {/* Additional fields */}
      <TextField
        label={<FormattedMessage id="Domicilio Organización Contraparte"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.address}
        name="address"
        error={Boolean(touched.address) && Boolean(errors.address)}
        helperText={touched.address && errors.address}
        multiline
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />

<TextField
        label={<FormattedMessage id="Número SAP"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.numeroSAP}
        name="numeroSAP"
        error={Boolean(touched.numeroSAP) && Boolean(errors.numeroSAP)}
        helperText={touched.numeroSAP && errors.numeroSAP}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />




<TextField
        label={<FormattedMessage id="Nombres de Contacto"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.firstNameContacto}
        name="firstNameContacto"
        error={Boolean(touched.firstNameContacto) && Boolean(errors.firstNameContacto)}
        helperText={touched.firstNameContacto && errors.firstNameContacto}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />


<TextField
        label={<FormattedMessage id="Apellidos de Contacto"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.lastNameContacto}
        name="lastNameContacto"
        error={Boolean(touched.lastNameContacto) && Boolean(errors.lastNameContacto)}
        helperText={touched.lastNameContacto && errors.lastNameContacto}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />


<TextField
        label={<FormattedMessage id="Rut de Contacto"/>}
        onBlur={handleBlur}
        onChange={(event) => {
          // Use a regular expression to allow only numbers and no dots or characters
          const newValue = event.target.value.replace(/[^0-9K]/gi, '');
          handleChange({ target: { name: 'rutcontacto', value: newValue } });
        }}
        value={values.rutcontacto}
        name="rutcontacto"
        error={Boolean(touched.rutcontacto) && Boolean(errors.rutcontacto)}
        helperText={touched.rutcontacto && errors.rutcontacto}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />


<TextField
        label={<FormattedMessage id="Email de Contacto"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.emailcontacto}
        name="emailcontacto"
        error={Boolean(touched.emailcontacto) && Boolean(errors.emailcontacto)}
        helperText={touched.emailcontacto && errors.emailcontacto}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />

<FormControl fullWidth sx={{ borderRadius: '20px', gridColumn: 'span 4' }}>
    <InputLabel id="nationalitycon-label">{<FormattedMessage id="Nacionalidad Contacto"/>}</InputLabel>
    <Select
      labelId="nationalitycon-label"
      id="nationalitycon"
      name="nationalitycon"
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.nationalitycon}
      error={Boolean(touched.nationalitycon) && Boolean(errors.nationalitycon)}
    >
      {countries.map((country, index) => (
        <MenuItem key={index} value={country}>
          {country}
        </MenuItem>
      ))}
    </Select>
    {touched.nationalitycon && errors.nationalitycon && (
      <FormHelperText error>{errors.nationalitycon}</FormHelperText>
    )}
  </FormControl>





<TextField
        label={<FormattedMessage id="Cargo de Contacto"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.cargocontacto}
        name="cargocontacto"
        error={Boolean(touched.cargocontacto) && Boolean(errors.cargocontacto)}
        helperText={touched.cargocontacto && errors.cargocontacto}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />



      <TextField
        label={<FormattedMessage id="Telefono de Contacto"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.celularcontacto}
        name="celularcontacto"
        error={Boolean(touched.celularcontacto) && Boolean(errors.celularcontacto)}
        helperText={touched.celularcontacto && errors.celularcontacto}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />


<TextField
        label={<FormattedMessage id="Nombre Completo Representante Legal de la contraparte"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.representante}
        name="representante"
        error={Boolean(touched.representante) && Boolean(errors.representante)}
        helperText={touched.representante && errors.representante}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />

<FormControl fullWidth sx={{ borderRadius: '20px', gridColumn: 'span 4' }}>
    <InputLabel id="nationalityrep-label">{<FormattedMessage id="Nacionalidad Representante Legal"/>}</InputLabel>
    <Select
      labelId="nationalityrep-label"
      id="nationalityrep"
      name="nationalityrep"
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.nationalityrep}
      error={Boolean(touched.nationalityrep) && Boolean(errors.nationalityrep)}
    >
      {countries.map((country, index) => (
        <MenuItem key={index} value={country}>
          {country}
        </MenuItem>
      ))}
    </Select>
    {touched.nationalityrep && errors.nationalityrep && (
      <FormHelperText error>{errors.nationalityrep}</FormHelperText>
    )}
  </FormControl>



      <TextField
        label={<FormattedMessage id="Nombre de Fantasía de la organización"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.nombrefantasia}
        name="nombrefantasia"
        error={Boolean(touched.nombrefantasia) && Boolean(errors.nombrefantasia)}
        helperText={touched.nombrefantasia && errors.nombrefantasia}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />

     

      <TextField
        label={<FormattedMessage id="Razón de Contratación de la Contraparte"/>} 
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.reason}
        name="reason"
        error={Boolean(touched.reason) && Boolean(errors.reason)}
        helperText={touched.reason && errors.reason}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />




      <TextField
        label={<FormattedMessage id="Comentarios Adicionales"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.comments}
        name="comments"
        error={Boolean(touched.comments) && Boolean(errors.comments)}
        helperText={touched.comments && errors.comments}
        multiline
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />
    </>
  )}



</FormControl>


 </Box>
 <Dialog
        open={showSuccessPopup}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Proceso Iniciado Exitosamente</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
          <p></p>

          <Box
  display="grid"
  gap="30px"
  gridTemplateColumns="1fr"  // Set to a single column
  sx={{
    borderRadius: "20px",
    "& > div": { gridColumn: "span 1" }, // Adjusting column span
  }}
>

  <div>
  <Typography variant="h3" gutterBottom>
  {<FormattedMessage id="Formulario de Evaluacion de Riesgo"/>}  
    </Typography>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    <FormattedMessage id="(i) ¿La Contraparte efectúa alguno de los siguientes giros: fundación, corporación sin fines de lucro, transporte, turismo, catering, taller de mantención?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={values.opGiro}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
      <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>





  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(ii) ¿La Contraparte firmará un contrato y/o la contraprestación que realizará es demostrable, como por ejemplo arreglar una cañería, comprar clavos, realizó publicidad etc?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opContrato"
      value={values.opContrato}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(iii) Estimación del Pago Anual superior a 100.000 USD anual?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opPagoanual"
      value={values.opPagoanual}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
      <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Si igual o más de 100.000 USD año"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No, menos de 100.000 USD año"/>} />
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Organización o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opReprenta"
      value={values.opReprenta}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(v) ¿La Contraparte se encuentra en el grupo que representa acumulado el 80% de Facturación anual?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="op80"
      value={values.op80}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(vi) ¿Existe algún conflicto de interés entre la Contraparte y La Organización, sus filiales, o algún colaborador de esta?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opCI"
      value={values.opCI}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>
  <div>
  <Typography variant="h6" gutterBottom>
                <FormattedMessage id="(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal?" />
                <InfoIcon onClick={() => setShowInfo(!showInfo)} />
              </Typography>
              {/* Explanatory Text */}
              <Dialog open={showInfo} onClose={() => setShowInfo(false)}>
                <DialogTitle><FormattedMessage id="Paraisos Fiscales: " /></DialogTitle>
                
                <DialogContent>
                <FormattedMessage id="Principado de Andorra, Anguila, Antigua y Barbuda, Aruba, Bahamas, Baréin, Barbados, Belice, Bermudas, Islas Vírgenes Británicas, Islas Caimán, Islas Cook, República de Chipre, Dominica, Gibraltar, Granada, Guernsey, Isla de Man, Jersey, República de Liberia, Malta, República de Mauricio, Montserrat, Antillas Neerlandesas, Niue, República de Panamá, Samoa, República de San Marino, San Cristóbal (San Kitts) y Nevis, Santa Lucía, República de Seychelles"/></DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowInfo(false)} color="primary">
                    <FormattedMessage id="Cerrar" />
                  </Button>
                </DialogActions>
              </Dialog>
    <RadioGroup
      aria-label="Opción"
      name="opParaiso"
      value={values.opParaiso}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>

  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(viii) ¿Es el país de pago de la Contraparte distinto al de la ubicación de su domicilio, por ejemplo la organización se encuentra en Argentina y pide los depositos de sus pagos en una cuenta de un banco en Bahamas?"/>}
    </Typography>
     {/* Explanatory Text */}
    
    <RadioGroup
      aria-label="Opción"
      name="opPaispago"
      value={values.opPaispago}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(ix) ¿La Contraparte ha sido sugerida por algún funcionario público? Por ejemplo empleados de Codelco, Ministerios, Servicios Públicos, Intendencias, Gobernaciones, entre otros"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opSugpep"
      value={values.opSugpep}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(x) ¿Algún hallazgo al consultar la situación tributaria de la contraparte en el sitio del SII.cl o en el sitio de impuestos internos de su país?"/>}
    <InfoIcon onClick={() => setShowInfo3(!showInfo3)} />

    </Typography>
    <Dialog open={showInfo3} onClose={() => setShowInfo3(false)}>
                <DialogTitle><FormattedMessage id="Impuestos Internos" /></DialogTitle>
                <DialogContent>
                Chile: https://zeus.sii.cl/cvc/stc/stc.html
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowInfo3(false)} color="primary">
                    <FormattedMessage id="Cerrar" />
                  </Button>
                </DialogActions>
              </Dialog>
    <RadioGroup
      aria-label="Opción"
      name="opSii"
      value={values.opSii}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(xi) ¿Encontró en la Dirección del Trabajo la existencia de Mora Previsional respecto de los trabajadores de la contraparte?"/>}
    <InfoIcon onClick={() => setShowInfo2(!showInfo2)} />
    </Typography>
     {/* Explanatory Text */}
     <Dialog open={showInfo2} onClose={() => setShowInfo2(false)}>
                <DialogTitle><FormattedMessage id="Direccion del Trabajo" /></DialogTitle>
                <DialogContent>
                Chile: https://tramites.dirtrab.cl/webitel2013/MoraPrev/GetMoraPrev.aspx
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowInfo2(false)} color="primary">
                    <FormattedMessage id="Cerrar" />
                  </Button>
                </DialogActions>
              </Dialog>
    <RadioGroup
      aria-label="Opción"
      name="opMoraprev"
      value={values.opMoraprev}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>


  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios?, adjunte aquí abajo si corresponde"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opComseeker"
      value={values.opComseeker}
      onChange={handleChange}
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required/>} label={<FormattedMessage id="Sí"/>} />
      <FormControlLabel value="No" control={<Radio  required/>} label={<FormattedMessage id="No"/>} />
    </RadioGroup>
  </div>
</Box>

   <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  acceptedFiles.forEach((file) => {
                  });
                  setUploadedFiles(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures || values.pictures.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>{<FormattedMessage id="Agregue aquí los Archivos, máximo 5Mb"/>}</p>
                    ) : (
                      <div>
                        {values.pictures.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>




                </Box>

                <div>
    

   
    </div>
          
        {/* BUTTONS */}
        <Box>
        <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              {<FormattedMessage id="Enviar"/>}
            </Button>
            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
              {<FormattedMessage id="Envio exitoso"/>}
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateDuediligence;