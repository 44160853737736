import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Header from "../../components/Header";
import BarChart from "../../components/BarChart";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import React, { useState, useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import TtvaluependWidget from "../../components/TtvaluependWidget";
import ComplaintpendWidget from "../../components/ComplaintpendWidget";
import FormpendWidget from "../../components/FormpendWidget";
import MeetingpenWidget from "../../components/MeetingpenWidget";
import DdPendingWidget from "../../components/DdPendingWidget";

import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MailsOutlinedIcon from "@mui/icons-material/SendSharp";
import MeetingRoom from "@mui/icons-material/MeetingRoom";
import CrisisAlert from "@mui/icons-material/CrisisAlert";
import CompareArrows from "@mui/icons-material/CompareArrows";
import ArrowForward from "@mui/icons-material/ArrowForward";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Attachment from "@mui/icons-material/Attachment";

import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { green } from "@mui/material/colors"; // Import the green color from Material-UI
import { FormattedMessage } from 'react-intl';

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { _id } = useParams();
  const loggedInUserId = useSelector((state) => state.user._id);
  const token = useSelector((state) => state.token);
  const [data, setData] = useState([]);
  const loggedidcontrato = useSelector((state) => state.user.idcontrato);
  const [formData, setformData] = useState([]);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [totalDeclarados, setTotalDeclarados] = useState(0);
  const [totalcam, setTotalcam] = useState(0);
  const [totalEvaluados, setTotalEvaluados] = useState(0);
  const [totalCampaign, setTotalCam] = useState(0);
  const [totalAutorizados, setTotalAutorizados] = useState(0);
  const [totalConConflicto, setTotalConConflicto] = useState(0);
  const [totalConMitigacion, setTotalConMitigacion] = useState(0);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const userProfile = useSelector((state) => state.user.userprofile);
  const [formTotalDays, setTotalDays] = useState([]);

  const { REACT_APP_API_ENDPOINT } = process.env;




  useEffect(() => {
    fetchData();
  }, []);

  // mi equipo forms data
  const fetchData = async () => {
    try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}formepd/contract/${loggedidcontrato}`, { //activar cuando muestre por contrato
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

          const formData = await response.json();
        

                // Add unique id and format createdAt
                const dataWithFormattedDate = formData.map((row) => ({
                  ...row,
                  id: row._id,
                  createdAt: row.createdAt
                    ? new Date(row.createdAt).toLocaleDateString("en-EN", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })
                    : "",
                }));
      
                const postsResponse = await fetch(`${REACT_APP_API_ENDPOINT}posts/contract/${loggedidcontrato}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });    
                
                const postsData1 = await postsResponse.json();
                  // Filter the campaign data based on the opcampaign field
                const postsData = postsData1.filter(row => row.opCampaign !== "login");
    
 

                const totalcam = postsData.reduce((total, post) => total + post.description.length, 0);
                setTotalcam(totalcam);

      // Update data with post IDs based on email search
      const updatedData = dataWithFormattedDate.map((row) => {
        const matchingPost = postsData.find((post) =>
          post.description.includes(row.email)
        );

        const campaignName = matchingPost ? matchingPost.comments : "";

        const campaignCreatedAt = matchingPost && matchingPost.createdAt
        ? new Date(matchingPost.createdAt)
        : null;
      
      const formCreatedAt = row.createdAt
        ? new Date(row.createdAt)
        : null;
      
      const timeDifference =
        campaignCreatedAt && formCreatedAt
          ? campaignCreatedAt.getTime() - formCreatedAt.getTime() // Calculate the time difference in milliseconds
          : 0;
      
      const formTotalDays = Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
      
      // Now, you can convert valid dates to ISO strings if needed
      const campaignCreatedAtISO = campaignCreatedAt ? campaignCreatedAt.toISOString() : null;
      const formCreatedAtISO = formCreatedAt ? formCreatedAt.toISOString() : null;



        return {
          ...row,
          postId: matchingPost ? matchingPost._id : "",
          campaignName: campaignName,
          formTotalDays: formTotalDays,

        };

      });

    //  setTotalDays(formTotalDays);
      setData(updatedData);
   
      // Filter data by estado "pendiente" 
      const filteredData = updatedData.filter((row) => row.estado === "Por Evaluar");
      setFilteredData(filteredData);
 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 



 
 
  const handleButtonClick = (route) => {
    // Use the `navigate` function to navigate to the specified route
    navigate(route);
  };
 
 





  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  
//****************************************************
return (
  <Box m="20px"> 
         
         <Box m="20px" display="flex" justifyContent="space-between">
  <Box
        display="flex"
        bgcolor="white" // Set background color to white
        boxShadow={3} // Add some shadow for a nice effect
        borderRadius={3} // Rounded corners
        style={{ textAlign: "right" }} // Align text to the right
        justifyContent="space-between"
        alignItems="center"
        marginTop="20px"
        paddingLeft="60px" paddingRight="60px"
      >


      <Link to="/complaints" style={{ textDecoration: "none", textAlign: "center" }}>
      <Box>

        <IconButton
            style={{ color: "red", fontSize: "2rem" }} // Set the color to red and increase the font size


          color="secondary"
          onClick={() => handleButtonClick("/complaints")}
        >
    
          <CrisisAlert />
        </IconButton>
        
        <Typography variant="h4" fontWeight="600"   style={{ color: "red" }} >
          <FormattedMessage id="DENUNCIAS" />
    
</Typography>
</Box>
      </Link>




   
    </Box>
    </Box>


    {/* HEADER */}
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Header title="" subtitle="" />
    </Box>

    {/* GRID & CHARTS */}
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="140px"
      gap="20px"
    >




{/* ROW 2 */}
   
<Box
  gridColumn={{ xs: 'span 12', md: 'span 4' }}
  gridRow="span 3"
        backgroundColor={colors.primary[400]}
        overflow="auto"
    >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.primary[500]}`}
          colors={colors.grey[100]}
          p="15px"
        >
          <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            <FormattedMessage id="Declaraciones Pendientes de Aprobación"/>

          </Typography>
        </Box>

        <FormpendWidget _id={_id} />

       

</Box>

<Box
  gridColumn={{ xs: 'span 12', md: 'span 4' }}
  gridRow="span 3"
        backgroundColor={colors.primary[400]}
        overflow="auto"
    >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.primary[500]}`}
          colors={colors.grey[100]}
          p="15px"
        >
          <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            <FormattedMessage id="Transferencias de Valor Pendientes de Aprobación"/>

          </Typography>
        </Box>

        <TtvaluependWidget _id={_id} />

       

</Box>



{userProfile === "comitetica" && (
<Box
  gridColumn={{ xs: 'span 12', md: 'span 4' }}
  gridRow="span 3"
  backgroundColor={colors.primary[400]}
  overflow="auto"
>
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    borderBottom={`4px solid ${colors.primary[500]}`}
    colors={colors.grey[100]}
    p="15px"
  >
    <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
      <FormattedMessage id=" Denuncias Pendientes de Investigación"/>

    </Typography>
  </Box>
  <ComplaintpendWidget _id={_id} />
</Box>
)}


<Box
  gridColumn={{ xs: 'span 12', md: 'span 4' }}
  gridRow="span 3"
        backgroundColor={colors.primary[400]}
        overflow="auto"
    >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.primary[500]}`}
          colors={colors.grey[100]}
          p="15px"
        >
          <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            <FormattedMessage id="Ultimas Reuniones Pendientes de Aprobación"/>

          </Typography>
        </Box>

        <MeetingpenWidget _id={_id} />

       

</Box>

<Box
    gridColumn={{ xs: 'span 12', md: 'span 4' }}
    gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
      >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              <FormattedMessage id="Diligencias Debidas Organizaciones"/>

            </Typography>
          </Box>

          <DdPendingWidget _id={_id} />

         

</Box>


        </Box>
        
      
    <Snackbar
open={notificationOpen}
autoHideDuration={14000}
onClose={() => setNotificationOpen(false)}
>
<MuiAlert
  elevation={6}
  variant="filled"
  onClose={() => setNotificationOpen(false)}
  severity="info"
>
  {notificationMessage}
</MuiAlert>
</Snackbar>
  </Box>

  
);
};

export default Dashboard;
