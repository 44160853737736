import { Box, Typography, Checkbox, useTheme, Slider, IconButton, Button, Modal, TextField } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";
import { Document, Page, pdfjs } from 'react-pdf';
import ProgressBarr from "../../components/ProgressBarr";

import { tokens } from "../../theme";
import CreateCampaign from "../../components/CreateCampaign";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import pdficon from '../../assets/pdf-icon.png';

import Header from "../../components/Header";
import { useMediaQuery } from "@mui/material";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import FlexBetween from "../../components/FlexBetween";
import { useParams } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";import CreateClient from "../../components/CreateClient";
import Paper from '@mui/material/Paper'; // Import the Paper component
import { Print } from '@mui/icons-material';

import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import DropfileRepo from '../../components/DropfilesRepo';
import ChatWidget from '../../components/chatWidget';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { FormattedMessage } from 'react-intl';
import { useReactToPrint } from "react-to-print";

import jsPDF from "jspdf";
import "jspdf-autotable";

import Certificon from '@mui/icons-material/Grading';
import PdfViewerModal from "../../components/pdfviewer"; //modal checklist viewer
import GroupIcon from '@mui/icons-material/PendingActions';
import PdfCertModalnon from "../../components/pdfcertchecknon"; //modal pendiente por file
import PdfCertModalTotalNon from "../../components/pdfcertchecknontotal"; //modal pendientes total
import PdfCertModalTotal from "../../components/pdfcertchecktotal";// modal leidos total
import PdfCertModal from "../../components/pdfcertcheck";// modal leidos por file

const Files = ({ }) => {

  const [textStyleColor, setTextStyleColor] = useState('red'); // Default color


  const [sessionDatax, setSessionDatax] = useState({});

  const componentRef = useRef();
  const [showPdfPopup, setShowPdfPopup] = useState(false);
  const [confirmation, setConfirmation] = useState(null); // null: initial state, "si", or "no"
  const [pageNumber, setPageNumber] = useState(1);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [agreement, setAgreement] = useState(false);

const location = useLocation();
const idcontrato = useSelector((state) => state.user.idcontrato);
const [editedRows, setEditedRows] = useState({});
const [showCreateClient, setShowCreateClient] = useState(false);
const { _id, picturePath, email } = useSelector((state) => state.user);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedinuserprofile = useSelector((state) => state.user.userprofile); 
  const [form, setForm] = useState({ picturePath: [] }); // Initialize as an empty object with pictures array

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dateRange, setDateRange] = useState([0, 100]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [totalDescriptionsCount, setTotalDescriptionsCount] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
    const s3BucketURL = 'https://repoeticpro.s3.amazonaws.com';
    const [arnombre, setArnombre] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [matchingFile, setMatchingFile] = useState(null);
    const [nonMatchingUsers, setNonMatchingUsers] = useState([]);
    const [nonMatchingFiles, setNonMatchingFiles] = useState([]);


  

  const fetchData = async () => {
    try {
      const responseRepository = await fetch(`${REACT_APP_API_ENDPOINT}repository/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const responseSession = await fetch(`${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!responseRepository.ok || !responseSession.ok) {
        throw new Error(`One or more API requests failed`);
      }
  
      const formData = await responseRepository.json();
      const sessionData = await responseSession.json();
  
      setSessionDatax(sessionData);
    // Log the fileName after successful data fetch

     // Format and set the data state
const dataWithFormattedDate = formData.map((row, index) => ({
  ...row,
  id: row._id, // Assuming _id is in formData
  createdAt: row.createdAt
    ? new Date(row.createdAt).toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })
    : "",
}));

setData(dataWithFormattedDate);
  } catch (error) {
    console.error("Error fetching session data:", error);
  }
};
  
const handleOpenPDF = (file, isMatchingUser, isMatchingFileName) => {
  const s3BaseUrl = 'https://repoeticpro.s3.amazonaws.com';
    // Construct the full URL to the PDF file stored in S3
    const s3Url = `${s3BaseUrl}/${encodeURIComponent(file.picturePath)}`;
    let computedColor = 'red'; // Default to red

    if (file.picturePath) {
      setArnombre(file.picturePath);
      setCurrentPdf(s3Url);
      setOpenPdfModal(true);
      setTextStyleColor(isMatchingUser && isMatchingFileName ? 'green' : 'red'); // Use isMatchingUser here
console.log("ismatchinguserrrxcolor", isMatchingUser);
console.log("ismatchinguserrrxcolorx", isMatchingFileName);

    
  } else {
      console.error("File picturePath is empty or undefined.");
  }
  };

  
  const handleConfirmadosClick = async () => {
    try {
      // Fetch sessions for the specific contract
      const responseSession = await fetch(`${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!responseSession.ok) {
        console.error("Failed to fetch sessions");
        return;
      }
  
      const sessionData = await responseSession.json();
  
      // Fetch additional user information for each session
      const matchingUsersPromises = sessionData.map(async (session) => {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}users/${session.userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = await response.json();
  
        return {
          userId: session.userId,
          firstName: userData?.firstName || '', // Use optional chaining (?.) here
          lastName: userData?.lastName || '',
          rut: userData?.rut || '',
          email: userData?.email || '',
          filenombre: session.fileName || '',
          occupation: userData?.occupation || '',
          createdAt: session.createdAt,
        };
      });
  

      const matchingUsers = await Promise.all(matchingUsersPromises);
      // Filter out users with undefined or empty filenombre
        const filteredUsers = matchingUsers.filter(user => user.filenombre);

    
        // Set the state with filtered users
        setFilteredUsers(filteredUsers);
        setPrintModalTotalOpen(true); // Open PdfViewerModal
    } catch (error) {
      console.error('Error handling Confirmados:', error);
    }
  };
  

  const handleCertificonClickTotalNon = async () => {
    try {
      // Fetch all users for the specific contract
      const responseUsers = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!responseUsers.ok) {
        console.error("Failed to fetch users");
        return;
      }
  
      const userData = await responseUsers.json();
  
      // Fetch sessions for the specific contract
      const responseSession = await fetch(`${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!responseSession.ok) {
        console.error("Failed to fetch sessions");
        return;
      }
  
      const sessionData = await responseSession.json();
  
      // Check for null or undefined before filtering
      if (userData === null || sessionData === null) {
        console.error("User data or session data is null");
        return;
      }
  
      // Get all user IDs from sessions with confirmed files
      const confirmedUsers = sessionData.map((session) => session.userId);
  
      // Filter out users who are not in confirmed sessions
      const nonMatchingUsers = userData.filter((user) => user._id && !confirmedUsers.includes(user._id));
  
      // Set the state with non-matching users and open the modal
      setFilteredUsers(nonMatchingUsers);
      setOpenPdfModalTotalNon(true);
    } catch (error) {
      console.error('Error handling CertificonClickTotalNon:', error);
    }
  };
  

const handleCertificonClick = async (file) => {
  try {
    setPrintModalOpen(true);
    setCertificateContent(file.picturePath);
    setArnombre(file.picturePath);

    // Filter the sessions based on the file name
    const filteredSessions = sessionDatax.filter((session) =>
      session.fileName && file.picturePath &&
      session.fileName.toLowerCase() === file.picturePath.toLowerCase()
    );

    // Fetch additional user information for each matching session
    const matchingUsersPromises = filteredSessions.map(async (session) => {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}users/${session.userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const userData = await response.json();

      return {
        userId: session.userId,
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        rut: userData.rut || '',
        email: userData.email || '',
        occupation: userData.occupation || '',
        createdAt: session.createdAt,
      };
    });

    // Wait for all promises to resolve
    const matchingUsers = await Promise.all(matchingUsersPromises);

    // Set the state with matching users and file
    setFilteredUsers(matchingUsers);
    setMatchingFile(file);
  } catch (error) {
    console.error('Error handling CertificonClick:', error);
  }
};

  
  const handleCertificonClickNon = async (file) => {
    setOpenPdfModalNon(true);
    setCertificateContent(file.picturePath);

    // Fetch all users for the specific contract
    const responseUsers = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!responseUsers.ok) {
      console.error("Failed to fetch users");
      return;
    }

    const userData = await responseUsers.json();

    // Fetch sessions for the specific contract
    const responseSession = await fetch(`${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!responseSession.ok) {
      console.error("Failed to fetch sessions");
      return;
    }

    const sessionData = await responseSession.json();

    // Check for null or undefined before filtering
    if (userData === null || sessionData === null) {
      console.error("User data or session data is null");
      return;
    }

    // Get all user IDs from sessions with the specified file name
    const usersInSessions = sessionData
      .filter((session) => session.fileName && session.fileName.toLowerCase() === file.picturePath.toLowerCase())
      .map((session) => session.userId);

    // Filter out users who are not in sessions

    
    const nonMatchingUsers = userData
    .filter((user) => user._id && !usersInSessions.includes(user._id));


    // Set the state with non-matching users and file
    setFilteredUsers(nonMatchingUsers);
    setMatchingFile(file);

    // Open PdfCertModalnon
    setOpenPdfModalNon(true);
  };

  // Make sure you have the state for PdfCertModalnon
  const [openPdfModalNon, setOpenPdfModalNon] = useState(false);
  const [openPdfModalTotalNon, setOpenPdfModalTotalNon] = useState(false);
  const [openPdfModalTotal, setOpenPdfModalTotal] = useState(false);

  

  const handleClosePdfModal = () => {
    setOpenPdfModal(false);

    setCurrentPdf(null);
  };

  const [certificateContent, setCertificateContent] = useState('');
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [printModalTotalOpen, setPrintModalTotalOpen] = useState(false);
  const [printModalTotalOpenNon, setPrintModalTotalOpenNon] = useState(false);


  const handleClosePrintModal = () => {
    setPrintModalOpen(false);
    setPrintModalTotalOpenNon(false);
    setPrintModalTotalOpen(false);

  };

  let filteredData = data;

  const handleFileUploaded = () => {
    // This function will be called when a file is successfully uploaded in Dropfiles
    // Place your code for the useEffect here
    // You can also pass any data from Dropfiles as needed
    // Example: fetchData();
  };


  const AWS = require('aws-sdk');

// Load your AWS credentials from environment variables
  AWS.config.update({
  
  accessKeyId: process.env.AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
  region: "sa-east-1", // Set your AWS region
  });

  const s3 = new AWS.S3();

  const FileItem = ({ file, onDelete, onCertificonClickNon, onCertificonClick, documentNumber, userId, sessionFileName, arnombre, sessionData }) => {
    const [isMatchingUser, setIsMatchingUser] = useState(false);
  const [isMatchingFileName, setIsMatchingFileName] = useState(false);

  useEffect(() => {
    // Set isMatchingUser and isMatchingFileName based on conditions
    const newUserMatch = userId === loggedInUserId;
    const newFileNameMatch = sessionFileName.some(item =>
      item.fileName && file.picturePath &&
      item.fileName.toLowerCase() === file.picturePath.toLowerCase()
    );

    setIsMatchingUser(newUserMatch);
    setIsMatchingFileName(newFileNameMatch);



  }, [userId, loggedInUserId, sessionFileName, file, textStyleColor]);

  const textStyle = {
    color: isMatchingUser && isMatchingFileName ? 'green' : 'red',
    // Add other styling properties if needed
  };
  
  const percentage = 1;


    const handleDownload = () => {
      const s3BaseUrl = 'https://repoeticpro.s3.amazonaws.com';
      const s3URL = `${s3BaseUrl}/${encodeURIComponent(file.picturePath)}`;

      const link = document.createElement('a');
      link.href = s3URL;
      link.setAttribute('download', file._id);
      document.body.appendChild(link); // Append to body to ensure it works across all browsers
      link.click();
      link.remove(); // Clean up after triggering the download
      // Async IIFE to log the download
      (async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}session/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              userId: loggedInUserId,
              fileName: file.picturePath, // Assuming file._id is the identifier for the file
              type: "Download",
              idcontrato: idcontrato,
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to log download');
          }

          // Optionally handle the successful response
        } catch (error) {
          console.error('Error logging download:', error);
        }
      })();
    };

    return (
      <Box display="grid" gridTemplateColumns="min-content auto min-content min-content min-content" alignItems="center" gap={2} sx={{ ...textStyle }}>
        <Typography sx={{ minWidth: '20px', textAlign: 'center' }}>{documentNumber}</Typography>
        <div>

          <a >

            <InsertDriveFileIcon /> {file.picturePath}
          </a>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {(loggedinuserprofile === 'admin' || loggedinuserprofile === 'superadmin') && (

        <IconButton onClick={() => onCertificonClickNon(file)}>
      <GroupIcon style={{ width: 20, height: 20 }} />
    </IconButton>
    )}
          {(loggedinuserprofile === 'admin' || loggedinuserprofile === 'superadmin') && (

          <IconButton onClick={() => onCertificonClick(file)}>
            <Certificon style={{ width: 20, height: 20 }} />
          </IconButton>
        )}

<IconButton onClick={() => handleOpenPDF(file, isMatchingUser, isMatchingFileName)}>
            <img src={pdficon} alt="PDF Icon" style={{ width: 20, height: 20 }} />
          </IconButton>

          <Typography>{file.createdAt}</Typography>
        </div>
        <div>
          <Typography variant="h6">{file.description}</Typography>
        </div>
        <div>
          {(loggedinuserprofile === 'admin' || loggedinuserprofile === 'superadmin') && (
            <IconButton onClick={() => onDelete(file._id)}>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </Box>
    );
  };

useEffect(() => {
  setShowSuccessPopup(false);
  setSnackbarOpen(false);
  fetchData();
}, [isFormSubmitted, dateRange, uploadedFiles]);




  const deleteRow = async (rowId) => {
    try {
      // Retrieve the file details from the database based on rowId
      const fileDetails = data.find((row) => row.id === rowId);

      if (!fileDetails) {
        console.error('File details not found');
        return;
      }

      // Remove the file from Amazon S3
      const s3BucketURL = 'https://repoeticpro.s3.amazonaws.com'; // Use the correct endpoint
      const s3URL = `${s3BucketURL}/${encodeURIComponent(fileDetails.picturePath)}`;

      const params = {
        Bucket: "repoeticpro", // Replace with your S3 bucket name
        Key: fileDetails.picturePath, // Replace with the S3 key (path) of the file
      };

      await s3.deleteObject(params).promise(); // Delete the file from S3

      // Send a DELETE request to delete the database record
      await fetch(`${REACT_APP_API_ENDPOINT}repository/${rowId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the data and filteredSearchData states after deleting the row
      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
      setFilteredSearchData(updatedData);
    } catch (error) {
      console.error('Error deleting row:', error);
    }
  };


  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };


  return (
    <Box m="20px">
    <Header title={<FormattedMessage id="Repositorio" />} subtitle={<FormattedMessage id="Documentos a leer" />} />
    <Box sx={{ borderRadius: "20px", mt: '1rem', display: 'flex', justifyContent: 'center' }}>
      
  {loggedinuserprofile === 'admin' || loggedinuserprofile === 'superadmin' && (

  <Button
    variant="contained"
    startIcon={<Print/>}
    onClick={handleConfirmadosClick}
    sx={{
      borderRadius: "20px",
      backgroundColor: colors.blueAccent[700],
      color: colors.grey[900],
      fontSize: "14px",
      fontWeight: "bold",
      paddingY: "10px",  // Adjust vertical padding as needed
      paddingX: "10px", // Add horizontal padding
      marginRight: "30px", // Add separation between buttons

    }}
  >
   
    {<FormattedMessage id="Confirmados"/>}
  </Button>
)}
  <p> </p>
  {loggedinuserprofile === 'admin' || loggedinuserprofile === 'superadmin' && (

  <Button
    variant="contained"
    startIcon={<Print/>}
    onClick={handleCertificonClickTotalNon}
    sx={{ borderRadius: "20px",
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[900],
                    fontSize: "14px",
                    fontWeight: "bold",
                    paddingY: "10px",  // Adjust vertical padding as needed
                    paddingX: "10px", // Add horizontal padding
                  }}
                
  >
    {<FormattedMessage id="Usuarios Sin Sesiones"/>}
  </Button>
  )}


<PdfViewerModal
  open={openPdfModal}
  onClose={handleClosePdfModal}
  pdfUrl={currentPdf}
  filename={arnombre}
  textStyleColor={textStyleColor}
/>



  <PdfCertModal
        open={printModalOpen}
        onClose={handleClosePrintModal}
        file={arnombre}
        users={filteredUsers}
        matchingFile={matchingFile}
      />

  <PdfCertModalTotal
        open={printModalTotalOpen}
        onClose={() => handleClosePrintModal(false)}
        file={arnombre}
        users={filteredUsers}
        matchingFile={matchingFile}
      />
      
      <PdfCertModalnon
        open={openPdfModalNon}
        onClose={() => setOpenPdfModalNon(false)}
        file={arnombre}
        users={filteredUsers}
        matchingFile={matchingFile}
        nonMatchingUsers={nonMatchingUsers} // Pass non-matching users here
      />

<PdfCertModalTotalNon
       open={openPdfModalTotalNon}
       onClose={() => setOpenPdfModalTotalNon(false)}
       file={arnombre}
       users={filteredUsers}
       matchingFile={matchingFile}
       nonMatchingUsers={nonMatchingUsers} // Pass non-matching users her
      />
  <p></p>
</Box>



{loggedinuserprofile === 'superadmin' || loggedinuserprofile === 'admin' && (

<Paper elevation={3} style={{ ...widgetStyle, marginTop: "1rem", marginBottom: "1rem" }}>
      <DropfileRepo _id={_id} onFileUploaded={handleFileUploaded} />
    </Paper>
        )}

  <React.Fragment>
  
    <Box m="40px 0 0 0" display="flex" justifyContent="space-between">
      <Paper elevation={3} style={{ ...widgetStyle, marginTop: "1rem", marginBottom: "1rem" }}>
        <div>

          {data.map((file, index) => (
            <FileItem
              key={file._id}
              file={file}
              onDelete={() => deleteRow(file._id)}
              onCertificonClick={() => handleCertificonClick(file)}
              onCertificonClickNon={() => handleCertificonClickNon(file)}
              documentNumber={index + 1}
              userId={loggedInUserId}
              sessionFileName={sessionDatax}
              arnombre={arnombre}
              textStyleColor={textStyleColor}
            />
          ))}
        </div>
      </Paper>
    </Box>
  </React.Fragment>
  

<p></p>
 
  </Box>



);
};

export default Files;
