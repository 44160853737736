import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { tokens } from "../../src/theme";
import { FormHelperText } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LogoImage from '../assets/eticprologo8.png';

const countries = ['admin', 'coadmin', 'declare', 'externo', 'comitetica', 'superadmin'];

const currentDate = new Date();
const endDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
const { REACT_APP_API_ENDPOINT } = process.env;

const CreateUser = ({ _id, updateDataGrid, triggerAlarm }) => {
  const location = useLocation();
  const theme = useTheme();
  const token = useSelector((state) => state.token);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const loggedInUserId = useSelector((state) => state.user._id);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const loggedInContract = useSelector((state) => state.user.idcontrato);
  const loggedclient = useSelector((state) => state.client);

  const loggedinuserlocation = useSelector((state) => state.user.location);

  const [users, setUsers] = useState([]); // State variable to store users
  const [selectedUser, setSelectedUser] = useState(""); // State variable to store selected user

  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const campaignSchema = yup.object().shape({
    title: yup.string().required("Se Requiere Texto"),
    message: yup.string().required("Se Requiere Texto"),
    profile: yup.string().notRequired("Seleccione un Perfil"), // Adding validation for user selection
    targetUser: yup.string().notRequired("Seleccione un Usuario"), // Adding validation for user selection
  });

  const initialValuesEditCampaign = {
    title: "",
    userId: loggedInUserId,
    message: "",
    profile: "",
    targetUser: "",
    picturePath: "",
    idcontrato: loggedInContract,
    estado: "Pendiente",
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${loggedInContract}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data); // Update users state with fetched data
      } else {
        console.error(`Failed to fetch users with status ${response.status}`);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
   

    fetchUsers();
  }, [loggedInContract]); // Fetch users when loggedInContract changes

// Define a function to send an email
const sendEmail = async ({ emails, emailTemplate, subject }) => {
  try {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        emails: emails,
        emailTemplate: emailTemplate,
        subject: subject,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to send email with status ${response.status}`);
    }

    console.log("Email sent successfully");
  } catch (error) {
    console.error("Error sending email:", error);
  }
};

// Define a function to send an SMS
const sendSMS = async ({ phonesms, smsTemplate }) => {
  try {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}sendSms/sendSms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        phonesms: phonesms,
        smsTemplate: smsTemplate,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to send SMS with status ${response.status}`);
    }

    console.log("SMS sent successfully");
  } catch (error) {
    console.error("Error sending SMS:", error);
  }
};


  const handleFormSubmit = async (values, onSubmitProps) => {
 

      try {
        const { targetUser, ...otherValues } = values; // Extract targetUser and other form values

        const emails = targetUser.split(',').map(email => email.trim()); // Split and trim email addresses
    console.log("emails", emails);
        for (const email of emails) {
          const updatedValues = { ...otherValues, targetuser: email, estado: "Enviado", targetprofile: values.profile };
    
          const response = await fetch(`${REACT_APP_API_ENDPOINT}message`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(updatedValues),
          });
    
          if (!response.ok) {
            throw new Error(`Failed to send message with status ${response.status}`);
          }
    

          const subject = `Aviso de Nuevo mensaje en Eticpro`;
     
          const emailTemplate = `
  <!DOCTYPE html>
  <html>
  <head>
    
  <style>
  .frame {
    border: 2px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
    text-align: left;
    position: relative; /* Add relative positioning to the frame */
  
  }
  
  .logo-container {
    position: absolute;
    top: 10px; /* Adjust top value to move the logo vertically */
    right: 10px; /* Adjust right value to move the logo horizontally */
  }
  
  /* Styles for the box containing the logo */
  .logo-box {
    text-align: center;
    margin-bottom: 20px;
  }
      .box {
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 5px;
      }
    </style>
  </head>
  <body>
  <div class="frame">
  <div class="logo-container">
    <img src="${LogoImage}" alt="Logo" className="logo" style={{  width: '10%' }}/>
  </div>
  <img src="${loggedclient.logo}" alt="Logo" style="width: 70px; height: 30px;">
  
  <p></p>
  
    <p>Estimado Usuario,</p>
  <p></p>
  <p></p>
  
    <p>Tiene un nuevo mensaje del equipo de cumplimiento de ${loggedclient.nombreempresa}</p>
  
    <div class="box">
      <p><strong>Referencia:</strong> ${values.title}</p>
      <p><strong>Mensaje:</strong> ${values.message}</p>
    </div>
  
  
    <p>Gracias por su atención.</p>
    <p>Equipo Encargado de Prevención de Delitos.</p>
    <p>Eticpro.</p>
  
    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
    <p>Escríbenos a hello@eticpro.com | Wsup: +569 77657352.
    </p>
    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
    <p>• No olvide que para ingresar a nuestra plataforma, debe ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${emails}
  
    </div>
  
  </body>
  </html>`;
  

await sendEmail({ emails: [email], emailTemplate, subject });

      // Fetch user information including "celular" field
      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}users/email/${email}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!userResponse.ok) {
        throw new Error(`Failed to fetch user information with status ${userResponse.status}`);
      }

      const userData = await userResponse.json();
      const firstUser = userData[0]; // Access the first element of the array
      const { celular } = firstUser; // Extract "celular" field from user data
      const phonesms = `+${celular}`; // agrega SIGNO + AL CELULAR!!!!

      // Send SMS
      await sendSMS({ phonesms, smsTemplate: `Hola, te informamos que ${loggedclient.nombreempresa} te ha enviado un mail a ${email}. Eticpro` });
    }

    onSubmitProps.resetForm();
    navigate("/successPage", {
      state: { message: "Mensaje enviado  con éxito" }
  });             
  } catch (error) {
    console.error(error);
  }
};

  if (isFormSubmitted) {
    return null;
  }


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesEditCampaign}
      validationSchema={campaignSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
            }}
          >
            <div>

<FormControl fullWidth sx={{ borderRadius: '20px', gridColumn: 'span 1' }}>
  <InputLabel id="selection-label">Seleccionar por</InputLabel>
  <Select
    labelId="selection-label"
    sx={{ 
      backgroundColor: "white",
    }}
    id="selection"
    name="selection"
    onBlur={handleBlur}
    onChange={handleChange}
    value={values.selection}
  >
    <MenuItem value="perfil">Perfil</MenuItem>
    <MenuItem value="usuario">Usuario</MenuItem>
    <MenuItem value="emails">Emails</MenuItem>
  </Select>
</FormControl>

  {/* Profile (Country) FormControl */}
  {values.selection === "perfil" && (
  <FormControl fullWidth sx={{ borderRadius: '20px', gridColumn: 'span 1' }}>
    <InputLabel id="profile-label">Perfil Destino</InputLabel>
    <Select
      labelId="profile-label"
      sx={{ 
        backgroundColor: "white",
      }}
      id="profile"
      name="profile"
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.profile}
      error={Boolean(touched.profile) && Boolean(errors.profile)}
    >
      {countries.map((country) => (
        <MenuItem key={country} value={country}>
          {country}
        </MenuItem>
      ))}
    </Select>
    {touched.profile && errors.profile && (
      <FormHelperText error>{errors.profile}</FormHelperText>
    )}
  </FormControl>
)}

{values.selection === "usuario" && (
  <FormControl fullWidth sx={{ borderRadius: '20px', gridColumn: 'span 1' }}>
    <InputLabel id="target-user-label">Usuario Destino</InputLabel>
    <Select
      labelId="target-user-label"
      sx={{
        backgroundColor: "white",
      }}
      id="target-user"
      name="targetUser" // Set the name attribute to "targetUser"
      onBlur={handleBlur}
      onChange={(e) => {
        handleChange(e);
        // Update the targetUser field to the email of the selected user
        setFieldValue("targetUser", e.target.value);
      }}
      value={values.targetUser} // Use targetUser directly as the value
      error={Boolean(touched.targetUser) && Boolean(errors.targetUser)}
    >
      {users.map((user) => (
        <MenuItem key={user._id} value={user.email}>
          {user.firstName} {user.lastName}
        </MenuItem>
      ))}
    </Select>


    {touched.emailsTxt && errors.emailsTxt && (
      <FormHelperText error>{errors.emailsTxt}</FormHelperText>
    )}
  </FormControl>
)}

{values.selection === "emails" && (
 <TextField
 label="Emails Usuarios"
 onBlur={handleBlur}
 onChange={handleChange}
 value={values.targetUser}
 name="targetUser"
 error={Boolean(touched.targetUser) && Boolean(errors.targetUser)}
 helperText={touched.targetUser && errors.targetUser}
 multiline
 fullWidth
 rows={5} // Set the number of rows to 3
 InputProps={{
   style: {
     background: 'white',
   },
 }}
/>

)}





</div>

<div>

            <TextField
              label="Titulo"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
              name="title"
              error={Boolean(touched.title) && Boolean(errors.title)}
              helperText={touched.title && errors.title}
              multiline
              fullWidth
              InputProps={{
                style: {
                  background: 'white',
                },
              }}
            />
             </div> 
            <TextField
              label="Mensaje"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.message}
              name="message"
              error={Boolean(touched.message) && Boolean(errors.message)}
              helperText={touched.message && errors.message}
              multiline
              fullWidth
              rows={4}
              maxRows={8}
              InputProps={{
                style: {
                  background: 'white',
                },
              }}
              sx={{ gridColumn: 'span 12' }}
            />
          </Box>
             
          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              Enviar
            </Button>
          {/* Add a success message box */}

          </Box>

          {isSuccessMessageVisible && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: colors.greenAccent[400], // Set the background color for success message
            color: 'white', // Set text color to white
            padding: '1rem',
            borderRadius: '15px',
            textAlign: 'center',
            zIndex: 9999, // Set a high z-index to ensure it appears above other elements
          }}
        >
          ¡Cambios Guardados Exitosamente!
        </Box>
      )}
        </form>
        
      )}
    </Formik>
  );
};
export default CreateUser;




