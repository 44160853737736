import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Dropzone from "react-dropzone";
import { Formik } from "formik";
import * as yup from "yup";
import FlexBetween from "../components/FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AWS from 'aws-sdk'; // Import the AWS SDK
import { FormattedMessage } from 'react-intl';
import { tokens } from "../../src/theme";





const greenText = { color: 'green' }; // Define the CSS style for green text

const Dropfiles = ({_id}) => {
  const userprofile = useSelector((state) => state.user.userprofile);
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [form, setForm] = useState({ picturePath: [] }); // Initialize as an empty object with pictures array
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const theme = useTheme();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const dispatch = useDispatch();
  const [dialogues, setDialogues] = useState([]); // Initialize dialogues as an empty array
  const [loading, setLoading] = useState(true);
  const loggedInUserId = useSelector((state) => state.user._id);
  const colors = tokens(theme.palette.mode);

  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [error, setError] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
  const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
  

  console.log("id", _id);

const campaignSchema = yup.object().shape({
  // Define your validation schema here

});
  
  const initialValuesEditCampaign = {
    idcontrato: idcontrato, // Update the initial value
    picture: "",
    description: "",
    picturePath: "",

};


AWS.config.update({
  accessKeyId: REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
  region: "sa-east-1", // Set your AWS region
  });
  
  // Create an S3 object
  const s3 = new AWS.S3();

  const getForm = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch form data');
      }
      const data = await response.json();
      setForm(data);
      setLoading(false);
      // Set the dialogues state
      const fetchedDialogues = data.dialogues || [];
      setDialogues(fetchedDialogues);
    } catch (error) {
      setError('An error occurred while fetching form data.');
      setLoading(false);
    }
  };
  
    useEffect(() => {
        getForm();
      }, []); // Empty dependency array

      
      const handleFormSubmit = async (values, onSubmitProps) => {
        try {
          await getForm();
        } catch (error) {
          console.error('Error fetching form data:', error);
          return;
        }
      
        try {
          const formData = new FormData();
      // Append newly uploaded files to S3
      if (uploadedFiles.length > 0) {
        uploadedFiles.forEach((file) => {
          formData.append('pictures', file); // Append the actual file
        });
            // Define the uploadFileToS3 function
            const uploadFileToS3 = async (file) => {
              const uniqueSuffix = Date.now() + "-";
              const key = uniqueSuffix + file.name; // Add the unique suffix to the filename
              formData.append('picturePath', key); // Append the actual file
              formData.append('idcontrato', idcontrato);

            AWS.config.update({
            
            accessKeyId: REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
            region: "sa-east-1", // Set your AWS region
            });
              const s3 = new AWS.S3();
  
              // Configure the S3 bucket and key where you want to upload the file
              const bucketName = 'repoeticpro';
        

              // Configure S3 upload parameters
              const params = {
                Bucket: bucketName,
                Key: key,
                Body: file, // The actual file object to upload
              };
  
    
    // Use the AWS S3 client to upload the file to S3
    try {
      await s3.upload(params).promise();
      console.log(`File uploaded to S3: ${key}`);
      return key; // Return the key for reference
    } catch (error) {
      console.error(`Error uploading file to S3: ${error.message}`);
      throw error;
    }
  };
        const s3Urls = await Promise.all(uploadedFiles.map(uploadFileToS3));

    }
          // Example of saving user data using fetch
          const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}complaints/files/${_id}`, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });
      
          onSubmitProps.resetForm();
          getForm(); // This already updates the form.picturePath with the merged array
          setUploadedFiles([]);
          if (userResponse.ok) {
            // Show the success message
            setIsSuccessMessageVisible(true);
      
            // Hide the success message after 5 seconds
            const timeoutId = setTimeout(() => {
              setIsSuccessMessageVisible(false);
            },4000);
      
            // Cleanup the timeout to avoid memory leaks
            return () => clearTimeout(timeoutId);
          } else {
            // Handle non-successful response (e.g., display an error message)
            console.error(`PUT request failed with status ${userResponse.status}`);
          }
        } catch (err) {
          console.log("Error creating complaint:", err);
      
          if (err.userResponse) {
            console.log("Response status:", err.userResponse.status);
                  err.userResponse.text().then(responseText => {
              console.log("Response data:", responseText);
            });
          }
          
        }
      };
      
      const handleDownload = (filePath, fileName) => {
        

        const s3BucketURL = 'https://repoeticpro.s3.amazonaws.com'; // Use the correct endpoint
        const s3URL = `${s3BucketURL}/${encodeURIComponent(fileName)}`;
      
        // Initialize the AWS S3 client for downloading
        AWS.config.update({
          accessKeyId: REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
          region: "sa-east-1", // Set your AWS region
        });
        const s3 = new AWS.S3();
    
    
        const downloadLink = document.createElement('a');
        downloadLink.href = s3URL;
        downloadLink.target = '_blank'; // Open in a new tab
        downloadLink.download = fileName; // Specify the filename for the downloaded file
        downloadLink.click();
    
       
      };
        
  

  return (

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
      <form onSubmit={handleSubmit}>
 <p></p>


    <Box>
    <Box display="flex">    

      <Box width="100%" >
        <Box flexBasis="50%" marginRight="1rem">
         
         {/* Check if the user is "comitetica" or "superadmin" */}
         {userprofile=== 'comitetica' || userprofile=== 'admin' || userprofile === 'superadmin' ? (
          <Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
            <Typography variant="h4">{<FormattedMessage id="Archivos Relacionados"/>}</Typography>
            {form.picturePath && form.picturePath.map((filePath, index) => {
  if (filePath) {
    const fileName = filePath.split('/').pop();
    return (
      <div key={index}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleDownload(filePath, fileName);
          }}
        >
          {fileName}
        </a>
      </div>
    );
  } else {
    return null; // or handle the case when filePath is null
  }
})}

          </Box>
        ) : (
          // Render an alternative message for users who don't have the required role
          <Typography variant="body1"></Typography>
        )}
          
          
          <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  acceptedFiles.forEach((file) => {
                  });
                  setUploadedFiles(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures || values.pictures.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>{<FormattedMessage id="Agregue archivos, máximo 5Mb"/>}</p>
                    ) : (
                      <div>
                        {values.pictures.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>
                </Box>
 {/* BUTTONS */}
 <Box>
        <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              {<FormattedMessage id="Guardar Archivos"/>}
            </Button>
            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
                Envio exitoso
              </Box>
            )}
    </Box>
          
    </Box>
    </Box>
    {/* Add a success message box */}
{isSuccessMessageVisible && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: colors.greenAccent[400], // Set the background color for success message
            color: 'white', // Set text color to white
            padding: '1rem',
            borderRadius: '15px',
            textAlign: 'center',
            zIndex: 9999, // Set a high z-index to ensure it appears above other elements
          }}
        >
          ¡Cambios Guardados Exitosamente!
        </Box>
      )}
    </Box>
    </Box>
  </form>
      )}
    </Formik>
);
};

export default Dropfiles;





