import { Box, Typography, useTheme, IconButton, Button, TextField, Slider } from "@mui/material";
import { DataGrid, GridToolbar, GridColDef, esES, GridRenderCellParams } from "@mui/x-data-grid";
import Reunion from "@mui/icons-material/Group";
import Read from "@mui/icons-material/LocalLibrary";
import CI from "@mui/icons-material/ListAlt";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Pause from "@mui/icons-material/Build";
import Login from "@mui/icons-material/PlayArrow";
import TV from "@mui/icons-material/AttachMoney";
import Denuncia from "@mui/icons-material/Warning";
import DD from "@mui/icons-material/ZoomIn";
import Otro from "@mui/icons-material/DoubleArrow";
import { createTheme } from '@mui/material/styles';

import Chip, { ChipProps } from "@mui/material/Chip";
import { green, red, blue, amber, teal, common, grey, deepPurple, deepOrange } from "@mui/material/colors";
import ProgressBarr from "../../components/ProgressBarr";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../../theme";
import Upload from "@mui/icons-material/AddSharp";
import ExcelJS from 'exceljs';
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import FlexBetween from "../../components/FlexBetween";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CreateUser from "../../components/CreateUser";
import RemoveUser from "../../components/RemoveUser";

import StatBox from "../../components/StatBox";
import Downx from "@mui/icons-material/PersonRemove";
import StatBoxnom from "../../components/StatBoxnom";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import Paper from '@mui/material/Paper'; // Import the Paper component
import { FormattedMessage } from 'react-intl';

const greenText = { color: 'green' }; // Define the CSS style for green text

const Users = ({ }) => {
const location = useLocation();
const idcontrato = useSelector((state) => state.user ? state.user.idcontrato : null);
const [editedRows, setEditedRows] = useState({});
const [showCreateUser, setShowCreateUser] = useState(false);
const [showRemoveUser, setShowRemoveUser] = useState(false);

const userProfile = useSelector((state) => state.user.userprofile);
const [user, setUser] = useState([]); // Updated: Initialize data state
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
    const [data1, setData1] = useState([]); // Updated: Initialize data state

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const loggedinuserprofile = useSelector((state) => state.user.userprofile);
  const loggedinusercontract = useSelector((state) => state.user ? state.user.idcontrato : null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dateRange, setDateRange] = useState([0, 100]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [totalcam, setTotalcam] = useState(0);


const filteredData = data

.filter((row) => {
  // Combine all column values into a single string
  const rowDataAsString = Object.values(row)
    .filter((value) => typeof value === 'string')
    .join(' ');

  // Apply the search filter to the concatenated string
  const searchInputLower = searchInput.toLowerCase();
  return rowDataAsString.toLowerCase().includes(searchInputLower);
});

useEffect(() => {
  setShowSuccessPopup(false);
  setSnackbarOpen(false);
  fetchData();
}, [isFormSubmitted, searchInput]);


const fetchData = async () => {
  try {
    let endpoint = `${REACT_APP_API_ENDPOINT}users/contract/${loggedinusercontract}`;

    // Check the user profile and update the endpoint accordingly
    if (userProfile === "superadmin") {
      endpoint = `${REACT_APP_API_ENDPOINT}users/`;
    }

    const response = await fetch(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const formData = await response.json();

    const dataWithFormattedDate = formData.map((row) => ({
      ...row,
      createdAt: row.createdAt
        ? new Date(row.createdAt).toLocaleDateString("en-EN", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })
        : "",
    }));

    setUser(formData);
    setData(dataWithFormattedDate);
    const totalcam = dataWithFormattedDate.length;
    setTotalcam(totalcam);

   
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


  const handleRowClick = (params, action) => {
    const rowId = params.row._id;
  
    if (action === "edit") {
      const { row, field } = params;
      const updatedRow = { ...row, [field]: params.value };
  
      // Update the local state immediately for a responsive user experience
      setData((prevData) => {
        const rowIndex = prevData.findIndex((r) => r.id === rowId);
        const newData = [...prevData];
        newData[rowIndex] = { ...newData[rowIndex], ...updatedRow };
        return newData;
      });
  
      // Save the changes to the server in the background
      saveRowChanges(rowId, updatedRow);
    } else if (action === "delete") {
      // Add code to delete the row from the database using the rowId
      deleteRow(rowId);
    }
  };
  
  const saveRowChanges = async (rowId, updatedRow) => {
  
    try {
      const response21 = await fetch(`${REACT_APP_API_ENDPOINT}users/${rowId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json" // Ensure correct content type
        },
        body: JSON.stringify(updatedRow),
      });
      
      // Log the response body or any relevant data from the response
      if (response21.ok) {
        const updatedData = await response21.json(); // Get the updated data from the response
        console.log("Updated data from server:", updatedData); // Log the updated data received from the server
      }
    } catch (error) {
      console.error("Error saving row changes:", error);
    }
  };
  

  const deleteRow = async (rowId) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}users/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the data and filteredData states after deleting the row
      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };

  

  const formatDateToEsES = (dateString) => {
    if (!dateString) return "";
  
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const esESDate = new Date(dateString).toLocaleDateString('es-ES');
  
    return esESDate;
  };
  
  const isSuperAdmin = userProfile === 'superadmin';


  const handleDownloadXLSX = async () => {
    if (data !== undefined && data !== null && data.length > 0) {
      try {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Usuarios');
  
        // Define the headers for your data (assuming the first object in data has the keys)
        const headers = Object.keys(data[0]);
  
        // Add the headers to the worksheet
        worksheet.addRow(headers);
  
        // Add the data rows to the worksheet
        data.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
  
        // Generate a blob from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Convert the buffer to a blob
        const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        // Download the XLSX file
        saveAs(xlsxBlob, 'usuarios.xlsx');
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
      // Handle the case when data is empty or undefined
    }
  };

  function getChipProps2(params: GridRenderCellParams): ChipProps {
    if (params.row.level === "1Linea" || params.row.level === "Directores" ) {
      return {
        label: params.value,
        style: {
          borderColor: red[500]
        }
      };
    
    } else {
      return {
        label: params.value,
        style: {
          borderColor: blue[50]
        }
      };
    }
  }


  function getChipProps1(params: GridRenderCellParams): ChipProps {
    if (params.row.status === "declarado" ) {
      return {
        icon: <CheckCircleIcon style={{ fill: green[500] }} />,

        label: params.value,
        style: {
          borderColor: green[500]
        }
      };}
      if (params.row.status === "Invitado" ) {
        return {
          icon: <Pause style={{ fill: deepPurple[500] }} />,

          label: params.value,
          style: {
            borderColor: blue[500]
          }
        };
    
    }
      if (params.row.status === "decpendiente" || params.row.status === "invitadoDD"  ) {
        return {
          icon: <WarningIcon style={{ fill: red[500] }} />,

          label: params.value,
          style: {
            borderColor: red[500]
          }
        };
    
    } else {
      return {

        label: params.value,
        style: {
          borderColor: blue[50]
        }
      };
    }
  }

  const columns = [

    {
      field: "firstName",
      headerName: <FormattedMessage id="Nombres"/>,
      flex: 1,
      editable: userProfile === "superadmin" || userProfile === "admin" , // Allow editing if user is an admin
    headerClassName: "bold-header",
  },
    {
      field: "lastName",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Apellidos"/>,
      flex: 1,
      editable: userProfile === "superadmin" || userProfile === "admin" , // Allow editing if user is an admin
    },
    {
      field: "email",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Email"/>,
      flex: 1,
      editable: userProfile === "superadmin" || userProfile === "superadmin" , // Allow editing if user is an admin
    },

    {
      field: "rut",headerClassName: "bold-header",
      headerName: <FormattedMessage id="ID/RUT"/>,
      flex: 1,
      editable: userProfile === "superadmin" || userProfile === "admin" , // Allow editing if user is an admin
    },

    {
      field: "celular",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Celular"/>,
      flex: 0.5,
      editable: true, // Make the column editable
    },
  

    {
      field: "userprofile",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Perfil"/>,
      flex: 1,
      editable: userProfile === "adminx" || userProfile === "superadmin", // Allow editing if user is an admin
    },

    {
      field: "location",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Organización"/>,
      flex: 1,
      editable: true, // Make the column editable
    },
    {
      field: "area",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Area"/>,
      flex: 1,
      editable: true, // Make the column editable
    },

    { field: "occupation",  renderCell: (params) => {
      return <Chip variant="outlined" size="small" {...getChipProps2(params)} />;
    }, headerName: <FormattedMessage id="Cargo" />, flex: 1.4,       editable: true ,headerClassName: "bold-header", 
  },

  

    {
      field: "level",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Nivel"/>,
      flex: 1,
      editable: true, // Make the column editable
    },
  
   
    
    {
      field: "status",headerClassName: "bold-header",renderCell: (params) => {
        return <Chip variant="outlined" size="small" {...getChipProps1(params)} />;
      },
      headerName: <FormattedMessage id="Estado"/>,
      flex: 1.2,
      editable: userProfile === "superadmin" || userProfile === "superadmin" , // Allow editing if user is an admin
    },
    {
      field: "wagon",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Wagon"/>,
      flex: 1,
      hide: true,
      editable: true, // Make the column editable

    },

    {
      field: "_id",headerClassName: "bold-header",
      headerName: <FormattedMessage id="ID"/>,
      flex: 1,
      hide: true,
    },
    {
      field: "createdAt",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Creado"/>,
      flex: 1,
      valueFormatter: (params) => formatDateToEsES(params.value), // Use the custom formatter
    },
    
   
    
    {
      field: "actions",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Action"/>,
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleRowClick(params, "edit")}
          >
            <EditIcon />
          </IconButton>
          {userProfile === "superadmin" && ( // Check if user is a superadmin

          <IconButton
            color="secondary"
            onClick={() => handleRowClick(params, "delete")}
          >
            <DeleteIcon />
          </IconButton>
          )}
        </>
      ),
    },

  ];

  if (loggedinuserprofile === 'superadmin') {

    columns.push({

    field: "idcontrato",headerClassName: "bold-header",
    headerName: <FormattedMessage id="Contrato"/>,
    flex: 1,
    editable: userProfile === "superadmin", // Allow editing if user is an admin
    hide: userProfile !== "superadmin", // Hide the column if user is not superadmin
  });

  columns.push({

    field: "password",headerClassName: "bold-header",
    headerName: <FormattedMessage id="password"/>,
    flex: 1,
    editable: userProfile === "superadmin", // Allow editing if user is an admin
    hide: userProfile !== "superadmin", // Hide the column if user is not superadmin
  });

  columns.push({

    field: "nombreempresa",headerClassName: "bold-header",
    headerName: <FormattedMessage id="cliente"/>,
    flex: 1,
    editable: userProfile === "superadmin", // Allow editing if user is an admin
    hide: userProfile !== "superadmin", // Hide the column if user is not superadmin
  });
}

  // Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "60%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

    const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue); // Ensure this is updating correctly
  };

  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };

  return (


    <Box m="10px">

    <Grid container spacing={2}>
    <Grid item xs={12} sm={3} md={4}>
    
    <Box display="flex" justifyContent="space-between" alignItems="center">
       
      <Header title={<FormattedMessage id="Usuarios" />} subtitle={<FormattedMessage id="Administrar mi usuarios" />} />

      </Box>
     </Grid>


<Grid item xs={12} sm={8} md={1}>


</Grid>

  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "100%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>
 <Box>

    {/* HEADER */}
            {/* Conditionally render the "Nuevo Usuario" button */}
            {(loggedinuserprofile === 'superadmin' || loggedinuserprofile === 'admin' ) && (
        <div>
          <Button
          onClick={() => setShowCreateUser(prevState => !prevState)}

            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[900],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Upload sx={{ mr: "10px" }} />
            <FormattedMessage id="Nuevo Usuario"/>
          </Button>
          {showCreateUser && <CreateUser />}
          </div>
      )}
            </Box>

       <p></p>
       <Box>

{/* HEADER */}
        {/* Conditionally render the "Nuevo Usuario" button */}
  {(loggedinuserprofile === 'superadmin' || loggedinuserprofile === 'admin' ) && (
    <div>
      <Button
      onClick={() => setShowRemoveUser(prevState => !prevState)}

        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[900],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        }}
      >
        <Downx sx={{ mr: "10px" }} />
        <FormattedMessage id="Desenrolar"/>
      </Button>
      {showRemoveUser && <RemoveUser />}
      </div>
  )}
        </Box>

   <p></p>
     
       <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gap="20px">


       <Paper elevation={3} style={widgetStyle}>
  <Box display="flex" alignItems="center" gap="1rem">
    <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word', fontSize: "35px", textAlign: "center" }}>
    <span style={greenText}>{filteredData.length.toString()}</span> 
    </Typography>Usuarios
  </Box>
</Paper>


  {/* Add more StatBox components here with similar props */}
</Box>



      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[50],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
        
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
         initialState={{
          columns: {
            columnVisibilityModel: {
              userId: false,
              area: false,
              _id: false,
              status: false,
              celular: false,
              'createdAt': false, // Corrected key
              'area': false, // Corrected key
              '_id': false, // Corrected key


            },
          },
        }}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    components={{ Toolbar: GridToolbar }}

           rows={filteredData.map((row) => ({
            ...row,
            id: row._id, 
          }))}  
          columns={columns}
          onRowClick={handleRowClick} // Updated: Pass the click handler
          headerClassName="bold-header"
        
          rowsPerPageOptions={[10, 25, 50]} // Include 25 as an option
          
        />
      </Box>

<p>

</p>


<Box>

                <Button
                    onClick={handleDownloadXLSX}
                    sx={{ borderRadius: "20px",
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      overflowX: 'auto',// Make the container horizontally scrollable

                    }}
                  >
                    <p></p>
                    <DownloadOutlinedIcon sx={{ borderRadius: "20px", mr: "10px" }} />
                  {<FormattedMessage id="Descargar Reporte"/>}

              </Button>
              </Box>
              <p></p>
              

    </Box>
  );
};

export default Users;
