import React, { useState } from 'react';
import { Box, useTheme, Button, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // State to manage the expansion of individual accordion items
  const [expandedPanels, setExpandedPanels] = useState({});

  const handleExpandAll = () => {
    const allExpanded = Object.values(expandedPanels).every(Boolean);
    const newExpandedPanels = {};
    sections.forEach((_, index) => {
      newExpandedPanels[index] = !allExpanded;
    });
    setExpandedPanels(newExpandedPanels);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanels({ ...expandedPanels, [panel]: isExpanded });
  };

  const sections = [
    {
      title: "¿Las denuncias son realmente anónimas?",
      content: `
        Sí, aunque guardamos el email de quien realizó la denuncia, si el usuario escoge no dar su nombre es opcional. Aunque lo hiciera, no se muestran los datos a ningún usuario ni compartimos esta información de ninguna forma respetando el uso confidencial de la información.
      `,
    },
    {
      title: "¿Qué uso le da Eticpro a la información que maneja?",
      content: `
        <div>
          <Typography variant="body1">
            En Eticpro, valoramos y respetamos la privacidad de nuestros usuarios y la confidencialidad de la información que manejan en nuestra plataforma. Nuestra organización tiene una política clara en cuanto al uso de la información, y nos gustaría explicarte cómo gestionamos los datos que recopilamos:
          </Typography>
          <br />
          <hr />
          <br />
          <Typography variant="body1">
            <strong>Uso de la información:</strong> La información que nuestros usuarios proporcionan a través de la plataforma de Eticpro se utiliza exclusivamente para fines relacionados con nuestros servicios y funciones. Esta información es fundamental para facilitar la gestión de declaraciones, campañas y otras actividades dentro de la plataforma.
          </Typography>
          <br />
          <hr />
          <br />
          <Typography variant="body1">
            <strong>Confidencialidad y seguridad:</strong> Mantenemos un estricto nivel de confidencialidad y seguridad en relación con la información que recopilamos. Implementamos medidas técnicas y organizativas para proteger los datos contra el acceso no autorizado, la divulgación y el uso indebido.
          </Typography>
          <br />
          <hr />
          <br />
          <Typography variant="body1">
            <strong>No compartimos con terceros:</strong> Es importante destacar que en Eticpro no compartimos, vendemos ni proporcionamos la información de nuestros usuarios a terceros, ya sean empresas, organizaciones o individuos. La información que recopilamos se mantiene dentro de nuestra plataforma y se utiliza exclusivamente para mejorar la experiencia de nuestros usuarios y brindarles un servicio de calidad.
          </Typography>
          <br />
          <hr />
          <br />
          <Typography variant="body1">
            <strong>Uso interno en Eticpro:</strong> La información que recopilamos se utiliza para optimizar y personalizar la experiencia del usuario en nuestra plataforma. Esto incluye aspectos como facilitar la navegación, proporcionar recomendaciones relevantes y mejorar nuestras funcionalidades.
          </Typography>
          <br />
          <hr />
          <br />
          <Typography variant="body1">
            <strong>Consentimiento del usuario:</strong> Siempre solicitamos el consentimiento expreso de nuestros usuarios para recopilar y utilizar su información. Los usuarios tienen el control sobre los datos que comparten y pueden acceder, editar o eliminar su información en cualquier momento.
          </Typography>
          <br />
          <hr />
          <br />
          <Typography variant="body1">
            <strong>Transparencia y comunicación:</strong> En Eticpro nos comprometemos a mantener una comunicación transparente con nuestros usuarios sobre cómo se utiliza la información y cuáles son sus derechos en relación con sus datos personales. Si tienes alguna pregunta o inquietud sobre el manejo de la información, no dudes en ponerte en contacto con nuestro equipo de soporte.
          </Typography>
          <br />
          <hr />
          <br />
          <Typography variant="body1">
            En resumen, en Eticpro utilizamos la información con responsabilidad y ética, enfocándonos en brindar un servicio eficiente y seguro a nuestros usuarios. Tu confianza es fundamental para nosotros, y estamos aquí para garantizar que tus datos estén protegidos y utilizados de manera adecuada dentro de nuestra plataforma.
          </Typography>
        </div>
      `,
    },
    
    {
      title: "¿Qué es Funcionario Público?",
      content: `
        <div>
          <Typography variant="body1">
            Significa e incluye:
          </Typography>
          <br />
          <hr />
          <br />
          <ol>
            <li>
              Cualquier oficial o empleado de gobierno o de una organización pública internacional o cualquier departamento o agencia del mismo o cualquier otra entidad de propiedad del estado o controladas por éste (incluidas las empresas de propiedad estatal). 
            </li>
            <li>
              Cualquier persona que actúe en una función oficial o empoderada de un gobierno u organización pública internacional. 
            </li>
            <li>
              Cualquier dirigente de un partido político o candidato a un cargo público.
            </li>
            <li>
               Cualquier persona que tiene o ejerce las funciones de representación, oficio o cargo creado especialmente, incluyendo, potencialmente, algunos líderes de etnias y comunidades. 
            </li>
            <li>
              Cualquier persona independiente que esté autorizada para representar a un funcionario del gobierno.
            </li>
          </ol>
        </div>
      `,
    },
    {
      title: "¿Qué es Persona Expuesta Públicamente (PEP)?",
      content: `
        <div>
          <Typography variant="body1">
            La Unidad de Análisis Financieros (UAF), según Circular N°49, indica que la Persona Expuesta Políticamente (PEP) son los chilenos o extranjeros que desempeñan o hayan desempeñado funciones públicas destacadas en un país, hasta a lo menos un año de finalizado el ejercicio de las mismas.
          </Typography>
          <Typography variant="body1">
            A lo menos deberán estar calificadas como PEP las siguientes personas (esta lista es a modo de ejemplo, no exhaustiva):
          </Typography><br>
          <Typography variant="body1">1. Presidente de la República.</Typography><br>
          <Typography variant="body1">2. Senadores, Diputados y Alcaldes.</Typography><br>
          <Typography variant="body1">3. Ministros de la Corte Suprema y de las Cortes de Apelaciones.</Typography><br>
          <Typography variant="body1">4. Ministros de Estado, Subsecretarios, Intendentes, Gobernadores, Secretarios Regionales Ministeriales, Embajadores, Jefes Superiores de Servicios tanto centralizados como descentralizados y el directivo superior inmediato que deba subrogar a cada uno de ellos.</Typography><br>
          <Typography variant="body1">5. Comandantes en Jefe de las Fuerzas Armadas, General Director de Carabineros, Director General de Investigaciones y el superior inmediato que deba subrogar a cada uno de ellos.</Typography><br>
          <Typography variant="body1">6. Directores y ejecutivos principales de empresas públicas.</Typography><br>
          <Typography variant="body1">7. Directores de sociedades anónimas nombrados por el Estado o sus organismos.</Typography><br>
          <Typography variant="body1">8. Miembros de las directivas de los partidos políticos.</Typography><br>
          <Typography variant="body1">9. Fiscal Nacional del Ministerio Público y Fiscales Regionales.</Typography><br>
          <Typography variant="body1">10. Contralor General de la República.</Typography><br>
          <Typography variant="body1">11. Consejeros del Banco Central de Chile.</Typography><br>
          <Typography variant="body1">12. Presidente y Consejeros del Consejo de Defensa del Estado.</Typography><br>
          <Typography variant="body1">13. Ministros del Tribunal Constitucional.</Typography><br>
          <Typography variant="body1">14. Ministros del Tribunal de la Libre Competencia.</Typography><br>
          <Typography variant="body1">15. Integrantes titulares y suplentes del Tribunal de Contratación Pública.</Typography><br>
          <Typography variant="body1">16. Miembros del Consejo de Alta Dirección Pública.</Typography><br>
          <Typography variant="body1">17. Consejeros Regionales (CORE).</Typography><br>
          <Typography variant="body1">18. Concejales.</Typography><br>
          <Typography variant="body1">19. Todos los candidatos en elecciones presidenciales, parlamentarias y municipales. De igual forma, las personas, naturales o jurídicas, con o sin fines de lucro, vinculadas a algún candidato a cargo de elección popular, desde el momento de inscripción de la respectiva candidatura, conforme a las disposiciones legales aplicables.</Typography>
        </div>
      `,
    },
    
    {
      title: "Ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas, IMPORTANTE Fechas de Implementación",
      content: `
        <div>
          <Typography variant="body1">
            La Ley N°20.393 establece que las personas jurídicas serán responsables de los siguientes delitos:
          </Typography>
    
          <ul>
            <li>Lavado de activos</li>
            <li>Financiamiento del terrorismo</li>
            <li>Cohecho a funcionario público nacional y extranjero</li>
            <li>Receptación</li>
            <li>Corrupción entre particulares</li>
            <li>Administración desleal</li>
            <li>Apropiación indebida</li>
            <li>Negociación incompatible</li>
            <li>Contaminación de aguas</li>
            <li>Delitos relacionados con la veda de productos</li>
            <li>Pesca ilegal de recursos del fondo marino</li>
            <li>Procesamiento y almacenamiento de productos escasos sin acreditar un origen legal</li>
          </ul>
    
          <Typography variant="body1">
            Además, la Ley 17.798, modificada sobre control de armas, hace potencialmente responsable a las personas jurídicas por los delitos contemplados en su título II, que incluyen:
          </Typography>
    
          <ul>
            <li>Posesión</li>
            <li>Porte</li>
            <li>Comercialización</li>
            <li>Tenencia</li>
            <li>Fabricación</li>
            <li>Importación</li>
            <li>Internación al país</li>
            <li>Exportación</li>
            <li>Transporte</li>
            <li>Almacenamiento</li>
            <li>Distribución</li>
            <li>Celebración de actos jurídicos sobre armas o municiones sin autorización adecuada</li>
            <li>Posesión de artículos o municiones prohibidas o sujetas a control</li>
          </ul>
    
          <Typography variant="body1">
            La Ley N°21.459 incorporó los delitos informáticos al catálogo normativo de la Ley N°20.393. Estos entrarán en vigor a partir del 20 de diciembre de 2022 e incluyen:
          </Typography>
    
          <ul>
            <li>Ataque a la integridad de un sistema informático</li>
            <li>Acceso ilícito</li>
            <li>Interceptación ilícita</li>
            <li>Ataque a la integridad de los datos informáticos</li>
            <li>Falsificación informática</li>
            <li>Receptación de datos informáticos</li>
            <li>Fraude informático</li>
            <li>Abuso de los dispositivos</li>
          </ul>
    
          <Typography variant="body1">
            Finalmente, la ley N°21.595 de Delitos Económicos, exigibles y punibles actualmente para las personas naturales, se aplicará también a las personas jurídicas a partir del 1 de septiembre de 2024. Todas estas infracciones legales generan responsabilidad penal de la persona jurídica cuando son cometidas directa e inmediatamente en su interés o para su provecho, por personas que realicen actividades de administración y supervisión o por las personas que estén bajo su dirección o supervisión.
          </Typography>
    
          <Typography variant="body1">
            Asimismo, la persona jurídica responde penalmente cuando los delitos hayan sido cometidos por personas naturales que estén bajo la supervisión directa de alguno de los sujetos anteriormente nombrados.
          </Typography>
        </div>
      `,
    },
    {
      title: "Cuestionario de Evaluación de Compliance según el Artículo 4 de la Ley 20.393",
      content: `
        <div>
          <Typography variant="body1">
          </Typography>
          <br />
          <hr />
          <br />
          <ol>
            <li>
              1. Identificación de Riesgos:
              <ul>
                <li>¿Ha identificado su organización las actividades o procesos que implican riesgo de conducta delictiva?</li>
                <ul>
                  <li>a) Sí, hemos identificado todos los riesgos relevantes y los hemos documentado en matrices de riesgos.</li>
                  <li>b) Hemos identificado algunos riesgos, pero falta profundizar en otros.</li>
                  <li>c) No hemos realizado una identificación formal de riesgos.</li>
                </ul>
                <li>Nota: Puede utilizar el repositorio de Eticpro para almacenar y gestionar estas matrices de riesgos.</li>
              </ul>
              2. Protocolos y Procedimientos:
              <ul>
                <li>¿Cuenta su organización con protocolos y procedimientos específicos para prevenir y detectar conductas delictivas en los procesos identificados como de riesgo?</li>
                <ul>
                  <li>a) Sí, tenemos protocolos bien definidos y documentados.</li>
                  <li>b) Tenemos algunos protocolos, pero necesitan mejoras.</li>
                  <li>c) No contamos con protocolos específicos.</li>
                </ul>
                <li>¿Estos protocolos incluyen canales seguros de denuncia?</li>
                <ul>
                  <li>a) Sí, contamos con canales seguros de denuncia.</li>
                  <li>b) Tenemos canales de denuncia, pero no son completamente seguros.</li>
                  <li>c) No contamos con canales de denuncia.</li>
                </ul>
                <li>¿Las sanciones internas por incumplimiento de estos protocolos están claramente establecidas y comunicadas a todos los trabajadores?</li>
                <ul>
                  <li>a) Sí, están claramente establecidas y comunicadas.</li>
                  <li>b) Están establecidas, pero la comunicación podría mejorar.</li>
                  <li>c) No están claramente establecidas ni comunicadas.</li>
                </ul>
                <li>¿Se ha incorporado la normativa interna en los contratos de trabajo y de prestación de servicios?</li>
                <ul>
                  <li>a) Sí, la normativa interna está incorporada en todos los contratos.</li>
                  <li>b) Está incorporada en algunos contratos, pero no en todos.</li>
                  <li>c) No está incorporada en los contratos.</li>
                </ul>
              </ul>
              3. Responsables de la Aplicación de Protocolos:
              <ul>
                <li>¿Ha asignado su organización a uno o más sujetos responsables de la aplicación de los protocolos de prevención de delitos?</li>
                <ul>
                  <li>a) Sí, hemos asignado a responsables con adecuada independencia.</li>
                  <li>b) Tenemos responsables, pero podrían tener más independencia.</li>
                  <li>c) No hemos asignado a responsables específicos.</li>
                </ul>
                <li>¿Estos responsables tienen facultades efectivas de dirección y supervisión, y acceso directo a la administración de la organización?</li>
                <ul>
                  <li>a) Sí, tienen facultades efectivas y acceso directo.</li>
                  <li>b) Tienen algunas facultades, pero no son suficientes.</li>
                  <li>c) No tienen facultades efectivas ni acceso directo.</li>
                </ul>
                <li>¿La organización proporciona a estos responsables los recursos y medios necesarios para realizar adecuadamente sus labores?</li>
                <ul>
                  <li>a) Sí, proporcionamos todos los recursos necesarios.</li>
                  <li>b) Proporcionamos algunos recursos, pero no todos.</li>
                  <li>c) No proporcionamos los recursos necesarios.</li>
                </ul>
                <li>Nota: Eticpro puede ser utilizado como un recurso y herramienta para apoyar estas funciones.</li>
              </ul>
              4. Evaluaciones Periódicas y Mejora Continua:
              <ul>
                <li>¿Realiza su organización evaluaciones periódicas del modelo de prevención por terceros independientes?</li>
                <ul>
                  <li>a) Sí, realizamos evaluaciones periódicas por terceros independientes.</li>
                  <li>b) Realizamos evaluaciones, pero no siempre son por terceros independientes.</li>
                  <li>c) No realizamos evaluaciones periódicas.</li>
                </ul>
                <li>¿Existen mecanismos de perfeccionamiento o actualización del modelo de prevención basados en estas evaluaciones?</li>
                <ul>
                  <li>a) Sí, contamos con mecanismos de perfeccionamiento y actualización.</li>
                  <li>b) Tenemos algunos mecanismos, pero no son suficientes.</li>
                  <li>c) No contamos con mecanismos de perfeccionamiento o actualización.</li>
                </ul>
              </ul>
              5. Medidas Eficaces para Prevenir la Reiteración de Delitos:
              <ul>
                <li>¿Ha adoptado su organización medidas eficaces para prevenir la reiteración de la misma clase de delitos objeto de la investigación?</li>
                <ul>
                  <li>a) Sí, hemos adoptado medidas eficaces y tenemos evidencia de la autonomía del encargado de prevención de delitos.</li>
                  <li>b) Hemos adoptado algunas medidas, pero no son completamente eficaces.</li>
                  <li>c) No hemos adoptado medidas específicas.</li>
                </ul>
              </ul>
            </li>
          </ol>
        </div>
      `,
    },
    
    
    {
      title: "Términos de privacidad de la información",
      content: `
        <div>
          <ul>
            <li>El acceso a determinados servicios que se ofrecen en el sitio puede requerir el ingreso de datos personales, a saber: dirección de e-mail, nombre, apellido, domicilio completo, tipo y número de documento y otros datos opcionales, o cualquier otra información que permita individualizarlo. En todos los casos que usted brinde información personal, y de acuerdo a la legislación vigente, usted declara que la información brindada es cierta.</li>
            <li>Los datos que usted ingrese al Sitio se utilizarán para:</li>
            <ul>
              <li>Envío de información de Productos y servicios</li>
              <li>Reporte de Irregularidades</li>
              <li>Análisis e investigaciones</li>
              <li>Compliance</li>
              <li>Asesoramiento legal</li>
              <li>Aplicaciones de servicios de proceso de negociación y gestión de reclamos previo a la judicialización</li>
              <li>Sistemas de comunicación internos y externos</li>
              <li>Obtener estadísticas</li>
            </ul>
            <li>El ingreso de datos personales al Sitio por parte de los usuarios es voluntario, sin embargo, Eticpro manifiesta que su ingreso facilitará el uso de los servicios que ofrece y la relación con los usuarios. En los casos en que usted nos brinde su información personal, usted acepta y presta su consentimiento libre, expreso e informado para que dicha información personal sea utilizada en su propio beneficio optimizando la calidad del servicio que le ofrecemos a fin de mantenerlo informado de posibles cambios, y autoriza a que la misma sea tratada, almacenada, recopilada en la base de datos de la compañía.</li>
            <li>Eticpro garantiza a los usuarios que ingresen sus datos en el Sitio, que los mismos serán encriptados para preservar la seguridad, integridad y confidencialidad de los datos considerados como personales. Eticpro realiza todos los esfuerzos necesarios para evitar su adulteración, pérdida, consulta o tratamiento no autorizado, que permitan detectar desviaciones, intencionales o no, de información, ya sea que los riesgos provengan de la acción humana o del medio técnico utilizado. Para ello, notificamos de nuestras prácticas de privacidad, y las alternativas sobre la manera que su información es recopilada y utilizada.</li>
            <li>El sistema de encriptado implica que los datos solo podrán ser interpretados por Eticpro y ningún intermediario tendrá acceso a la información.</li>
            <li>El usuario podrá acceder a sus datos de carácter personal, rectificarlos, cancelarlos u oponerse a su tratamiento, mediante notificación al responsable de la base de datos hello@eticpro.com (LATAM).</li>
            <li>Eticpro no cederá esta información con terceros. No obstante, Eticpro podrá enviar a sus usuarios ofertas promocionales o comunicados especiales, donde el usuario siempre tendrá la opción de solicitar dejar de recibirlos.</li>
            <li>El usuario responderá, en cualquier caso, de la veracidad de los datos facilitados, reservándose Eticpro el derecho de excluir a todo usuario que haya facilitado datos falsos, sin perjuicio de iniciar acciones legales.</li>
            <li>Eticpro se reserva el derecho de brindar información a organismos de control de cualquier país y/o autoridades judiciales que así lo requieran y cuando medien razones fundadas relativas a la seguridad pública, la defensa nacional o la salud pública.</li>
          </ul>
        </div>
      `,
    },
  ];

  return (
    <Box m="20px">
      <Header title="GLOSARIO" subtitle="Terminos y Preguntas Frecuentes" />

      {/* Collapse All/Expand All Buttons */}
      <Box mb={2} display="flex" justifyContent="flex-end">
      <Button variant="outlined" style={{ backgroundColor: "grey[100]" }} onClick={handleExpandAll}>
          {Object.values(expandedPanels).every(Boolean) ? 'Contraer Todo' : 'Expandir Todo'}
        </Button>
      </Box>

      {/* Accordion Sections */}
      {sections.map((section, index) => (
        <Accordion
          key={index}
          expanded={expandedPanels[index] || false}
          onChange={handleAccordionChange(index)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color={colors.greenAccent[300]} variant="h5">
              {section.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography dangerouslySetInnerHTML={{ __html: section.content }} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
