import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import LogoImage from '../assets/eticprologo8.png';

const editformSchema = yup.object().shape({
    veredicto: yup.string().notRequired("notRequired"),
    detalleveredicto: yup.string().notRequired("notRequired"),
 

});


const initialValuesEditForm = {

  emailaviso: "",
  dateveredicto: "",
  veredicto: "",
  detalleveredicto: "",
  idveredicto: "",

}

const Form = ({ _id }) => {
  const [form, setForm] = useState(null);
  const [currentDate, setCurrentDate] = useState("");

  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const loggedInUserId = useSelector((state) => state.user._id);

  const [pageType, setPageType] = useState("editform");
  const iseditform = pageType === "editform";
  const token = useSelector((state) => state.token);
  const [formData, setFormData] = useState(initialValuesEditForm);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [users, setUsers] = useState([]); // State to hold fetched users
  const loggedclient = useSelector((state) => state.client);


  const fetchUsers = async () => {
    try {
      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (userResponse.ok) {
        const usersData = await userResponse.json();
        setUsers(usersData);
      } else {
        console.log("Failed to get users");
      }

     

      } catch (error) {
        console.log("Error:", error);
      }
    };


    const getForm = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const formData = await response.json();
          setForm(formData);
        } else {
          console.log("Failed to get form");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

  useEffect(() => {
   
    fetchUsers(); // Fetch the list of users

    getForm(); // Call the function to fetch the form data
  
  }, [_id, token]);

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {

      // Generate a random 16-character string for idevaluation
      const generateId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let id = '';
        for (let i = 0; i < 16; i++) {
          id += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return id;
      };
  
      // Patch the form data with new values and estado
      const updatedFormData = {
        estado: formData.estado,
        idveredicto: generateId(), // Add the idevaluation field with a random 16-character value
        dateveredicto: currentDate, // Set the updatedAt value to the current date
        veredicto: values.veredicto,
        detalleveredicto: values.detalleveredicto,
        emailaviso: formData.emailaviso,

      };
      
    const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedFormData),
    });
    if (response.ok) {
      // Send email after the patch is successful
      const emailTemplate = `
      <!DOCTYPE html>
      <html>
      <head>
        <style>
          .frame {
            border: 2px solid #ccc;
            padding: 20px;
            border-radius: 10px;
            width: 80%;
            margin: 0 auto;
            text-align: left;
            position: relative;
          }
          .logo-container {
            position: absolute;
            top: 10px;
            right: 10px;
          }
          .logo-box {
            text-align: center;
            margin-bottom: 20px;
          }
          .box {
            background-color: #f2f2f2;
            padding: 10px;
            border-radius: 5px;
          }
        </style>
      </head>
      <body>
      <div class="frame">
      <div class="logo-container">
      <img src=${LogoImage} alt="Logo" style="width: 100%;" />
      </div>
      <img src=${loggedclient.logo} alt="Logoemp" style="width: 15%;" />
      <p>Estimado(a) Responsable de Comité Ética,</p>
      <p>Le informamos que se ha resuelto su denuncia  correspondiente a la organización ${loggedclient.nombreempresa}. Le invitamos ingresar a Eticpro, opción "Denuncias" para ver detalles:</p>
      <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
      <p>Para soporte escribanos a <a href="mailto:hello@eticpro.com">hello@eticpro.com</a> o por Whatsapp al +56 977 657 352.</p>
      <p>Gracias por su atención y cooperación.</p>
      <p>Equipo Encargado de Prevención de Delitos</p>
      <p>Eticpro.</p>
      <p><a href="www.eticpro.com">www.eticpro.com</a></p>
    </div>
      </body>
      </html>
    `;
      const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: [formData.emailaviso, formData.selectedInvestigador ],
          emailTemplate,
          subject : "Resultado Evaluacion de Compliance",

        }),
      });

      if (emailResponse.ok) {
        // Email sent successfully
        console.log("Email sent successfully");
      } else {
        // Error sending email
        console.log("Failed to send email");
      }
    } else {
      // Handle unsuccessful patch request
      console.log("Failed to update form data");
    }

    onSubmitProps.resetForm();
    navigate("/complaints");
  } catch (error) {
    console.log(error);
    // Handle error
  }
};





useEffect(() => {
  if (form) {
    setFormData(form);
  }
}, [form]);


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={iseditform ? initialValuesEditForm : initialValuesEditForm}
      validationSchema={editformSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {iseditform && (
              <>
                <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
                  <Typography variant="h4" gutterBottom>
                    Cierre de Investigación.
                  </Typography>
                 
                </FormControl>

                <TextField
                  label="Decisión de Acción (Resolución)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.veredicto}
                  name="veredicto"
                  error={Boolean(touched.veredicto) && Boolean(errors.veredicto)}
                  helperText={touched.veredicto && errors.veredicto}
                  multiline
                  fullWidth
                  minRows={12}
                  maxRows={20}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />

<TextField
                  label="Detalle de Resolución"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.detalleveredicto}
                  name="detalleveredicto"
                  error={Boolean(touched.detalleveredicto) && Boolean(errors.detalleveredicto)}
                  helperText={touched.detalleveredicto && errors.detalleveredicto}
                  multiline
                  fullWidth
                  minRows={12}
                  maxRows={20}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />

  

              </>
            )}
          </Box>
       
          {/* BUTTONS */}
          <Box>
         
          <Button
                fullWidth
                type="submit"
                color="secondary"
                variant="contained"
                onClick={() => {
                  const newEstado =  "Resuelta";
                  
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    estado: newEstado,
                  }));
                  
                  setCurrentDate(format(new Date(), "yyyy-MM-dd")); // Set the current date
                }}
                sx={{ borderRadius: "20px",
                  m: "2rem 0",
                  p: "1rem",
                  backgroundColor: palette.primary.red,
                  color: palette.background.light,
                  "&:hover": { color: palette.primary.light },
                }}
              >
                {<FormattedMessage id="Enviar"/>}
              </Button>

           
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;