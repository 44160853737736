import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { format } from "date-fns";
import { FormattedMessage } from 'react-intl';
import LogoImage from '../assets/eticprologo8.png';

const editformSchema = yup.object().shape({
  opauth: yup.string().notRequired("notRequired"),
  authtxt: yup.string().notRequired("notRequired"),
  opmitigacion: yup.string().notRequired("notRequired"),
  mitigaciontxt: yup.string().notRequired("notRequired"),
  emailaviso: yup.string().notRequired("notRequired"),
  comentariosAdi: yup.string().notRequired("notRequired"),
});

const initialValuesEditForm = {

  opauth: "",
  authtxt: "",
  opmitigacion: "",
  mitigaciontxt: "",
  comentariosAdi: "",
  estado: "",
  emailaviso: "",
  dateval: "",

};

const Form = ({ _id }) => {
  const [form, setForm] = useState(null);
  const [currentDate, setCurrentDate] = useState("");

  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [pageType, setPageType] = useState("editform");
  const iseditform = pageType === "editform";
  const token = useSelector((state) => state.token);
  const [formData, setFormData] = useState(initialValuesEditForm);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const loggedclient = useSelector((state) => state.client);

  useEffect(() => {
   
    const getForm = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}formepd/${_id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const formData = await response.json();
          setForm(formData); // Set the form data obtained from the server
        } else {
          console.log("Failed to get form");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
    getForm(); // Call the function to fetch the form data
  
  }, [_id, token]);

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      // Generate a random 16-character string for idevaluation
      const generateId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let id = '';
        for (let i = 0; i < 16; i++) {
          id += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return id;
      };

      const currentDate = new Date();

      const formattedDate = currentDate.toISOString().split('T')[0];

      // Patch the form data with new values and estado
      const updatedFormData = {
        ...values,
        estado: formData.estado,
        emailaviso: values.emailaviso.split(",").map((email) => email.trim()),
        idevaluacion: generateId(), // Add the idevaluation field with a random 16-character value
        dateval: currentDate, // Set the updatedAt value to the current date
      };
      
    const response = await fetch(`${REACT_APP_API_ENDPOINT}updateform/${_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedFormData),
    });
    if (response.ok) {
      // Send email after the patch is successful
      const emailTemplate = `
      <!DOCTYPE html>
<html>
<head>
  <style>
    .frame {
      border: 2px solid #ccc;
      padding: 20px;
      border-radius: 10px;
      width: 80%;
      margin: 0 auto;
      text-align: left;
      position: relative;
    }
    .logo-container {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .logo-box {
      text-align: center;
      margin-bottom: 20px;
    }
    .box {
      background-color: #f2f2f2;
      padding: 10px;
      border-radius: 5px;
    }
  </style>
</head>
<body>
  <div class="frame">
    <div class="logo-container">
      <img src="${LogoImage}" alt="Logo" style="width: 10%;" />
    </div>
    <img src=${loggedclient.logo} alt="Logoemp" style="width: 15%;" /> <!-- Updated width to 10% -->
    
    <p>Hola,</p>
    <p>Se ha recibido en Compliance la declaración de:</p>
    <ul>
        <li>Nombre: {{formData.firstName}} {{formData.lastName}}</li>
        <li>Rut: {{formData.rut}}</li>
        <li>Email: {{formData.email}}</li>
        <li>Organización: {{formData.location}}</li>
        <li>Rol: {{formData.occupation}}</li>
        <li>Fecha declaración: {{formData.createdAt}}</li>
        <li>Conflicto: {{formData.opconflicto}}</li>
        <li>Tipo conflicto: {{formData.tipoconflicto}}</li>
        <li>Detalle: {{formData.conflictxt}}</li>
        <li>Evaluación Realizada: {{formData.estado}}</li>
        <li>Resultado Evaluación: {{values.authtxt}}</li>
        <li>Mitigación: {{values.opmitigacion}}</li>
        <li>Detalle Mitigación: {{values.mitigaciontxt}}</li>
        <li>Fecha Evaluación: {{currentDate}}</li>
    </ul>
    <p>Gracias.</p>
</body>
</html>
      `;
      const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: "hello@unbiax.com",
          emailTemplate,
          subject : "Recepción  Declaración Conflicto de Interés",

        }),
      });

      if (emailResponse.ok) {
        // Email sent successfully
        console.log("Email sent successfully");
      } else {
        // Error sending email
        console.log("Failed to send email");
      }
    } else {
      // Handle unsuccessful patch request
      console.log("Failed to update form data");
    }

    onSubmitProps.resetForm();
    navigate("/forms");
  } catch (error) {
    console.log(error);
    // Handle error
  }
};
useEffect(() => {

const getForm = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}formepd/${_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const formData = await response.json();
      setForm(formData);
    } else {
      console.log("Failed to get form");
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

}, []);


useEffect(() => {
  if (form) {
    setFormData(form);
  }
}, [form]);


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={iseditform ? initialValuesEditForm : initialValuesEditForm}
      validationSchema={editformSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {iseditform && (
              <>
        <Typography variant="h3">{<FormattedMessage id="Para su Acción"/>}</Typography>


                <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
                  <Typography variant="h6" gutterBottom>
                  {<FormattedMessage id="AUTORIZA?"/>}
                  </Typography>
                  <RadioGroup
                    aria-label="Opción"
                    name="opauth"
                    value={values.opauth}
                    onChange={handleChange}
                    sx={{ borderRadius: "20px",
                      "& .MuiSvgIcon-root": {
                        color: palette.primary.light, // Selected radio color (red)
                      },
                      "& .Mui-checked": {
                        color: "#FFFFFF", // Not selected radio color (white)
                      },
                    }}
                  >
                    <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                  </RadioGroup>
                </FormControl>

                <TextField
                  label={<FormattedMessage id="Detalle las razones de la Autorización/Rechazo"/>}
                  
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.authtxt}
                  name="authtxt"
                  error={Boolean(touched.authtxt) && Boolean(errors.authtxt)}
                  helperText={touched.authtxt && errors.authtxt}
                  multiline
                  fullWidth
                  minRows={4}
                  maxRows={8}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />

                <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
                  <Typography variant="h6" gutterBottom>
                  {<FormattedMessage id="MITIGACION"/>}
                  </Typography>
                  <RadioGroup
                    aria-label="Opción"
                    name="opmitigacion"
                    value={values.opmitigacion}
                    onChange={handleChange}
                    sx={{ borderRadius: "20px",
                      "& .MuiSvgIcon-root": {
                        color: palette.primary.light, // Selected radio color (red)
                      },
                      "& .Mui-checked": {
                        color: "#FFFFFF", // Not selected radio color (white)
                      },
                    }}
                  >
                    <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                  </RadioGroup>
                </FormControl>

                <TextField
             label={<FormattedMessage id="Detalle las acciones de mitigación"/>}
             onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mitigaciontxt}
                  name="mitigaciontxt"
                  error={Boolean(touched.mitigaciontxt) && Boolean(errors.mitigaciontxt)}
                  helperText={touched.mitigaciontxt && errors.mitigaciontxt}
                  multiline
                  fullWidth
                  minRows={4}
                  maxRows={8}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />
  <Typography variant="h6" gutterBottom>
                  {<FormattedMessage id="INFORMACIÓN ADICIONAL"/>}
                  </Typography>
  <TextField
             label={<FormattedMessage id="Otros Comentarios que desee agregar"/>}
             onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.comentariosAdi}
                  name="comentariosAdi"
                  error={Boolean(touched.mitigaciontxt) && Boolean(errors.comentariosAdi)}
                  helperText={touched.comentariosAdi && errors.comentariosAdi}
                  multiline
                  fullWidth
                  minRows={4}
                  maxRows={8}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />
<TextField

label={<FormattedMessage id="Compartir resultado a email(separado por comas)"/>}
onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.emailaviso}
                  name="emailaviso"
                  error={Boolean(touched.emailaviso) && Boolean(errors.emailaviso)}
                  helperText={touched.emailaviso && errors.emailaviso}
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={6}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />

              </>
            )}
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit" color="secondary" variant="contained"
              
              onClick={() => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  estado: "Evaluada",
                }));
                setCurrentDate(format(new Date(), "yyyy-MM-dd")); // Set the current date

              }}
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.primary.red,
                color: palette.background.light,
                "&:hover": { color: palette.primary.light },
              }}
            >
              {<FormattedMessage id="Enviar"/>}
            </Button>
            
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;
