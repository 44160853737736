import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { format } from "date-fns";
import LogoImage from '../assets/eticprologo8.png';
import { FormattedMessage } from 'react-intl';

const editformSchema = yup.object().shape({

  opauth: yup.string().notRequired(),
  authtxt: yup.string().notRequired("notRequired"),
  emailaviso: yup.string().notRequired("notRequired"),
  estado: yup.string().notRequired("notRequired"),


});

const initialValuesEditForm = {

  emailaviso: "",
  estado: "",

};

const Form = ({ _id }) => {
  const [form, setForm] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const loggedInUserId = useSelector((state) => state.user._id);

  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [pageType, setPageType] = useState("editform");
  const iseditform = pageType === "editform";
  const token = useSelector((state) => state.token);
  const [formData, setFormData] = useState(initialValuesEditForm);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const loggedinUserprofile = useSelector((state) => state.user.userprofile);

  useEffect(() => {
    const getForm = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}meetings/${_id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      
          
        if (response.ok) {
          const formData = await response.json();

          setForm(formData); // Set the form data obtained from the server
        } else {
          console.log("Failed to get form");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
  
    getForm(); // Call the function to fetch the form data
  
  }, [_id, token]);

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      // Generate a random 16-character string for idevaluation
      const generateId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let id = '';
        for (let i = 0; i < 16; i++) {
          id += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return id;
      };
  
      let updatedFormData = {
        emailaviso: values.emailaviso.split(",").map((email) => email.trim()).join(', '), // Convert to a string
        estado: formData.estado,
        opauth: formData.opauth,
       
 
      };
  
      if (loggedinUserprofile === "admin" || loggedinUserprofile === "aprobador" || loggedinUserprofile === "superadmin") {
        updatedFormData = {
          ...updatedFormData,
          idevaluacion: generateId(),
          authtxt: values.authtxt,
          dateval: currentDate, 
          opauth: values.opauth,
          estado: "Evaluada",
          aprobador: loggedInUserId,
        };
        
      } else {}



    const response = await fetch(`${REACT_APP_API_ENDPOINT}meetings/${_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedFormData),
    });
    if (!response.ok) {
        console.log("Failed to update form data. Response status:", response.status);
        console.log("Response body:", await response.text()); // or response.json() if the response is JSON
      }
    if (response.ok) {
      navigate("/successPage");

      // Send email after the patch is successful



    const emailTemplate = `
      <!DOCTYPE html>
      <html>
      <head>
        <style>
          .frame {
            border: 2px solid #ccc;
            padding: 20px;
            border-radius: 10px;
            width: 80%;
            margin: 0 auto;
            text-align: left;
            position: relative;
          }
          .logo-container {
            position: absolute;
            top: 10px;
            right: 10px;
          }
          .logo-box {
            text-align: center;
            margin-bottom: 20px;
          }
          .box {
            background-color: #f2f2f2;
            padding: 10px;
            border-radius: 5px;
          }
        </style>
      </head>
      <body>
        <div class="frame">
          <div class="logo-container">
            <img src=${LogoImage} alt="Logo" style="width: 100%;" />
          </div>
          <img src=${loggedclient.logo} alt="Logoemp" style="width: 15%;" /> <!-- Updated width to 10% -->
          
          <p>Estimado(a) Usuario,</p>
          <p>Le informamos que se ha evaluado de parte del equipo de Compliance su reunión en la organización ${formData.location}</p>
          <p>Tipo: ${formData.tipott}</p>
          <p>Organización: ${formData.ubicacion}</p>
          <p>Fecha declaracion: ${formData.createdAt}</p>
          <p>Resultado Evaluación: ${formData.estado}</p>
          <p>Le invitamos ingresar a Eticpro en nuestro link de abajo, para revisar el estado de su proceso</p>
          <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
          <p>Para soporte escribanos a <a href="mailto:hello@eticpro.com">hello@eticpro.com</a> o por Whatsapp al +56 977 657 352.</p>
          <p>Gracias por su atención y cooperación.</p>
          <p>Equipo Encargado de Prevención de Delitos</p>
          <p>Eticpro.</p>
          <p><a href="www.eticpro.com">www.eticpro.com</a></p>
        </div>
      </body>
      </html>
    `;

      const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: values.emailaviso ? values.emailaviso.split(",").map((email) => email.trim()) : [],
          emailTemplate,
                    subject : "Resultado Evaluacion de Compliance",

        }),
      });
    
  
      if (emailResponse.ok) {
        // Email sent successfully
        console.log("Email sent successfully");
      } else {
        // Error sending email
        console.log("Failed to send email");
      }

    } else {
      // Handle unsuccessful patch request
      console.log("Failed to update form data");
    }


    onSubmitProps.resetForm();
    navigate("/successPage");
  } catch (error) {
    console.log(error);
    // Handle error
  }
};


useEffect(() => {

const getForm = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}meetings/${_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const formData = await response.json();
      setForm(formData);
    } else {
      console.log("Failed to get form");
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

}, []);


useEffect(() => {
  if (form) {
    setFormData(form);
  }
}, [form]);


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={iseditform ? initialValuesEditForm : initialValuesEditForm}
      validationSchema={editformSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

           
                <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
                  <Typography variant="h6" gutterBottom>
                    AUTORIZA ?:
                  </Typography>
                  <RadioGroup
                    aria-label="Opción"
                    name="opauth"
                    value={values.opauth}
                    onChange={handleChange}
                    sx={{ borderRadius: "20px",
                      "& .MuiSvgIcon-root": {
                        color: palette.primary.light, // Selected radio color (red)
                      },
                      "& .Mui-checked": {
                        color: "#FFFFFF", // Not selected radio color (white)
                      },
                    }}
                  >
                    <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                  </RadioGroup>
                </FormControl>

                <TextField
                  label="Detalle las razones de la Autorización/Rechazo."
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="authtxt"
                  error={Boolean(touched.authtxt) && Boolean(errors.authtxt)}
                  helperText={touched.authtxt && errors.authtxt}
                  multiline
                  fullWidth
                  minRows={4}
                  maxRows={8}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />

<TextField
                  label="Mail(s) a quien desea informar de resultado de evaluacion (separados por coma si es mas de uno)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.emailaviso}
                  name="emailaviso"
                  error={Boolean(touched.emailaviso) && Boolean(errors.emailaviso)}
                  helperText={touched.emailaviso && errors.emailaviso}
                  multiline
                  fullWidth
                  minRows={1}
                  maxRows={3}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />


  <Button
    fullWidth
    type="submit"
    color="secondary"
    variant="contained"
    onClick={() => {
      setFormData((prevFormData) => ({
        ...prevFormData,
      }));
      setCurrentDate(format(new Date(), "yyyy-MM-dd"));
    }}
    sx={{ borderRadius: "20px",
      m: "2rem 0",
      p: "1rem",
      backgroundColor: palette.primary.red,
      color: palette.background.light,
      "&:hover": { color: palette.primary.light },
    }}
  >
    {<FormattedMessage id="Enviar"/>}
  </Button>



          </Box>


 

        </form>
      )}
    </Formik>
  );
};

export default Form;
