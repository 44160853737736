import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import TtvaluependWidget from "../../components/TtvaluependWidget";
import ComplaintpendWidget from "../../components/ComplaintpendWidget";
import FormpendWidget from "../../components/FormpendWidget";
import MeetingpenWidget from "../../components/MeetingpenWidget";
import DdPendingWidget from "../../components/DdPendingWidget";
import DdPendingPostulanteWidget from "../../components/DdPendingPostulanteWidget";


import { Link } from "react-router-dom";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CrisisAlert from "@mui/icons-material/CrisisAlert";
import CompareArrows from "@mui/icons-material/CompareArrows";
import Attachment from "@mui/icons-material/Attachment";
import { green } from "@mui/material/colors"; // Import the green color from Material-UI
import { FormattedMessage } from 'react-intl';

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { _id } = useParams();
  const loggedInUserId = useSelector((state) => state.user._id);
  const token = useSelector((state) => state.token);
  const [data, setData] = useState([]);
  const loggedidcontrato = useSelector((state) => state.user.idcontrato);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const userProfile = useSelector((state) => state.user.userprofile);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (userProfile) {
      // Check user profile and redirect accordingly
      if (userProfile === "externo") {
        navigate("/complaints"); // Redirect to the complaints page
      } 
      if (userProfile === "declare") {
        navigate("/dashDeclare"); // Redirect to the complaints page
      } 
    
    }
  }, [userProfile]); // Add userProfile to dependency array

 
  const handleButtonClick = (route) => {
    // Use the `navigate` function to navigate to the specified route
    navigate(route);
  };
 
  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  
  const fetchData = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/contract/${loggedidcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();







      const filteredData = formData.filter((row) => 
        (row.estado === "Por Evaluar" && row.flag === "URGENTE") || 
        (row.estado === "En Investigacion" && row.delay >= 20 && row.flag === "URGENTE")
    );
     // Sort filtered data based on the first column (`flag`)
     const sortedData = filteredData.sort((a, b) => {
      if (a.flag === "" && b.flag !== "") return 1; // a comes after b
      if (a.flag !== "" && b.flag === "") return -1; // a comes before b
      return a.flag.localeCompare(b.flag); // standard string comparison
  });
    setFilteredData(sortedData);

    
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();

  }, []);
//****************************************************
return (
    <Box m="20px"> 
               <Header title={<FormattedMessage id="Mis Pendientes" />} subtitle={<FormattedMessage id="Mis Pendientes de evaluación o aprobación" />} />

  <Box m="20px" display="flex" justifyContent="space-between">
        <Box
        display="flex"
        bgcolor="white" // Set background color to white
        boxShadow={3} // Add some shadow for a nice effect
        borderRadius={3} // Rounded corners
        style={{ textAlign: "right" }} // Align text to the right
        justifyContent="space-between"
        alignItems="center"
        marginTop="20px"
        paddingLeft="60px" paddingRight="60px"
      >
 
        {/* Repeat the above structure for Icons 2 to 5 with appropriate routes */}
        {/* Icon 2 */}

        {filteredData.length > 0 && (
  <Link to="/complaints" style={{ textDecoration: "none", textAlign: "center" }}>
    <Box display="flex" alignItems="center">
      <IconButton
        style={{ color: "red", fontSize: "2rem" }} // Set the color to red and increase the font size
        onClick={() => handleButtonClick("/complaints")}
      >
        {/* You can add an icon here, for example: */}
        <WarningIcon />
      </IconButton>

      <Box textAlign="center" ml={2}> {/* Add margin-left for spacing */}
        <Typography
          variant="h2"
          fontWeight="1000"
          style={{ color: "red" }}
        >
          {filteredData.length}
        </Typography>
        <Typography
          variant="h5"
          fontWeight="600"
          style={{ color: "red" }}
        >
          <FormattedMessage id="DENUNCIAS URGENTE (LEY KARIN)" />
        </Typography>
      </Box>
    </Box>
  </Link>
)}





     
      </Box>
      </Box>

      {(userProfile === "comitetica" || userProfile === "superadmin" || userProfile === "admin" ) && (
  <Box
    gridColumn={{ xs: 'span 12', md: 'span 4' }}
    gridRow="span 3"
    backgroundColor={colors.primary[400]}
    overflow="auto"
  >
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom={`4px solid ${colors.primary[500]}`}
      colors={colors.grey[100]}
      p="15px"
    >
      <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
        <FormattedMessage id=" Denuncias"/>

      </Typography>
    </Box>
    <ComplaintpendWidget _id={_id} />
  </Box>
)}

      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="" subtitle="" />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >




{/* ROW 2 */}
     
<Box
    gridColumn={{ xs: 'span 12', md: 'span 4' }}
    gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
      >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              <FormattedMessage id="Declaraciones"/>

            </Typography>
          </Box>

          <FormpendWidget _id={_id} />

         

</Box>

<Box
    gridColumn={{ xs: 'span 12', md: 'span 4' }}
    gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
      >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              <FormattedMessage id="Transferencias de Valor"/>

            </Typography>
          </Box>

          <TtvaluependWidget _id={_id} />

         

</Box>





<Box
    gridColumn={{ xs: 'span 12', md: 'span 4' }}
    gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
      >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              <FormattedMessage id="Últimas Reuniones"/>

            </Typography>
          </Box>

          <MeetingpenWidget _id={_id} />

          

</Box>

<Box
    gridColumn={{ xs: 'span 12', md: 'span 4' }}
    gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
      >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              <FormattedMessage id="Diligencias Debidas Organizaciones"/>

            </Typography>
          </Box>

          <DdPendingWidget _id={_id} />

         

</Box>

<Box
    gridColumn={{ xs: 'span 12', md: 'span 4' }}
    gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
      >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              <FormattedMessage id="Diligencias Debidas Postulantes"/>

            </Typography>
          </Box>

          <DdPendingPostulanteWidget _id={_id} />

         

</Box>

</Box>
          
        
      <Snackbar
  open={notificationOpen}
  autoHideDuration={14000}
  onClose={() => setNotificationOpen(false)}
>
  <MuiAlert
    elevation={6}
    variant="filled"
    onClose={() => setNotificationOpen(false)}
    severity="info"
  >
    {notificationMessage}
  </MuiAlert>
</Snackbar>
    </Box>

    
  );
};

export default Dashboard;
