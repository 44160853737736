import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import FindIn from '@mui/icons-material/Troubleshoot';
import AddIcon from '@mui/icons-material/AlarmAddRounded';
import ContactSupport from '@mui/icons-material/Report';
import LogoImage1 from '../../assets/eticprologo1.png';
import ConflictoDialog from "../../components/ConflictFollow"; // Adjust the path as needed


import { Box, Button, TextField, useMediaQuery, Typography, useTheme, Checkbox } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSessionStart, setLogin } from "../../state";
import { FormattedMessage } from 'react-intl';
import LogoImage from '../../assets/eticprologo8.png';
import FollowComplaint from "../../components/FollowComplaint";
import ComplaintDialog from "../../components/ComplaintDialog";
import PrivacyPolicyModal from '../../components/PrivacyPolicyModal';
import TroubleShoot from "@mui/icons-material/Troubleshoot";


const PopupMessage = ({ message }) => (
  <Box
    sx={{
      borderRadius: "20px",
      position: "fixed",
      bottom: "20px",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "white",
      padding: "8px 16px",
      borderRadius: "4px",
      zIndex: 9999,
    }}
  >
    {message}
  </Box>
);



const loginSchema = yup.object().shape({
  email: yup.string().email("Email invalido").required("Requerido"),
  password: yup.string().required("Requerido"),

});

const resetSchema = yup.object().shape({
 
  forgotPasswordEmail: yup.string().email("Email invalido").notRequired(),
});

const registerSchema = yup.object().shape({
  firstName: yup.string().notRequired("notRequired"),
  lastName: yup.string().notRequired("notRequired"),
  email: yup.string().email("Email invalido").required("Requerido").transform(value => value.toLowerCase()),
  password: yup.string().required("Requerido"),

});

const initialValuesLogin = {
  email: "",
  password: "",
};

const initialValuesReset = {
forgotPasswordEmail: "", // New field to handle forgot password email
};

const initialValuesRegister = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  userprofile: "externo", // Add this line
  idcontrato: "sincontrato",
};



const Form = () => {
    const [showErrorMessage, setShowErrorMessage] = useState(false); // State to control the visibility of the error message
    const [loading, setLoading] = useState(false); // State to manage loading state
    const [modalOpen, setModalOpen] = useState(false);

    const [modalConflictoOpen, setModalConflictoOpen] = useState(false);

    const [complaintDialogOpen, setComplaintDialogOpen] = useState(false); // State for the complaint dialog
    const [followComplaintOpen, setFollowComplaintOpen] = useState(false); // State for the complaint dialog
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
    const [isAgreed, setIsAgreed] = useState(true);  // Checkbox está seleccionado por defecto

    const [pageType, setPageType] = useState("login");
    const { palette } = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const isLogin = pageType === "login";
    const isRegister = pageType === "register";
    const isReset = pageType === "reset";
    const { REACT_APP_API_ENDPOINT } = process.env;
    const [isresetPassword, setIsResetPassword] = useState(false); // State to control whether the user wants to reset password
    const [showForgotPasswordBox, setShowForgotPasswordBox] = useState(false); // State to control the visibility of the "forgot password" box
    
    const [showPopup, setShowPopup] = useState(false);
    const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false);
    const SESSION_DURATION = 3600000; // 1 hour in milliseconds
    const sessionStart = useSelector((state) => state.sessionStart);
    const sessionExpiration = useSelector((state) => state.sessionExpiration);
    const [clients, setClients] = useState([]);



    const handleCloseComplaintDialog = () => {
      setComplaintDialogOpen(false);
    };
  
    const handleCloseFollowComplaint = () => {
      setFollowComplaintOpen(false);
    };
    
    const handleModalOpen = () => {
      setModalOpen(true);
    };
  
    const handleModalClose = () => {
      setModalOpen(false);
    };

    const handleModalConflictoOpen = () => {
      setModalConflictoOpen(true); // Ensure this state change is happening
    };
    
    const handleModalConflictoClose = () => {
      setModalConflictoOpen(false);
    };
    


   

    const handleMakeComplaint = () => {
      setComplaintDialogOpen(true);
    };

    const handleFollowComplaint = () => {
      setFollowComplaintOpen(true);
    };

    const login = async (values, onSubmitProps) => {
      setLoading(true); // Set loading state to true while waiting for the response
    
      try {
        const loggedInResponse = await fetch(`${REACT_APP_API_ENDPOINT}auth/login`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        });
    
        if (loggedInResponse.ok) {
          const loggedIn = await loggedInResponse.json();
          onSubmitProps.resetForm();
    
          const sessionStartTimestamp = new Date().toISOString();
          dispatch(setSessionStart(sessionStartTimestamp));
    
          
          dispatch(
            setLogin({
              user: loggedIn.user,
              token: loggedIn.token,
              userprofile: loggedIn.userprofile,
              rangoAprobacion: loggedIn.user.rangoAprobacion,
            })
          );
    
          navigate("/home");
    
          // Log session data for admin users
          if (loggedIn.userprofile === "aprobador" || loggedIn.userprofile === "admin") {
            console.log("estas adentro aprobador o admin");
            navigate("/complaints");
          }
        } else {
          // If login is unsuccessful, show the error message and reset the form
          setShowErrorMessage(true);
          onSubmitProps.resetForm();
        }
      } catch (error) {
        console.error("Login error:", error);
        // Handle network errors or other exceptions here
        // You might want to reset the form here as well
        setShowErrorMessage(true);
        onSubmitProps.resetForm();
      } finally {
        // Regardless of success or error, set loading state to false
        setLoading(false);
      }
    };
    


    const resetPopup = () => {
      setTimeout(() => {
        setShowPopup(false);
      }, 5000); // 5000 milliseconds (5 seconds)
    };


      const register = async (values, onSubmitProps) => {
        const userData = { ...values, userprofile: "externo", idcontrato: "sincontrato" };
        const formData = new FormData();
        for (let key in userData) {
          formData.append(key, userData[key]);
        }
      
        try {
          const savedUserResponse = await fetch(
            `${REACT_APP_API_ENDPOINT}auth/register`,
            {
              method: "POST",
              body: formData,
            }
          );
    
      if (savedUserResponse.status === 201) {
        // Registration was successful
        const savedUser = await savedUserResponse.json();
        onSubmitProps.resetForm();
        setPageType("login");
      } else if (savedUserResponse.status === 409) {
        // Handle the 409 (Conflict) error for duplicate email
        console.log("Before setting emailAlreadyRegistered:", emailAlreadyRegistered);
        setEmailAlreadyRegistered(true); // Set emailAlreadyRegistered to true
        setTimeout(() => {
          setEmailAlreadyRegistered(false); // Toggle emailAlreadyRegistered back to false after 6 seconds
        }, 6000); // 6000 milliseconds = 6 seconds        
      } else {
        // Handle other registration errors
        console.error('Registration error:', savedUserResponse.status);
        // You can display a different error message or take other actions here.
        console.log("error 0 sssss");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Registration error:', error);
    }
      };
  







          const forgotPassword = async (values) => {
            const resetPasswordResponse = await fetch(
              `${REACT_APP_API_ENDPOINT}auth/forgot-password`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: values.forgotPasswordEmail }),
              }
            );
            const resetPasswordResult = await resetPasswordResponse.json();
            // Handle the result (e.g., display a message or error to the user)
            if (resetPasswordResult) {
              setPageType("login");
            }
          };

          const handleFormSubmit = async (values, onSubmitProps) => {
            setShowErrorMessage(false);
          

            if (isRegister) await register(values, onSubmitProps);


            if (showForgotPasswordBox) {
              setShowPopup(true);
              resetPopup();
              await forgotPassword(values);
              onSubmitProps.resetForm();
              setShowForgotPasswordBox(false);
            } else {
              if (isLogin) {
                await login(values, onSubmitProps);
              } else if (isReset) {
                console.log("Performing password reset...");
                setPageType("login");
                navigate("/home");
              }
            }
            setShowErrorMessage(false);

          };

        

          const handleOpenPrivacyPolicy = () => {
            setPrivacyPolicyOpen(true);
          };
        
          const handleClosePrivacyPolicy = () => {
            setPrivacyPolicyOpen(false);
          };
  
 return (
  <Formik
    onSubmit={handleFormSubmit}
    initialValues={isLogin ? initialValuesLogin : initialValuesReset}
    validationSchema={isLogin ? loginSchema : (isReset ? resetSchema : registerSchema)}
    >
      {({
       values,
       errors,
       touched,
       handleBlur,
       handleChange,
       handleSubmit,
       setFieldValue,
       resetForm,
      }) => (
       
      <form onSubmit={handleSubmit}>
         <Box sx={{              
           backgroundColor: 'white',
           alignItems: 'center', p: 3 }}>
          
        

          <div style={{ position: "relative", textAlign: "center" }}>
      <img
        src={LogoImage1}
        alt='Eticpro'
        className='logo-image'
        style={{ maxWidth: "135px" }} // Adjusted to 120px to enlarge by 20%
      />
        <p></p>
      </div>
  {loading && (
            <Typography variant="body1" sx={{ borderRadius: "20px", textAlign: "center", mt: 2 }}>
              {<FormattedMessage id="Cargando..."/>}
            </Typography>
          )}

{showErrorMessage && !isAgreed && (
  <Box
    sx={{
      color: "red",
      marginBottom: "10px",
      textAlign: "center",
    }}
  >
    <Typography variant="body2">Debes aceptar la política de privacidad para continuar.</Typography>
  </Box>
)}

        {/* Conditionally render the error message */}
        {showErrorMessage && isAgreed && (
          <Typography color="error" variant="body1" sx={{ borderRadius: "20px", textAlign: "center", mt: 2 }}>
            Lo sentimos! Tu correo o contraseña son inválidos.
          </Typography>
        )}
          
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{ borderRadius: "20px",
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >

{isRegister && (


              <>
              {emailAlreadyRegistered && (
  <Typography
    color="error"
    variant="body1"
    sx={{
      borderRadius: "20px",
      textAlign: "center",
      mt: 2,
      width: "400%", // Adjust the width as needed
      marginLeft: "auto", // Center the error message horizontally
      marginRight: "auto",
    }}
  >
    Su usuario ya está creado en nuestro sistema. Porfavor vuelva a inicio y haga click en "Recuperar Contraseña" si no la recuerda.
  </Typography>
)}

                <TextField
                  label="Nombres"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  error={
                    Boolean(touched.firstName) && Boolean(errors.firstName)
                  }
                  helperText={touched.firstName && errors.firstName}
                   sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
                />
                <TextField
                  label="Apellidos"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  name="lastName"
                  error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
                    background: 'white', // Set the background color to white
                  },
                }}
                />
<TextField
  label="Email"
  onBlur={handleBlur}
  onChange={(event) => {
    // Remove spaces and convert the email input to lowercase
    const emailWithoutSpaces = event.target.value.trim().toLowerCase();
    handleChange({ target: { name: "email", value: emailWithoutSpaces } });
  }}
  value={values.email}
  name="email"
  error={Boolean(touched.email) && Boolean(errors.email)}
  helperText={touched.email && errors.email}
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white', // Set the background color to white
    },
  }}
/>

            
              <TextField
                label="Contraseña"
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
              />
                <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                 
                </Box>
              </>
            )}


 

{/*

<head>
<script src="https://www.google.com/recaptcha/enterprise.js?render=6LdhdCIoAAAAADrXUbS7kB0zwO770YS1L4JoFqfP"></script>
...
</head>

<script>
function onClick(e) {
  e.preventDefault();
  grecaptcha.enterprise.ready(async () => {
    const token = await grecaptcha.enterprise.execute('6LdhdCIoAAAAADrXUbS7kB0zwO770YS1L4JoFqfP', {action: 'LOGIN'});
    // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
    // reCAPTCHA Enterprise to the end user's browser.
    // This token must be validated by creating an assessment.
    // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
  });
}
</script>


          {/* Conditionally render Email and Contraseña fields based on the pageType and showForgotPasswordBox */}
          {isLogin && (
            <>
  <TextField
  label="Email"
  onBlur={handleBlur}
  onChange={(event) => {
    // Remove spaces and convert the email input to lowercase
    const emailWithoutSpaces = event.target.value.trim().toLowerCase();
    handleChange({ target: { name: "email", value: emailWithoutSpaces } });
  }}
  value={values.email}
  name="email"
  error={Boolean(touched.email) && Boolean(errors.email)}
  helperText={touched.email && errors.email}
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white', // Set the background color to white
    },
  }}
/>


              <TextField
                label="Contraseña"
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          
              />
              
            </>
          )}




          {/* Show "Olvidé contraseña" link or "Olvidé contraseña (Email)" field */}
          {isReset && (
            <>

            <TextField
                  label="Olvidaste tu contraseña?, ingresa tu Email."
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.forgotPasswordEmail}
                  name="forgotPasswordEmail"
                  error={
                    Boolean(touched.forgotPasswordEmail) &&
                    Boolean(errors.forgotPasswordEmail)
                  }
                  helperText={touched.forgotPasswordEmail && errors.forgotPasswordEmail}
                  sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
                />
                
            </>
          )}
          </Box>

          


          {/* BUTTONS */}
        

          
     
<Box
  display="flex"
  alignItems="center"
  gap="8px"
  sx={{ mt: 2 }}
>
  {/* Align "Recuperar Contraseña" button to the right */}

    

 
  <Checkbox
    checked={isAgreed}
    onChange={() => setIsAgreed(prev => !prev)}  // Cambia el estado del checkbox
    sx={{ color: palette.primary.main, '&.Mui-checked': { color: palette.primary.main } }}
  />
  <Typography
    variant="body2"
    sx={{ color: palette.text.secondary }}
  >
    Acepto la{' '}
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        setPrivacyPolicyOpen(true);
      }}
      style={{ color: palette.primary.main, textDecoration: 'underline' }}
    >
      Política de Privacidad
    </a>
  </Typography>
</Box>

<PrivacyPolicyModal open={privacyPolicyOpen} onClose={handleClosePrivacyPolicy} />

<Box>
            <Button
              fullWidth
              type="submit"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: "#05445e", // Correct color format
                color: "white",
                "&:hover": { backgroundColor: palette.primary.light, color: palette.primary.medium },
              }}
              disabled={loading || !isAgreed} // Deshabilitar el botón si no se ha aceptado la política de privacidad
>


              {loading ? <FormattedMessage id="Cargando..."/> : isLogin ? "INGRESAR" : (isRegister ? "Nuevo Usuario" : "ESTABLECER CONTRASEÑA")}
            </Button>
            <Typography
              onClick={() => {
                setPageType(isLogin ? "register" : "login");
                resetForm();
                setShowPopup(false); // Reset the message displayed before
              }}
              sx={{ borderRadius: "20px",
                textDecoration: "underline",
                color: palette.primary.main,
                "&:hover": {
                  cursor: "pointer",
                  color: palette.primary.light,
                },
              }}
            >
            
             
            </Typography>
            
            <Typography
              onClick={() => {

                setPageType(isLogin ? "reset" : "login");
                resetForm();
                   {/* Show the popup message if showPopup is true */}
                setShowForgotPasswordBox(isLogin);
                setShowPopup(false); // Reset the message displayed before

              }}
              sx={{ borderRadius: "20px",
                textDecoration: "underline",
                color: palette.primary.medium,
                "&:hover": {
                  cursor: "pointer",
                  color: palette.primary.medium,
                },
              }}
            >
           {isLogin ? "Recuperar Contraseña" : "Recordé la contraseña"}
            </Typography>
</Box>

</Box>
<Button
  onClick={handleModalOpen} // Open the modal on button click
  sx={{ 
    borderRadius: "20px",
    m: "2rem 0",
    p: "1rem",
    backgroundColor: "#338BA8", // Correct color format
    color: "white",
    width: "40%", // Ajusta el ancho del botón según sea necesario
    gridColumn: "span 1",
    display: "flex", // Flex layout
    alignItems: "center", // Vertical alignment
    justifyContent: "center", // Horizontal alignment
    gap: "0.5rem", // Space between icon and text
    "&:hover": { backgroundColor: palette.primary.light, color: palette.primary.medium },
  }}
>
  <ContactSupport style={{ fontSize: '1.5rem' }} /> {/* Aumenta el tamaño del icono */}
  Canal de denuncias
</Button>



{/*

<Button
  onClick={handleModalConflictoOpen} // Open the modal on button click
  sx={{  
    borderRadius: "20px",
    m: "2rem 0",
    p: "1rem", 
    backgroundColor: "#338BA8", // Correct color format
    color: "white",
    width: "40%", // Ajusta el ancho del botón según sea necesario
    gridColumn: "span 1",
    display: "flex", // Flex layout
    alignItems: "center", // Vertical alignment
    justifyContent: "center", // Horizontal alignment
    gap: "0.5rem", // Space between icon and text
    "&:hover": { backgroundColor: palette.primary.light, color: palette.primary.medium },
  }} 
> 
*/}




   



  
          {/* Conditionally render PopupMessage */}
        {showPopup && <PopupMessage message="Envio de mail exitoso, chequee su bandeja de entrada o spam por favor" />}
      
   
        
    <Dialog 
      open={modalOpen} 
      onClose={handleModalClose} 
      BackdropProps={{ 
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }, 
        onClick: handleModalClose 
      }}
    >
      <Box 
        sx={{ 
          padding: '20px', 
          maxWidth: '600px', 
          margin: 'auto', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          minHeight: '320px' // Reduced height by 20%
        }}
      >
        <Typography 
          variant="h2" 
          align="center" 
          gutterBottom 
          sx={{ fontWeight: 'bold' }}
        >
          ¿Qué deseas hacer?
        </Typography>
        <Box 
          sx={{ 
            margin: '10px', 
            display: 'flex', 
            gap: '10px', 
            justifyContent: 'center', 
            alignItems: 'center', 
            flexWrap: 'wrap' 
          }}
        >
          <div 
            className='p-6 border border-gray-300 rounded-md flex flex-col justify-center items-center relative' 
            style={{ 
              backgroundColor: 'rgb(236, 253, 255)', 
              width: '45%', 
              height: '120px', // Reduced height by 20%
              maxWidth: '300px', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center' 
            }}
          >
            <Button
              onClick={handleMakeComplaint}
              sx={{
                borderRadius: "20px",
                p: "4.0 rem", 
                color: 'rgb(15, 15, 98)',
                width: "100%", 
                height: "100%", 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center', 
                textAlign: 'center', 
                '&:hover': { backgroundColor: "#00FF00" }
              }}
            >
              <AddIcon style={{ fontSize: '2.6rem', marginBottom: '0.5rem' }} /> 
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Crear Denuncia</Typography> {/* Bold title */}
            </Button>
          </div>
          <div 
            className='p-6 border border-gray-300 rounded-md flex flex-col justify-center items-center relative' 
            style={{ 
              backgroundColor: 'rgb(236, 253, 255)', 
              width: '45%', 
              height: '120px', // Reduced height by 20%
              maxWidth: '300px', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center' 
            }}
          >
            <Button
              onClick={handleFollowComplaint}
              sx={{
                borderRadius: "10px",
                p: "4.0 rem", 
                color: 'rgb(15, 15, 98)',
                width: "100%", 
                height: "100%", 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center', 
                textAlign: 'center', 
                '&:hover': { backgroundColor: "#00FF00" }
              }}
            >
              <FindIn style={{ fontSize: '2.6rem', marginBottom: '0.5rem' }} /> 
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dar Seguimiento a Denuncia</Typography> {/* Bold title */}
            </Button>
          </div>
          </Box>

  </Box>
  <Dialog open={complaintDialogOpen}>
    <DialogContent>
      <ComplaintDialog open={complaintDialogOpen} onClose={handleCloseComplaintDialog} />
    </DialogContent>
    <DialogActions />
  </Dialog>


          <Dialog open={followComplaintOpen}>
          <DialogContent>

          <FollowComplaint
        open={followComplaintOpen}
        onClose={handleCloseFollowComplaint}
      />
        </DialogContent>
        <DialogActions>
        </DialogActions>
        </Dialog>


        <Dialog open={modalConflictoOpen}>
          <DialogContent>

          <ConflictoDialog 
        open={modalConflictoOpen}
        onClose={handleModalConflictoClose}
      />
        </DialogContent>

     
        </Dialog>

      

          </Dialog>
       
          <Dialog 
      open={modalConflictoOpen} 
      onClose={handleModalConflictoClose} 
      BackdropProps={{ 
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }, 
        onClick: handleModalConflictoClose 
      }}
    >
      
  <Dialog open={complaintDialogOpen}>
    <DialogContent>
      <ComplaintDialog open={complaintDialogOpen} onClose={handleCloseComplaintDialog} />
    </DialogContent>
    <DialogActions />
  </Dialog>


          <Dialog open={followComplaintOpen}>
          <DialogContent>

          <FollowComplaint
        open={followComplaintOpen}
        onClose={handleCloseFollowComplaint}
      />
        </DialogContent>
        <DialogActions>
        </DialogActions>
        </Dialog>


        <Dialog open={modalConflictoOpen}>
          <DialogContent>

          <ConflictoDialog 
        open={modalConflictoOpen}
        onClose={handleModalConflictoClose}
      />
        </DialogContent>

     
        </Dialog>

      

          </Dialog>
        </form>
        
      )}
    </Formik>
  );
};

export default Form;
