import { Box, Typography, useTheme, IconButton, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";

import { tokens } from "../../theme";
import CreateCampaign from "../../components/CreateCampaign";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import FlexBetween from "../../components/FlexBetween";
import { useParams } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";
import CreateClient from "../../components/CreateClient";

import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { FormattedMessage } from 'react-intl';
import Modal from '@mui/material/Modal';


const Clients = ({ }) => {
const location = useLocation();
const idcontrato = useSelector((state) => state.user.idcontrato);
const [editedRows, setEditedRows] = useState({});
const [showCreateClient, setShowCreateClient] = useState(false);
const { _id, picturePath, email } = useSelector((state) => state.user);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedinuserprofile = useSelector((state) => state.user.userprofile); 

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dateRange, setDateRange] = useState([0, 100]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [totalDescriptionsCount, setTotalDescriptionsCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editedRow, setEditedRow] = useState({});

  const handleRowDoubleClick = (params) => {
    setSelectedRow(params.row);
    setEditedRow({ ...params.row }); // Initialize editedRow with the selected row data

    setOpenModal(true);
  };

  const handleFieldChange = (event, field) => {
    setEditedRow({ ...editedRow, [field]: event.target.value });
  };

  const handlePatchData = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}clients/${editedRow.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editedRow),
      });
      if (!response.ok) {
        throw new Error('Failed to update row');
      }
      // Update UI or any necessary state after successful update
      setOpenModal(false); // Close modal after successful update
    } catch (error) {
      console.error('Error updating row:', error);
      // Handle error (e.g., show error message)
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  let filteredData = data;

const handleDateFilterChange = (event, newValue) => {
  setDateRange(newValue);
};

filteredData = data
.filter((row) => {
  // Apply the date filter based on the "createdAt" field (assuming it is a numeric value)
  const createdAt = parseInt(row.createdAt);
  return createdAt >= dateRange[0] && createdAt <= dateRange[1];
})
.filter((row) => {
  // Combine all column values into a single string
  const rowDataAsString = Object.values(row)
    .filter((value) => typeof value === 'string')
    .join(' ');

  // Apply the search filter to the concatenated string
  const searchInputLower = searchInput.toLowerCase();
  return rowDataAsString.toLowerCase().includes(searchInputLower);
});


useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const formData = await response.json();

      // Add unique id and format createdAt
      const dataWithFormattedDate = formData.map((row) => ({
        ...row,
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("es-ES", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
      }));

      setData(dataWithFormattedDate); // Correctly updates state triggering re-render
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally: Update the state to reflect the error in the UI
    }
  };

  // Call fetchData() within useEffect
  fetchData();
}, [isFormSubmitted, dateRange, REACT_APP_API_ENDPOINT, token]); // Ensure dependencies are correctly listed


  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}clients`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();
  
      // Add unique id and format createdAt
      const dataWithFormattedDate = formData.map((row) => ({
        ...row,
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("es-ES", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
      }));
  
      setData(dataWithFormattedDate); // Set the fetched data to the state here
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const handleRowClick = async (params, action) => {
    const rowId = params.row._id;
    
    if (action === "edit") {
      const { row, field } = params;
      const updatedRow = { ...row, [field]: params.value };
  


      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [row.id]: updatedRow,
      }));
  
      // Save the edited row
      await saveRowChanges(rowId, updatedRow);
    } else if (action === "delete") {
      // Add code to delete the row from the database using the rowId
     // deleteRow(rowId);
    }
  };
  
  // Function to save the changes for a specific row
  const saveRowChanges = async (rowId, updatedRow) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}clients/${rowId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });
      if (response.ok) {
        const updatedData = data.map((row) => row.id === updatedRow.id ? updatedRow : row);
        setData(updatedData); // Update the state to refresh the DataGrid
      } else {
        // Handle the error, e.g., show a notification
      }
    } catch (error) {
      console.error("Error saving row changes:", error);
      // Optionally handle the error, e.g., show a notification
    }
  };
  

  const deleteRow = async (rowId) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}clients/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the data and filteredData states after deleting the row
      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
      setFilteredData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };
  const handleCellEditCommit = (params) => {
    const { id, field, value } = params;
    const row = data.find((row) => row.id === id);
  
    if (row) {
      const updatedRow = { ...row, [field]: value };
  
  
  
      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [id]: updatedRow,
      }));
  
      const updatedData = data.map((row) => (row.id === id ? updatedRow : row));
      setData(updatedData);
      setFilteredData(updatedData);
    }
  };
  
  const [sortModel, setSortModel] = useState([
    {
      field: "estado",
      sort: "asc", // Initial sorting order (ascending or descending)
    },
  ]);


  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const columns = [

 {
      field: "_id",
      headerName: "Id Contrato",
      width: 200,
     headerClassName: "bold-header",
  },
  {
    field: "nombreempresa",
    headerName: "Nombre",
    flex: 1,
    editable: true, // Make the column editable
  headerClassName: "bold-header",
},
    {
      field: "rutemp",
      hide: true,

      headerName: "Rut Organización",
      flex: 1,
      editable: true, // Make the column editable
    headerClassName: "bold-header",
  },

  {
    field: "fee",
    hide: true,

    headerName: "Fee",
    flex: 1,
    editable: true, // Make the column editable
  headerClassName: "bold-header",
},

{
  field: "feePartner",

  headerName: "Affiliate",
  flex: 1,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},

{
  field: "feePer",

  headerName: "Affiliate %",
  flex: 1,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},

{
  field: "comments",

  headerName: "Comentarios",
  flex: 1,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},

{
  field: "ocRecurrencia",

  headerName: "ocRecurrencia",
  flex: 1,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},

{
  field: "facRecurrencia",

  headerName: "facRecurrencia",
  flex: 1,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},

{
  field: "pagoRecurrencia",

  headerName: "pagoRecurrencia",
  flex: 1,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},
   
    {
      field: "mailserver",
      headerName: "mailserver",
      flex: 1,
      hide: true,
      editable: true, // Make the column editable
    headerClassName: "bold-header",
  },
  

  {
    field: "cipherstype",
    headerName: "cipherstype",
    flex: 1,
    hide: true,
    editable: true, // Make the column editable
  headerClassName: "bold-header",
  },

{
  field: "mailport",
  headerName: "mailport",
  flex: 1,
  hide: true,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},

{
  field: "mailuser",
  headerName: "usermail",
  flex: 1,
  hide: true,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},

{
  field: "passmail",
  headerName: "passmail",
  flex: 1,
  hide: true,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},


   

    {
      field: "estado",
      headerName: "estado",
      flex: 1,
      editable: true, // Make the column editable
    headerClassName: "bold-header",
  },

    {
      field: "product",
      headerName: "Producto",
      flex: 1,
      editable: true, // Make the column editable
    headerClassName: "bold-header",
  },




    {
      field: "nombrecontacto",
      headerName: "Sponsor",
      flex: 1,
      editable: true, // Make the column editable
    headerClassName: "bold-header",
  },
  {
    field: "celularcontacto",
    headerName: "Tel Sponsor",
    flex: 1,
    hide: true,
    editable: true, // Make the column editable
  headerClassName: "bold-header",
},

{
  field: "mailcontacto",
  headerName: "Mail Sponsor",
  hide: true,

  flex: 1,
  hide: true,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},

  {
    field: "mailfactura",
    headerName: "Factura",
    flex: 1,
    hide: true,

    editable: true, // Make the column editable
  headerClassName: "bold-header",
},

{
  field: "mailcom",
  headerName: "Contacto Comercial",
  flex: 1,
  hide: true,

  editable: true, // Make the column editable
headerClassName: "bold-header",
},

{
  field: "oc",
  headerName: "OC",
  flex: 1,
  hide: true,

  editable: true, // Make the column editable
headerClassName: "bold-header",
},
{
  field: "totalcountcontrato",
  headerName: "Invitaciones",
  flex: 1,
  editable: true,
headerClassName: "bold-header",
},
{
  field: "logo",
  headerName: "logo",
  flex: 1,
  editable: true, // Make the column editable
headerClassName: "bold-header",
},

    {
      field: "inicioplan",
      headerName: "Inicio Plan",
      flex: 1, headerClassName: "bold-header",
      editable: true,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        });
      

    },
  },
    {
      field: "industria",
      headerName: "Rubro",
      flex: 1,

      editable: true, // Make the column editable
    headerClassName: "bold-header",
  },
  {
    field: "cmf",
    headerName: "CMF",
    hide: true,

    flex: 1,
    editable: true, // Make the column editable
  headerClassName: "bold-header",
},
    {
      field: "actions",
      headerName: "Actions",
      width: 120,headerClassName: "bold-header",
      renderCell: (params) => (
        <>
          {/* Check if the user is a superadmin */}
          {loggedinuserprofile === 'superadmin' && (
            <>
          <IconButton
            color="primary"
            onClick={() => handleRowClick(params, "edit")}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleRowClick(params, "delete")}
          >
            <DeleteIcon />
          </IconButton>
          </>
      )}
    </>
  ),
}

  ];

  return (
    <Box m="20px">
      <Header title={<FormattedMessage id="Clientes" />} subtitle={<FormattedMessage id="Administrar mis Clientes" />} />
      <p>ID Contrato: {idcontrato}</p>

      <div>
      <Box>
          <Button
          onClick={() => setShowCreateClient(prevState => !prevState)}

            sx={{ borderRadius: "20px",
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[900],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Upload sx={{ borderRadius: "20px", mr: "10px" }} />
            <FormattedMessage id="Nuevo Cliente"/>
            
            
          </Button>
          {showCreateClient && <CreateClient />}

        </Box>
    </div>
    <Box m="20px 0">
          <TextField
            label="Buscar..."
            value={searchInput}
            onChange={handleSearchInputChange}
          />
    </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
     <DataGrid
      initialState={{
        columns: {
          columnVisibilityModel: {
            feePartner: false,
            feePer: false,

            mailcom: false,
            nombrecontacto: false,
            celularcontacto: false,
            passmail: false,
            mailuser: false,
            mailport: false,
            cipherstype: false,
            mailserver: false,
            pagoRecurrencia: false,
            facRecurrencia: false,
            ocRecurrencia: false,
            comments: false,
          },
        },
      }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        components={{ Toolbar: GridToolbar }}
        headerClassName="bold-header"
        rows={filteredData.map((item) => ({
          ...item,
          id: item._id,
        }))}
        columns={columns.filter((column) => !column.hide)}
        onRowClick={handleRowClick}
        onRowDoubleClick={handleRowDoubleClick}
        sortModel={sortModel} // Pass the sorting configuration to the DataGrid
        onSortModelChange={(model) => setSortModel(model)} // Handle changes to sorting configuration
      />
 {/* Modal to display and edit row details */}
 <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '90vw', maxHeight: '90vh', overflow: 'auto', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6">Editar Detalles</Typography>
          {selectedRow && (
            <div>
              {/* Loop through all keys of selectedRow and display editable fields */}
              {Object.keys(selectedRow).map((key) => (
                <TextField
                  key={key}
                  label={key}
                  value={editedRow[key] || ''}
                  onChange={(event) => handleFieldChange(event, key)}
                  fullWidth
                  margin="normal"
                />
              ))}
            </div>
          )}
          <Button onClick={handlePatchData} variant="contained" color="primary">Guardar Cambios</Button>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </Box>
      </Modal>


      </Box>

     
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
     <DataGrid
      initialState={{
        columns: {
          columnVisibilityModel: {
            
            oc: false,
            _id: false,
            rutemp: false,
            comments: false,
            mailserver: false,
            cipherstype: false,
            mailport: false,
            mailuser: false,
            nombrecontacto: false,
            celularcontacto: false,
            mailcontacto: false,
            mailfactura: false,
            mailcom: false,
            mailuser: false,
            totalcountcontrato: false,
            logo: false,
            industria: false,
            cmf: false,
            passmail: false,


          },
        },
      }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        components={{ Toolbar: GridToolbar }}
        headerClassName="bold-header"
        rows={filteredData.map((item) => ({
          ...item,
          id: item._id,
        }))}
        columns={columns}
        onRowClick={handleRowClick}
        onRowDoubleClick={handleRowDoubleClick}
        sortModel={sortModel} // Pass the sorting configuration to the DataGrid
        onSortModelChange={(model) => setSortModel(model)} // Handle changes to sorting configuration
      />
 {/* Modal to display and edit row details */}
 <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '90vw', maxHeight: '90vh', overflow: 'auto', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6">Editar Detalles</Typography>
          {selectedRow && (
            <div>
              {/* Loop through all keys of selectedRow and display editable fields */}
              {Object.keys(selectedRow).map((key) => (
                <TextField
                  key={key}
                  label={key}
                  value={editedRow[key] || ''}
                  onChange={(event) => handleFieldChange(event, key)}
                  fullWidth
                  margin="normal"
                />
              ))}
            </div>
          )}
          <Button onClick={handlePatchData} variant="contained" color="primary">Guardar Cambios</Button>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </Box>
      </Modal>


      </Box>




    </Box>
  );
};

export default Clients;
