import { Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider } from "@mui/material";

import { DataGrid, GridToolbar, GridColDef, esES, GridRenderCellParams } from "@mui/x-data-grid";
import Reunion from "@mui/icons-material/Group";
import Read from "@mui/icons-material/LocalLibrary";
import CI from "@mui/icons-material/ListAlt";

import Login from "@mui/icons-material/PlayArrow";
import TV from "@mui/icons-material/AttachMoney";
import Denuncia from "@mui/icons-material/Warning";
import DD from "@mui/icons-material/ZoomIn";
import Otro from "@mui/icons-material/DoubleArrow";
import { createTheme } from '@mui/material/styles';

import Chip, { ChipProps } from "@mui/material/Chip";
import { green, red, blue } from "@mui/material/colors";
import ProgressBarr from "../../components/ProgressBarr";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import Upload from "@mui/icons-material/AddSharp";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StatBoxnom from "../../components/StatBoxnom";  
 import Grid from "@mui/material/Grid";
 import SearchIcon from "@mui/icons-material/Search";
 import { FormattedMessage } from 'react-intl';


const Allsessions = ({}) => {
const location = useLocation();
const idcontrato = useSelector((state) => state.user.idcontrato);
const [editedRows, setEditedRows] = useState({});
const theme = useTheme();
const colors = tokens(theme.palette.mode);
const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
const [data, setData] = useState([]); // Updated: Initialize data state
const [user, setUser] = useState([]); // Updated: Initialize data state
const [userData, setUserData] = useState([]); // Updated: Initialize user data state
const navigate = useNavigate();
const dispatch = useDispatch();
const token = useSelector((state) => state.token);
const loggedInUserId = useSelector((state) => state.user._id);
const { palette } = useTheme();
const main = palette.neutral.main;
const [filteredSearchData, setFilteredSearchData] = useState([]);
const { REACT_APP_API_ENDPOINT } = process.env;
const loggedinuserprofile = useSelector((state) => state.user.userprofile);
const [isFormSubmitted, setIsFormSubmitted] = useState(false);
const [dateRange, setDateRange] = useState([0, 100]);
const [isLoading, setIsLoading] = useState(true);
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [showSuccessPopup, setShowSuccessPopup] = useState(false);
const userProfile = useSelector((state) => state.user.userprofile);
const [exportedData, setExportedData] = useState(null);

const [searchInput, setSearchInput] = useState("");
const [totalcam, setTotalcam] = useState(0);

const [idcontratos, setIdcontratos] = useState([]);


const filteredData = data.filter((row) => {
  // Combine all relevant user fields into a single string
  const userFields = [
    row.user.userprofile,
    row.user.firstName,
    row.user.lastName,
    row.user.rut,
    row.user.occupation, // Include occupation
    row.user.location,   // Include location
    row.user.area,   // Include location
    row.user.email, // Include email field
    row.user.level, // Include email field

   // Include email field



    // Add other relevant user fields
  ].filter((value) => typeof value === 'string').join(' ');

  // Combine all other column values into a single string
  const rowDataAsString = Object.values(row)
    .filter((value) => typeof value === 'string')
    .join(' ');

  // Combine both user and other fields for search
  const combinedSearchString = `${userFields} ${rowDataAsString}`;

  // Apply the search filter to the combined string
  const searchInputLower = searchInput.toLowerCase();
  return combinedSearchString.toLowerCase().includes(searchInputLower);
});


useEffect(() => {
  setShowSuccessPopup(false);
  setSnackbarOpen(false);
  fetchData();
}, [isFormSubmitted, searchInput]);


// Calculate the count of each area type from filtered data
const userp = filteredData.reduce((counts, item) => {
  const usertt = item.user.userprofile || 'Unknown';
  counts[usertt] = (counts[usertt] || 0) + 1;
  return counts;
}, {});


// Calculate the count of each area type from filtered data
const tipottCounts = filteredData.reduce((counts, item) => {
  const tipott = item.user.area || 'Unknown';
  counts[tipott] = (counts[tipott] || 0) + 1;
  return counts;
}, {});


// Calculate the count of each conflict type from filtered data
const userEmailCounts = filteredData.reduce((counts, item) => {
const userEmail = item.user.rut || 'Unknown';
counts[userEmail] = (counts[userEmail] || 0) + 1;
return counts;
}, {});

// Calculate the count of each conflict type from filtered data
const userContrato = filteredData.reduce((counts, item) => {
  const userEmail = item.user.idcontrato || 'Unknown';
  counts[userEmail] = (counts[userEmail] || 0) + 1;
  return counts;
  }, {});



  
const fetchData = async () => {
  try {
    let endpoint = `${REACT_APP_API_ENDPOINT}session/${loggedInUserId}`;
    // Check the user profile and update the endpoint accordingly
    if (userProfile === "superadmin") {
      endpoint = `${REACT_APP_API_ENDPOINT}session/`;
    }

    if (userProfile === "admin"|| userProfile === "coadmin" || userProfile === "aprobador" || userProfile === "aprobador1" || userProfile === "aprobador2" || userProfile === "sales" || userProfile === "broker" ) {
      endpoint = `${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`;
    }
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const formData = await response.json();
    const dataWithFormattedDate = formData.map((row) => {
      const formattedRow = {
        ...row,
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false, // Ensure 24-hour format
            timeZone: "UTC", // Specify the time zone to avoid inconsistencies
            })
          : "",
        user: row.user, // Ensure user information is included
      };
      return formattedRow;
    });
    
    setIsLoading(false);

    // Sort the data chronologically based on 'createdAt'
    dataWithFormattedDate.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });

    setData(dataWithFormattedDate);
 

  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const dataxperfil = Object.entries(userp).map(([label, count]) => ({
  label,
  count,
}));

const datax = Object.entries(tipottCounts).map(([label, count]) => ({
  label,
  count,
}));
const dataxconflicto = Object.entries(userEmailCounts).map(([label, count]) => ({
  label,
  count,
}));
const dataxcontrato = Object.entries(userContrato).map(([label, count]) => ({
  label,
  count,
}));

// Sort the data arrays by count
dataxperfil.sort((a, b) => b.count - a.count);
datax.sort((a, b) => b.count - a.count);
dataxconflicto.sort((a, b) => b.count - a.count);
dataxcontrato.sort((a, b) => b.count - a.count);



// Initialize a Map to store counts for each date
const dateCounts = new Map();

// Count occurrences for each date
filteredData.forEach((row) => {
const dateKey = new Date(row.createdAt).toLocaleDateString("es-ES", {
  day: "2-digit",
  month: "2-digit",
  year: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false, // Ensure 24-hour format
  timeZone: "UTC", // Specify the time zone to avoid inconsistencies
});

if (dateCounts.has(dateKey)) {
dateCounts.set(dateKey, dateCounts.get(dateKey) + 1);
} else {
dateCounts.set(dateKey, 1);
}
});

// Convert the Map to an array of objects for chart data
const chartData = Array.from(dateCounts).map(([date, count]) => ({
label: date,
count: count,
}));

// Sort the chart data by date
chartData.sort((a, b) => new Date(b.label) - new Date(a.label));

// Render charts only if data is available


  const handleRowClick = async (params, action) => {
    const rowId = params.row._id;
    
    if (action === "edit") {
      const { row, field } = params;
      const updatedRow = { ...row, [field]: params.value };

      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [row.id]: updatedRow,
      }));
  
      // Save the edited row
      await saveRowChanges(rowId, updatedRow);
    } else if (action === "delete") {
      // Add code to delete the row from the database using the rowId
      deleteRow(rowId);
    }
  };
  
  // Function to save the changes for a specific row
  const saveRowChanges = async (rowId, updatedRow) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}session/${rowId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });
    } catch (error) {
      console.error("Error saving row changes:", error);
    }
  };
  
  const deleteRow = async (rowId) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}session/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };

  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
 
  const handleDownloadXLSX = async () => {
    if (data !== undefined && data !== null && data.length > 0) {
      try {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sesiones');
  
        // Define the headers for your data (assuming the first object in data has the keys)
        const headers = Object.keys(data[0]);
  
        // Add the headers to the worksheet
        worksheet.addRow(headers);
  
        // Add the data rows to the worksheet
        data.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
  
        // Generate a blob from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Convert the buffer to a blob
        const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        // Download the XLSX file
        saveAs(xlsxBlob, 'actividad.xlsx');
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
    }
  };

  if (isLoading) {
    return <div>{<FormattedMessage id="Cargando..."/>}</div>;
  }




  function getChipProps(params: GridRenderCellParams): ChipProps {
    if (params.value === "login") {
      return {
        icon: <Login style={{ fill: green[500] }} />,
        label: params.value,
        style: {
          borderColor: red[500]
        }
      };
    }
      if (params.value === "Read") {
        return {
          icon: <Read style={{ fill: blue[500] }} />,
          label: params.value,
          style: {
            borderColor: green[500]
          }
        };    
    }
    if (params.value === "CI") {
      return {
        icon: <CI style={{ fill: blue[500] }} />,
        label: params.value,
        style: {
          borderColor: green[500]
        }
      };    
  }
    if (params.value === "TV") {
      return {
        icon: <TV style={{ fill: blue[500] }} />,
        label: params.value,
        style: {
          borderColor: green[500]
        }
      };    
  }
  
  if (params.value === "Denuncia") {
    return {
      icon: <Denuncia style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500]
      }
    };    
  }
  
  if (params.value === "Repo") {
    return {
      icon: <Repo style={{ fill: green[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500]
      }
    };    
  }
  if (params.value === "PEP") {
    return {
      icon: <Reunion style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500]
      }
    };    
  }  if (params.value === "DD") {
    return {
      icon: <DD style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500]
      }
    };    
  }  if (params.value === "DD RA") {
    return {
      icon: <DD style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500]
      }
    };    
  }  if (params.value === "DD RMB") {
    return {
      icon: <DD style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500]
      }
    };    
  }
  
     else {
      return {
        icon: <Otro style={{ fill: blue[500] }} />,
        label: params.value,
        style: {
          borderColor: blue[500]
        }
      };
    }
  }


  function getChipProps1(params: GridRenderCellParams): ChipProps {
    if (params.row.user.level === "1Linea" || params.row.user.level === "Directores" ) {
      return {
        label: params.value,
        style: {
          borderColor: red[500]
        }
      };
    
    } else {
      return {
        label: params.value,
        style: {
          borderColor: blue[50]
        }
      };
    }
  }
  

  const columns = [  
    {
      field: "createdAt",
      headerName: <FormattedMessage id="Actividad" />,
      flex: 1, headerClassName: "bold-header",
  
      valueGetter: (params) => {
        // Parse the createdAt string to a Date object
        const createdAt = new Date(params.row.createdAt);
        createdAt.setHours(createdAt.getHours() - 4);

    
        // Extract date and time parts separately
        const options = {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false, // Ensure 24-hour format
            timeZone: "UTC", // Specify the time zone to avoid inconsistencies
        };
    
        // Adjust the options to match the local time zone
        options.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        const formattedDateTime = createdAt.toLocaleString("es-ES", options);
    
        return formattedDateTime;
    },
    
    
    
    },


  

{ field: "email", headerName: <FormattedMessage id="Email" />, editable: true, flex: 1, valueGetter: (params) => params.row.user.email, 
headerClassName: "bold-header",
},

 {
      field: "user._id",
      headerName: <FormattedMessage id="User id" />,
      flex: 1,
      valueGetter: (params) => params.row.user._id,
      headerClassName: "bold-header",
    },
    {
      field: "user.userprofile",
      headerName: <FormattedMessage id="Perfil" />,
      flex: 1,
      valueGetter: (params) => params.row.user.userprofile,
      headerClassName: "bold-header",
    },

    { field: "user.firstName", headerName: <FormattedMessage id="Nombres"/>, flex: 1, valueGetter: (params) => params.row.user.firstName,
    headerClassName: "bold-header",
  },
    { field: "user.lastName", headerName: <FormattedMessage id="Apellidos"/>, flex: 1,  valueGetter: (params) => params.row.user.lastName, 
    headerClassName: "bold-header",
  },
    { field: "user.rut", headerName: <FormattedMessage id="Rut"/>, flex: 1,   valueGetter: (params) => params.row.user.rut, 
    headerClassName: "bold-header",
  },

  { field: "user.area", headerName: <FormattedMessage id="Area"/>, flex: 1,  valueGetter: (params) => params.row.user.area, 
  headerClassName: "bold-header",
},

  { field: "user.occupation",  renderCell: (params) => {
    return <Chip variant="outlined" size="small" {...getChipProps1(params)} />;
  }, headerName: <FormattedMessage id="Cargo" />, flex: 2,       editable: true ,headerClassName: "bold-header", valueGetter: (params) => params.row.user.occupation, 
},

 
  { field: "user.location", headerName: <FormattedMessage id="Organización"/>, flex: 1,  valueGetter: (params) => params.row.user.location, 
  headerClassName: "bold-header",
},

{ field: "type", renderCell: (params) => {
  return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
}, headerName: <FormattedMessage id="Tipo" />, editable: true, flex: 1, valueGetter: (params) => params.row.type, 
headerClassName: "bold-header",
},

{ field: "user.level", renderCell: (params) => {
  return <Chip variant="outlined" size="small" {...getChipProps1(params)} />;
}, headerName: <FormattedMessage id="Nivel"/>, flex: 1,  valueGetter: (params) => params.row.user.level, 
headerClassName: "bold-header",
},



{ field: "fileName", headerName: <FormattedMessage id="Archivo" />, flex: 1, valueGetter: (params) => params.row.fileName, 
headerClassName: "bold-header",
},

  // Add more columns if needed
  
  ];
  

if (loggedinuserprofile === 'superadmin') {


  // Conditionally add the "Actions" column
  columns.push({
    field: "actions",
    headerName: <FormattedMessage id="Actions"/>,
    width: 120,
    renderCell: (params) => (
      <>
     <IconButton
            color="primary"
            onClick={() => handleRowClick(params, "edit")}
          >
            <EditIcon />
          </IconButton>
        <IconButton color="secondary" onClick={() => handleRowClick(params, "delete")}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
  });
}

// Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

const styles = {
  // Define the rounded corners style for the box

  roundedBox: {
    width: "60%", // Make the box 50% wide
    borderRadius: "10px", // Adjust the value to control the roundness
    padding: "10px", // Add some padding for spacing
    backgroundColor: "white", // Set the background color to white
  },
  roundedInput: {
    borderRadius: "10px", // Adjust the value to control the roundness
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}; 

const handleSearchInputChange = (event) => {
  const inputValue = event.target.value;
  setSearchInput(inputValue);
};


const widgetStyle = {
  padding: '1rem',
  borderRadius: '15px', // Adjust the value for rounded corners
};

  return (
    <Box m="10px">

    <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={4}>
    
    <Box display="flex" justifyContent="space-between" alignItems="center">
       
        <Header title={<FormattedMessage id="Actividad" />} subtitle={<FormattedMessage id="Log de Usuarios" />} />

        </Box>
     </Grid>


<Grid item xs={12} sm={8} md={1}>


</Grid>

<Grid item xs={12} sm={6} md={4}>
     {/* Slider */}
   
   </Grid>
  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "130%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>


 <p></p>

<Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gap="20px">
{/* StatBox - 3 columns */}
<Box gridColumn={{ xs: 'span 3', md: 'span 3' }} gridRow="span 1">
<div style={{ marginRight: '25px' }}>
 <StatBoxnom
   title={filteredData.length.toString()}
   subtitle= {<FormattedMessage id="Sesiones" />}

   icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
 />
</div>
</Box>
</Box>
 
      <Box
        m="40px 0 0 0"
        height="50vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[400],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {

            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

        
<DataGrid
         rowHeight={40}
         localeText={esES.components.MuiDataGrid.defaultProps.localeText}
         initialState={{
          columns: {
            columnVisibilityModel: {
              userId: false,
           
              'user.rut': false, // Corrected key
              'user.firstName': false, // Corrected key
              'user.lastName': false, // Corrected key
              'user._id': false, // Corrected key
              'user.userprofile': false, // Corrected key
              'user.level': false, // Corrected key

            },
          },
        }}
         columns={columns}
         rows={filteredData.map((row) => ({
           ...row,
           id: row._id, 
         }))}
         headerClassName="bold-header"
         components={{ Toolbar: GridToolbar }}
       
         rowsPerPageOptions={[10, 25, 50]} // Include 25 as an option
 
         onRowClick={handleRowClick}
              />
              
              <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message="Copiado al portapapeles"
        />

      </Box>
<p>

</p>
<Box>
                <Button
                    onClick={handleDownloadXLSX}
                    sx={{ borderRadius: "20px",
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      overflowX: 'auto',// Make the container horizontally scrollable

                    }}
                  >
                    <p></p>
                    <DownloadOutlinedIcon sx={{ borderRadius: "20px", mr: "10px" }} />
                    {<FormattedMessage id="Descargar Reporte"/>}

              </Button>
              </Box>
              <p></p>

      {/*barras*/}
  {/* Bar Chart */}
  <Box gridColumn={{ xs: 'span 12', md: 'span 6' }} gridRow="span 2" backgroundColor={colors.primary[400]}>
          <Typography variant="h5" fontWeight="600" sx={{ borderRadius: "20px", padding: "30px 30px 0 30px" }}>
          {<FormattedMessage id="Por Fecha" />}

           
                      </Typography>
      <Box height="240px" mt="40px">
        <BarChart data={chartData} />
      </Box>
    

        
        </Box>




      <Box
              gridColumn={{ xs: 'span 12', md: 'span 4' }}
              sx={{ overflowX: 'auto' , paddingY: '40px' }} // Make the container horizontally scrollable

              gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ borderRadius: "20px", padding: "30px 30px 0 30px", overflowX: 'auto', paddingY:  '20px'}} // Make the container horizontally scrollable

                      
                    >
                              {<FormattedMessage id="Usuarios por Area" />}

                    </Typography>

                    <Box height="250px" mt="20px">

                    <BarChart data={datax} />

                    </Box>

        </Box>
        <p></p>
        {userProfile === 'superadmin' && (
        <>
          <Box
            gridColumn={{ xs: 'span 12', md: 'span 4' }}
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            sx={{ overflowX: 'auto', paddingY: '20px' }}
          >
            {/* Usuarios por Contrato chart content */}
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ borderRadius: "20px", padding: "30px 30px 0 30px", overflowX: 'auto', paddingY: '20px' }}
            >       
               {<FormattedMessage id="Usuarios por Contrato" />}

            </Typography>

            <Box height="250px" mt="20px">
              <BarChart data={dataxcontrato} />
            </Box>
          </Box>
          <p></p>
          </>
      )}

        <Box
              gridColumn={{ xs: 'span 12', md: 'span 4' }}
              gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    sx={{ overflowX: 'auto' , paddingY: '20px' }} // Make the container horizontally scrollable

                  >

                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ borderRadius: "20px", padding: "30px 30px 0 30px" }}
                    >
                                     {<FormattedMessage id="Usuarios por Perfil" />}

                      
                    </Typography>


                    <Box height="250px" mt="-20px">

                    <BarChart data={dataxperfil} />

                    </Box>


        </Box>
<p></p>
   <Box
                    gridColumn={{ xs: 'span 12', md: 'span 4' }}
                    gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          sx={{
                            width: '100%',
                            overflowX: 'auto',}} // Make the container horizontally scrollable

                        >
                          <Box
                            mt="25px"
                            p="0 30px"
                            display="flex "
                            justifyContent="space-between"
                            alignItems="center"
                          >
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      fontWeight="600"
                                      color={colors.grey[100]}
                                    >
                                                                       {<FormattedMessage id="Usuarios por Rut" />}

                                   </Typography>
                                    <Typography
                                      variant="h3"
                                      fontWeight="bold"
                                      color={colors.greenAccent[500]}
                                    >
                                    </Typography>
                                  </Box>
                            <Box>
                              
                            </Box>
                          
                          
                          </Box>
                              <Box height="250px" m="-20px 0 0 0">
                              <BarChart data={dataxconflicto} />

                            
                  </Box></Box>

<p></p>

    </Box>
    
  );
};

export default Allsessions;