import React, { useEffect, useState } from 'react';
import { Box, Button ,CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper'; // Import the Paper component
import { FormattedMessage } from 'react-intl';

import FormDetailWidget from '../../components/FormDetailWidget';
import FormevWidget from '../../components/FormevWidget';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import DropfileFormWidget from '../../components/DropFilesFormsWidget';

const FormevPage = () => {
  const [form, setForm] = useState(null);
  const [user, setUser] = useState([]); // Initialize user as an empty array

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstUser, setFirstUser] = useState(null); // Define firstUser with an initial value of null

  const dispatch = useDispatch();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const { _id } = useParams();
  const loggedInUserId = useSelector((state) => state.user._id);
  const email = useSelector((state) => state.user.email);
  const location = useLocation();
  const { formData, formTotalDays } = location.state;

  const { REACT_APP_API_ENDPOINT } = process.env;

  

  const getForm = async () => {
    try {
      const response1 = await fetch(`${REACT_APP_API_ENDPOINT}users/email/${email}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response1.ok) {
        throw new Error('Failed to fetch user data');
      }
      const usersArray = await response1.json();
      setUser(usersArray);

      // Assuming you want to check the profile of the first user in the array
      const firstUserFromResponse = usersArray.length > 0 ? usersArray[0] : null;
      setFirstUser(firstUserFromResponse); // Update firstUser state with the first user in the array



      const response = await fetch(`${REACT_APP_API_ENDPOINT}formepd/${_id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch form data');
      }

      const data = await response.json();
      setForm(data);
      setLoading(false);

    
    } catch (error) {
      setError('An error occurred while fetching form data.');
      setLoading(false);
    }
  };

  useEffect(() => {
    getForm();
  }, [token]);

  

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!form || Object.keys(form).length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        
        <Typography>No form data found.</Typography>
      </Box>
    );
  }

  const { userprofile, estado } = form;

    const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };



  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  return (
    <Box display="flex" flexDirection="column">
      <Paper elevation={3} style={widgetStyle}>
        <FormDetailWidget _id={_id} formTotalDays={formTotalDays} />
      </Paper>
    
      <Box mt={2} />
    
      <Box>
        <Paper elevation={3} style={widgetStyle}>
          {(firstUser?.userprofile === "admin" || firstUser?.userprofile === "aprobador" || firstUser?.userprofile === "superadmin") && estado === "Por Evaluar" && <FormevWidget _id={_id} />}
        </Paper>
      </Box>
      <Paper elevation={3} style={{ ...widgetStyle, marginTop: '1rem', marginBottom: '1rem' }}>
          <DropfileFormWidget _id={_id} />
        </Paper>
    </Box>
    
  );
  
 
  
};

export default FormevPage;
