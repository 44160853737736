import React, { useState } from "react";
import { Divider } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { Dialog, CircularProgress } from "@mui/material";
import { Checkbox } from "@mui/material";
import { FormattedMessage } from 'react-intl';

import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";

const customModalStyles = {
  content: {
    width: '400px',
    height: '280px',
    margin: 'auto',
    background: '#ffffff', // Set the background color to white
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 1, 0.5)', // Set the background color of the overlay to semi-transparent black
  },

};
const { REACT_APP_API_ENDPOINT } = process.env;

const createformSchema = yup.object().shape({
  rut: yup.string().required("Requerido"),
  telefono: yup.string().notRequired("notRequired"),
  firstName: yup.string().notRequired("notRequired"),
  lastName: yup.string().notRequired("notRequired"),
  email: yup.string().email("invalid email").required("required"),
  location: yup.string().notRequired("notRequired"),
  occupation: yup.string().notRequired("notRequired"), // Updated validation for occupation field
  area: yup.string().notRequired("notRequired"),

  

  opImplementado: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtImplementado: yup.string().notRequired("notRequired"),
  opCertificado: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtCertificado: yup.string().notRequired("notRequired"),
  opOficial: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtOficial: yup.string().notRequired("notRequired"),
  opCapacitaciones: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtCapacitaciones: yup.string().notRequired("notRequired"),
  opCapacitacionesproveedores: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtCapacitacionesproveedores: yup.string().notRequired("notRequired"),
  opCodigoetica: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtCodigoetica: yup.string().notRequired("notRequired"),
  txtManualcumplimiento: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtManualcumplimiento: yup.string().notRequired("notRequired"),
  opCanaldenuncia: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtCanaldenuncia: yup.string().notRequired("notRequired"),
  opRepholding: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtRepholding: yup.string().notRequired("notRequired"),
  opChequeo: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtChequeo: yup.string().notRequired("notRequired"),
  opPep: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtPep: yup.string().notRequired("notRequired"),
  opInvestigacionI: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtInvestigacionI: yup.string().notRequired("notRequired"),
  opInvestigacionII: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtInvestigacionII: yup.string().notRequired("notRequired"),
  opTransmit: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtTransmit: yup.string().notRequired("notRequired"),


  campaignid: yup.string().notRequired("notRequired"),
  idcontrato: yup.string().notRequired("notRequired"),
  
  emailaviso: yup.string().notRequired("notRequired"),
  idevaluacion: yup.string().notRequired("notRequired"),
  confirmRead: yup.boolean().oneOf([true], "Debes confirmar que has leído la información."),
  

});

const initialValuescreateform = {
 //gral
 userId:  "",
 estado:  "",
 idcontrato:  "",

   //dd ext contacto
   rut:  "",
   telefono:  "",
   firstName:  "",
   lastName:  "",
   email:  "",
   location:  "",
   occupation:  "",
   area:  "",

 

//cdd
opImplementado:  "",
txtImplementado:  "",
opCertificado:  "",
txtCertificado:  "",
opOficial:  "",
txtOficial:  "",
opCapacitaciones:  "",
txtCapacitaciones:  "",
opCapacitacionesproveedores:  "",
txtCapacitacionesproveedores:  "",
opCodigoetica:  "",
txtCodigoetica:  "",
opManualcumplimiento:  "",
txtManualcumplimiento:  "",
opCanaldenuncia:  "",
txtCanaldenuncia:  "",
opRepholding:  "",
txtRepholding:  "",
opChequeo:  "",
txtChequeo:  "",
opPep:  "",
txtPep:  "",
opInvestigacionI:  "",
txtInvestigacionI:  "",
opInvestigacionII:  "",
txtInvestigacionII:  "",
opTransmit:  "",
txtTransmit:  "",


confirmRead: false,

};



const Form = () => {
  const [isFormepdSavedModalOpen, setIsFormepdSavedModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Step 1: Initialize the loading state
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const [pageType, setPageType] = useState("createform");
  const iscreateform = pageType === "createform";
  const [isConflictxtClicked, setIsConflictxtClicked] = useState(false);

  const location = useLocation();
  const pathnameParts = location.pathname.split("/");

  const [campaignid, idcontrato, idcompany, email] = location.pathname
    .split("/")
    .pop()
    .split(",")
    .map((value) => decodeURIComponent(value.replace("%40", "@")));

  console.log("url", campaignid, idcontrato, idcompany, email );
  let errorMessageElement; // Define the errorMessageElement variable
  let ddId = campaignid;

  const createform = async (values, onSubmitProps) => {
    try {
      setLoading(true); // Step 3: Set loading state to true before form submission

       // Make a request to obtain a temporary token
    const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ }),
    });

    if (!tokenResponse.ok) {
      throw new Error('Failed to generate temporary token');
    }

    const { token } = await tokenResponse.json();


    const savedUserResponse = await fetch(`${REACT_APP_API_ENDPOINT}dd/${campaignid}`, {
      method: "PATCH",
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...values, estado: "En Evaluacion" }),
    });

      if (savedUserResponse.status === 200) {
        // Form successfully created
       // onSubmitProps.resetForm();
        navigate("/successPage", {
          state: { message: "Cuestionario enviado con éxito a Eticpro" }
      });           









      } else if (savedUserResponse.status === 409) {
        // Email conflict, display an error message to the user
        toast.error("Formulario ya habia sido guardado antes");
        setIsFormepdSavedModalOpen(true);
        onSubmitProps.resetForm();

        throw new Error("Email ya existia para esta campaña");
      } else {
        // Other error occurred, handle as desired
        throw new Error("Ocurrió un error");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false); // Make sure to reset the loading state when done
    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    if (!checkboxChecked) {
      // Display the popup message here
      toast.error("Haga click en el checkbox antes de enviar");
      return;
    }
  
    if (iscreateform) {
      await createform(values, onSubmitProps);
    }
  };


  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  return (
    <div style={{ display: "grid", placeItems: "center", height: "300", backgroundColor: "white" }}>

<Box p={3} border="1px solid" borderRadius={8} style={{ backgroundColor: "white" }}>

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={{
      ...initialValuescreateform,
      campaignid: campaignid, // Set campaignid to the value extracted from the URL
      idcontrato: idcontrato,
      idcompany: idcompany, 
      email: email.replace("%40", "@"),
    }}   

  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
      <form onSubmit={handleSubmit}>
      {/* Loading Popup */}
      <Dialog open={loading} onClose={() => {}}>
        <Box sx={{ borderRadius: "20px", p: 2, textAlign: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="body1" mt={2}>
            Procesando datos...
          </Typography>
        </Box>
      </Dialog>

<div>

<Modal
        isOpen={isFormepdSavedModalOpen}
        onRequestClose={() => setIsFormepdSavedModalOpen(false)}
        contentLabel="Formepd Saved Modal"
        style={customModalStyles} // Apply custom styles to the modal
      >
        <h2>Lo sentimos, su declaración ya fue enviada anteriormente.</h2>
        Si tiene alguna consulta, envienos un mail a hello@eticpro.com indicando su nombre y rut para revisar su caso. Gracias
        <button
          onClick={() => setIsFormepdSavedModalOpen(false)}
          style={{ fontSize: '18px', padding: '10px 20px' }} // Add custom styles to the button
        >
          Cerrar
        </button>  
         </Modal>
      <Typography variant="h4">ETICPRO-  Formulario de Declaración Diligencia Debida(Contraparte)</Typography>
     
    </div>

   
        <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
          {iscreateform && (
            <>


<TextField
  label="Su Rut Personal (Sin Guión)"
  onBlur={handleBlur}
  onChange={(event) => {
    // Use a regular expression to remove any non-numeric characters
    const cleanedValue = event.target.value.replace(/[^0-9K]/gi, "");
    // Update the field value with the cleaned value
    handleChange({ target: { name: "rut", value: cleanedValue } });
  }}
  value={values.rut}
  name="rut"
  error={Boolean(touched.rut) && Boolean(errors.rut)}
  helperText={touched.rut && errors.rut}
  multiline
  fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4" }}
  inputProps={{
    inputMode: "numeric", // Show numeric keyboard on mobile devices
  }}
/>


              <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Sus Nombres"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              name="firstName"
              error={!!touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />

    
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Sus Apellidos"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              name="lastName"
              error={!!touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />
              <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Su Empleador u Organización"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.location}
              name="location"
              error={!!touched.location && !!errors.location}
              helperText={touched.location && errors.location}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Su Area"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.area}
              name="area"
              error={!!touched.location && !!errors.location}
              helperText={touched.location && errors.location}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />
              <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Su Cargo"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.occupation}
              name="occupation"
              error={!!touched.occupation && !!errors.occupation}
              helperText={touched.occupation && errors.occupation}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Su Telefono Celular  ej.+56xxxxx"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.telefono}
              name="telefono"
              error={!!touched.telefono && !!errors.telefono}
              helperText={touched.telefono && errors.telefono}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />
 
             

              
<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
<Typography variant="h6" gutterBottom>
<Box
    border="2px solid"
    p="1rem"
    mt="2rem"
    mb="2rem"
    bgcolor="white"
  >
    <Typography>
    Nuestra organización esta comprometida con la integridad y el cumplimiento de la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas, UK Bribery Act del Reino Unido y Foreign Corrupt Practices Act (FCPA) de EE. UU. Para el logro total de dicho compromiso, la organización ha adoptado una política de revisión previa a todos los postulantes con las que se relaciona. El programa de revisión de postulantes requiere que estos provean la información necesaria para que la organización pueda revisar y evaluar los antecedentes necesarios con el fin de cumplir con la ley. Esta revisión consiste en un cuestionario de diligencia debida que deberá ser contestado por el postulante. 
      </Typography>
 


  </Box>

</Typography>

{<FormattedMessage id="1. En relación con la Ley N°20.393, UK Bribery Act o FCPA ¿En su organización existe implementado un Modelo de Prevención de Delitos u otro sistema como programa de Compliance sobre prevención de delitos como lavado de activos, anticorrupción etc., para dar cumplimiento a cuerpos normativos nacionales o extranjeros?"/>}

<RadioGroup
  aria-label="Opción"
  name="opImplementado"
  value={values.opImplementado}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtImplementado}
                name="txtImplementado"
                error={Boolean(touched.txtImplementado) && Boolean(errors.txtImplementado)}
                helperText={touched.txtImplementado && errors.txtImplementado} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>


   {<FormattedMessage id="2. En el caso de contar su organización con un Modelo de Prevención de Delitos, ¿Se encuentra este certificado? (Favor suministrar detalles)"/>}
<RadioGroup
  aria-label="Opción"
  name="opCertificado"
  value={values.opCertificado}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtCertificado}
                name="txtCertificado"
                error={Boolean(touched.txtCertificado) && Boolean(errors.txtCertificado)}
                helperText={touched.txtCertificado && errors.txtCertificado} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>


   {<FormattedMessage id="3. ¿En su organización existe un Oficial de Cumplimiento o un Encargado de Prevención de Delitos que gestione o vele por el correcto funcionamiento del Modelo de Prevención de Delitos"/>}
<RadioGroup
  aria-label="Opción"
  name="opOficial"
  value={values.opOficial}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtOficial}
                name="txtOficial"
                error={Boolean(touched.txtOficial) && Boolean(errors.txtOficial)}
                helperText={touched.txtOficial && errors.txtOficial} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>


   {<FormattedMessage id="4. ¿En su organización realizan capacitaciones con respecto a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares? Entregar detalles, en términos de la frecuencia de las capacitaciones y los niveles y áreas de su organización a las que se les hace esta capacitación."/>}
<RadioGroup
  aria-label="Opción"
  name="opCapacitaciones"
  value={values.opCapacitaciones}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtCapacitaciones}
                name="txtCapacitaciones"
                error={Boolean(touched.txtCapacitaciones) && Boolean(errors.txtCapacitaciones)}
                helperText={touched.txtCapacitaciones && errors.txtCapacitaciones} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>


   {<FormattedMessage id="5. ¿Existen capacitaciones relacionados a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares para los contratistas o proveedores que les prestan servicios / proveen un bien a su organización?"/>}

<RadioGroup
  aria-label="Opción"
  name="opCapacitacionesproveedores"
  value={values.opCapacitacionesproveedores}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtCapacitacionesproveedores}
                name="txtCapacitacionesproveedores"
                error={Boolean(touched.txtCapacitacionesproveedores) && Boolean(errors.txtCapacitacionesproveedores)}
                helperText={touched.txtCapacitacionesproveedores && errors.txtCapacitacionesproveedores} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>



   {<FormattedMessage id="6. ¿Existe un Código de Ética y/o Manual de Cumplimiento en su compañía? Adjunte documentación"/>}
<RadioGroup
  aria-label="Opción"
  name="opCodigoetica"
  value={values.opCodigoetica}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtCodigoetica}
                name="txtCodigoetica"
                error={Boolean(touched.txtCodigoetica) && Boolean(errors.txtCodigoetica)}
                helperText={touched.txtCodigoetica && errors.txtCodigoetica} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>



   {<FormattedMessage id="7. ¿Su organización tiene un canal de denuncias?"/>}
<RadioGroup
  aria-label="Opción"
  name="opManualcumplimiento"
  value={values.opManualcumplimiento}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label="Comentarios"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtManualcumplimiento}
                name="txtManualcumplimiento"
                error={Boolean(touched.txtManualcumplimiento) && Boolean(errors.txtManualcumplimiento)}
                helperText={touched.txtManualcumplimiento && errors.txtManualcumplimiento} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>








   {<FormattedMessage id="8. ¿Usted o su organización representará a su organización ante terceros o ante una autoridad pública?"/>}
<RadioGroup
  aria-label="Opción"
  name="opRepholding"
  value={values.opRepholding}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtRepholding}
                name="txtRepholding"
                error={Boolean(touched.txtRepholding) && Boolean(errors.txtRepholding)}
                helperText={touched.txtRepholding && errors.txtRepholding} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>



   {<FormattedMessage id="9. Para establecer relaciones contractuales con terceros, ¿Existe un proceso de chequeo de antecedentes legales en relación a la ley N°20.393, UK Bribery Act, FCPA o si está la persona jurídica o sus controladores en listas de sancionados internacionales? Entregar detalles"/>}
<RadioGroup
  aria-label="Opción"
  name="opChequeo"
  value={values.opChequeo}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtChequeo}
                name="txtChequeo"
                error={Boolean(touched.txtChequeo) && Boolean(errors.txtChequeo)}
                helperText={touched.txtChequeo && errors.txtChequeo} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>



   {<FormattedMessage id="10. ¿Algún Gerente, ejecutivo de la alta administración,  superior jerárquico o Director de su organización es en la actualidad también Persona Expuesta Políticamente  o un funcionario público?, ¿Se ha presentado esta situación en el pasado (hasta 1 año atrás)? Entregar detalles"/>}
<RadioGroup
  aria-label="Opción"
  name="opPep"
  value={values.opPep}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtPep}
                name="txtPep"
                error={Boolean(touched.txtPep) && Boolean(errors.txtPep)}
                helperText={touched.txtPep && errors.txtPep} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>



   {<FormattedMessage id="11. ¿Su organización, o alguna sociedad relacionada (la matriz de su organización, o una filial) se ha(n) visto sujeta(s) a alguna investigación iniciada en base a una acusación por comisión de actos ilícitos relacionados a la responsabilidad penal de las personas jurídicas (local y/o internacional) dentro de los últimos 5 años o ha estado en listas de sancionados internacionales?"/>}
<RadioGroup
  aria-label="Opción"
  name="opInvestigacionI"
  value={values.opInvestigacionI}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtInvestigacionI}
                name="txtInvestigacionI"
                error={Boolean(touched.txtInvestigacionI) && Boolean(errors.txtInvestigacionI)}
                helperText={touched.txtInvestigacionI && errors.txtInvestigacionI} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>



   {<FormattedMessage id="12. ¿Se encuentra su organización o algún trabajador, gerente,  superior jerárquico,  ejecutivo de la alta administración o director, actualmente o en los últimos 5 años sujeto a una investigación por cualquier acto ilícito relacionado con los delitos de lavado de activos, financiamiento del terrorismo, cohecho de funcionario público nacional o extranjero, receptación, corrupción entre particulares, administración desleal, apropiación indebida, negociación incompatible, contaminación de aguas, delitos relacionados con la veda de productos, pesca ilegal de recursos del fondo marino, y procesamiento y almacenamiento de productos escasos (colapsados o sobreexplotados) sin acreditar un origen legal, la ley N°21.240 de Inobservancia de Aislamiento y Otras Medidas Sanitarias e infracción a la ley N° 21.227 que faculta el acceso a prestaciones del Seguro de Cesantía en el contexto de la crisis sanitaria producida por la enfermedad Covid-19, llamada también ley de Protección al Empleo?"/>}
<RadioGroup
  aria-label="Opción"
  name="opInvestigacionII"
  value={values.opInvestigacionII}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtInvestigacionII}
                name="txtInvestigacionII"
                error={Boolean(touched.txtInvestigacionII) && Boolean(errors.txtInvestigacionII)}
                helperText={touched.txtInvestigacionII && errors.txtInvestigacionII} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>


   {<FormattedMessage id="13. ¿En su organización los administradores, directores y/o accionistas han transmitido de manera clara a los empleados y a terceros con los que tengan relaciones contractuales el compromiso de su organización con el desarrollo legal y ético del negocio, de cero tolerancia ante la corrupción y ha transmitido el contenido de los programas y políticas de cumplimiento que tenga la organización?"/>}
   <RadioGroup
  aria-label="Opción"
  name="opTransmit"
  value={values.opTransmit}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtTransmit}
                name="txtTransmit"
                error={Boolean(touched.txtTransmit) && Boolean(errors.txtTransmit)}
                helperText={touched.txtTransmit && errors.txtTransmit} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>



<FormControlLabel
  control={
    <Checkbox
      checked={values.confirmRead}
      onChange={(e) => {
        setFieldValue("confirmRead", e.target.checked);
        setCheckboxChecked(e.target.checked);
      }}
      name="confirmRead"
      color="primary"
    />
  }
  label={<FormattedMessage id="He leído y comprendido la información anterior."/>}
  sx={{ borderRadius: "20px", gridColumn: "span 5" }}
/>


</FormControl>
            </>
          )}

        </Box>
      
        {/* BUTTONS */}
        <Box>
        {values.confirmRead && (
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
              {<FormattedMessage id="Confirmo que la información anterior es verdadera y completa."/>} {<FormattedMessage id="Enviar"/>}
              </Button>
            </Box>
          )}


          <Typography
            onClick={() => {
    
              resetForm();
            }}
            sx={{ borderRadius: "20px",
              textDecoration: "underline",
              color: palette.primary.light,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
          </Typography>
        </Box>
        
       
      </form>
    )}
  </Formik>
   </Box>
   </div>
  );
};





export default Form; 