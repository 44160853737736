import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { es } from 'date-fns/locale';
import { FormattedMessage } from 'react-intl';
import LogoImage from '../assets/eticprologo8.png';

const editformSchema = yup.object().shape({
  opauth: yup.string().notRequired(),
  authtxt: yup.string().notRequired("notRequired"),
  emailaviso: yup.string().notRequired("notRequired"),

});

const initialValuesEditForm = {

  opauth: "",
  authtxt: "",
  dateeval: "",
  estado: "",
  selectedInvestigador: "",
  emailauth: "",
  emailaviso: "",

}

const Form = ({ _id }) => {
  const [form, setForm] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [searchInput, setSearchInput] = useState('');
  const [fieldValue, setFieldValue] = useState('');

  const [filteredClients, setFilteredClients] = useState([]);
  
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [selectedClient, setSelectedClient] = useState(''); // Add this state

  const [pageType, setPageType] = useState("editform");
  const iseditform = pageType === "editform";
  const token = useSelector((state) => state.token);
  const [formData, setFormData] = useState(initialValuesEditForm);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [users, setUsers] = useState([]); // State to hold fetched users
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedInUseremail = useSelector((state) => state.user.email);
  const [profilePicture, setProfilePicture] = useState(null);
  const [clients, setClients] = useState(null);
  const loggedclient = useSelector((state) => state.client);
  const [clientSelected, setClientSelected] = useState(false);


  const fetchUsers = async () => {
    try {
      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (userResponse.ok) {
        const usersData = await userResponse.json();
        setUsers(usersData);
        setClients(usersData);

      } else {
        console.log("Failed to get users");
      }

     


      } catch (error) {
        console.log("Error:", error);
      }
    };

  useEffect(() => {

    const getForm = async () => {
      try {
        
        const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}`, {

        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const formData = await response.json();
          setForm(formData); // Set the form data obtained from the server
        } else {
          console.log("Failed to get form");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

   fetchUsers(); // Fetch the list of users
    getForm(); // Call the function to fetch the form data

  }, [_id, token]);
 

  const handleFormSubmit = async (values, onSubmitProps) => {

    try {
       
    
      // Generate a random 16-character string for idevaluation
      const generateId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let id = '';
        for (let i = 0; i < 16; i++) {
          id += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return id;
      };


      // Patch the form data with new values and estado
      const updatedFormData = {
        opauth: values.opauth,
        emailaviso: values.emailaviso.split(",").map((email) => email.trim()).join(', '), // Convert to a string

        authtxt: values.authtxt,
        estado: formData.estado,
        idevaluacion: generateId(), // Add the idevaluation field with a random 16-character value
        dateval: currentDate,  // Set the updatedAt value to the current date
        selectedInvestigador: fieldValue,
        emailauth: loggedInUseremail,
      };
      
       const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}`, {

        method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedFormData),
    });

    if (response.ok) {
// patch perfil del investigador a Investigador(similar a admin) asi puede ver la denuncia
const updatedFormDataInv = {
  
  userprofile: "investigador",
 
};
 const responseInv = await fetch(`${REACT_APP_API_ENDPOINT}users/email/${fieldValue}`, {

  method: "PATCH",
headers: {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
},
body: JSON.stringify(updatedFormDataInv),
});      
if (responseInv.ok) {

// Send email after the patch is successful



    
      const emailTemplate = `
      <!DOCTYPE html>
      <html>
      <head>
        <style>
          .frame {
            border: 2px solid #ccc;
            padding: 20px;
            border-radius: 10px;
            width: 80%;
            margin: 0 auto;
            text-align: left;
            position: relative;
          }
          .logo-container {
            position: absolute;
            top: 10px;
            right: 10px;
          }
          .logo-box {
            text-align: center;
            margin-bottom: 20px;
          }
          .box {
            background-color: #f2f2f2;
            padding: 10px;
            border-radius: 5px;
          }
        </style>
      </head>
      <body>
        <div class="frame">
          <div class="logo-container">
            <img src=${LogoImage} alt="Logo" style="width: 100%;" />
          </div>
          <img src=${loggedclient.logo} alt="Logoemp" style="width: 15%;" /> <!-- Updated width to 10% -->
          
          <p>Estimado(a) Usuario,</p>
          <p>Le informamos que se ha evaluado de parte del equipo de Compliance la denuncia en la organización ${formData.location}</p>
          <p>El mail del investigador seleccionado acargo de la investigación es: ${fieldValue} </p>

          <p>Id Denuncia: ${_id}</p>
          <p>Tipo de Denuncia: ${formData.tipoconflicto}</p>
          <p>Resultado Evaluación: ${formData.estado}</p>
          <p>Id Evaluación: ${updatedFormData.idevaluacion}</p>
          <p>Para soporte escribanos a <a href="mailto:hello@eticpro.com">hello@eticpro.com</a> o por Whatsapp al +56 977 657 352.</p>
          <p>Gracias por su atención y cooperación.</p>
          <p>Equipo Encargado de Prevención de Delitos</p>
          <p>Eticpro.</p>
          <p><a href="www.eticpro.com">www.eticpro.com</a></p>
        </div>
      </body>
      </html>
    `;

      const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: [updatedFormData.emailauth, updatedFormData.selectedInvestigador, values.emailaviso, "ggardella@compliancegc.com", "sgardella@compliancegc.com"], 
          

          emailTemplate,
          subject : "Resultado Evaluacion de Denuncia",
        }),
      });

      if (emailResponse.ok) {
        // Email sent successfully
        console.log("Email sent successfully");
      } else {
        // Error sending email
        console.log("Failed to send email");
      }
    } else {
      // Handle unsuccessful patch request
      console.log("Failed to update form data");
    }

    onSubmitProps.resetForm();
    navigate("/complaints");
  }} catch (error) {
    console.log(error);
    // Handle error
  }
};



useEffect(() => {
  if (form) {
    setFormData(form);
  }
}, [form]);



const handleSearchInputChange = (event) => {
  const inputValue = event.target.value;
  setSearchInput(inputValue);
  if (inputValue.length >= 3) {
    const filtered = clients.filter(client =>
      client.email.includes(inputValue)
    );
    setFilteredClients(filtered);
  } else {
    setFilteredClients([]);
   
  }
};


const handleClientSelect = async (client) => {
  try {
    setSearchInput(client.email); // Update searchInput with the selected client's email
    setFilteredClients([]);
    
    setFieldValue(client.email); 

    setClientSelected(true); // Mark client as selected
  } catch (error) {
    console.error('An error occurred:', error);
    alert('An unexpected error occurred. Please try again later.'); // Fallback error message
  }
};


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={iseditform ? initialValuesEditForm : initialValuesEditForm}
      validationSchema={editformSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {iseditform && (
              <>
                <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
                  <Typography variant="h6" gutterBottom>
                    
                    {<FormattedMessage id="Investigar"/>}
                  </Typography>
                  <RadioGroup
                    aria-label="Opción"
                    name="opauth"
                    value={values.opauth}
                    onChange={handleChange}
                    sx={{ borderRadius: "20px",
                      "& .MuiSvgIcon-root": {
                        color: palette.primary.light, // Selected radio color (red)
                      },
                      "& .Mui-checked": {
                        color: "#FFFFFF", // Not selected radio color (white)
                      },
                    }}
                  >
     <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />

                  </RadioGroup>
                </FormControl>

                <TextField
                  label={<FormattedMessage id="Detalle las razones de Investigar o desistir de la investigación"/>}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.authtxt}
                  name="authtxt"
                  error={Boolean(touched.authtxt) && Boolean(errors.authtxt)}
                  helperText={touched.authtxt && errors.authtxt}
                  multiline
                  fullWidth
                  minRows={12}
                  maxRows={20}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />
   

   <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
    background: 'white', // Set the background color to white
  },
}}>
  <Box>
    <Typography variant="body2" color="textSecondary">
      {searchInput && searchInput.length < 3 && "Por favor introduzca al menos 3 caracteres mail investigador."}
    </Typography>
    <TextField
      label={<FormattedMessage id="Mail investigador" />}
      onChange={handleSearchInputChange}
      value={searchInput}
      fullWidth
      sx={{
        marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
          background: 'white',
        },
      }}
    />
    {Array.isArray(filteredClients) && filteredClients.length > 0 && (
      <Paper>
        <List>
          {filteredClients.map((client) => (
            <ListItem key={client._id} button onClick={() => handleClientSelect(client)}>
              <ListItemText primary={client.email} />
            </ListItem>
          ))}
        </List>
      </Paper>
    )}
    <Box display="flex" justifyContent="center"></Box>
  </Box>

  {touched.email && errors.email && (
    <Typography variant="body2" color="error">
        {errors.email}
    </Typography>
  )}
</FormControl>





              </>
            )}
          </Box>
          <TextField
                  label="Mail(s) a quien desea informar de la denuncia separados por coma (Gerente General, RRHH, Superior Jerárquico)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.emailaviso}
                  name="emailaviso"
                  error={Boolean(touched.emailaviso) && Boolean(errors.emailaviso)}
                  helperText={touched.emailaviso && errors.emailaviso}
                  multiline
                  fullWidth
                  minRows={1}
                  maxRows={3}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />
          {/* BUTTONS */}
          <Box>
         
                    <Button
            fullWidth
            type="submit"
            color="secondary"
            variant="contained"
            disabled={!values.opauth} // Disable the button if opauth is not selected
            onClick={() => {
              const newEstado = values.opauth === "Si" ? "En Investigacion" : "No investigar";

              setFormData((prevFormData) => ({
                ...prevFormData,
                estado: newEstado,
              }));

              setCurrentDate(format(new Date(), "yyyy-MM-dd")); // Set the current date
            }}
            sx={{
              borderRadius: "20px",
              m: "2rem 0",
              p: "1rem",
              backgroundColor: palette.primary.red,
              color: palette.background.light,
              "&:hover": { color: palette.primary.light },
            }}
          >
            {<FormattedMessage id="Enviar"/>}
          </Button>



           
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;