import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "./Header";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "./WidgetWrapper";
import FlexBetween from "./FlexBetween";
import { setNotifications } from "../state";
import { differenceInDays } from "date-fns";
import { FormattedMessage } from 'react-intl';

const Forms = ({ _id }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedidcontrato = useSelector((state) => state.user.idcontrato);
  const userProfile = useSelector((state) => state.user.userprofile);

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [notifications, setNotifications] = useState(0);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [formTotalDays, setTotalDays] = useState([]);

  useEffect(() => {
    fetchData();

  }, []);

  const fetchData = async () => {

    try {
      let endpoint = `${REACT_APP_API_ENDPOINT}formepd/user/${loggedInUserId}`;
      // Check the user profile and update the endpoint accordingly
      if (userProfile === "superadmin") {
        endpoint = `${REACT_APP_API_ENDPOINT}formepd/`;
      }

      if (userProfile === "admin"|| userProfile === "coadmin" || userProfile === "aprobador"   ) {
        endpoint = `${REACT_APP_API_ENDPOINT}formepd/contract/${loggedidcontrato}`;
      }
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();
       // Add unique id and format createdAt
       const dataWithFormattedDate = formData.map((row) => ({
        ...row,
        id: row._id,
        createdAt1: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-EN", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
          createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("es-ES", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
      }));

      setData(formData);

      // Filter data by estado "pendiente"
      const filteredData = dataWithFormattedDate.filter((row) => row.estado === "Por Evaluar");
      setFilteredData(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const handleRowClick = (params, event) => {
    // Check if the click event occurred on the delete button
    const isDeleteButton = event.target.tagName.toLowerCase() === 'button';

    // Navigate to the formev page only if the click event didn't occur on the delete button
    if (!isDeleteButton) {
      const rowId = params.row._id; // Assuming _id is the unique identifier for the row
      navigate(`/formev/${rowId}`, {
        state: {
          formData: params.row, // Pass the row data
          formTotalDays: params.row.formTotalDays // Pass the formTotalDays value
      
        }
      });   
    }
  };


  const columns = [
    {
      field: "fullName",
      headerName: "Nombre",
      width: 200,
      valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`
    ,headerClassName: "bold-header",
  },


    {
      field: "tardanza",headerClassName: "bold-header",
      headerName: "Demora",
      width: 100,      valueGetter: (params) => {
        const createdAt = new Date(params.row.createdAt);
        const createdAt1 = new Date(params.row.createdAt1);

        const currentDate = new Date();
        const daysDifference = differenceInDays(currentDate, createdAt1);
        return daysDifference;
      },
    },
  ];


  return (
    <Box m="05px">
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}

          rows={filteredData}
          columns={columns}
          onRowClick={handleRowClick}
          autoHeight
        />
      </Box>
    </Box>
  );
};

export default Forms;
