import React, { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

import {
  Modal,
  Box,
  Button,
  TextField,
  InputLabel, // ImporonDrop={(acceptedFiles)t the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import FlexBetween from "./FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AWS from 'aws-sdk';
import { FormattedMessage } from 'react-intl';
import LogoImage from '../assets/eticprologo8.png';
import RegSession from "./RegSession";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles


const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
const { REACT_APP_API_ENDPOINT } = process.env;

AWS.config.update({
accessKeyId: REACT_APP_ACCESS_KEY_ID,
secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
region: "sa-east-1", // Set your AWS region
});

// Create an S3 object
const s3 = new AWS.S3();

const currentDate = new Date();

const CreateTtvalue = ({ _id, data }) => {
  const location = useLocation();
  const userId = useSelector((state) => state.user._id);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedInUserContract = useSelector((state) => state.user.idcontrato);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const loggedInUsermail = useSelector((state) => state.user.email);
  const userprofile = useSelector((state) => state.user.userprofile); 
  const { palette } = useTheme();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const greenText = { color: 'green' }; // Define the CSS style for green text
  const [uploadedFiles1, setUploadedFiles1] = useState([]);
  const [uploadedFiles2, setUploadedFiles2] = useState([]);
  const [uploadedFiles3, setUploadedFiles3] = useState([]);
  const [uploadedFiles4, setUploadedFiles4] = useState([]);
  const loggedclient = useSelector((state) => state.client);
  const [wagon1users, setWagon1Users] = useState([]);


    const campaignSchema = yup.object().shape({
      // Define your validation schema here
      jefeProducto:  yup.string().required("Este campo es obligatorio"),
      tipott: yup.string().required("Este campo es obligatorio"),
      entrega: yup.string().required("Este campo es obligatorio"),
      titulo: yup.string().notRequired(),
      lastNameBen: yup.string().notRequired(),
      rutBen: yup.string().notRequired(),
      direccionBen: yup.string().notRequired(),
      formadeaporte: yup.string().notRequired(),
      numerocuenta: yup.string().notRequired(),
      apericia: yup.string().notRequired(),
      razoncontrato: yup.string().notRequired(),
      producto: yup.string().notRequired(),
      nombreactividad: yup.string().notRequired(),
      Legitimacion: yup.string().notRequired(),
      oradorPropio: yup.string().notRequired(),
      nombreinstitucion: yup.string().notRequired(),
      direccioninstitucion: yup.string().notRequired(),
      permisoinstitucion: yup.string().notRequired(),
      descripciontt: yup.string().notRequired(),
      detallealcance: yup.string().notRequired(),
      grupobjetivo: yup.string().notRequired(),
      ubicacion: yup.string().notRequired(),
      tiemporequerido: yup.string().notRequired(),
      duraciontt: yup.string().notRequired(),
      valortt: yup.string().notRequired(),
      resultadosesp: yup.string().notRequired(),
      opconflicto: yup.string().notRequired(),
      opinstitucion: yup.string().notRequired(),
      detalleconflicto: yup.string().notRequired(),
      comentarios: yup.string().notRequired(),
      docregistro: yup.string().notRequired(),
      pictures: yup.string().notRequired(""),

      

    });
 

    const initialValuesEditCampaign = {
      userId: loggedInUserId,
      emailSolicitante: loggedInUsermail,
      jefeProducto: data?.jefeProducto || "", 
      wagon: "",
      rangoAprobacion: "",
      autoEvaluacion: 'No',  // Store just one value, initialized as an empty string
      tipott:  data?.tipott || "", // Use data if available
      estado: "Por Evaluar",
      idcontrato: idcontrato, // Update the initial value
      razonauspicio: data?.razonauspicio || "",
      tipoauspicio: data?.tipoauspicio || "",
      propositott: data?.propositott || "",
      descripciontt: data?.descripciontt || "",
      valortt: data?.valortt || "",
      oppreciojusto: data?.oppreciojusto || "",
      costoevento: data?.costoevento || "",
      comunidad: data?.comunidad || "",
      ubicacion: data?.ubicacion || "",
      nombreproyecto: data?.nombreproyecto || "",
      nombreorganizacion: data?.nombreorganizacion || "",
      oplucro: data?.oplucro || "",
      replegal: data?.replegal || "",
      domiciliolegal: data?.domiciliolegal || "",
      mailreplegal: data?.mailreplegal || "",
      link: data?.link || "",
      nombrecontacto: data?.nombrecontacto || "",
      telefonocontacto: data?.telefonocontacto || "",
      emailcontacto: data?.emailcontacto || "",
      opdd: data?.opdd || "",
      ddrespaldo: data?.ddrespaldo || "",
      oppagos: data?.oppagos || "",
      exenciontributaria: data?.exenciontributaria || "",
      cartasolicitud: data?.cartasolicitud || "",
      valoranticipado: data?.valoranticipado || "",
      acuerdo: data?.acuerdo || "",
      duraciontt: data?.duraciontt || "",
      entrega:  "",
      estadoCierre: "Pendiente",
      orgpermiso: data?.orgpermiso || "",
      nombreb1: data?.nombreb1 || "",
      cargob1: data?.cargob1 || "",
      organizacionb1: data?.organizacionb1 || "",
      funcionariob1: data?.funcionariob1 || "",
      nombreb2: data?.nombreb2 || "",
      cargob2: data?.cargob2 || "",
      organizacionb2: data?.organizacionb2 || "",
      funcionariob2: data?.funcionariob2 || "",
      nombreb3: data?.nombreb3 || "",
      cargob3: data?.cargob3 || "",
      organizacionb3: data?.organizacionb3 || "",
      funcionariob3: data?.funcionariob3 || "",
      modoviaje: data?.modoviaje || "",
      clasesviaje: data?.clasesviaje || "",
      descripcionviaje: data?.descripcionviaje || "",
      opalojamiento: data?.opalojamiento || "",
      organizacionBen: data?.organizacionBen || "", 
      beneficios: data?.beneficios || "",
      agenda: data?.agenda || "",
      opcomida: data?.opcomida || "",
      recomendaciones: data?.recomendaciones || "",
      informe: data?.informe || "",
      utilizacion: data?.utilizacion || "",
      frecuencia: data?.frecuencia || "",
      repeticion: data?.repeticion || "",
      actoresext: data?.actoresext || "",
      miembros: data?.miembros || "",
      presupuesto: data?.presupuesto || "",
      lugar: data?.lugar || "",
      hotelapropiado: data?.hotelapropiado || "",
      traslados: data?.traslados || "",
      oradores: data?.oradores || "",
      responsable: data?.responsable || "",
      cantidadarticulos: data?.cantidadarticulos || "",
      valortotalarticulos: data?.valortotalarticulos || "",
      costoporpersona: data?.costoporpersona || "",
      costoporpersonatransporte: data?.costoporpersonatransporte || "",
      costoporpersonainvitacion: data?.costoporpersonainvitacion || "",
      costoporpersonacomida: data?.costoporpersonacomida || "",
      costoporpersonaotros: data?.costoporpersonaotros || "",
      otrosgastos: data?.otrosgastos || "",
      nombrerepresentantes1: data?.nombrerepresentantes1 || "",
      cargosrepresentantes1: data?.cargosrepresentantes1 || "",
      nombrerepresentantes2: data?.nombrerepresentantes2 || "",
      cargosrepresentantes2: data?.cargosrepresentantes2 || "",
      nombrerepresentantes3: data?.nombrerepresentantes3 || "",
      cargosrepresentantes3: data?.cargosrepresentantes3 || "",
      nombrerepresentantes4: data?.nombrerepresentantes4 || "",
      cargosrepresentantes4: data?.cargosrepresentantes4 || "",
      numeroasistentes: data?.numeroasistentes || "",
      oporganizadoreventoestatal: data?.oporganizadoreventoestatal || "",
      txtorganizadoreventoestatal: data?.txtorganizadoreventoestatal || "",
      opotrosauspicios: data?.opotrosauspicios || "",
      txtotrosauspicios: data?.txtotrosauspicios || "",
      opmencionauspicio: data?.opmencionauspicio || "",
      opmkt: data?.opmkt || "",
      txtmkt: data?.txtmkt || "",
      formadeaporte: data?.formadeaporte || "",
      opfuncionario: data?.opfuncionario || "",
      opauth:  "",
      commentcierre: data?.commentcierre || "",
      titulo: data?.titulo || "",
      firstNameBen: data?.firstNameBen || "",
      lastNameBen: data?.lastNameBen || "",
      rutBen: data?.rutBen || "",
      direccionBen: data?.direccionBen || "",
      numerocuenta: data?.numerocuenta || "",
      apericia: data?.apericia || "",
      razoncontrato: data?.razoncontrato || "",
      producto: data?.producto || "",
      nombreactividad: data?.nombreactividad || "",
      Legitimacion: data?.Legitimacion || "",
      oradorPropio: data?.oradorPropio || "",
      nombreinstitucion: data?.nombreinstitucion || "",
      direccioninstitucion: data?.direccioninstitucion || "",
      permisoinstitucion: data?.permisoinstitucion || "",
      detallealcance: data?.detallealcance || "",
      grupobjetivo: data?.grupobjetivo || "",
      tiemporequerido:  "",
      resultadosesp: data?.resultadosesp || "",
      opconflicto: data?.opconflicto || "",
      opinstitucion: data?.opinstitucion || "",
      detalleconflicto: data?.detalleconflicto || "",
      comentarios: data?.comentarios || "",
      docregistro: data?.docregistro || "",
      pictures1: "",
      pictures2: "",
      pictures3: "",
      pictures4: "",
      opauths:   [],
      authtxts:  [],
      datevals:  [],
      estados:  [],
    };
  
  
    useEffect(() => {
      // Fetch or define the wagon1users dynamically (as per your previous logic)
      const fetchAprobadores = async () => {
        try {
          const response = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}` },
          });
          
          const aprobadores = await response.json();
          
          const aprobadorDetails = aprobadores
            .filter(user => user.wagon === "1")
            .map(user => user.email);
          
          setWagon1Users(aprobadorDetails);
        } catch (error) {
          console.error('Error fetching aprobadores:', error);
        }
      };
  
      fetchAprobadores();
    }, []);
  
  const handleFormSubmit = async (values, onSubmitProps) => {
    const formData = new FormData();

    try {
      for (const key in values) {
        formData.append(key, values[key]);
        
      }
      const uploadToS3 = async (files) => {
        const bucketName = 'repoeticpro';
        const uniqueSuffix = Date.now() + "-";
        const s3Urls = [];
      
        AWS.config.update({
          accessKeyId: REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
          region: "sa-east-1", // Set your AWS region
        });
        const s3 = new AWS.S3();
      
        for (const file of files) {
          const key = uniqueSuffix + file.name;
      
          const params = {
            Bucket: bucketName,
            Key: key,
            Body: file,
          };
      
          try {
            await s3.upload(params).promise();
            console.log(`File uploaded to S3: ${key}`);
            s3Urls.push(key);
          } catch (error) {
            console.error(`Error uploading file to S3: ${error.message}`);
            throw error;
          }
        }
      
        return s3Urls;
      };
      
      if (uploadedFiles1.length > 0) {
        const s3Urls1 = await uploadToS3(uploadedFiles1);
        formData.append('pictures1', s3Urls1);
      }
      
      if (uploadedFiles2.length > 0) {
        const s3Urls2 = await uploadToS3(uploadedFiles2);
        formData.append('pictures1', s3Urls2);
      }
      
      if (uploadedFiles3.length > 0) {
        const s3Urls3 = await uploadToS3(uploadedFiles3);
        formData.append('pictures1', s3Urls3);
      }
      

      const responseaprobadores = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }); 
      
      const aprobadores = await responseaprobadores.json();
      
      const aprobadorEmails = aprobadores
        .filter(user => user.wagon) // Ensure rangoAprobacion is not empty
        .sort((a, b) => a.wagon - b.wagon) // Sort by rangoAprobacion from minor to mayor
        .map(user => user.email)
        .filter(email => email); // Exclude empty emails
      
      console.log("aprobadores", aprobadores);
      console.log("aprobadorEmails", aprobadorEmails);
      
      // Counter to track how many emails are appended
      let appendedEmailCount = 0;
      
      // Append each email to formData
      aprobadorEmails.forEach(email => {
        if (
          email !== loggedInUsermail && // Skip if the email is the same as loggedInUsermail
          !(loggedInUsermail === "gbravo@bago.cl" && email === "bago.jefeproducto@eticpro.com") // Skip if the specific condition is met
        ) {
          formData.append('aprobadores[]', email);
          appendedEmailCount++; // Increment the counter only when an email is appended
        }
      });
      
      // Now compare appendedEmailCount to aprobadorEmails.length
      if (appendedEmailCount !== aprobadorEmails.length) {
        formData.set('autoEvaluacion', "Si");  // Set "Si" if the lengths are not equal
        console.log("appendedEmailCount", appendedEmailCount);
        console.log("aprobadorEmails.length", aprobadorEmails.length);
      } else {
        formData.set('autoEvaluacion', "No");  // Set "No" if they are equal
      }
      
      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      
      });
      
      if (userResponse.ok) {
        console.log("User data stored successfully");
      
      
        RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "TV" });


  const emailTemplate = `
  <!DOCTYPE html>
  <html>
  <head>
    <style>
      .frame {
        border: 2px solid #ccc;
        padding: 20px;
        border-radius: 10px;
        width: 80%;
        margin: 0 auto;
        text-align: left;
        position: relative;
      }
      .logo-container {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .logo-box {
        text-align: center;
        margin-bottom: 20px;
      }
      .box {
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 5px;
      }
    </style>
  </head>
  <body>
    <div class="frame">
      <div class="logo-container">
        <img src=${LogoImage} alt="Logo" style="width: 100%;" />
      </div>
      <img src=${loggedclient.logo} alt="Logoemp" style="width: 15%;" /> <!-- Updated width to 10% -->
      
      <p>Estimado(a) Usuario,</p>
      <p>Le informamos que  hemos recibido la transferencia de valor.  Le invitamos ingresar a Eticpro en nuestro link de abajo, módulo Transferencias de Valor para revisar el estado y acción.</p>
      <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
      
      <p>Para soporte escribanos a <a href="mailto:hello@eticpro.com">hello@eticpro.com</a> o por Whatsapp al +56 977 657 352.</p>
      <p>Gracias por su atención y cooperación.</p>
      <p>Equipo Encargado de Prevención de Delitos</p>
      <p>Eticpro.</p>
      <p><a href="www.eticpro.com">www.eticpro.com</a></p>
    </div>
  </body>
  </html>
`;
  const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      emails: aprobadorEmails, // Use the aprobadorEmails array
      emailTemplate,
      mailserver: process.env.MAILSERVER, 
      subject : "Transferencia de Valor Recibida",

    }),
  });


  if (emailResponse.ok) {
    // Email sent successfully
    console.log("Email sent successfully");
  } else {
    // Error sending email
    console.log("Failed to send email");
  }


  setIsFormSubmitted(true);
  setShowSuccessPopup(true);
  onSubmitProps.resetForm();
  navigate("/successPage", {
    state: { message: "Transferencia de Valor creada con éxito" }
});             

} else {
  console.error(
    "Error storing user data:",
    userResponse.status,
    userResponse.statusText,
  );
}


   


    } catch (err) {
  console.log("Error creating Ttvalue:", err);

  if (err.response) {
    console.log("Response status:", err.response.status);

    // Use the .text() method to get the response body as text
    err.response.text().then(responseText => {
      console.log("Response data:", responseText);
    });
  }
}
  };

  

  
  return (

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
 <p></p>
 

          <Box>
          <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
              <InputLabel>Seleccionar Transferencia de Valor</InputLabel>

              <Select
                label="Transferencia de Valor"
                value={values.tipott}
                name="tipott"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.tipott) && Boolean(errors.tipott)}
              >
                <MenuItem value="">
                  <em>Seleccionar Transferencia de Valor</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="CPS">Contratacion Profesional de la Salud (CPS)</MenuItem>
                <MenuItem value="Contratacion Organizaciones">Contratacion Organizaciones(SCO)</MenuItem>
              
                <MenuItem value="Evento">Eventos</MenuItem>
                <MenuItem value="Junta Comite">Junta asesora Comité</MenuItem>
                <MenuItem value="Invitaciones">Invitaciones</MenuItem>
        <MenuItem value="Posicionamiento">Posicionamiento y Apoyo</MenuItem> 
        

              </Select>

              {touched.tipott && errors.tipott && (
                <Typography variant="body2" color="error">
                  {errors.tipott}
                </Typography>
              )}
            </FormControl>
          </Box>


          <p></p>
          <Box>
  <FormControl
    fullWidth
    sx={{
      borderRadius: "20px",
      gridColumn: "span 4",
      '& input': {
        background: 'white', // Set the background color to white
      },
    }}
  >
    <InputLabel>Seleccionar Jefe de Producto</InputLabel>

    <Select
      label="Jefe de Producto Aprobador"
      value={values.jefeProducto}
      name="jefeProducto"
      onBlur={handleBlur}
      onChange={handleChange}
      error={Boolean(touched.jefeProducto) && Boolean(errors.jefeProducto)}
    >
      <MenuItem value="">
        <em>Seleccionar Jefe de Producto</em>
      </MenuItem>
      
      {/* Dynamically populate MenuItem with emails from wagon1users */}
      {wagon1users.map((email) => (
        <MenuItem key={email} value={email}>
          {email}
        </MenuItem>
      ))}

    </Select>

    {touched.jefeProducto && errors.jefeProducto && (
      <Typography variant="body2" color="error">
        {errors.jefeProducto}
      </Typography>
    )}
  </FormControl>
</Box>


      
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(2, minmax(0, 1fr))"
          sx={{ borderRadius: "20px",
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
            


{values.tipott === "CPS" && (
  <>

      <Typography>
          </Typography>

          <TextField
  label="Título profesional"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.titulo}
  name="titulo"
  error={Boolean(touched.titulo) && Boolean(errors.titulo)}
  helperText={touched.titulo && errors.titulo}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Nombres"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.firstNameBen}
  name="firstNameBen"
  error={Boolean(touched.firstNameBen) && Boolean(errors.firstNameBen)}
  helperText={touched.firstNameBen && errors.firstNameBen}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Apellidos"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.lastNameBen}
  name="lastNameBen"
  error={Boolean(touched.lastNameBen) && Boolean(errors.lastNameBen)}
  helperText={touched.lastNameBen && errors.lastNameBen}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="RUT"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.rutBen}
  name="rutBen"
  error={Boolean(touched.rutBen) && Boolean(errors.rutBen)}
  helperText={touched.rutBen && errors.rutBen}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Dirección Particular"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.direccionBen}
  name="direccionBen"
  error={Boolean(touched.direccionBen) && Boolean(errors.direccionBen)}
  helperText={touched.direccionBen && errors.direccionBen}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Forma de Pago: transferencia bancaria, aporte en especies..."
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.formadeaporte}
  name="formadeaporte"
  error={Boolean(touched.formadeaporte) && Boolean(errors.formadeaporte)}
  helperText={touched.formadeaporte && errors.formadeaporte}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Número de cuenta si corresponde:"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.numerocuenta}
  name="numerocuenta"
  error={Boolean(touched.numerocuenta) && Boolean(errors.numerocuenta)}
  helperText={touched.numerocuenta && errors.numerocuenta}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>



<TextField
  label="Área de pericia"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.apericia}
  name="apericia"
  error={Boolean(touched.apericia) && Boolean(errors.apericia)}
  helperText={touched.apericia && errors.apericia}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Razón para contratar este profesional"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.razoncontrato}
  name="razoncontrato"
  error={Boolean(touched.razoncontrato) && Boolean(errors.razoncontrato)}
  helperText={touched.razoncontrato && errors.razoncontrato}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Producto"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.producto}
  name="producto"
  error={Boolean(touched.producto) && Boolean(errors.producto)}
  helperText={touched.producto && errors.producto}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Tipo de actividad: Charla,  Preparacion Material Cientifico, Partipacion en Panel.."
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.nombreactividad}
  name="nombreactividad"
  error={Boolean(touched.nombreactividad) && Boolean(errors.nombreactividad)}
  helperText={touched.nombreactividad && errors.nombreactividad}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Legitimacion del Evento"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.Legitimacion}
  name="Legitimacion"
  error={Boolean(touched.Legitimacion) && Boolean(errors.Legitimacion)}
  helperText={touched.Legitimacion && errors.Legitimacion}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>


<TextField
  label="Para oradores: Orador propio o apoyo de terceros en reuniones?"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.oradorPropio}
  name="oradorPropio"
  error={Boolean(touched.oradorPropio) && Boolean(errors.oradorPropio)}
  helperText={touched.oradorPropio && errors.oradorPropio}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>


<Typography variant="h6" gutterBottom>

¿Trabaja en alguna institucion?</Typography>
<RadioGroup
  aria-label="Opción"
  name="opinstitucion"
  value={values.opinstitucion}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>
{values.opinstitucion === 'Si' && ( // Only render if the answer is 'Si'
<div>
<TextField
  label="Nombre de institucion donde trabaja(si no trabaja puede dejar vacio)"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.nombreinstitucion}
  name="nombreinstitucion"
  error={Boolean(touched.nombreinstitucion) && Boolean(errors.nombreinstitucion)}
  helperText={touched.nombreinstitucion && errors.nombreinstitucion}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Direccion de las institucion donde trabaja"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.direccioninstitucion}
  name="direccioninstitucion"
  error={Boolean(touched.direccioninstitucion) && Boolean(errors.direccioninstitucion)}
  helperText={touched.direccioninstitucion && errors.direccioninstitucion}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>
<p></p>
 Necesita permiso de su institución para ser contratado externamente? 

<RadioGroup
  label="Necesita permiso de su institución para ser contratado externamente?"
  aria-label="Opción"
  name="permisoinstitucion"
  value={values.permisoinstitucion}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>

{values.permisoinstitucion === 'Si' && ( // Only render if the answer is 'Si'
<div>
        <Divider sx={{ borderRadius: "20px", gridColumn: "span 5" }} />

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles4(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures4", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures4 || values.pictures4.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte(Límite Máximo 5Mb )   Permiso Aquí.</p>
                    ) : (
                      <div>
                        {values.pictures4.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>
                </Box>
        
   </div>)}

</div>)}




<TextField
  label="Detalle de la Necesidad y objeto de la colaboración"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.descripciontt}
  name="descripciontt"
  error={Boolean(touched.descripciontt) && Boolean(errors.descripciontt)}
  helperText={touched.descripciontt && errors.descripciontt}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Detalle Alcance del servicio: (numero de actividades,  cantidad de publico, preparación, ejecución, seguimiento)"

  onBlur={handleBlur}
  onChange={handleChange}
  value={values.detallealcance}
  name="detallealcance"
  error={Boolean(touched.detallealcance) && Boolean(errors.detallealcance)}
  helperText={touched.detallealcance && errors.detallealcance}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Grupo objetivo al que va dirigido: ( auxiliares enfermería, doctores..)  "
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.grupobjetivo}
  name="grupobjetivo"
  error={Boolean(touched.grupobjetivo) && Boolean(errors.tipoCgrupobjetivoontratoServicio)}
  helperText={touched.grupobjetivo && errors.grupobjetivo}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>


<div style={{ marginBottom: '10px' }}>Fecha de entrega del servicio:</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    <p></p>

<TextField
  label="Lugar del Servicio"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.ubicacion}
  name="ubicacion"
  error={Boolean(touched.ubicacion) && Boolean(errors.ubicacion)}
  helperText={touched.ubicacion && errors.ubicacion}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Tiempo requerido del servicio"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.tiemporequerido}
  name="tiemporequerido"
  error={Boolean(touched.tiemporequerido) && Boolean(errors.tiemporequerido)}
  helperText={touched.tiemporequerido && errors.tiemporequerido}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Duración requerida del servicio"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.duraciontt}
  name="duraciontt"
  error={Boolean(touched.duraciontt) && Boolean(errors.duraciontt)}
  helperText={touched.duraciontt && errors.duraciontt}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Monto de compensación (tasa por hora o monto total diario / servicio)"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.valortt}
  name="valortt"
  error={Boolean(touched.valortt) && Boolean(errors.valortt)}
  helperText={touched.valortt && errors.valortt}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Resultados esperados y uso en los negocios"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.resultadosesp}
  name="resultadosesp"
  error={Boolean(touched.resultadosesp) && Boolean(errors.resultadosesp)}
  helperText={touched.resultadosesp && errors.resultadosesp}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 1",
    '& input': {
      background: 'white',
    },
  }}
/>
<div>
¿Posee el PDS algún conflicto de interés relacionado con gerentes o miembros del directorio?"

  <RadioGroup
  aria-label="Opción"
  name="opconflicto"
  value={values.opconflicto}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>
</div>

<div>

{values.opconflicto === 'Si' && ( // Only render if the answer is 'Si'

<TextField
  label="Si tiene conflicto detalle aquí: "
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.detalleconflicto}
  name="detalleconflicto"
  error={Boolean(touched.detalleconflicto) && Boolean(errors.detalleconflicto)}
  helperText={touched.detalleconflicto && errors.detalleconflicto}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

)}
</div>

<TextField
  label="otros Comentarios? detalle aquí:"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.comentarios}
  name="comentarios"
  error={Boolean(touched.comentarios) && Boolean(errors.comentarios)}
  helperText={touched.comentarios && errors.comentarios}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>




<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
<Typography variant="h6" gutterBottom>

Es el Prestador del extranjero ?
</Typography>
<RadioGroup
  aria-label="Opción"
  name="docregistro"
  value={values.docregistro}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>


{values.docregistro === 'Si' && ( // Only render if the answer is 'Si'
<div>
        <Divider sx={{ borderRadius: "20px", gridColumn: "span 5" }} />

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles1(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures1", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures1 || values.pictures1.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjuntar Documento Registro Nacional de Prestadores Individuales de Salud.</p>
                    ) : (
                      <div>
                        {values.pictures1.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>




                </Box>
          

   
   </div>
      )}
</FormControl>  

<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },

          }}>



<Typography variant="h6" gutterBottom>

Adjunte Borrador del Contrato (5Mb Máximo)   .
</Typography>

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles2(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures2", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures2 || values.pictures2.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjuntar Borrador Contrato aquí</p>
                      ) : (
                      <div>
                        {values.pictures2.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>


                <Typography variant="h6" gutterBottom>

Adjunte Antecedentes del profesional Aquí (5Mb Máximo ).
</Typography>

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  setUploadedFiles3(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures3", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures3 || values.pictures3.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjuntar Curriculum Vitae (Mandatorio), Publicaciones(opcional) u otra información de respaldo de su experiencia.</p>
                      ) : (
                      <div>
                        {values.pictures3.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>

 </FormControl>  


   </>
)}

{values.tipott === "Contratacion Organizaciones" && (
    <>
<TextField
  label="Tipo de actividad: Charla, Congreso.."
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.nombreactividad}
  name="nombreactividad"
  error={Boolean(touched.nombreactividad) && Boolean(errors.nombreactividad)}
  helperText={touched.nombreactividad && errors.nombreactividad}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>


<TextField
  label="Detalle de la Necesidad y objeto de la Contraprestación"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.descripciontt}
  name="descripciontt"
  error={Boolean(touched.descripciontt) && Boolean(errors.descripciontt)}
  helperText={touched.descripciontt && errors.descripciontt}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Tipo y magnitud del(los) servicios(s) a cambio (por ejemplo, numero participantes, área de la cabina en m², tiempo contratado,  logotipo, referencia a la audiencia, mención como auspiciador en el anuncio y durante el evento, otros).  "

  onBlur={handleBlur}
  onChange={handleChange}
  value={values.detallealcance}
  name="detallealcance"
  error={Boolean(touched.detallealcance) && Boolean(errors.detallealcance)}
  helperText={touched.detallealcance && errors.detallealcance}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Grupo objetivo al que va dirigido: ( auxiliares enfermería, doctores..)  "
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.grupobjetivo}
  name="grupobjetivo"
  error={Boolean(touched.grupobjetivo) && Boolean(errors.tipoCgrupobjetivoontratoServicio)}
  helperText={touched.grupobjetivo && errors.grupobjetivo}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<div style={{ marginBottom: '10px' }}>Fecha de entrega del servicio:</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    <p></p>

<TextField
  label="Lugar del Servicio"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.ubicacion}
  name="ubicacion"
  error={Boolean(touched.ubicacion) && Boolean(errors.ubicacion)}
  helperText={touched.ubicacion && errors.ubicacion}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>



<TextField
  label="Duración requerida del servicio"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.duraciontt}
  name="duraciontt"
  error={Boolean(touched.duraciontt) && Boolean(errors.duraciontt)}
  helperText={touched.duraciontt && errors.duraciontt}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Monto de compensación (tasa por hora o monto total diario / servicio)"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.valortt}
  name="valortt"
  error={Boolean(touched.valortt) && Boolean(errors.valortt)}
  helperText={touched.valortt && errors.valortt}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Resultados esperados y uso en los negocios"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.resultadosesp}
  name="resultadosesp"
  error={Boolean(touched.resultadosesp) && Boolean(errors.resultadosesp)}
  helperText={touched.resultadosesp && errors.resultadosesp}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>
¿Posee  algún conflicto de interés relacionado con gerentes o miembros del directorio?"

  <RadioGroup
  aria-label="Opción"
  name="opconflicto"
  value={values.opconflicto}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>
<div>

{values.opconflicto === 'Si' && ( // Only render if the answer is 'Si'

<TextField
  label="Si tiene conflicto detalle aquí: "
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.detalleconflicto}
  name="detalleconflicto"
  error={Boolean(touched.detalleconflicto) && Boolean(errors.detalleconflicto)}
  helperText={touched.detalleconflicto && errors.detalleconflicto}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

)}
</div>
<TextField
  label="otros Comentarios? detalle aquí:"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.comentarios}
  name="comentarios"
  error={Boolean(touched.comentarios) && Boolean(errors.comentarios)}
  helperText={touched.comentarios && errors.comentarios}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>






<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },

          }}>

<Typography variant="h6" gutterBottom>

Por favor adjuntar Lista de precio Aquí (5Mb Máximo)
</Typography>

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles2(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures2", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures2 || values.pictures2.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjuntar Lista de precios</p>
                      ) : (
                      <div>
                        {values.pictures2.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>

 </FormControl>  


<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },

          }}>

<Typography variant="h6" gutterBottom>

Por favor adjuntar Borrador del contrato Aquí(5Mb Máximo)
</Typography>

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  setUploadedFiles3(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures3", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures3 || values.pictures3.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjuntar Borrador del contrato de la Prestación y/u otro archivo que sea relevante .</p>
                      ) : (
                      <div>
                        {values.pictures3.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>

 </FormControl>  
</>

)}

{values.tipott === "Evento" && (
 <>

 <Typography>
     </Typography>

     <TextField
label="Nombre Evento"
onBlur={handleBlur}
onChange={handleChange}
value={values.nombreactividad}
name="nombreactividad"
error={Boolean(touched.nombreactividad) && Boolean(errors.nombreactividad)}
helperText={touched.nombreactividad && errors.nombreactividad}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Tópico/Enfoque del Evento"
onBlur={handleBlur}
onChange={handleChange}
value={values.detallealcance}
name="razoncontrato"
error={Boolean(touched.detallealcance) && Boolean(errors.detallealcance)}
helperText={touched.detallealcance && errors.detallealcance}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


    <div>

          <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
              <InputLabel>Seleccionar Tipo de Evento</InputLabel>

              <Select
                label="Tipo de Evento"
                value={values.descripciontt}
                name="descripciontt"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.tipott) && Boolean(errors.tipott)}
              >
                <MenuItem value="">
                  <em>Seleccionar Tipo de Evento</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="Internacional">Internacional</MenuItem>
                <MenuItem value="Nacional">Nacional</MenuItem>
              
                <MenuItem value="Pequeña Reunion Clinica con Coffee/Almuerzo">Pequeña Reunion Clinica con Coffee/Almuerzo</MenuItem>
                <MenuItem value="Cena Promocional">Cena Promocional"</MenuItem>
           
        

              </Select>

              {touched.descripciontt && errors.descripciontt && (
                <Typography variant="body2" color="error">
                  {errors.descripciontt}
                </Typography>
              )}
            </FormControl>
        

          </div>
          <p></p>

<TextField
label="¿Cuál es el propósito del Evento organizado por la organización  y qué mensajes se entregarán durante el Evento? ¿A qué productos, indicaciones o compuestos se referirá el Evento?"
onBlur={handleBlur}
onChange={handleChange}
value={values.propositott}
name="propositott"
error={Boolean(touched.propositott) && Boolean(errors.propositott)}
helperText={touched.propositott && errors.propositott}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Beneficios: ¿Cuáles son los beneficios anticipados del evento para la compañía? ¿Cuáles son los beneficios científicos para los participantes pasivos? ¿Cómo mejorarán las presentaciones educativas la práctica de la medicina? (Ej: Profundización del conocimiento científico de ciertos especialistas sobre indicaciones significativas de los productos de la compañía, presentación de los últimos resultados de ciertos estudios clínicos, etc.)"
onBlur={handleBlur}
onChange={handleChange}
value={values.resultadosesp}
name="resultadosesp"
error={Boolean(touched.resultadosesp) && Boolean(errors.resultadosesp)}
helperText={touched.resultadosesp && errors.resultadosesp}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Número de cuenta si corresponde:"
onBlur={handleBlur}
onChange={handleChange}
value={values.numerocuenta}
name="numerocuenta"
error={Boolean(touched.numerocuenta) && Boolean(errors.numerocuenta)}
helperText={touched.numerocuenta && errors.numerocuenta}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>



<TextField
label="Prueba de servicios y ejecución: ¿Quién documentará la ejecución efectiva del evento, incluyendo los servicios relevantes? ¿Cómo se lo documentará?"
onBlur={handleBlur}
onChange={handleChange}
value={values.Legitimacion}
name="Legitimacion"
error={Boolean(touched.Legitimacion) && Boolean(errors.Legitimacion)}
helperText={touched.Legitimacion && errors.Legitimacion}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Lugar: ¿Dónde se realizará el evento?  ¿Cumple la selección del lugar los requisitos de Apropiado?"
onBlur={handleBlur}
onChange={handleChange}
value={values.ubicacion}
name="ubicacion"
error={Boolean(touched.ubicacion) && Boolean(errors.ubicacion)}
helperText={touched.ubicacion && errors.ubicacion}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Participantes pasivos (asistentes): Número, área(s) de pericia, calificaciones, país de origen"
onBlur={handleBlur}
onChange={handleChange}
value={values.numeroasistentes}
name="numeroasistentes"
error={Boolean(touched.numeroasistentes) && Boolean(errors.numeroasistentes)}
helperText={touched.numeroasistentes && errors.numeroasistentes}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Participantes activos (por ejemplo, oradores / consultores): Número, nombres, área(s) de pericia, calificaciones, detalles (preliminares) de los servicios (tópicos, duración, etc.), país de origen"
onBlur={handleBlur}
onChange={handleChange}
value={values.nombreactividad}
name="oradorPropio"
error={Boolean(touched.oradorPropio) && Boolean(errors.oradorPropio)}
helperText={touched.oradorPropio && errors.oradorPropio}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Indicar si se alojaran invitados en un Hotel "
onBlur={handleBlur}
onChange={handleChange}
value={values.opalojamiento}
name="opalojamiento"
error={Boolean(touched.opalojamiento) && Boolean(errors.opalojamiento)}
helperText={touched.opalojamiento && errors.opalojamiento}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Indicar si habrá servicio de comida / almuerzo / cena (preliminar)"
onBlur={handleBlur}
onChange={handleChange}
value={values.opcomida}
name="opcomida"
error={Boolean(touched.opcomida) && Boolean(errors.opcomida)}
helperText={touched.opcomida && errors.opcomida}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="En caso de contestar afirmativamente en la pregunta anterior, recordar que el restaurante y el menú deben ser Apropiados y reflejar el carácter de trabajo. Señalar el presupuesto  costos por persona, total y  describir el menu."
onBlur={handleBlur}
onChange={handleChange}
value={values.costoporpersonacomida}
name="costoporpersonacomida"
error={Boolean(touched.costoporpersonacomida) && Boolean(errors.costoporpersonacomida)}
helperText={touched.costoporpersonacomida && errors.costoporpersonacomida}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Obsequios y regalos ¿Planea distribuir obsequios o regalos? Descripción de los objetos y su valor de acuerdo a los liniamientos internos"
onBlur={handleBlur}
onChange={handleChange}
value={values.costoporpersonaotros}
name="costoporpersonaotros"
error={Boolean(touched.costoporpersonaotros) && Boolean(errors.costoporpersonaotros)}
helperText={touched.costoporpersonaotros && errors.costoporpersonaotros}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Material promocional y científico: Lista de materiales promocionales y científicos. Asegúrese que los eventos se identifiquen como promocionales con pendones "
onBlur={handleBlur}
onChange={handleChange}
value={values.txtmkt}
name="txtmkt"
error={Boolean(touched.txtmkt) && Boolean(errors.txtmkt)}
helperText={touched.txtmkt && errors.txtmkt}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Presupuesto preliminar: Estimación del marco preliminar de presupuesto (y detalles)"
onBlur={handleBlur}
onChange={handleChange}
value={values.costoporpersonainvitacion}
name="costoporpersonainvitacion"
error={Boolean(touched.costoporpersonainvitacion) && Boolean(errors.costoporpersonainvitacion)}
helperText={touched.costoporpersonainvitacion && errors.costoporpersonainvitacion}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<div style={{ marginBottom: '10px' }}>
  Fecha de realización:
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    <p></p>
    </div>
        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  setUploadedFiles3(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures3", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures3 || values.pictures3.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjuntar otra información de respaldo</p>
                      ) : (
                      <div>
                        {values.pictures3.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>


</>
)}

{values.tipott === "Junta Comite" && (
   <>

  <TextField
  label="Propósito: ¿Cuál es el propósito / alcance de la Junta Asesora y cuáles son sus beneficios anticipados? (¿A qué indicaciones y / o compuestos se referirá la Junta Asesora?)"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.propositott}
  name="propositott"
  error={Boolean(touched.propositott) && Boolean(errors.propositott)}
  helperText={touched.propositott && errors.propositott}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Resultado: ¿Qué servicios proveerán los actores externos en el contexto de la reunión de la Junta Asesora?"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.detallealcance}
  name="detallealcance"
  error={Boolean(touched.descriptiontt) && Boolean(errors.detallealcance)}
  helperText={touched.detallealcance && errors.detallealcance}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Recolección de las recomendaciones: ¿Cómo se recogerán las recomendaciones de los actores externos?"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.recomendaciones}
  name="recomendaciones"
  error={Boolean(touched.recomendaciones) && Boolean(errors.recomendaciones)}
  helperText={touched.recomendaciones && errors.recomendaciones}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
    
  <TextField
  label="Informe / resumen: ¿Quién / qué función preparará el informe de la reunión y será responsable por los siguientes pasos? (Siempre hay un escritor médico en sitio)"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.informe}
  name="informe"
  error={Boolean(touched.informe) && Boolean(errors.informe)}
  helperText={touched.informe && errors.informe}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Utilización en el negocio: ¿Quién (empleado de BAGÓ) evaluará los pasos siguientes y dentro de qué marco de tiempo?  ¿Cómo se utilizarán los resultados de la Junta Asesora en el negocio, y cómo será documentada dicha utilización?"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.utilizacion}
  name="utilizacion"
  error={Boolean(touched.utilizacion) && Boolean(errors.utilizacion)}
  helperText={touched.utilizacion && errors.utilizacion}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Frecuencia: Si se proponen reuniones múltiples (regionales), ¿puede el número ser sostenido de forma objetiva, por ejemplo, debido a participantes de diferentes lugares, disponibilidad, gastos de viaje?"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.frecuencia}
  name="frecuencia"
  error={Boolean(touched.frecuencia) && Boolean(errors.frecuencia)}
  helperText={touched.frecuencia && errors.frecuencia}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
 
  
  <TextField
  label="Repetición: ¿Reuniones previas han abordado el mismo / similar tema o asunto? De ser así, ¿hay una necesidad legítima de otra reunión sobre el mismo / similar tema en este momento?"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.repeticion}
  name="repeticion"
  error={Boolean(touched.repeticion) && Boolean(errors.repeticion)}
  helperText={touched.repeticion && errors.repeticion}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Número de actores externos: ¿Cuál es el número propuesto de actores externos que participarían en la Junta Asesora?  ¿De qué forma se puede sostener objetivamente ese número?  (El área de contratación  debe dar una explicación informal corta) "
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.actoresext}
  name="actoresext"
  error={Boolean(touched.actoresext) && Boolean(errors.actoresext)}
  helperText={touched.actoresext && errors.actoresext}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Nombre, país de origen y calificación de los miembros identificados de la Junta Asesora (preliminar): ¿Cumplen las calificaciones de los actores externos la necesidad identificada, documentada?  - adjunte las credenciales de ser necesario"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.miembros}
  name="miembros"
  error={Boolean(touched.miembros) && Boolean(errors.miembros)}
  helperText={touched.miembros && errors.miembros}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Presupuesto y honorarios: ¿Cuál es el presupuesto total y cuál es el monto esperado de pagos a los miembros de la Junta Asesora con base en los servicios específicos y los gastos relacionados esperados? (por favor revisar que se ajusta al valor justo de mercado adjuntado formulario de contratación PDS)"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.presupuesto}
  name="presupuesto"
  error={Boolean(touched.presupuesto) && Boolean(errors.presupuesto)}
  helperText={touched.presupuesto && errors.presupuesto}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Lugar (preliminar): ¿Cumple la selección del lugar los requisitos de compliance? (Refiérase a lineamientos internos) adjunte información del lugar  "
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.lugar}
  name="lugar"
  error={Boolean(touched.lugar) && Boolean(errors.lugar)}
  helperText={touched.lugar && errors.lugar}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  <TextField
  label="Refierase al hotel  del alojamiento si es distinto en donde el cual se hará la reunión (señale la razón del por qué es Apropiado)"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.hotelapropiado}
  name="hotelapropiado"
  error={Boolean(touched.hotelapropiado) && Boolean(errors.hotelapropiado)}
  helperText={touched.hotelapropiado && errors.hotelapropiado}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  <TextField
  label="Traslados: indique si habrá compra de pasajes aereos o traslados terrestres "
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.traslados}
  name="traslados"
  error={Boolean(touched.traslados) && Boolean(errors.traslados)}
  helperText={touched.traslados && errors.traslados}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  <TextField
  label="Servicio de comida / almuerzo / cena (presupuesto preliminar) recuerde el monto máximo por persona. También adjunte el menú"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.costoporpersonacomida}
  name="costoporpersonacomida"
  error={Boolean(touched.costoporpersonacomida) && Boolean(errors.costoporpersonacomida)}
  helperText={touched.costoporpersonacomida && errors.costoporpersonacomida}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  <TextField
  label="¿Alguno de los miembros de la junta asesora tiene algún conflicto de interés que lo relacione con gerentes o miembros del directorio de Bagó Chile S.A?"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.opconflicto}
  name="opconflicto"
  error={Boolean(touched.opconflicto) && Boolean(errors.opconflicto)}
  helperText={touched.opconflicto && errors.opconflicto}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  <TextField
  label="Obsequios y regalos: ¿Planea distribuir obsequios corporativos?  (Refiérase a lineamientos internos) adjunte la cotización "
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.costoporpersonainvitacion}
  name="costoporpersonainvitacion"
  error={Boolean(touched.costoporpersonainvitacion) && Boolean(errors.costoporpersonainvitacion)}
  helperText={touched.costoporpersonainvitacion && errors.costoporpersonainvitacion}
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
 

 <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>

<Typography variant="h6" gutterBottom>
Obsequios y regalos: ¿Planea distribuir obsequios corporativos?  (Refiérase a lineamientos internos) adjunte la cotización.

</Typography>
<RadioGroup
  aria-label="Opción"
  name="oppagos"
  value={values.oppagos}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>

 
<div style={{ marginBottom: '10px' }}>
  Fecha de Realización:
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    <p></p>
    </div>

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  setUploadedFiles2(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures2", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures2 || values.pictures2.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte la Cotización y/u otro archivo relevante (5Mb Máximo) </p>
                    ) : (
                      <div>
                        {values.pictures2.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>

 </FormControl>  
  
  
  
  </>
)}

{values.tipott === "Invitaciones" && (

    <>

     <TextField
label="Nombre y tópico / enfoque del Congreso al que se desea invitar "
onBlur={handleBlur}
onChange={handleChange}
value={values.nombreactividad}
name="nombreactividad"
error={Boolean(touched.nombreactividad) && Boolean(errors.nombreactividad)}
helperText={touched.nombreactividad && errors.nombreactividad}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>
<div style={{ marginBottom: '10px' }}>
  Fecha de Realización:
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    <p></p>
    </div>
<TextField
label="Enfoque del Congreso al que se desea invitar "
onBlur={handleBlur}
onChange={handleChange}
value={values.descripciontt}
name="descripciontt"
error={Boolean(touched.descripciontt) && Boolean(errors.descripciontt)}
helperText={touched.descripciontt && errors.descripciontt}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Detalles del Congreso: Tipo de Congreso, internacional vs. nacional, lugar, fecha, duración, participantes, etc."
onBlur={handleBlur}
onChange={handleChange}
value={values.detallealcance}
name="detallealcance"
error={Boolean(touched.detallealcance) && Boolean(errors.detallealcance)}
helperText={touched.detallealcance && errors.detallealcance}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>



<TextField
label="Tipo de invitación : alojamiento,  registro, comidas, traslados "
onBlur={handleBlur}
onChange={handleChange}
value={values.tipoauspicio}
name="tipoauspicio"
error={Boolean(touched.tipoauspicio) && Boolean(errors.tipoauspicio)}
helperText={touched.tipoauspicio && errors.tipoauspicio}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Interés / Beneficios para la compañía: ¿Cuál es el propósito e interés en invitar al PDS a participar en el Congreso y qué información se entregará durante el Congreso?  ¿Cuáles son los beneficios  de la participación del PDS para el paciente que utiliza  los productos de nuestra compañía .¿A qué productos, indicaciones o compuestos se referirá en el congreso ?"
onBlur={handleBlur}
onChange={handleChange}
value={values.propositott}
name="propositott"
error={Boolean(touched.propositott) && Boolean(errors.propositott)}
helperText={touched.propositott && errors.propositott}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Beneficios para los participantes pasivos: ¿Cuáles son los beneficios científicos para los participantes pasivos? ¿Cómo mejorarán las presentaciones educativas la práctica de la medicina? (Ej.: Profundización del conocimiento científico de ciertos especialistas sobre indicaciones significativas de los productos de la compañía, presentación de los últimos resultados de ciertos estudios clínicos, etc.)"
onBlur={handleBlur}
onChange={handleChange}
value={values.beneficios}
name="beneficios"
error={Boolean(touched.beneficios) && Boolean(errors.beneficios)}
helperText={touched.beneficios && errors.beneficios}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Cual será la prueba de asistencia al congreso (foto de diploma, certificado, foto de lista de asistencia etc.)?"
onBlur={handleBlur}
onChange={handleChange}
value={values.Legitimacion}
name="Legitimacion"
error={Boolean(touched.Legitimacion) && Boolean(errors.Legitimacion)}
helperText={touched.Legitimacion && errors.Legitimacion}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>



<TextField
label="Agenda preliminar: Dimensión, escala, tamaño, magnitud de las sesiones, número de grupos, número de talleres, número de oradores por grupo, etc. – adjuntar"
onBlur={handleBlur}
onChange={handleChange}
value={values.agenda}
name="agenda"
error={Boolean(touched.agenda) && Boolean(errors.agenda)}
helperText={touched.agenda && errors.agenda}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Participantes pasivos (asistentes): Número, área(s) de pericia, calificaciones, país de origen"
onBlur={handleBlur}
onChange={handleChange}
value={values.numeroasistentes}
name="numeroasistentes"
error={Boolean(touched.numeroasistentes) && Boolean(errors.numeroasistentes)}
helperText={touched.numeroasistentes && errors.numeroasistentes}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Participantes activos (por ejemplo, oradores / consultores): Número, nombres, área(s) de pericia, calificaciones, detalles (preliminares) de los servicios (tópicos, duración, etc.), país de origen"
onBlur={handleBlur}
onChange={handleChange}
value={values.oradores}
name="oradores"
error={Boolean(touched.oradores) && Boolean(errors.oradores)}
helperText={touched.oradores && errors.oradores}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Lugar (preliminar) por favor señale por qué es un lugar apropiado (Refiérase a lineamientos internos)"
onBlur={handleBlur}
onChange={handleChange}
value={values.lugar}
name="lugar"
error={Boolean(touched.lugar) && Boolean(errors.lugar)}
helperText={touched.lugar && errors.lugar}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Hoteles + desayuno (preliminar) (Refiérase a lineamientos internos)"
onBlur={handleBlur}
onChange={handleChange}
value={values.hotelapropiado}
name="hotelapropiado"
error={Boolean(touched.hotelapropiado) && Boolean(errors.hotelapropiado)}
helperText={touched.hotelapropiado && errors.hotelapropiado}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Servicio de comida / almuerzo / cena (preliminar)"
onBlur={handleBlur}
onChange={handleChange}
value={values.opcomida}
name="opcomida"
error={Boolean(touched.opcomida) && Boolean(errors.opcomida)}
helperText={touched.opcomida && errors.opcomida}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Presupuesto preliminar: Estimado del marco preliminar de presupuesto (y detalles)"
onBlur={handleBlur}
onChange={handleChange}
value={values.presupuesto}
name="presupuesto"
error={Boolean(touched.presupuesto) && Boolean(errors.presupuesto)}
helperText={touched.presupuesto && errors.presupuesto}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Responsable principal: Indique el nombre del empleado de la compañía que será el principal responsable de adjuntar los respaldos "
onBlur={handleBlur}
onChange={handleChange}
value={values.responsable}
name="responsable"
error={Boolean(touched.responsable) && Boolean(errors.responsable)}
helperText={touched.responsable && errors.responsable}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Material promocional y científico: Lista de materiales promocionales y científicos. Asegúrese que los eventos se identifiquen como promocionales con pendones "
onBlur={handleBlur}
onChange={handleChange}
value={values.txtmkt}
name="txtmkt"
error={Boolean(touched.txtmkt) && Boolean(errors.txtmkt)}
helperText={touched.txtmkt && errors.txtmkt}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Responsable principal: Indique el nombre del empleado de la compañía que será el principal responsable de adjuntar los respaldos "
onBlur={handleBlur}
onChange={handleChange}
value={values.costoporpersonainvitacion}
name="costoporpersonainvitacion"
error={Boolean(touched.costoporpersonainvitacion) && Boolean(errors.costoporpersonainvitacion)}
helperText={touched.costoporpersonainvitacion && errors.costoporpersonainvitacion}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>

<Typography variant="h6" gutterBottom>
Adjunte borrador de carta de invitación aquí (asegúrese de que el PDS lo firme previo al evento)
</Typography>



        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  setUploadedFiles2(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures2", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures2 || values.pictures2.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Carta de  solicitud y/u otro archivo relevante (5Mb Máximo) </p>
                    ) : (
                      <div>
                        {values.pictures2.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>

 </FormControl>  


</>
)}

{values.tipott === "Posicionamiento" && (


    <>
    <Typography variant="h4" gutterBottom>

    Información sobre los destinatarios de articulos de apoyo al paciente o articulos de posicionamiento de marca
    </Typography>
     <TextField
label="Nombre de la Organización"
onBlur={handleBlur}
onChange={handleChange}
value={values.organizacionBen}
name="organizacionBen"
error={Boolean(touched.organizacionBen) && Boolean(errors.organizacionBen)}
helperText={touched.organizacionBen && errors.organizacionBen}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>
<div style={{ marginBottom: '10px' }}>
  Fecha de Entrega:
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    <p></p>
    </div>
<TextField
label="Dirección de la Organización – Calle, Nº, Ciudad"
onBlur={handleBlur}
onChange={handleChange}
value={values.direccionBen}
name="direccionBen"
error={Boolean(touched.direccionBen) && Boolean(errors.direccionBen)}
helperText={touched.direccionBen && errors.direccionBen}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Nombre y cargo de persona natural que recibe el artículo"
onBlur={handleBlur}
onChange={handleChange}
value={values.firstNameBen}
name="firstNameBen"
error={Boolean(touched.firstNameBen) && Boolean(errors.firstNameBen)}
helperText={touched.firstNameBen && errors.firstNameBen}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Propósito y necesidad de la entrega del artículo (posicionamiento de marca o ayuda al paciente)"
onBlur={handleBlur}
onChange={handleChange}
value={values.propositott}
name="propositott"
error={Boolean(touched.propositott) && Boolean(errors.propositott)}
helperText={touched.propositott && errors.propositott}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Descripción del artículo (mochila, cooler, bandas elásticas, bloqueadores etc)"
onBlur={handleBlur}
onChange={handleChange}
value={values.descripciontt}
name="descripciontt"
error={Boolean(touched.descripciontt) && Boolean(errors.descripciontt)}
helperText={touched.descripciontt && errors.descripciontt}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Valor monetario del artículo individual entregado"
onBlur={handleBlur}
onChange={handleChange}
value={values.costoporpersona}
name="costoporpersona"
error={Boolean(touched.costoporpersona) && Boolean(errors.costoporpersona)}
helperText={touched.costoporpersona && errors.costoporpersona}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>




<TextField
label="Cantidad de artículos entregados"
onBlur={handleBlur}
onChange={handleChange}
value={values.cantidadarticulos}
name="cantidadarticulos"
error={Boolean(touched.cantidadarticulos) && Boolean(errors.cantidadarticulos)}
helperText={touched.cantidadarticulos && errors.cantidadarticulos}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Valor total de los artículos entregados"
onBlur={handleBlur}
onChange={handleChange}
value={values.valortotalarticulos}
name="valortotalarticulos"
error={Boolean(touched.valortotalarticulos) && Boolean(errors.valortotalarticulos)}
helperText={touched.valortotalarticulos && errors.valortotalarticulos}
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>
<Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  setUploadedFiles2(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures2", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures2 || values.pictures2.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte archivo relevante (5Mb Máximo) </p>
                    ) : (
                      <div>
                        {values.pictures2.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>

</>
)}


      </Box>






        {/* BUTTONS */}
        <Box
  display="flex"
  justifyContent="center"  // Center horizontally
  alignItems="center"      // Center vertically (optional, if needed)
>
        <Button
  fullWidth
  type="submit"
  color="secondary"
  variant="contained"
  sx={{
    borderRadius: '20px',
    m: '2rem 0',
    p: '1rem',
    width: '50%', // Set the width to 50%
    backgroundColor: theme.palette.primary.red,
    color: theme.palette.background.light,
    '&:hover': { color: theme.palette.primary.light },
  }}
>
  {<FormattedMessage id="Enviar"/>}
</Button>

            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
                  {<FormattedMessage id="Envio exitoso"/>}
                
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateTtvalue;