import { Box, IconButton, useTheme, Badge, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LogoImage from '../../assets/eticprologo1.png';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateMessages } from "../../state";
import { FormattedMessage } from 'react-intl';
import SpainFlag from '../../assets/spain.png';

const { REACT_APP_API_ENDPOINT } = process.env;

const Topbar = ({ setIsSidebar, onLanguageSelect, locale, setLocale }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedInUserContract = useSelector((state) => state.user.idcontrato);
  const userProfile = useSelector((state) => state.user.userProfile);
  const token = useSelector((state) => state.token);
  const loggedInUserEmail = useSelector((state) => state.user.email);
  const sessionStart = useSelector((state) => state.sessionStart);
  const sessionExpiration = useSelector((state) => state.sessionExpiration);
  const messages = useSelector((state) => state.messages);

  const [isLogoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const currentTime = new Date().getTime();
  const expirationTime = new Date(sessionExpiration).getTime();
  const remainingTime = Math.max(0, expirationTime - currentTime); // Ensure remaining time is non-negative
  const minutes = Math.floor(remainingTime / 60000);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const remainingSeconds = (remainingTime / 1000) % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(remainingMinutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  const sessionStartDate = new Date(sessionStart);
  const hours1 = sessionStartDate.getHours();
  const minutes1 = sessionStartDate.getMinutes();
  const seconds1 = sessionStartDate.getSeconds();
  const formattedHours1 = String(hours1).padStart(2, '0');
  const formattedMinutes1 = String(minutes1).padStart(2, '0');
  const formattedSeconds1 = String(seconds1).padStart(2, '0');
  const formattedSessionStart = `${formattedHours1}:${formattedMinutes1}:${formattedSeconds1}`;

  const pendingMessagesCount = Array.isArray(messages) ? messages.filter((message) => !message.isRead).length : 0;

  const fetchData = async () => {
    try {
      let endpoint = `${REACT_APP_API_ENDPOINT}message/mail/${loggedInUserEmail}`;
      if (userProfile === "superadmin") {
        endpoint = `${REACT_APP_API_ENDPOINT}message/`;
      } else if (userProfile === "admin" || userProfile === "coadmin") {
        endpoint = `${REACT_APP_API_ENDPOINT}message/contract/${loggedInUserContract}`;
      }
      const response = await fetch(endpoint, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const formData = await response.json();
      dispatch(updateMessages(formData));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    if (minutes === 0) {
      logout();
    }
  }, [minutes]);

  const handleNotificationClick = () => {
    navigate(`/message`);
  };

  const handleSettingsClick = () => {
    navigate(`/users`);
  };

  const handleLogoutOpen = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutClose = () => {
    setLogoutDialogOpen(false);
  };

  const handleLogoutConfirm = () => {
    handleLogoutClose();
    logout();
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = '/';
  };

  const LanguageSelector = () => {
    const handleLanguageSelect = (selectedLocale) => {
      onLanguageSelect(selectedLocale);
    };

    return (
      <Box display="flex" alignItems="center" marginLeft="10px">
        {userProfile !== "" && (
          <img
            src={SpainFlag}
            alt="Spain"
            style={{ marginRight: '5px', width: '25px', height: '20px', cursor: 'pointer' }}
            onClick={() => handleLanguageSelect('es')}
          />
        )}
      </Box>
    );
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <div style={{ position: "relative", textAlign: "center" }}>
        <img
          src={LogoImage}
          alt='Eticpro'
          className='logo-image'
          style={{ maxWidth: "200px" }}
        />
      </div>

      <Box display="flex" bgcolor="white" p={2} boxShadow={3} borderRadius={3} style={{ textAlign: "right" }}>
       
            {/* 
             <p>

          {formattedHours} <FormattedMessage id="Hrs" /> {formattedMinutes} Min
        </p> */}
        <IconButton onClick={handleNotificationClick}>
          <Badge badgeContent={pendingMessagesCount} color="error">
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
        <IconButton onClick={handleLogoutOpen}>
          <PowerSettingsNewIcon />
        </IconButton>
      {/* <LanguageSelector />*/}
      </Box>

      <Dialog open={isLogoutDialogOpen} onClose={handleLogoutClose}>
        <DialogTitle><FormattedMessage id="Cerrar Sesión" /></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="¿Está seguro de salir de Eticpro?" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutClose} color="primary">
            <FormattedMessage id="Cancelar" />
          </Button>
          <Button onClick={handleLogoutConfirm} color="primary">
            <FormattedMessage id="Salir" />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Topbar;
