import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  Paper,
  List,
  ListItem,
  ListItemText,
  
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import FlexBetween from "./FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AWS from 'aws-sdk';
import { FormattedMessage } from 'react-intl';
import LogoImage from '../assets/eticprologo8.png';
import RegSession from "./RegSession";

const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

AWS.config.update({
accessKeyId: REACT_APP_ACCESS_KEY_ID,
secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
region: "sa-east-1", // Set your AWS region
});

// Create an S3 object
const s3 = new AWS.S3();

const currentDate = new Date();
const { REACT_APP_API_ENDPOINT } = process.env;

const CreateComplaint = ({ _id }) => {
  const location = useLocation();
  const loggedinUseremail = useSelector((state) => state.user.email);
  const userId = useSelector((state) => state.user._id);
  const [logoEmp, setLogoEmp] = useState(""); // Add this state

  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [clients, setClients] = useState([]); // State to hold the list of clients
  const loggedInUserId = useSelector((state) => state.user._id);
  const [uploadedFiles, setUploadedFiles] = useState([]); // State for uploaded files
  const { palette } = useTheme();
  const [idcontrato, setIdcontrato] = useState(""); // Add this state
  const [form, setForm] = useState({ picturePath: "" }); // Initialize as an empty object 
  const [searchInput, setSearchInput] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const loggedclient = useSelector((state) => state.client);

  const [clientSelected, setClientSelected] = useState(false);
  const [tipoconflictoSelected, setTipoconflictoSelected] = useState(false);
  const [selectedClient, setSelectedClient] = useState(''); // Add this state


  const campaignSchema = yup.object().shape({
    // Define your validation schema here
    relacion: yup.string().notRequired(""),
    areacometio: yup.string().notRequired("Campo obligatorio"),
    cargocometio: yup.string().notRequired("Campo obligatorio"),
    queocurrio: yup.string().notRequired("Campo obligatorio"),
    cuandocurrio: yup.string().notRequired("Campo obligatorio"),
    desdecuando: yup.string().notRequired(""),
    dondeocurrio: yup.string().notRequired("Campo obligatorio"),
    comoevidencio: yup.string().notRequired("Campo obligatorio"),
    quiencometio: yup.string().notRequired("Campo obligatorio"),
    habladocon: yup.string().notRequired(""),
    masinfo: yup.string().notRequired(""),
    tipoconflicto: yup.string().required("Campo obligatorio"),


  });

  const initialValuesEditCampaign = {
   
    userId: loggedInUserId,
    idcontrato: idcontrato, // Update the initial value
    location:"",
    relacion:"",
    areacometio: "",
    cargocometio: "",
    queocurrio:"",
    cuandoocurrio:"",
    desdecuando:"",
    dondeocurrio: "",
    comoevidencio:"",
    quiencometio:"",
    habladocon:"",
    masinfo:"",
    tipoconflicto:"",
    picturePath:"",
    estado: "Por Evaluar",
    opauth:"",
    authtxt:"",
    emailaviso: loggedinUseremail,
    idevaluacion: "",
    dateval: "",
    selectedInvestigador: "",
    dateinv: "",
    idinvestigacion: "",
    resumeninv: "",
    dateveredicto:"",
    veredicto: "",
    detalleveredicto: "",
    idveredicto: "",

  };
  



  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}clients`, {
          method: "GET",

          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setClients(data);
          
        } else {
          // Handle error when fetching clients
          console.log("Failed to fetch clients");
        }
      } catch (error) {
        console.log(error);
        // Handle error
      }
    };

    fetchClients();
  }, [token]);


  const handleFormSubmit = async (values, onSubmitProps) => {
    let userData1; // Declare userData1 outside the try block
    let emailTemplate = ""; // Initialize emailTemplate variable
    let subject = ""; // Initialize emailTemplate variable
  setForm(uploadedFiles);
          try {
            const formData = new FormData();
         // Append existing files as File objects
         if (form.picturePath && form.picturePath.length > 0) {
          form.picturePath.forEach((file) => {
          formData.append('pictures', s3Urls); // You can store the S3 URLs in your data
          formData.append('idcontrato', idcontrato);
          formData.append('userId', loggedInUserId);
          });
        }
    
        // Append newly uploaded files to S3
        if (uploadedFiles.length > 0) {
    
              // Define the uploadFileToS3 function
              const uploadFileToS3 = async (file) => {
                AWS.config.update({
                accessKeyId: REACT_APP_ACCESS_KEY_ID,
                secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
                region: "sa-east-1", // Set your AWS region
                });
                const s3 = new AWS.S3();
    
                // Configure the S3 bucket and key where you want to upload the file
                const bucketName = 'repoeticpro';
                const uniqueSuffix = Date.now() + "-";
                const key = uniqueSuffix + file.name; // Add the unique suffix to the filename
           
                // Configure S3 upload parameters
                const params = {
                  Bucket: bucketName,
                  Key: key,
                  Body: file, // The actual file object to upload
                };
    
      // Use the AWS S3 client to upload the file to S3
      try {
        await s3.upload(params).promise();
        console.log(`File uploaded to S3: ${key}`); 
           formData.append("picturePath", key);
           console.log(`picturePath appended to formData: ${JSON.stringify(formData.picturePath)}`);

        return key; // Return the key for reference
      } catch (error) {
        console.error(`Error uploading file to S3: ${error.message}`);
        throw error;
      }

    };
    const s3Urls = await Promise.all(uploadedFiles.map(uploadFileToS3));
  }
      // Append other form data to the FormData
      formData.append("idcontrato", idcontrato);
      formData.append("location", values.location);
      formData.append("relacion", values.relacion);
      formData.append("areacometio", values.areacometio);
      formData.append("cargocometio", values.cargocometio);
      formData.append("queocurrio", values.queocurrio);
      formData.append("cuandoocurrio", values.cuandoocurrio);
      formData.append("desdecuando", values.desdecuando);
      formData.append("dondeocurrio", values.dondeocurrio);
      formData.append("comoevidencio", values.comoevidencio);
      formData.append("quiencometio", values.quiencometio);
      formData.append("habladocon", values.habladocon);
      formData.append("masinfo", values.masinfo);
      formData.append("tipoconflicto", values.tipoconflicto);
      formData.append("estado", "Por Evaluar");
      formData.append("emailaviso", loggedinUseremail);
   
   
  

      const userResponse99 = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      
      });
      if (userResponse99.status === 200) {

        userData1 = await userResponse99.json();

        const allUsers = userData1.userprofile; // Assuming user data is stored in the userprofile property
        const comiteeticaUsers = userData1.filter(user => user.userprofile === "comitetica");
        console.log("Comiteetica Users:", comiteeticaUsers);
        const comiteeticaEmails = comiteeticaUsers.map(user => user.email);
        console.log("Comiteetica emails:", comiteeticaEmails);



  subject = `Importante: Denuncia recibida`;

        const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            emails: [comiteeticaEmails, "ggardella@compliancegc.com", "sgardella@compliancegc.com"], // Properly format the emails as an array

            emailTemplate,
            subject,
            mailserver: loggedclient.mailserver,
            mailport: loggedclient.mailport,
            mailuser: loggedclient.mailuser,
            passmail: loggedclient.passmail,
            cipherstype: loggedclient.cipherstype,
          }),
        });
                  
    
            if (emailResponse.ok) {
           // Email sent successfully
           console.log(`Email sent successfully to ${comiteeticaEmails}`);
          } else {
            // Error sending email
            console.log(`Failed to send email to ${comiteeticaEmails}`);
          }
               
            const subjectRec = `Importante: Denuncia ha sido recibida`;

            const emailTemplateRec = `<!DOCTYPE html>
            <html>
            <head>
              <style>
                .frame {
                  border: 2px solid #ccc;
                  padding: 20px;
                  border-radius: 10px;
                  width: 80%;
                  margin: 0 auto;
                  text-align: left;
                  position: relative;
                }
                .logo-container {
                  position: absolute;
                  top: 10px;
                  right: 10px;
                }
                .logo-box {
                  text-align: center;
                  margin-bottom: 20px;
                }
                .box {
                  background-color: #f2f2f2;
                  padding: 10px;
                  border-radius: 5px;
                }
              </style>
            </head>
            <body>
              <div class="frame">
                <div class="logo-container">
                  <img src="${LogoImage}" alt="Logo" style="width: 100%;" />
                </div>
                <img src=${loggedclient.logo} alt="Logoemp" style="width: 15%;" /> <!-- Updated width to 10% -->
                <p>Estimado(a) Usuario,</p>
                <p>Le informamos que hemos recibido la denuncia para revisión correspondiente a la organización ${loggedclient.nombreempresa}. Le invitamos ingresar a Eticpro en nuestro link de abajo, módulo Denuncias para revisar el estado de su denuncia</p>
                <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
                <p>Para soporte escribanos a <a href="mailto:hello@eticpro.com">hello@eticpro.com</a> o por Whatsapp al +56 977 657 352.</p>
                <p>Gracias por su atención y cooperación.</p>
                <p>Equipo Encargado de Prevención de Delitos</p>
                <p>Eticpro.</p>
                <p><a href="www.eticpro.com">www.eticpro.com</a></p>
              </div>
            </body>
            </html>
            `;
            
            const requestBody = {
              emails: [loggedinUseremail, "ggardella@compliancegc.com", "sgardella@compliancegc.com", "hadesdes@gmail.com"], // Properly format the emails as an array
              emailTemplate: emailTemplateRec,
              subject: subjectRec,
              mailserver: loggedclient.mailserver,
              mailport: loggedclient.mailport,
              mailuser: loggedclient.mailuser,
              passmail: loggedclient.passmail,
              cipherstype: loggedclient.cipherstype,
            };
            
            console.log("Request Body:", requestBody);
            
            const emailResponseexterno = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(requestBody),
            });
        
                if (emailResponseexterno.ok) {
                  // Email sent successfully
                  console.log(`Email sent successfully to ${loggedinUseremail}`);
                } else {
                  // Error sending email
                  console.log(`Failed to send email to ${loggedinUseremail}`);
                }
                     


      }


      // Example of saving user data using fetch
      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}complaints`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      if (userResponse.ok) {
        setShowSuccessPopup(true);
        setTimeout(() => {
          setShowSuccessPopup(false);
        }, 5000);
  
        onSubmitProps.resetForm();
      } else {
        console.error(
          "Error storing complaint data:",
          userResponse.status,
          userResponse.statusText
        );
      }

 

      onSubmitProps.resetForm();
      navigate("/successPage", {
        state: { message: "Denuncia creada con éxito" }
    });             
    } catch (err) {
      console.log("Error creating complaint:", err);
  
      if (err.response) {
        console.log("Response status:", err.response.status);
  
        // Use the .text() method to get the response body as text
        err.response.text().then(responseText => {
          console.log("Response data:", responseText);
        });
      }
    }
  };
  

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);
    if (inputValue.length >= 3) {
      const filtered = clients.filter(client =>
        client.nombreempresa.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredClients(filtered);
    } else {
      setFilteredClients([]);
     
    }
  };


  const handleClientSelect = async (client) => {
    try {
      setSelectedClient(client.nombreempresa);
      setSearchInput(client.nombreempresa);
      setFilteredClients([]);
  
      // Generate token
      const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });
  
      const { token } = await tokenResponse.json();
  
      // Fetch client data based on nombreempresa
      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}clients/nombre/${client.nombreempresa}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (userResponse.status === 200) {
        const userData = await userResponse.json();
        console.log('Fetched contract data:', userData);
  
        // Set idcontrato from userData
        setIdcontrato(userData[0]._id);
        setLogoEmp(userData[0].logo);

        setClientSelected(true); // Mark client as selected


      } else {
        console.error('Failed to fetch user data:', userResponse.status);
      }
  
  
    } catch (error) {
      console.error('An error occurred:', error);
      alert('An unexpected error occurred. Please try again later.'); // Fallback error message
    }
  };

  return (

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
 <p></p>
 
<Box>

  
  {/* New selection field for clients */}
  <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>

  <Box>
                  <Typography variant="body2" color="textSecondary">
                    {searchInput.length < 3 && "Por favor introduzca al menos 3 caracteres."}
                  </Typography>
                  <TextField
                    label={<FormattedMessage id="Organización donde hará la Denuncia" />}
                    onChange={handleSearchInputChange}
                    value={searchInput}
                    fullWidth
                    sx={{
                      marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
                        background: 'white',
                      },
                    }}
                  />
                  {filteredClients.length > 0 && (
                    <Paper>
                      <List>
                        {filteredClients.map((client) => (
                          <ListItem key={client._id} button onClick={() => handleClientSelect(client)}>
                            <ListItemText primary={client.nombreempresa} />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  )}
                  <Box display="flex" justifyContent="center">
                  

                 

                  </Box>
                </Box>



              {touched.clientId && errors.clientId && (
                <Typography variant="body2" color="error">
                    {errors.nombreempresa}
                </Typography>
              )}
            </FormControl>
          </Box>

          <p></p>

        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{ borderRadius: "20px",
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          


          <FormControl>
      <InputLabel htmlFor="tipoconflicto">{<FormattedMessage id="Evento"/>}</InputLabel>
      <Select
        id="tipoconflicto"
        name="tipoconflicto"
        value={values.tipoconflicto || ''}
        onChange={handleChange}
      >
        <MenuItem value="Conflicto de Interés">{<FormattedMessage id="Conflicto de Interés"/>}</MenuItem>
        <MenuItem value="Abuso o Acoso o Discriminación">{<FormattedMessage id="Abuso, Acoso o Discriminación"/>}</MenuItem>
        <MenuItem value="Delitos P. Jurídica">{<FormattedMessage id="Delitos de responsabilidad penal de persona jurídica"/>}</MenuItem>
        <MenuItem value="Relaciones Partes Interesadas">{<FormattedMessage id="Relaciones con partes interesadas"/>}</MenuItem>
        <MenuItem value="Violencia en el lugar de trabajo">{<FormattedMessage id="Violencia en el lugar de trabajo"/>}</MenuItem>
        <MenuItem value="Robo Recursos.">{<FormattedMessage id="Robo, fraude o mal uso recursos"/>}</MenuItem>
        <MenuItem value="Libre Competencia.">{<FormattedMessage id="Libre competencia"/>}</MenuItem>
        <MenuItem value="Proteccion Información.">{<FormattedMessage id="Protección de confidencialidad de información"/>}</MenuItem>

        <MenuItem value="Otro">{<FormattedMessage id="Otro"/>}</MenuItem>
      </Select>
    </FormControl>

        <TextField
            label={<FormattedMessage id="Area a denunciar"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.areacometio}
            name="areacometio" // Match the field name with the schema
            error={Boolean(touched.areacometio) && Boolean(errors.areacometio)}
            helperText={touched.areacometio && errors.areacometio}
            multiline
            fullWidth
            sx={{
              borderRadius: "20px",
              gridColumn: "span 4",
              '& input': {
                background: 'white', // Set the background color to white
              },
            }}
          />

<TextField
            label={<FormattedMessage id="Cargo a denunciar"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargocometio}
            name="cargocometio"
            error={Boolean(touched.cargocometio) && Boolean(errors.cargocometio)}
            helperText={touched.cargocometio && errors.cargocometio}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />




<TextField
            label={<FormattedMessage id="Relación con la organización que denuncia"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.relacion}
            name="relacion"
            error={Boolean(touched.relacion) && Boolean(errors.relacion)}
            helperText={touched.relacion && errors.relacion}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Qué ocurrió?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.queocurrio}
            name="queocurrio"
            error={Boolean(touched.queocurrio) && Boolean(errors.queocurrio)}
            helperText={touched.queocurrio && errors.queocurrio}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Cuándo ocurrió?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cuandoocurrio}
            name="cuandoocurrio"
            error={Boolean(touched.cuandoocurrio) && Boolean(errors.cuandoocurrio)}
            helperText={touched.cuandoocurrio && errors.cuandoocurrio}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Desde cuándo cree usted que ha estado ocurriendo?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.desdecuando}
            name="desdecuando"
            error={Boolean(touched.desdecuando) && Boolean(errors.desdecuando)}
            helperText={touched.desdecuando && errors.desdecuando}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Dónde ocurrió este incidente o infracción específicamente?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.dondeocurrio}
            name="dondeocurrio"
            error={Boolean(touched.dondeocurrio) && Boolean(errors.dondeocurrio)}
            helperText={touched.dondeocurrio && errors.dondeocurrio}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Cómo se dio cuenta de cuenta de esta situación?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.comoevidencio}
            name="comoevidencio"
            error={Boolean(touched.comoevidencio) && Boolean(errors.comoevidencio)}
            helperText={touched.comoevidencio && errors.comoevidencio}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />



<TextField
            label={<FormattedMessage id="¿Puede identificar quien(es) han cometido estos hechos, detalle nombres si es así?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.quiencometio}
            name="quiencometio"
            error={Boolean(touched.quiencometio) && Boolean(errors.quiencometio)}
            helperText={touched.quiencometio && errors.quiencometio}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label={<FormattedMessage id="Indique si ha hablado con alguien de esto o denunciado el hecho"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.habladocon}
            name="habladocon"
            error={Boolean(touched.habladocon) && Boolean(errors.habladocon)}
            helperText={touched.habladocon && errors.habladocon}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label={<FormattedMessage id="Otra información que complemente lo anterior"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.masinfo}
            name="masinfo"
            error={Boolean(touched.masinfo) && Boolean(errors.masinfo)}
            helperText={touched.masinfo && errors.masinfo}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />



        
        </Box>
    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
   <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  acceptedFiles.forEach((file) => {
                  });
                  setUploadedFiles(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures || values.pictures.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>{<FormattedMessage id="Agregue aquí los Archivos, máximo 5Mb"/>}</p>
                    ) : (
                      <div>
                        {values.pictures.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>




                </Box>
          
        {/* BUTTONS */}
        <Box>
        <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              {<FormattedMessage id="Enviar"/>}
            </Button>
            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
                              {<FormattedMessage id= "Envio Exitoso"/>}

              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateComplaint;