import { Box, Typography, Checkbox, useTheme, Slider, IconButton, Button, Modal, TextField } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";
import { Document, Page } from 'react-pdf';
import Grid from "@mui/material/Grid";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import pdficon from '../../assets/pdf-icon.png';
import SearchIcon from "@mui/icons-material/Search";

import Header from "../../components/Header";
import { useMediaQuery } from "@mui/material";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import FlexBetween from "../../components/FlexBetween";
import { useParams } from "react-router-dom";
import Upload from "@mui/icons-material/Mail";
import CreateClient from "../../components/CreateClient";
import Paper from '@mui/material/Paper'; // Import the Paper component
import { Print } from '@mui/icons-material';
import ppticon from "../../assets/ppt-icon.png";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import DropfileRepoBP from '../../components/DropfilesRepoBP';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { FormattedMessage } from 'react-intl';
import { useReactToPrint } from "react-to-print";
import { pdfjs } from 'react-pdf';

import jsPDF from "jspdf";
import "jspdf-autotable";

import Certificon from '@mui/icons-material/Grading';
import PdfViewerModal from "../../components/pdfviewer"; //modal checklist viewer
import PptViewerModal from "../../components/pptViewerModal"; //modal for PPT viewer
import ViewIcon from '../../assets/view-icon.png';

import GroupIcon from '@mui/icons-material/PendingActions';
import PdfCertModalnon from "../../components/pdfcertchecknon"; //modal pendiente por file
import PdfCertModalTotalNon from "../../components/pdfcertchecknontotal"; //modal pendientes total
import PdfCertModalTotal from "../../components/pdfcertchecktotal";// modal leidos total
import PdfCertModal from "../../components/pdfcertcheck";// modal leidos por file
import Chatbot from '../../components/Chatbot';

import StatBoxnom from "../../components/StatBoxnom";   

import image from "../../components/pdfcertcheck";// modal leidos por file
import PersonAddIcon from "@mui/icons-material/PersonAdd";


const Files = ({ }) => {

  const [textStyleColor, setTextStyleColor] = useState('red'); // Default color

  const [sessionDatax, setSessionDatax] = useState({});
  const userProfile = useSelector((state) => state.user.userprofile);
  const loggedinusercontract = useSelector((state) => state.user.idcontrato);

  const componentRef = useRef();
  const [showPdfPopup, setShowPdfPopup] = useState(false);
  const [confirmation, setConfirmation] = useState(null); // null: initial state, "si", or "no"
  const [pageNumber, setPageNumber] = useState(1);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [openPptModal, setOpenPptModal] = useState(false);
  const [client, setClient] = useState([]);

  const [currentPdf, setCurrentPdf] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [agreement, setAgreement] = useState(false);

const location = useLocation();
const idcontrato = useSelector((state) => state.user.idcontrato);
const [editedRows, setEditedRows] = useState({});
const [showCreateClient, setShowCreateClient] = useState(false);
const { _id, picturePath, email } = useSelector((state) => state.user);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedinuserprofile = useSelector((state) => state.user.userprofile); 
  const [form, setForm] = useState({ picturePath: [] }); // Initialize as an empty object with pictures array
  const [showLibrary, setShowLibrary] = useState(false);

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT, REACT_APP_API_ENDPOINTBP  } = process.env;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dateRange, setDateRange] = useState([0, 100]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [totalDescriptionsCount, setTotalDescriptionsCount] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
    const [arnombre, setArnombre] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [matchingFile, setMatchingFile] = useState(null);
    const [nonMatchingUsers, setNonMatchingUsers] = useState([]);
    const [nonMatchingFiles, setNonMatchingFiles] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [indexText, setIndexText] = useState([]);

      const s3BaseUrl = 'https://repobatonpass.s3.amazonaws.com';

   // Checkbox states with default checked values
   const [pptChecked, setPptChecked] = useState(true);
   const [docChecked, setDocChecked] = useState(true);
   const [pdfChecked, setPdfChecked] = useState(true);
   const [xlsChecked, setXlsChecked] = useState(true);
   const [pngChecked, setPngChecked] = useState(true);
   const [file, setFile] = useState(null);
   const [ticketData, setticketData] = useState([]);
   const [productoCliente, setProductClient] = useState(0);
   const [totalWordCount, setTotalWordCount] = useState(0);
   const [price, setPrice] = useState(0);
  // Fetch data effect

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseRepository = await fetch(`${REACT_APP_API_ENDPOINTBP}repository/contract/${idcontrato}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseSession = await fetch(`${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!responseRepository.ok || !responseSession.ok) {
          throw new Error(`One or more API requests failed`);
        }

        const formData = await responseRepository.json();
        const sessionData = await responseSession.json();

        // Format and set the data state
        const dataWithFormattedDate = formData.map((row) => ({
          ...row,
          id: row._id, // Assuming _id is in formData
          createdAt: row.createdAt
            ? new Date(row.createdAt).toLocaleDateString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
              })
            : "",
        }));
        setData(dataWithFormattedDate);
        setSessionDatax(sessionData);
      } catch (error) {
        console.error("Error fetching session data:", error);
      }
    };

    fetchData();
  }, [idcontrato, token, REACT_APP_API_ENDPOINT]);


  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchInput(event.target.value.toLowerCase());
  };
  

// Effect to apply search filter whenever search input changes or data updates

useEffect(() => {
  const filteredResults = data.filter((item) => {
    const extension = item.picturePath.split('.').pop().toLowerCase();
    const matchesSearch = item.textExtracted.toLowerCase().includes(searchInput);

    // Check if the file extension matches any selected checkbox
    const extensionMatches =
      (pptChecked && (extension === 'ppt' || extension === 'pptx')) ||
      (docChecked && (extension === 'doc' || extension === 'docs' || extension === 'docx')) ||
      (pdfChecked && extension === 'pdf') ||
      (pngChecked && extension === 'jpg') ||
      (pngChecked && extension === 'png') ||
      (xlsChecked && (extension === 'xls' || extension === 'xlsx'));

    // Return true if both extension and search matches
    return extensionMatches && (matchesSearch || searchInput === '');
  });

  // Check if searchInput is blank and data is loaded for the first time
  const isInitialLoad = data.length > 0 && searchInput === '';

  // Set filtered data only if not the initial load and there is a searchInput or matches found
  if (!isInitialLoad || searchInput !== '') {
    setFilteredData(filteredResults);
  } else {
    setFilteredData([]);
  }
}, [data, searchInput, pptChecked, docChecked, pdfChecked, xlsChecked]);

  
  // Handle file click to open viewer or perform actions
  const handleOpenDocument = (file, isMatchingUser, isMatchingFileName) => {
    if (!file || !file.picturePath) {
      console.error("File object is empty or does not contain picturePath.");
      return;
    }
    const fileExtension = file.picturePath.split(".").pop().toLowerCase();

    if (fileExtension === "pdf") {
      setOpenPdfModal(true);
    } else if (fileExtension === "ppt" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "pptx" || fileExtension === "doc" || fileExtension === "docx" || fileExtension === "xls" || fileExtension === "xlsx") {
      setOpenPptModal(true);
    } else {
      console.error("No soporta este tipo de archivos para abrir");
    }

    const s3Url = `${s3BaseUrl}/${encodeURIComponent(file.picturePath)}`;
    setCurrentPdf(s3Url);
    setTextStyleColor(isMatchingUser && isMatchingFileName ? 'green' : 'red');
    setCurrentFile(file);
    setFileType(fileExtension);
  };


  






    const styles = {
      // Define the rounded corners style for the box
      roundedBox: {
        width: "30%", // Make the box 50% wide
        borderRadius: "10px", // Adjust the value to control the roundness
        padding: "10px", // Add some padding for spacing
        backgroundColor: "white", // Set the background color to white
      },
      roundedInput: {
        borderRadius: "10px", // Adjust the value to control the roundness
      },
      sliderContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    };

    const handleDateFilterChange = (event, newValue) => {
      setDateRange(newValue);
    };



  const fetchData = async () => {
    try {
      const responseRepository = await fetch(`${REACT_APP_API_ENDPOINTBP}repository/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const responseSession = await fetch(`${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!responseRepository.ok || !responseSession.ok) {
        throw new Error(`One or more API requests failed`);
      }
  
      const formData = await responseRepository.json();
      const sessionData = await responseSession.json();
      setSessionDatax(sessionData);
    // Log the fileName after successful data fetch

     // Format and set the data state
const dataWithFormattedDate = formData.map((row, index) => ({
  ...row,
  id: row._id, // Assuming _id is in formData
  createdAt: row.createdAt
    ? new Date(row.createdAt).toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })
    : "",
}));

setData(dataWithFormattedDate);
  } catch (error) {
    console.error("Error fetching session data:", error);
  }
};
  


  const getFileIcon = () => {
    switch (file.type) {
      case 'pdf':
        return <PictureAsPdfIcon />;
      case 'ppt':
        return <SlideshowIcon />;
      default:
        return <InsertDriveFileIcon />;
    }
  };


  
  const handleConfirmadosClick = async () => {
    try {
      // Fetch sessions for the specific contract
      const responseSession = await fetch(`${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!responseSession.ok) {
        console.error("Failed to fetch sessions");
        return;
      }
  
      const sessionData = await responseSession.json();
  
      // Fetch additional user information for each session
      const matchingUsersPromises = sessionData.map(async (session) => {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}users/${session.userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = await response.json();
  
        return {
          userId: session.userId,
          firstName: userData?.firstName || '', // Use optional chaining (?.) here
          lastName: userData?.lastName || '',
          rut: userData?.rut || '',
          email: userData?.email || '',
          filenombre: session.fileName || '',
          occupation: userData?.occupation || '',
          createdAt: session.createdAt,
        };
      });
  

      const matchingUsers = await Promise.all(matchingUsersPromises);
      // Filter out users with undefined or empty filenombre
        const filteredUsers = matchingUsers.filter(user => user.filenombre);

    
        // Set the state with filtered users
        setFilteredUsers(filteredUsers);
        setPrintModalTotalOpen(true); // Open PdfViewerModal
    } catch (error) {
      console.error('Error handling Confirmados:', error);
    }
  };
  

  const handleCertificonClickTotalNon = async () => {
    try {
      // Fetch all users for the specific contract
      const responseUsers = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!responseUsers.ok) {
        console.error("Failed to fetch users");
        return;
      }
  
      const userData = await responseUsers.json();
  
      // Fetch sessions for the specific contract
      const responseSession = await fetch(`${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!responseSession.ok) {
        console.error("Failed to fetch sessions");
        return;
      }
  
      const sessionData = await responseSession.json();
  
      // Check for null or undefined before filtering
      if (userData === null || sessionData === null) {
        console.error("User data or session data is null");
        return;
      }
  
      // Get all user IDs from sessions with confirmed files
      const confirmedUsers = sessionData.map((session) => session.userId);
  
      // Filter out users who are not in confirmed sessions
      const nonMatchingUsers = userData.filter((user) => user._id && !confirmedUsers.includes(user._id));
  
      // Set the state with non-matching users and open the modal
      setFilteredUsers(nonMatchingUsers);
      setOpenPdfModalTotalNon(true);
    } catch (error) {
      console.error('Error handling CertificonClickTotalNon:', error);
    }
  };
  

const handleCertificonClick = async (file) => {
  try {
    setPrintModalOpen(true);
    setCertificateContent(file.picturePath);
    setArnombre(file.picturePath);
    setIndexText(file.indexText);
    // Filter the sessions based on the file name
    const filteredSessions = sessionDatax.filter((session) =>
      session.fileName && file.picturePath &&
      session.fileName.toLowerCase() === file.picturePath.toLowerCase()
    );

    // Fetch additional user information for each matching session
    const matchingUsersPromises = filteredSessions.map(async (session) => {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}users/${session.userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const userData = await response.json();

      return {
        userId: session.userId,
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        rut: userData.rut || '',
        email: userData.email || '',
        occupation: userData.occupation || '',
        createdAt: session.createdAt,
      };
    });

    // Wait for all promises to resolve
    const matchingUsers = await Promise.all(matchingUsersPromises);

    // Set the state with matching users and file
    setFilteredUsers(matchingUsers);
    setMatchingFile(file);
  } catch (error) {
    console.error('Error handling CertificonClick:', error);
  }
};

const handleCloseModal = () => {
  setOpenPdfModal(false);
  setOpenPptModal(false);
  setCurrentFile(null);
  setFileType(null);
};  

  const handleCertificonClickNon = async (file) => {
    setOpenPdfModalNon(true);
    setCertificateContent(file.picturePath);

    // Fetch all users for the specific contract
    const responseUsers = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!responseUsers.ok) {
      console.error("Failed to fetch users");
      return;
    }

    const userData = await responseUsers.json();

    // Fetch sessions for the specific contract
    const responseSession = await fetch(`${REACT_APP_API_ENDPOINT}session/contract/${idcontrato}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!responseSession.ok) {
      console.error("Failed to fetch sessions");
      return;
    }

    const sessionData = await responseSession.json();

    // Check for null or undefined before filtering
    if (userData === null || sessionData === null) {
      console.error("User data or session data is null");
      return;
    }

    // Get all user IDs from sessions with the specified file name
    const usersInSessions = sessionData
      .filter((session) => session.fileName && session.fileName.toLowerCase() === file.picturePath.toLowerCase())
      .map((session) => session.userId);

    // Filter out users who are not in sessions

    
    const nonMatchingUsers = userData
    .filter((user) => user._id && !usersInSessions.includes(user._id));


    // Set the state with non-matching users and file
    setFilteredUsers(nonMatchingUsers);
    setMatchingFile(file);

    // Open PdfCertModalnon
    setOpenPdfModalNon(true);
  };

  // Make sure you have the state for PdfCertModalnon
  const [openPdfModalNon, setOpenPdfModalNon] = useState(false);
  const [openPdfModalTotalNon, setOpenPdfModalTotalNon] = useState(false);
  const [openPdfModalTotal, setOpenPdfModalTotal] = useState(false);

  

  const handleClosePdfModal = () => {
    setOpenPdfModal(false);

setOpenPptModal(false);
    setCurrentPdf(null);
  };

  const [certificateContent, setCertificateContent] = useState('');
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [printModalTotalOpen, setPrintModalTotalOpen] = useState(false);
  const [printModalTotalOpenNon, setPrintModalTotalOpenNon] = useState(false);


  const handleClosePrintModal = () => {
    setPrintModalOpen(false);
    setPrintModalTotalOpenNon(false);
    setPrintModalTotalOpen(false);

  };

  const toggleLibrary = () => {
    setShowLibrary(!showLibrary);
  };
  const handleFileUploaded = async () => {
    try {
      // Perform actions after file upload success, such as fetching updated data
      setShowSuccessPopup(true); // Optionally show a success message or trigger any related UI updates
    } catch (error) {
      console.error('Error handling file upload:', error);
      // Handle errors as needed
    }
  };

// Function to highlight search term in text and extract context
const highlightAndExtractContext = (text, searchTerm) => {
  if (!searchTerm.trim()) return text; // Return original text if search term is empty

  const words = text.split(' ');
  const index = words.findIndex(word => word.toLowerCase().includes(searchTerm.toLowerCase()));

  if (index === -1) {
    return text; // Return original text if search term is not found
  }

  // Extract 5 words before and after the search term
  const start = Math.max(0, index - 30);
  const end = Math.min(words.length, index + 30); // +6 because end is exclusive

  const contextWords = words.slice(start, end);

  // Highlight search term
  const highlightedText = contextWords.map(word => {
    if (word.toLowerCase().includes(searchTerm.toLowerCase())) {
      return `<mark>${word}</mark>`;
    } else {
      return word;
    }
  }).join(' ');

  return highlightedText;
};


  const handleRowClick = (params, action) => {
    const rowId = params.row._id;
  
    if (action === "edit") {
      const { row, field } = params;
      const updatedRow = { ...row, [field]: params.value };
  
      // Update the local state immediately for a responsive user experience
      setData((prevData) => {
        const rowIndex = prevData.findIndex((r) => r.id === rowId);
        const newData = [...prevData];
        newData[rowIndex] = { ...newData[rowIndex], ...updatedRow };
        return newData;
      });
  
      // Save the changes to the server in the background
      saveRowChanges(rowId, updatedRow);
    } else if (action === "delete") {
      // Add code to delete the row from the database using the rowId
      deleteRow(rowId);
    }
  };

  const AWS = require('aws-sdk');

// Load your AWS credentials from environment variables
  AWS.config.update({
  
  accessKeyId: process.env.AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
  region: "sa-east-1", // Set your AWS region
  });

  const s3 = new AWS.S3();

 const FileItem = ({ file, onDelete, onCertificonClickNon, onCertificonClick, documentNumber, userId, sessionFileName, arnombre, indexText, sessionData, textExtracted }) => {
  const [isMatchingUser, setIsMatchingUser] = useState(false);
  const [isMatchingFileName, setIsMatchingFileName] = useState(false);

  useEffect(() => {
    // Set isMatchingUser and isMatchingFileName based on conditions
    const newUserMatch = userId === loggedInUserId;
    const newFileNameMatch = sessionFileName.some(item =>
      item.fileName && file.picturePath &&
      item.fileName.toLowerCase() === file.picturePath.toLowerCase()
    );

    setIsMatchingUser(newUserMatch);
    setIsMatchingFileName(newFileNameMatch);



  }, [userId, loggedInUserId, sessionFileName, file, textExtracted, indexText,  textStyleColor]);

  const textStyle = {
    color: isMatchingUser && isMatchingFileName ? 'green' : 'red',
    // Add other styling properties if needed
  };
  
    

    const handleDownload = () => {
      const s3BaseUrl = 'https://repobatonpass.s3.amazonaws.com';
      const s3URL = `${s3BaseUrl}/${encodeURIComponent(file.picturePath)}`;

      const link = document.createElement('a');
      link.href = s3URL;
      link.setAttribute('download', file._id);
      document.body.appendChild(link); // Append to body to ensure it works across all browsers
      link.click();
      link.remove(); // Clean up after triggering the download
      // Async IIFE to log the download
      (async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}session/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              userId: loggedInUserId,
              fileName: file.picturePath, // Assuming file._id is the identifier for the file
              type: "Download",
              idcontrato: idcontrato,
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to log download');
          }

          // Optionally handle the successful response
        } catch (error) {
          console.error('Error logging download:', error);
        }
      })();
    };

    
    return (
      <Box
      display="grid"
      gridTemplateColumns="min-content auto min-content min-content min-content"
      alignItems="center"
      gap={2}
      sx={{
        width: '100%', // Occupy full horizontal space
        borderBottom: '1px solid #ccc', // Optional border for visual separation
        padding: '10px', // Internal padding for better readability
      }}
    >
      <Typography sx={{ minWidth: '20px', textAlign: 'center' }}>{file.documentNumber}</Typography>
      <div>
        <a>
          <InsertDriveFileIcon /> {file.picturePath}
        </a>
        <p></p>
        <Typography
          sx={{
            maxWidth: '600px', // Maximum width for indexText
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal', // Allow text to wrap to multiple lines
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 5, // Limit to 5 lines
            overflow: 'hidden',
            marginBottom: '10px', // Space between text and other elements
          }}
          title={file.indexText} // Show full text in tooltip on hover
          dangerouslySetInnerHTML={{ __html: highlightAndExtractContext(file.textExtracted, searchInput) }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
    
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {(loggedinuserprofile === 'adminx' || loggedinuserprofile === 'superadminx') && (
            <IconButton onClick={() => onCertificonClickNon(file)}>
              <GroupIcon style={{ width: 20, height: 20 }} />
            </IconButton>
          )}
          {(loggedinuserprofile === 'adminx' || loggedinuserprofile === 'superadminx') && (
            <IconButton onClick={() => onCertificonClick(file)}>
              <Certificon style={{ width: 20, height: 20 }} />
            </IconButton>
          )}
          <IconButton onClick={() => handleOpenDocument(file)}>
            <img src={ViewIcon} alt="PDF Icon" style={{ width: 20, height: 20 }} />
          </IconButton>
          <Typography>{file.createdAt}</Typography>
        </div>
      </div>
      <div>
      </div>
      <div>
        {(loggedinuserprofile === 'admin' || loggedinuserprofile === 'superadmin') && (
          <IconButton onClick={() => onDelete(file._id)}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </Box>
    
    );
  };

useEffect(() => {

  setShowSuccessPopup(false);
  setSnackbarOpen(false);
  fetchData();
  getForm(); // Cargar los datos iniciales al montar el componente

}, [isFormSubmitted, dateRange, uploadedFiles]);


const formatDateToEsES = (dateString) => {
  if (!dateString) return "";

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const esESDate = new Date(dateString).toLocaleDateString('es-ES');

  return esESDate;
};
const countWords = (text) => {
  return text.trim().split(/\s+/).length;
};
const getForm = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_ENDPOINTBP}kb/contract/${loggedinusercontract}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();

     

  
    } else {
      console.error('Failed to fetch updated complaint data');
    }
  } catch (error) {
    console.error('Error fetching updated complaint data:', error);
  }
};


  const deleteRow = async (rowId) => {
    try {
      // Retrieve the file details from the database based on rowId
      const fileDetails = data.find((row) => row.id === rowId);

      if (!fileDetails) {
        console.error('File details not found');
        return;
      }

      // Remove the file from Amazon S3
      const s3BucketURL = 'https://repobatonpass.s3.amazonaws.com'; // Use the correct endpoint
      const s3URL = `${s3BucketURL}/${encodeURIComponent(fileDetails.picturePath)}`;

      const params = {
        Bucket: "repobatonpass", // Replace with your S3 bucket name
        Key: fileDetails.picturePath, // Replace with the S3 key (path) of the file
      };

      await s3.deleteObject(params).promise(); // Delete the file from S3

      // Send a DELETE request to delete the database record
      await fetch(`${REACT_APP_API_ENDPOINTBP}repository/${rowId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the data and filteredSearchData states after deleting the row
      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
      setFilteredSearchData(updatedData);
    } catch (error) {
      console.error('Error deleting row:', error);
    }
  };


  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };

  const columns = [

    {
      field: "picturePath",
      headerName: <FormattedMessage id="Archivo"/>,
      flex: 1,
      editable: userProfile === "superadmin" || userProfile === "admin" , // Allow editing if user is an admin
    headerClassName: "bold-header",
  },
    {
      field: "indexText",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Resumen"/>,
      flex: 1,
      editable: userProfile === "superadmin" || userProfile === "admin" , // Allow editing if user is an admin
    },
  


    {
      field: "updatedAt",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Actualizado"/>,
      flex: 1,
      valueFormatter: (params) => formatDateToEsES(params.value), // Use the custom formatter
    },
    
    {
      field: "user.firstName", 
      headerName: <FormattedMessage id="Creador"/>,
      flex: 1, 
      valueGetter: (params) => `${params.row.user.firstName} ${params.row.user.lastName}`,
      headerClassName: "bold-header",
    },
    
    {
      field: "metadata1",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Tipo"/>,
      flex: 1,
    },
    
    {
      field: "actions",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Action"/>,
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleRowClick(params, "edit")}
          >
            <EditIcon />
          </IconButton>
          <IconButton
        onClick={() => handleOpenDocument(params.row)} // Pass params.row to access the entire row data
      >
            <img src={ViewIcon} alt="PDF Icon" style={{ width: 20, height: 20 }} />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleRowClick(params, "delete")}
          >
            <DeleteIcon />
          </IconButton>
          
        </>
      ),
    },

  ];

  return (
    <Box m="20px">
     


          <Header title={<FormattedMessage id="Knowledge Base" />} subtitle={<FormattedMessage id="Base de conocimientos" />} />
   
          <Box
      sx={{
        display: "flex",
        alignItems: "left", // Centrar el icono y el input verticalmente
        width: "100%", // Hacer que el Box interno ocupe el 100% del ancho disponible
        maxWidth: "400px", // Limitar el ancho máximo para mantener la estética
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "8px", // Ajustar el padding para el espaciado
        boxSizing: "border-box", // Asegurarse de que el padding se incluya en el ancho total
      }}
    >
      <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
      <TextField
        label=""
        value={searchInput}
        onChange={handleSearchInputChange}
        InputProps={{
          style: {
            borderRadius: "20px", // Redondear las esquinas del TextField
            width: "100%", // Hacer que el input ocupe el 100% del ancho dentro del Box
            height: "100%", // Establecer la altura del TextField al 100%
          },
        }}
        sx={{
          height: "100%", // Establecer la altura del TextField al 100%
          width: "100%", // Hacer que el TextField ocupe el 100% del ancho
        }}
      />
    </Box>
    <Box sx={{ borderRadius: "20px", mt: '1rem', display: 'flex', justifyContent: 'center' }}>
      



<p> </p>
{loggedinuserprofile === 'adminx' || loggedinuserprofile === 'superadminx' && (

<Button
  variant="contained"
  startIcon={<Print/>}
  onClick={handleCertificonClickTotalNon}
  sx={{ borderRadius: "20px",
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[900],
                  fontSize: "14px",
                  fontWeight: "bold",
                  paddingY: "10px",  // Adjust vertical padding as needed
                  paddingX: "10px", // Add horizontal padding
                }}
              
>
  {<FormattedMessage id="Usuarios Sin Sesiones"/>}
</Button>
)}
<p></p>

<Grid item xs={12} sm={12} md={12}>
  {/* Wrap the Search input in a Box */}
 
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Ajustar el padding para el espaciado
      display: "flex",
      alignItems: "center", // Centrar el icono y el input verticalmente
      justifyContent: "center", // Centrar el contenido horizontalmente
      height: "60%", // Establecer la altura del Box al 60%
      width: "100%", // Asegurarse de que el contenedor ocupe el 100% del ancho disponible
      boxSizing: "border-box", // Asegurarse de que el padding se incluya en el ancho total
    }}
  >
   
  </Box>
</Grid>

<p></p>



<PdfViewerModal
  open={openPdfModal}
  onClose={handleClosePdfModal}
  pdfUrl={currentPdf}
  filename={arnombre}
  textStyleColor={textStyleColor}
/>
<PptViewerModal
        open={openPptModal}
        onClose={handleClosePdfModal}
        pdfUrl={currentPdf}
        filename={arnombre}
        textStyleColor={textStyleColor}
      />
 

  <PdfCertModal
        open={printModalOpen}
        onClose={handleClosePrintModal}
        file={arnombre}
        users={filteredUsers}
        matchingFile={matchingFile}
      />

  <PdfCertModalTotal
        open={printModalTotalOpen}
        onClose={() => handleClosePrintModal(false)}
        file={arnombre}
        users={filteredUsers}
        matchingFile={matchingFile}
      />
      
      <PdfCertModalnon
        open={openPdfModalNon}
        onClose={() => setOpenPdfModalNon(false)}
        file={arnombre}
        users={filteredUsers}
        matchingFile={matchingFile}
        nonMatchingUsers={nonMatchingUsers} // Pass non-matching users here
      />

<PdfCertModalTotalNon
       open={openPdfModalTotalNon}
       onClose={() => setOpenPdfModalTotalNon(false)}
       file={arnombre}
       users={filteredUsers}
       matchingFile={matchingFile}
       nonMatchingUsers={nonMatchingUsers} // Pass non-matching users her
      />
  <p></p>
</Box>
<Box>
{/* Checkboxes */}
<label>
        <input
          type="checkbox"
          checked={pptChecked}
          onChange={(e) => setPptChecked(e.target.checked)}
        />
        ppt/pptx
      </label>
      <label>
        <input
          type="checkbox"
          checked={docChecked}
          onChange={(e) => setDocChecked(e.target.checked)}
        />
        doc/docs
      </label>
      <label>
        <input
          type="checkbox"
          checked={pdfChecked}
          onChange={(e) => setPdfChecked(e.target.checked)}
        />
        pdf
      </label>
      <label>
        <input
          type="checkbox"
          checked={xlsChecked}
          onChange={(e) => setXlsChecked(e.target.checked)}
        />
        xls/xlsx
      </label>
       <label>
        <input
          type="checkbox"
          checked={pngChecked}
          onChange={(e) => setPngChecked(e.target.checked)}
        />
        jpg/png
      </label>
      </Box>
    <div>
    {(loggedinuserprofile === 'admin' || loggedinuserprofile === 'superadmin')  && (

<Box m="10px" display="flex" justifyContent="space-between">
  <Paper elevation={3} style={{ ...widgetStyle, margin: "5px", padding: "10px", height: "200px" }}>
    <DropfileRepoBP _id={_id} onFileUploaded={handleFileUploaded} dropFileBoxStyle={{ padding: "5px", fontSize: "0.8rem" }} />
  </Paper>
</Box>


       )}
    </div>
  
    <Box m="40px 0 0 0" justifyContent="space-between">

    <Paper elevation={1} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <div>
        {filteredData.map((file, index) => (
          <FileItem
            key={file._id}
            file={file}
            onDelete={() => deleteRow(file._id)}
            documentNumber={index + 1}
            userId={loggedInUserId}
            sessionFileName={sessionDatax}
            arnombre={arnombre}
            indexText={indexText}
            
            textStyleColor={textStyleColor}
          />
        ))}
      </div>
    </Paper>
   
    </Box>
    {productoCliente !== "Query" && (
    
    <Paper elevation={3} style={{ ...widgetStyle, marginTop: '1rem', marginBottom: '1rem' }}>
            <Chatbot _id={_id} />

    </Paper>
  )}
        <>
      <Button variant="contained" onClick={toggleLibrary} sx={{ marginBottom: '10px' }}>
        {showLibrary ? 'Ocultar Biblioteca' : 'Ver Biblioteca'}
      </Button>
      {showLibrary && (
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[50],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            components={{ Toolbar: GridToolbar }}
            rows={data.map((row) => ({
              ...row,
              id: row._id,
            }))}
            columns={columns}
            onRowClick={handleRowClick} // Updated: Pass the click handler
            headerClassName="bold-header"
            rowsPerPageOptions={[10, 25, 50]} // Include 25 as an option
          />
        </Box>
      )}
    </>
<p></p>
 
  </Box>



);
};

export default Files;
