import { Box, Typography, useTheme, IconButton, Button, TextField, Slider } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../../theme";
import Upload from "@mui/icons-material/AddSharp";import ExcelJS from 'exceljs';
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import FlexBetween from "../../components/FlexBetween";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CreateMessage from "../../components/CreateMessage";
import StatBox from "../../components/StatBox";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StatBoxnom from "../../components/StatBoxnom";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import Paper from '@mui/material/Paper'; // Import the Paper component
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { setMessageToRead, updateMessages } from "../../state";
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system'; // Ensure this import is present
import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/EmailSharp';

const StyledDialogTitle = styled(DialogTitle)({
  position: 'relative',
  paddingRight: '2rem', // Adjust space for the icon
});

const CustomDialogTitle = styled(DialogTitle)({
  position: 'relative',
  paddingRight: '2rem', // Adjust space for the icon
});

const IconWrapper = styled('div')({
  position: 'absolute',
  top: '10px',
  right: '50px', // Adjusted for the Message icon
  color: '#000', // Set icon color to black (or any color you prefer)
});

const greenText = { color: 'green' }; // Define the CSS style for green text

const Users = ({ }) => {

  const [selectedRow, setSelectedRow] = useState(null);
  const [editedCell, setEditedCell] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

const location = useLocation();
const idcontrato = useSelector((state) => state.user ? state.user.idcontrato : null);
const [editedRows, setEditedRows] = useState({});
const [showCreateUser, setShowCreateUser] = useState(false);
const userProfile = useSelector((state) => state.user.userprofile);
const [user, setUser] = useState([]); // Updated: Initialize data state
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const loggedinuserprofile = useSelector((state) => state.user.userprofile);
  const loggedinusercontract = useSelector((state) => state.user ? state.user.idcontrato : null);
  const loggedinuserEmail = useSelector((state) => state.user.email);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dateRange, setDateRange] = useState([0, 100]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [totalcam, setTotalcam] = useState(0);
  const [dataUpdated, setDataUpdated] = useState(false);

  const getRowId = (row) => row._id;
  const getRowClassName = (params) => {
    // Check if the message is not read and apply the 'unread-row' class
    return !params.row.isRead ? 'unread-row' : '';
  };

  const handleDateFilterChange = (event, newValue) => {
  setDateRange(newValue);
};

const filteredData = data
.filter((row) => {
  // Apply the date filter based on the "createdAt" field (assuming it is a numeric value)
  const createdAt = parseInt(row.createdAt);
  return createdAt >= dateRange[0] && createdAt <= dateRange[1];
})
.filter((row) => {
  // Combine all column values into a single string
  const rowDataAsString = Object.values(row)
    .filter((value) => typeof value === 'string')
    .join(' ');

  // Apply the search filter to the concatenated string
  const searchInputLower = searchInput.toLowerCase();
  return rowDataAsString.toLowerCase().includes(searchInputLower);
});

useEffect(() => {
  setShowSuccessPopup(false);
  setSnackbarOpen(false);
  fetchData();
}, [dataUpdated, isFormSubmitted, searchInput, updateMessages]);




const fetchData = async () => {
  try {
    let endpoint;

    if (userProfile === "declare") {
      // Filter data based on the logged-in user's email
      endpoint = `${REACT_APP_API_ENDPOINT}message/mail/${loggedinuserEmail}`;
      
    } else if (userProfile === "superadmin") {
      endpoint = `${REACT_APP_API_ENDPOINT}message/`;
    } else if (userProfile === "admin" || userProfile === "coadmin") {
      endpoint = `${REACT_APP_API_ENDPOINT}message/contract/${loggedinusercontract}`;
    } else {
      // Handle other user profiles here
    }

    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const formData = await response.json();
    console.log("mensajes2x", formData);

    const dataWithFormattedDate = formData.map((row) => ({
      ...row,
      id: row._id || "sin cliente", // Use "cliente no encontrado" if _id is not available
      createdAt: row.createdAt
        ? new Date(row.createdAt).toLocaleDateString("en-EN", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })
        : "",
    }));

    // Filter the data based on the condition profile === loggedinuserprofile
    const filteredData  = dataWithFormattedDate;
    setUser(formData);
    setData(filteredData);
    const totalcam = filteredData.length;
    setTotalcam(totalcam);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


  

  
  const handleRowClick = (params, action) => {
    const rowId = params.row._id;

    if (action === "edit") {
      const { row, field } = params;
      const updatedRow = { ...row, [field]: params.value };
  
      // Update the local state immediately for a responsive user experience
      setData((prevData) => {
        const rowIndex = prevData.findIndex((r) => r.id === rowId);
        const newData = [...prevData];
        newData[rowIndex] = { ...newData[rowIndex], ...updatedRow };
        return newData;
      });
  
      // Save the changes to the server in the background
      saveRowChanges(rowId, updatedRow);
    } else if (action === "delete") {
      // Add code to delete the row from the database using the rowId
      deleteRow(rowId);
    }  else if (action === "display") {
      dispatch(setMessageToRead({ messageId: rowId, isRead: true }));

      // Handle the "display" action, e.g., show a dialog with the message details
      setSelectedRow(params.row); // Assuming there's a state variable for the selected row
      setIsDialogOpen(true); // Open the dialog
    }
  };
  
  useEffect(() => {
    setShowSuccessPopup(false);
    setSnackbarOpen(false);
    fetchData();
  }, [dataUpdated, isFormSubmitted, searchInput]);
  
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  
// Function to save the changes for a specific row
const saveRowChanges = async (rowId, updatedRow) => {
  try {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}message/${rowId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedRow),
    });

    if (response.ok) {
      // Update the data state with the updatedRow
      setData((prevData) => {
        // Find the index of the row to update
        const rowIndex = prevData.findIndex((row) => row.id === rowId);

        // Create a new array with the updatedRow replacing the old row
        const newData = [...prevData];
        newData[rowIndex] = { ...newData[rowIndex], ...updatedRow };

        return newData;
      });
    } else {
      console.error("Failed to update row:", response.status);
    }
  } catch (error) {
    console.error("Error saving row changes:", error);
  }
};

  

  const deleteRow = async (rowId) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}message/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the data and filteredData states after deleting the row
      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };

  

  const formatDateToEsES = (dateString) => {
    if (!dateString) return "";
  
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const esESDate = new Date(dateString).toLocaleDateString('es-ES');
  
    return esESDate;
  };
  
  const isSuperAdmin = userProfile === 'superadmin';


  const handleDownloadXLSX = async () => {
    if (data !== undefined && data !== null && data.length > 0) {
      try {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Mensajes');
  
        // Define the headers for your data (assuming the first object in data has the keys)
        const headers = Object.keys(data[0]);
  
        // Add the headers to the worksheet
        worksheet.addRow(headers);
  
        // Add the data rows to the worksheet
        data.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
  
        // Generate a blob from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Convert the buffer to a blob
        const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        // Download the XLSX file
        saveAs(xlsxBlob, 'Mensajes.xlsx');
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
      // Handle the case when data is empty or undefined
    }
  };

 
const isAdmin = userProfile === "admin";

const columns = [
  {
    field: "title",
    headerName: "Referencia",
    editable: isSuperAdmin,
    headerClassName: "bold-header",
    cellClassName: (params) =>
    editedCell === params.field ? "editing-cell" : "",
    width: 250,
  },
  {
    field: "message",
    headerClassName: "bold-header",
    headerName: "Mensaje",
    flex: 1,
    editable: isSuperAdmin,
    cellClassName: (params) =>
    editedCell === params.field ? "editing-cell" : "",
  },

  {
    field: "createdAt",
    headerClassName: "bold-header",
    headerName: "Enviado",
    width: 100,  },
  {
    field: "targetprofile",
    headerClassName: "bold-header",
    headerName: "Target Perfil",
    flex: 1,
    hide: !isSuperAdmin || !isAdmin, // Hide if user is not superadmin
    editable: isSuperAdmin,
    cellClassName: (params) =>
    editedCell === params.field ? "editing-cell" : "",
  },
  {
    field: "targetuser",
    headerClassName: "bold-header",
    headerName: "Target Usuario",
    flex: 1,
    hide: !isSuperAdmin || !isAdmin, // Hide if user is not superadmin
    editable: isSuperAdmin,
    cellClassName: (params) =>
    editedCell === params.field ? "editing-cell" : "",
  },
  {
    field: "estado",
    headerClassName: "bold-header",
    headerName: "estado",
    flex: 1,
    hide: !isSuperAdmin || !isAdmin,// Hide if user is not superadmin
    editable: isSuperAdmin || isAdmin,
  },
];

// Add the "Acción" column only if the user is superadmin
if (isSuperAdmin) {
  columns.push({
    field: "actions",
    headerClassName: "bold-header",
    headerName: "Action",
    width: 100,
    renderCell: (params) => (
      <>
        <IconButton color="primary" onClick={() => handleRowClick(params, "edit")}>
          <EditIcon />
        </IconButton>
        <IconButton
          color="secondary"
          onClick={() => handleRowClick(params, "delete")}
        >
          <DeleteIcon />
        </IconButton>
      </>
    ),
  });
}
  
  

  // Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

const formatDate = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ""; // Check if the date is valid

  const formattedDate = date.toLocaleDateString("en-EN", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit"
  });
  return formattedDate;
};

const updatedColumns = isSuperAdmin || isAdmin
  ? columns // If the user is a superadmin, keep all columns
  : columns.filter((column) => column.field !== "profile" && column.field !== "estado" && column.field !== "actions");


  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "60%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

    const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue); // Ensure this is updating correctly
  };
  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };
  return (


    <Box m="10px">

    <Grid container spacing={2}>
    <Grid item xs={12} sm={3} md={4}>
    
    <Box display="flex" justifyContent="space-between" alignItems="center">
       
      <Header title={<FormattedMessage id="Centro de Mensajes" />} subtitle={<FormattedMessage id="Mis Mensajes" />} />

      </Box>
     </Grid>


<Grid item xs={12} sm={8} md={1}>


</Grid>

  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "100%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>
 <Box>

    {/* HEADER */}
            {/* Conditionally render the "Nuevo Usuario" button */}
      {(loggedinuserprofile === 'superadmin' || loggedinuserprofile === 'admin') &&  (
        <div>
          <Button
          onClick={() => setShowCreateUser(prevState => !prevState)}

            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[900],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Upload sx={{ mr: "10px" }} />
           
           <FormattedMessage id="Nuevo Mensaje"/>

          </Button>
          {showCreateUser && <CreateMessage />}
          </div>
      )}
            </Box>

       <p></p>

     
       <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gap="20px">


       <Paper elevation={3} style={widgetStyle}>
  <Box display="flex" alignItems="center" gap="1rem">
    <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word', fontSize: "35px", textAlign: "center" }}>
    <span style={greenText}>{filteredData.length.toString()}</span> 
    </Typography>Mensajes
  </Box>
</Paper>


</Box>

<Dialog open={isDialogOpen} onClose={handleCloseDialog}>
<StyledDialogTitle>
<MessageIcon /> 

        <strong>{selectedRow?.title}</strong>
        <IconWrapper>
        </IconWrapper>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#000',
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>
        <p>{selectedRow?.message}</p>
        <p>{formatDateToEsES(selectedRow?.createdAt)}</p>
      </DialogContent>
      <DialogActions>
     
      </DialogActions>
    </Dialog>

      <Box
        m="40px 0 0 0"
        height="65vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[50],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
        
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        components={{ Toolbar: GridToolbar }}
        onCellDoubleClick={(params, event) => handleRowClick(params, "display")}
        rows={filteredData.map((row) => ({
          ...row,
          id: row._id,
        }))}
        columns={updatedColumns}
        onRowClick={handleRowClick}
        headerClassName="bold-header"
        rowsPerPageOptions={[5, 10]}
        getRowId={getRowId} // Set the getRowId prop
        getRowClassName={getRowClassName} // Set the getRowClassName prop
      />
    </Box>

<p>

</p>


<Box>

                <Button
                    onClick={handleDownloadXLSX}
                    sx={{ borderRadius: "20px",
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      overflowX: 'auto',// Make the container horizontally scrollable

                    }}
                  >
                    <p></p>
                    <DownloadOutlinedIcon sx={{ borderRadius: "20px", mr: "10px" }} />
                    {<FormattedMessage id="Descargar Reporte"/>}

              </Button>
              </Box>
              <p></p>
              

    </Box>
  );
};

export default Users;
