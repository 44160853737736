import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import DashDeclare from "./scenes/dashdecl";
import Risk from "./scenes/risk";
import MyRisks from "./scenes/myrisks";
import Team from "./scenes/team";
import Forms from "./scenes/forms";
import Campaigns from "./scenes/campaigns";
import Form from "./scenes/form";
import Users from "./scenes/usuarios";
import FAQ from "./scenes/faq";
import Notifications from "./scenes/dashboard";
import Allcamp from "./scenes/allcamp";
import Alldecl from "./scenes/alldecl";
import Allcomplaints from "./scenes/allcomplaints";
import Allttvalues from "./scenes/allttvalues";
import Allsessions from "./scenes/allsessions";
import Messages from "./scenes/messages";
import Allmeetings from "./scenes/allmeetings";
import Clients from "./scenes/clients";
import ResetPassword from "./scenes/reset-password";
import Complaints from "./scenes/complaints";
import Meetings from "./scenes/meetings";
import Ttvalues from "./scenes/ttvalues";
import Repository from "./scenes/repository";
import RepositoryBP from "./scenes/repositoryBP";

import Dd from "./scenes/dd";
import DdPostulante from "./scenes/ddPostulante";

import Company from "./scenes/company";
import LoginPage from "./scenes/loginPage";
import FormevPage from "./scenes/formevPage";
import ComplaintevPage from "./scenes/complaintevPage";
import TtvalueEvPage from "./scenes/ttvalueEvPage";
import MeetingsEvPage from "./scenes/meetingsEvPage";
import DdevPage from "./scenes/ddevPage";
import DdevPostulantePage from "./scenes/ddevPostulantePage";

import RiskevPage from "./scenes/riskevPage";
import RiskMitPage from "./scenes/riskMitPage";
import SuccessPage from "./scenes/successPage";
import Formepd from "./scenes/formepd";
import Myformepd from "./scenes/myformepd";
import Ddext from "./scenes/ddext";
import DdextPostulante from "./scenes/ddextPostulante";
import Contab from "./scenes/contab";


import CampaigndetailPage from "./scenes/campaigndetailPage";
import MyForms from "./scenes/myforms";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useSelector } from "react-redux";
import { IntlProvider, FormattedMessage } from 'react-intl';
import translations from './translations'; // import your translation files

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const isAuth = useSelector((state) => Boolean(state.token));
  const sidebar = document.querySelector('.sidebar');
  const mainContent = document.querySelector('.main-content');
  const userProfile = useSelector((state) => state.userProfile);
  const [locale, setLocale] = useState('es'); // state for current locale

  const handleLanguageChange = (selectedLocale) => {
    setLocale(selectedLocale);
  }
  const messages = translations[locale]; // get the translations for the locale

  return (
<IntlProvider locale={locale} messages={messages} onError={(error) => { /* Handle missing message... */ }}>
      <LanguageSelector onLanguageChange={handleLanguageChange} />

    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!isAuth && (
              <Routes>
              <Route path="/formepd" element={<Formepd />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="/ddext/campaign/:_idcam" element={<Ddext />} />
              <Route path="/ddextPostulante/campaign/:_idcam" element={<DdextPostulante />} />

              <Route path="/successPage" element={<SuccessPage />} />
              <Route path="/formepd/campaign/:_idcam" element={<Formepd />} />
              <Route path="/" element={<LoginPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>

          )}
          {isAuth && (
            <>
              <Sidebar isSidebar={isSidebar} />
             
              <main className="content">
              <Topbar
                    setIsSidebar={setIsSidebar}
                    onLanguageSelect={handleLanguageChange} // Correct prop name
                  />
             <Routes>
                  
                <Route path="/myformepd/campaign/:_idcam" element={<Myformepd />} />
                <Route path="/reset-password/" element={<ResetPassword />} />
                <Route path="/risk" element={<Risk />} />
                <Route path="/message" element={<Messages />} />
                <Route path="/formepd" element={<Formepd />} />
                <Route path="/successPage" element={<SuccessPage />} />
                <Route path="/formevPage/updateForm/:_id" element={<FormevPage />} />
                <Route path="/complaintev/:_id" element={<ComplaintevPage />} />
                <Route path="/complaintev/updateForm/:_id" element={<ComplaintevPage />} />
                <Route path="/ttvalueEvPage/:_id" element={<TtvalueEvPage />} />
                <Route path="/meetingsEvPage/:_id" element={<MeetingsEvPage />} />
                <Route path="/formev/:_id" element={<FormevPage/>} />
                <Route path="/campaigndetailPage/:campaignid" element={<CampaigndetailPage/>} />
                <Route path="/myforms" element={<MyForms />} />
                <Route path="/myrisks" element={<MyRisks />} />
                <Route path="/riskevPage/:_idrisk" element={<RiskevPage />} />
                <Route path="/riskmitPage/:_id" element={<RiskMitPage />} />
                <Route path="/complaints" element={<Complaints />} />
                <Route path="/meetings" element={<Meetings />} />
                <Route path="/ttvalues" element={<Ttvalues />} />
                <Route path="/ddevPage/:_id" element={<DdevPage />} />
                <Route path="/ddevPostulantePage/:_id" element={<DdevPostulantePage />} />
                <Route path="/contab/" element={<Contab />} />

                <Route path="/dd/" element={<Dd />} />
                <Route path="/ddPostulante/" element={<DdPostulante />} />

                <Route path="/company/" element={<Company />} />
                  <Route path="/form" element={<Form />} />
                  <Route path="/home" element={<Dashboard />} />
                  <Route path="/dashdeclare" element={<DashDeclare />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/repository" element={<Repository />} />
                  <Route path="/repositoryBP" element={<RepositoryBP />} />

                  <Route path="/allcamp" element={<Allcamp />} />
                  <Route path="/alldecl" element={<Alldecl />} />
                  <Route path="/allmeetings" element={<Allmeetings />} />
                  <Route path="/allcomplaints" element={<Allcomplaints />} />
                  <Route path="/allttvalues" element={<Allttvalues />} />
                  <Route path="/allsessions" element={<Allsessions />} />
                  <Route path="/campaigns" element={<Campaigns />} />
                  <Route path="/forms" element={<Forms />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="*" element={<Navigate to="/home" replace />} />
                </Routes>
              </main>
            </>
          )}
                    
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
    </IntlProvider>
  );
}

function LanguageSelector({ onLanguageChange }) {
  const handleLanguageSelect = (e) => {
    const selectedLocale = e.target.value;
    onLanguageChange(selectedLocale);
  };

  
}

export default App;
