//aqui esta el widget del form de Nueva camapaña que incluye enviar mails y guardar en posts separados por coma

import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MyPostWidget from "../components/MyPostWidget";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { tokens } from "../../src/theme";
import { FormHelperText } from '@mui/material'; // Import FormHelperText from Material-UI
import { FormattedMessage } from 'react-intl';

const countries = ['Chile',
'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia', 'Austria',
'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia',
'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon',
'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo (Congo-Brazzaville)', 'Costa Rica',
'Cote d\'Ivoire', 'Croatia', 'Cuba', 'Cyprus', 'Czechia (Czech Republic)', 'Democratic Republic of the Congo', 'Denmark', 'Djibouti',
'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini (fmr. "Swaziland")',
'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea',
'Guinea-Bissau', 'Guyana', 'Haiti', 'Holy See', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel',
'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho',
'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands',
'Mauritania', 'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar (formerly Burma)',
'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia (formerly Macedonia)',
'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine State', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar',
'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe',
'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa',
'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Tajikistan', 'Tanzania', 'Thailand',
'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates',
'United Kingdom', 'United States of America', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe',
];

const currentDate = new Date();
const endDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000); // Adding 30 days in milliseconds
const { REACT_APP_API_ENDPOINT } = process.env;


const CreateUser = ({ _id }) => {
  const location = useLocation();
  const theme = useTheme();
  const token = useSelector((state) => state.token);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const loggedInUserId = useSelector((state) => state.user._id);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const loggedIncontract = useSelector((state) => state.user.idcontrato);

  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const campaignSchema = yup.object().shape({
  firstName: yup.string().required("Se Requiere Nombres"), // Required
  lastName: yup.string().required("Se Requiere Apellidos"), // Required
  email: yup.string().required("Se Requiere email"), // Required
  location: yup.string().notRequired("Se Requiere Organización"), // Required
  userProfile: yup.string().notRequired("Se Requiere Perfil de Usuario"), // Required
  area: yup.string().notRequired("Se Requiere Área"), // Required
  occupation: yup.string().notRequired("Se Requiere Cargo"), // Required
  celular: yup.string().notRequired("Se Requiere Celular"), // Required
  nationality: yup.string().notRequired("Se Requiere Nacionalidad"), // Required

  rut: yup
  .string()
  .required("Se Requiere Rut") // Required
  .min(8, "Rut debe contener al menos 8 números") // Ensure at least one digit
});



    

  const initialValuesEditCampaign = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    picturePath: "",
    location: "",
    occupation: "",
    userprofile: "salesadded",
    idcontrato: loggedIncontract,
    campaignid: "",
    area: "",
    rut: "",
    celular: "",
    status: "Pendiente",
    nationality: "",
  
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      const password = values.rut.substring(0, 6); // Get the first 6 digits of the "rut" as the password
      values.password = password;
      const { REACT_APP_API_ENDPOINT } = process.env;
    
      const response = await fetch(`${REACT_APP_API_ENDPOINT}users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      });
      setIsFormSubmitted(true);

      if (response.ok) {
        // Show the success message
        setIsSuccessMessageVisible(true);
  
        // Hide the success message after 5 seconds
        const timeoutId = setTimeout(() => {
          setIsSuccessMessageVisible(false);
        },3000);
      
        // Cleanup the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);


      } else {
        // Handle non-successful response (e.g., display an error message)
        console.error(`PUT request failed with status ${response.status}`);
      }
      // Continue with the navigation logic
    } catch (error) {
      console.log(error);
      // Handle error
    }
    
};

if (isFormSubmitted) {
    // Return null or any other component you want to show after successful submission
    // You may also navigate to another page if needed.
    return null;
  }



  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesEditCampaign}
      validationSchema={campaignSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
            }}
          >



            <TextField
  label="Rut (Sin Guión)"
  onBlur={handleBlur}
  onChange={(event) => {
    // Use a regular expression to allow only numbers and no dots or characters
    const newValue = event.target.value.replace(/[^0-9K]/gi, '');
    handleChange({ target: { name: 'rut', value: newValue } });
  }}
  value={values.rut}
  name="rut"
  error={Boolean(touched.rut) && Boolean(errors.rut)}
  helperText={touched.rut && errors.rut}
  multiline
  fullWidth
  InputProps={{
    style: {
      background: 'white', // Set the background color to white
    },
  }}
/>


            <TextField
              label="Nombres"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              name="firstName"
              error={Boolean(touched.firstName) && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              multiline
              fullWidth
              InputProps={{
                style: {
                  background: 'white', // Set the background color to white
                },
              }}
            />

<TextField
              label="Apellidos"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              name="lastName"
              error={Boolean(touched.lastName) && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              multiline
              fullWidth
              InputProps={{
                style: {
                  background: 'white', // Set the background color to white
                },
              }}         />
                  
       

<TextField
              label="email"
              onBlur={handleBlur}
              onChange={(event) => {
                // Use a regular expression to remove any non-numeric characters
                const emailWithoutSpaces = event.target.value.replace(/\s/g, '');
                // Update the field value with the cleaned value
                handleChange({ target: { name: "email", value: emailWithoutSpaces } });
              }}
              value={values.email}
              name="email"
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              multiline
              fullWidth
              InputProps={{
                style: {
                  background: 'white', // Set the background color to white
                },
              }}
            />
             <TextField
              label="Organización"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.location}
              name="location"
              error={Boolean(touched.location) && Boolean(errors.location)}
              helperText={touched.location && errors.location}
              multiline
              fullWidth
              InputProps={{
                style: {
                  background: 'white', // Set the background color to white
                },
              }}      
             />
            <TextField
              label="Cargo"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.occupation}
              name="occupation"
              error={Boolean(touched.occupation) && Boolean(errors.occupation)}
              helperText={touched.occupation && errors.occupation}
              multiline
              fullWidth
              InputProps={{
                style: {
                  background: 'white', // Set the background color to white
                },
              }}            />
         
      


       

              <TextField
              label="area"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.area}
              name="area"
              error={Boolean(touched.area) && Boolean(errors.area)}
              helperText={touched.area && errors.area}
              multiline
              fullWidth
              InputProps={{
                style: {
                  background: 'white', // Set the background color to white
                },
              }}            />


<TextField
              label="Celular"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.celular}
              name="celular"
              error={Boolean(touched.celular) && Boolean(errors.celular)}
              helperText={touched.celular && errors.celular}
              multiline
              fullWidth
              InputProps={{
                style: {
                  background: 'white', // Set the background color to white
                },
              }}            />
         

         <FormControl fullWidth sx={{ borderRadius: '20px', gridColumn: 'span 4' }}>
         <InputLabel id="nationality-label">{<FormattedMessage id="Nacionalidad" />} </InputLabel>
    
    <Select
      labelId="nationality-label"
      sx={{ 
      backgroundColor: "white",
   
    }}
      id="nationality"
      name="nationality"
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.nationality}
      
      error={Boolean(touched.nationality) && Boolean(errors.nationality)}
    >
      {countries.map((country, index) => (
        <MenuItem key={index} value={country}>
          {country}
        </MenuItem>
      ))}
    </Select>
    {touched.nationality && errors.nationality && (
      <FormHelperText error>{errors.nationality}</FormHelperText>
    )}
  </FormControl>
  </Box>
        
          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              Guardar
            </Button>
          {/* Add a success message box */}

          </Box>
          {isSuccessMessageVisible && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: colors.greenAccent[400], // Set the background color for success message
            color: 'white', // Set text color to white
            padding: '1rem',
            borderRadius: '15px',
            textAlign: 'center',
            zIndex: 9999, // Set a high z-index to ensure it appears above other elements
          }}
        >
          ¡Cambios Guardados Exitosamente!
        </Box>
      )}
        </form>
        
      )}
    </Formik>
  );
};

export default CreateUser;
