import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper'; // Import the Paper component
import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  CircularProgress,
} from "@mui/material";import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import ChatWidget from '../../components/chatWidget';
import DropfileWidget from '../../components/DropfilesWidget';
import { tokens } from "../../theme";

import FlexBetween from "../../components/FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Formik } from "formik";
import * as yup from "yup";

import DialogWidget from '../../components/DialogWidget'; // Adjust the path based on your file structure

import ComplaintEvWidget from '../../components/ComplaintEvWidget';
import ComplaintInvWidget from '../../components/ComplaintInvWidget';
import ComplaintVeredictoWidget from '../../components/ComplaintVeredictoWidget';

import ComplaintDetailWidget from '../../components/ComplaintDetailWidget';
import Dropzone from "react-dropzone";




const ComplaintevPage = () => {
  const [form, setForm] = useState(null);
  const [user, setUser] = useState([]); // Initialize user as an empty array
  const [uploadedFiles, setUploadedFiles] = useState([]); // State for uploaded files
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [iframeVisible, setIframeVisible] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstUser, setFirstUser] = useState(null); // Define firstUser with an initial value of null

  const dispatch = useDispatch();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const { _id } = useParams();
  const loggedInUserId = useSelector((state) => state.user._id);
  const email = useSelector((state) => state.user.email);
  const userProfile = useSelector((state) => state.user.userprofile);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);

  const [dialogues, setDialogues] = useState([]); // Initialize dialogues as an empty array

  const getForm = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}`, {
        method: 'GET',
        headers: { 
          Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch form data');
      }

      const data = await response.json();
      setForm(data);
      setLoading(false);

      // Set the dialogues state
      const fetchedDialogues = data.dialogues || [];
      setDialogues(fetchedDialogues);

    } catch (error) {
      setError('An error occurred while fetching form data.');
      setLoading(false);
    }
  };

  const handlePresentarButtonClick = () => {
    setIframeVisible(true); // Show iframe when button is clicked
  };
  
  useEffect(() => {
    getForm();
  }, [token]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!form || Object.keys(form).length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography>No form data found.</Typography>
      </Box>
    );
  }

  const { estado } = form;

  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };

  return (
    <Box display="flex">
      <Box width="50%" padding="2rem 6%" display="flex" flexDirection="column" justifyContent="space-between">
        <Paper elevation={3} style={widgetStyle}>
        <Box>
  <Button
    onClick={() => window.open('https://tramitesjuridico.dt.gob.cl/login', '_blank')}
    sx={{
      borderRadius: "20px",
      backgroundColor: colors.blueAccent[700],
      color: colors.grey[900],
      fontSize: "14px",
      fontWeight: "bold",
      padding: "10px 20px",
      marginTop: "20px", // Add spacing at the top
    }}
  >
    Presentar Denuncia a Dirección del Trabajo
  </Button>
</Box>

          <ComplaintDetailWidget _id={_id} />
        </Paper>
      </Box>
      
      <Box width="50%" display="flex" flexDirection="column">
        <Paper elevation={3} style={widgetStyle}>

          {estado === "Por Evaluar" && (userProfile === "comitetica" || userProfile === "superadmin" || userProfile === "admin") ? (
            <ComplaintEvWidget _id={_id} />
          ) : estado === "En Investigacion" && (userProfile === "comitetica" || userProfile === "admin" || userProfile === "investigador" || userProfile === "superadmin") ? (
            <ComplaintInvWidget _id={_id} />
          ) : estado === "En Veredicto" && (userProfile === "comitetica" || userProfile === "superadmin" || userProfile === "admin") ? (
            <ComplaintVeredictoWidget _id={_id} />
          ) : null}
        </Paper>
        <Paper elevation={3} style={{ ...widgetStyle, marginTop: '1rem', marginBottom: '1rem' }}>
          <DropfileWidget _id={_id} />
        </Paper>
        <Paper elevation={3} style={{ ...widgetStyle, marginTop: '1rem', marginBottom: '1rem' }}>
          <ChatWidget _id={_id} />
        </Paper>

    




      </Box>
 
    </Box>
    
  );
  

    
};

export default ComplaintevPage;





