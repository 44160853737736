import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import FlexBetween from "./FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormattedMessage } from 'react-intl';
import RegSession from "./RegSession";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;


AWS.config.update({
  accessKeyId: REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
  region: "sa-east-1", // Set your AWS region
  });
  const s3 = new AWS.S3();


const currentDate = new Date();
const endDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000); // Adding 30 days in milliseconds
const { REACT_APP_API_ENDPOINT } = process.env;

const CreateMeeting = ({ _id }) => {

  const location = useLocation();
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [clients, setClients] = useState([]); // State to hold the list of clients
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [idcontrato, setIdcontrato] = useState(""); // Add this state
  const loggedinidcontrato = useSelector((state) => state.user.idcontrato);
  const userId = useSelector((state) => state.user._id);
  const userprofile = useSelector((state) => state.user.userprofile); 

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const [form, setForm] = useState({ picturePath: "" }); // Initialize as an empty object 
  const [uploadedFiles, setUploadedFiles] = useState([]);


  const campaignSchema = yup.object().shape({
    title: yup.string().required("El asunto es obligatorio"),
    start: yup
      .mixed()
      .required("La fecha es obligatoria"),
    willPayReceiveValue: yup.string().notRequired(""),
    participantes: yup.string().required("Los participantes son obligatorios"),
    empresa: yup.string().required("La organización es obligatoria"), // Make 'empresa' mandatory
    area: yup.string().required("El área es obligatoria"), // Make 'area' mandatory
    direccion: yup.string().required("La dirección es obligatoria"), // Make 'direccion' mandatory
    rutfunc: yup.string().notRequired(""),
    cargo: yup.string().notRequired(""),
    otrosasistentes: yup.string().notRequired(""),
    numeroacta: yup.string().notRequired(""),
    namepub: yup.string().notRequired(""),
    pictures1: yup.array().notRequired(), // Make pictures1 optional

  });
  
  

  const initialValuesEditCampaign = {

    userId: userId,
    idcontrato: loggedinidcontrato,
    title: "",
    start: "",
    willPayReceiveValue: "",
    participantes: "",
    empresa: "",
    area: "",
    direccion: "",
    rutfunc: "",
    cargo: "",
    otrosasistentes: "",
    numeroacta: "",
    namepub: "",
    pictures1: [],

  };
  

  const handleFormSubmit = async (values, onSubmitProps) => {

    console.log("values", values);
    
    const uploadToS3 = async (files) => {
      const bucketName = 'repoeticpro';
      const uniqueSuffix = Date.now() + "-";
      const s3Urls = [];
  
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        region: "sa-east-1",
      });
  
      const s3 = new AWS.S3();
  
      for (const file of files) {
        const key = uniqueSuffix + file.name;
        const params = {
          Bucket: bucketName,
          Key: key,
          Body: file,
        };
  
        try {
          await s3.upload(params).promise();
          console.log(`File uploaded to S3: ${key}`);
          s3Urls.push(key);
        } catch (error) {
          console.error(`Error uploading file to S3: ${error.message}`);
          throw error;
        }
      }
  
      return s3Urls;
    };
  
    try {
     // Append form values to FormData

      if (uploadedFiles.length > 0) {
        
        const s3Urls1 = await uploadToS3(uploadedFiles);
      }

      const filesToUpload = uploadedFiles.length > 0 ? uploadedFiles : [];
// Append to form values
const updatedValues = {
  ...values,
  pictures1: filesToUpload,
};

      // Example of saving user data using fetch
      const userResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}meetings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedValues),
      });
  
      const responseData = await userResponse.json();
      console.log("Response from API:", responseData);
  
      if (userResponse.ok) {
        console.log("User data stored successfully");
        onSubmitProps.resetForm();
        navigate("/successPage", {
          state: { message: "Reunión creada con éxito" }
        });
      } else {
        console.error("Error storing user data:", userResponse.status, userResponse.statusText);
      }
    } catch (err) {
      console.log("Error creating complaint:", err);
  
      if (err.response) {
        console.log("Response status:", err.response.status);
        err.response.text().then(responseText => {
          console.log("Response data:", responseText);
        });
      }
    }
  
    const idcontrato = loggedinidcontrato;
    RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "PEP" });
  };
  
  
  

  return (

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
   {/* Date picker */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
            <div style={{ marginBottom: '10px' }}>Fecha de la Reunión :    </div>

  <DatePicker
    selected={values.start}
    onChange={(date) => setFieldValue('start', date)}
    onBlur={handleBlur}
    name="start"
    className="form-control"
    placeholderText="Seleccione Fecha"
    style={{ fontSize: '16px', width: '100%', borderRadius: '20px', backgroundColor: 'white' }}
  />


            {/* Error message for date picker */}
            {touched.start && errors.start && (
              <Typography variant="caption" color="error">
                {errors.start}
              </Typography>
            )}
 <p></p>
 </Box>
 
<Box>

             



<p></p>




<TextField
            label={<FormattedMessage id="Organismo/Organización/Institución/Comunidad con quien se hará la reunión"/>}

            onBlur={handleBlur}
            onChange={handleChange}
            value={values.empresa}
            name="empresa"
            error={Boolean(touched.empresa) && Boolean(errors.empresa)}
            helperText={touched.empresa && errors.empresa}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />

<TextField
            label={<FormattedMessage id="Area funcional con quien se hará la reunión"/>}
            
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.area}
            name="area"
            error={Boolean(touched.area) && Boolean(errors.area)}
            helperText={touched.area && errors.area}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />

<TextField
            label={<FormattedMessage id="Cargo de la Persona de interés"/>}

            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargo}
            name="cargo"
            error={Boolean(touched.cargo) && Boolean(errors.cargo)}
            helperText={touched.cargo && errors.cargo}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />

<TextField
            label={<FormattedMessage id="Nombre de la Persona de interés"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.namepub}
            name="namepub"
            error={Boolean(touched.namepub) && Boolean(errors.namepub)}
            helperText={touched.namepub && errors.namepub}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />




<TextField
            label={<FormattedMessage id="Rut en caso de ser fiscalizador"/>}
            onBlur={handleBlur}
            onChange={(event) => {
              // Use a regular expression to allow only numbers and no dots or characters
              const newValue = event.target.value.replace(/[^0-9K]/gi, '');
              handleChange({ target: { name: 'rutfunc', value: newValue } });
            }}
            value={values.rutfunc}
            name="rutfunc"
            error={Boolean(touched.rutfunc) && Boolean(errors.rutfunc)}
            helperText={touched.rutfunc && errors.rutfunc}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />

<TextField
            label={<FormattedMessage id="Número de participantes en la reunión"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.participantes}
            name="participantes"
            error={Boolean(touched.participantes) && Boolean(errors.participantes)}
            helperText={touched.participantes && errors.participantes}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />


<TextField
            label={<FormattedMessage id="Nombres de otros participantes a la reunión"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.otrosasistentes}
            name="otrosasistentes"
            error={Boolean(touched.otrosasistentes) && Boolean(errors.otrosasistentes)}
            helperText={touched.otrosasistentes && errors.otrosasistentes}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />


<TextField
            label={<FormattedMessage id="Lugar, Dirección o medio"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.direccion}
            name="direccion"
            error={Boolean(touched.direccion) && Boolean(errors.direccion)}
            helperText={touched.direccion && errors.direccion}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />
    <p></p>



<TextField
            label={<FormattedMessage id="Proposito de la Reunión"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.title}
            name="title"
            error={Boolean(touched.title) && Boolean(errors.title)}
            helperText={touched.title && errors.title}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />

<TextField
            label={<FormattedMessage id="Si hay invitación o regalo u otra transferencia de valor indicar valor"/>}

            onBlur={handleBlur}
            onChange={handleChange}
            value={values.willPayReceiveValue}
            name="willPayReceiveValue"
            error={Boolean(touched.willPayReceiveValue) && Boolean(errors.willPayReceiveValue)}
            helperText={touched.willPayReceiveValue && errors.willPayReceiveValue}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />


<TextField
            label={<FormattedMessage id="Acuerdos realizados o N° Acta Reunión"/>}

            onBlur={handleBlur}
            onChange={handleChange}
            value={values.numeroacta}
            name="numeroacta"
            error={Boolean(touched.numeroacta) && Boolean(errors.numeroacta)}
            helperText={touched.numeroacta && errors.numeroacta}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />

  <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
               <Dropzone
               multiple={false}
               onDrop={(acceptedFiles) => {
                 setUploadedFiles(acceptedFiles); // Update the uploadedFiles state here
                 setFieldValue("pictures1", acceptedFiles); // Update Formik's field value as well, if needed
               }}
             >
               {({ getRootProps, getInputProps }) => (
                 <Box
                   {...getRootProps()}
                   border={`2px dashed ${palette.primary.main}`}
                   p="1rem"
                   sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                 >
                   <input {...getInputProps()} />
                   {!values.pictures1 || values.pictures1.length === 0 ? ( // Check if "pictures" is empty or not
                     <p>Adjunte Acta Aquí si corresponde. (5Mb Máximo) </p>
                   ) : (
                     <div>
                       {values.pictures1.map((file, index) => (
                         <FlexBetween key={index}>
                           <Typography>{file.name}</Typography>
                           <EditOutlinedIcon />
                         </FlexBetween>
                       ))}
                     </div>
                   )}
                  </Box>
                )}
              </Dropzone>




                </Box>
        
        </Box>


        {/* BUTTONS */}
        <Box>
        <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              {<FormattedMessage id="Enviar"/>}
            </Button>
            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
                            {<FormattedMessage id="Envío exitoso"/>}

               
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateMeeting;